import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '../../services/config.service';
import { CommunicationDetailsPayload, CommunicationDetailsResponse, CommunicationModeCountResponse } from './interfaces/communication.modal';


@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  effectGetCommunicationDetails(obj: CommunicationDetailsPayload): Observable<CommunicationDetailsResponse>{
    let queryUrl = `${this.configService.appConfig.appBaseUrl}notificationLogs/all/?skip=${obj?.skip ?? 0}&limit=${obj?.limit ?? 20}`;
     if (obj?.type) {
        queryUrl = `${queryUrl}&type=${obj?.type}`;
      }
     if (obj?.pagination) {
        queryUrl = `${queryUrl}&pagination=${obj?.pagination}`;
      }
      if (obj?.startDate) {
        queryUrl = `${queryUrl}&startDate=${obj?.startDate.valueOf()}`;
      }
      if (obj?.endDate) {
        queryUrl = `${queryUrl}&endDate=${obj?.endDate.valueOf()}`;
      }
      if(obj?.textSearch){
        queryUrl = `${queryUrl}&searchText=${obj?.textSearch}`;
      } 
      if(obj?.sortByFieldsStr){
        queryUrl = `${queryUrl}&sortByFieldsStr=${obj?.sortByFieldsStr}`;
      }
         return this.http.get<CommunicationDetailsResponse>(queryUrl);
  }

  effectGetCommunicationDetailsCountByType(obj: CommunicationDetailsPayload): Observable<CommunicationModeCountResponse>{
    let queryUrl = `${this.configService.appConfig.appBaseUrl}notificationLogs/countByType/?skip=${obj?.skip ?? 0}&limit=${obj?.limit ?? 20}`;
     if (obj?.type) {
        queryUrl = `${queryUrl}&type=${obj?.type}`;
      }
     if (obj?.pagination) {
        queryUrl = `${queryUrl}&pagination=${obj?.pagination}`;
      }
      if (obj?.startDate) {
        queryUrl = `${queryUrl}&startDate=${obj?.startDate.valueOf()}`;
      }
      if (obj?.endDate) {
        queryUrl = `${queryUrl}&endDate=${obj?.endDate.valueOf()}`;
      }
      if(obj?.textSearch){
        queryUrl = `${queryUrl}&searchText=${obj?.textSearch}`;
      } 
      if(obj?.sortByFieldsStr){
        queryUrl = `${queryUrl}&sortByFieldsStr=${obj?.sortByFieldsStr}`;
      }
         return this.http.get<CommunicationModeCountResponse>(queryUrl);
  }

}