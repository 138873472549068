import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Field, OrderSettings , Processor, Section, Slot} from '../Settings/interfaces/orderSettings.interface'

import { ConfigService } from '../../services/config.service';
import { IAssignmentWindowApiResonse, IOBDSettings, IRoutePlanSettings } from '../../app/delivery-managenment-system/services/common.service';
import { IConsignmentAssignForm, IProgressSettings, IQueueSetting, IReportSetting, IStatusesAllowed } from './interfaces/lmdMmdSettings.interface';
import { IMMDFormMetaData, ILMDFormMetaData } from './interfaces/mmd-formSettings.interface';
import { IDMSAllSettingsWithModule, IDMSMetaDataSettings, IGeofenceSetting, IGroupsOfDMSSettingsWithModule } from './interfaces/Interface-processor';
import { RoutePlanCategory } from '../../app/delivery-managenment-system/middle-mile-delivery/interfaces/mmd-interfaces';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

    effectOrderTableSettings(): Observable<OrderSettings> {
        let url = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/order`;
         return this.http.get<OrderSettings>(url);
    }
    putFormSectionSetting(payload: Section[]){
      let url = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/order/form`;
       return this.http.put<unknown>(url, {section:payload});
    }
    putTableFieldSetting(payload: Field[]){
      let url = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/order/table`;
       return this.http.put<unknown>(url, {fields:payload});
    }
    putSlotSetting(payload: Slot[]){
      let url = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/order/slots`;
       return this.http.put<unknown>(url, payload);
    }

    getAllStatusOptions(moduleName?){
      let queryUrl =  (this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/delivery/status?type=delivery');
      if(moduleName){
          queryUrl = queryUrl + "&moduleType="+ moduleName
      }
      return this.http.get(queryUrl)
    }

     getNotificationFormValue(moduleType, notificationType='notifyRecipient'){
      let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings'
      if(moduleType){
          url = url + '?pReq='+moduleType +"."+notificationType
      }
      return this.http.get(url)
    }

    setNotificationFormValues(moduleType, data){
      let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/notifyRecipient/moduleType/'+moduleType
      return this.http.put(url, data)
    }

    getReportSetting(moduleType = 'lmd') {
      let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings';
      if(moduleType){
          url = url + '?pReq='+moduleType+'.reports'
      }
      return this.http.get<IReportSetting>(url);
    }
    setReportSettings(moduleType , data){
      let url = this.configService.appConfig.appBaseUrl +'deliveryManagement/settings/reports/moduleType/'
      if(moduleType){
        url = url + moduleType
      }
      return this.http.put(url, data)
   }
   
   updateDmsQueueSettingsByModuleType(moduleType, payLoad){

    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/queue/moduleType/'
    if(moduleType){
        url = url + moduleType
    }
    return this.http.put<IQueueSetting>(url, payLoad)
   }

   getRoundTripSettingsByModuleType(moduleType: string){

    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/roundTrip/moduleType/';
    if (moduleType) {
      url = url + moduleType;
    }
    return this.http.get<IRoutePlanSettings>(url);
  }

  setRoundTripSettings(moduleType: string, payload: IRoutePlanSettings) {
    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/roundTrip/moduleType/';
    if (moduleType) {
      url = url + moduleType;
    }
    return this.http.put<IRoutePlanSettings>(url, payload);
  }
  
  getObdSettingsByModuleType(){
    let url =  this.configService.appConfig.appBaseUrl +  `account/trackerModule/settings?pReq=obd`
    return this.http.get<IOBDSettings>(url);
  }

  setObdSettings(payload:IOBDSettings){
    let url =  this.configService.appConfig.appBaseUrl +  `account/trackerModule/settings/obd`
    return this.http.put<IOBDSettings>(url,payload);    
  }
  
  getAssignmentWindownSettings(moduleType: string){
    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/assignmentWindow/moduleType/' + moduleType
    return this.http.get<IAssignmentWindowApiResonse>(url)
  }


  //   settings service
  putAssignmentWindownSettings(moduleType, payload): Observable<IAssignmentWindowApiResonse> {
    let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/settings/assignmentWindow/moduleType/' + moduleType
    return this.http.put<IAssignmentWindowApiResonse>(url, payload)
  }
  getAllProcessors(): Observable<IProcessorList[]> {
    let url = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/processors/all`
    return this.http.get<IProcessorList[]>(url)
  }

  putProcessorSettings(payLoad: Processor) { 
    let url = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/processor` 
    return this.http.put(url, payLoad)
  }

  getFormSettingsByModuleType(module: string){
    const url = `${this.configService.appConfig.appBaseUrl}deliveryManagement/settings/form/moduleType/` + module
    return this.http.get<IConsignmentAssignForm>(url)
  }
  
  putFormSettingsByModuleType(module: string,payload:IConsignmentAssignForm){
    const url = `${this.configService.appConfig.appBaseUrl}deliveryManagement/settings/form/moduleType/` + module
    return this.http.put<IConsignmentAssignForm>(url,payload)
  }

  getFormMetadataForMMD(): Observable<IMMDFormMetaData> {
    let url = `${this.configService.appConfig.appBaseUrl}account/settings/metaData/mmd`
    return this.http.get<IMMDFormMetaData>(url)
  }

  getFormMetaDataForLMD(){
    let url = `${this.configService.appConfig.appBaseUrl}account/settings/metaData/lmd`
    return this.http.get<ILMDFormMetaData>(url)
  }

  getProgressSettingsByModule(moduleType:string){
    let url = `${this.configService.appConfig.appBaseUrl}deliveryManagement/settings/progress/moduleType/${moduleType}`
    return this.http.get<IProgressSettings>(url)
  }

  setProgressSettingsByModule(payload:IProgressSettings,moduleType:string){
    let url = `${this.configService.appConfig.appBaseUrl}deliveryManagement/settings/progress/moduleType/${moduleType}`
    return this.http.put<IProgressSettings>(url,payload)
  }

  setGeofenceSettingsByAsset(payload:IGeofenceSetting,module,typeId){
    let url = `${this.configService.appConfig.appBaseUrl}settings/module/${module}/typeId/${typeId}`
    return this.http.put<IGeofenceSetting>(url,payload)
  }
  getGeofenceSettingsByAsset(id:string){
    let url = `${this.configService.appConfig.appBaseUrl}settings/all/query/typeId:geoFence,accountId:${id}?isMultiple=false`
    return this.http.get<IGeofenceSetting>(url)
  }


  getDMSMetadataSettings(moduleType:string,groupType:string){
    let url = `${this.configService.appConfig.appBaseUrl}account/settings/metaData/`+moduleType+`?groupType=`+groupType
    return this.http.get<IDMSMetaDataSettings>(url)
  }
  getDMSMetadataDynamicValues(urlRes:string,method:string){
    let url = this.configService.appConfig.appBaseUrl + urlRes
    if(method==="get"){
      return this.http.get(url)
    }
  }

  getAllDMSSettingsWithModule(moduleType:string){
    let url = `${this.configService.appConfig.appBaseUrl}deliveryManagement/settings?pReq=${moduleType}`
    return this.http.get<IDMSAllSettingsWithModule>(url)
  }
  putGenericSettingsWithModuleTypeAndPath(moduleType,path,payload){
    let url = `${this.configService.appConfig.appBaseUrl}deliveryManagement/settings/moduleType/${moduleType}/path/${path}`
    return this.http.put(url,payload)
  }
  getDMSMetaDataByGroups(module:string){
    let url = this.configService.appConfig.appBaseUrl + `settings/module/${module}/types`
    return this.http.get<IGroupsOfDMSSettingsWithModule>(url)
  }



  putGnericSettingsForSelectedModule(payload:unknown,typeId:string,module:string){
    // let url = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/order/${typeId}?moduleType=${module}` 
    let url = `${this.configService.appConfig.appBaseUrl}account/settings/moduleType/${module}/path/${typeId}` 
    return this.http.put(url, payload)
  }



  getOrderAllSettingsforSelectedModule(module:string){
    // let url = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/order?moduleType=${module}` 
    let url = `${this.configService.appConfig.appBaseUrl}account/settings/moduleType/${module}`
    return this.http.get<IDMSAllSettingsWithModule>(url)
  }



  effectGetStatusesAllowedByModule(module:string){
    const url = this.configService.appConfig.appBaseUrl + `deliveryManagement/management/delivery/statusesAllowed?type=delivery&moduleType=${module}`
    return this.http.get<IStatusesAllowed[]>(url)
  }
  
  // get route plan category list 
  effectGetRoutePlanCategory() {
    let url = this.configService.appConfig.appBaseUrl + 'settings/account/all/query/typeId:routePlanCategories,module:mmd?isMultiple=false';
   
    return this.http.get<RoutePlanCategory>(url);
  }
  

}


export interface ISection {
  id: string
  label: string
  order: number
  enabled: boolean
}

export interface IProcessorList {
  name: string,
  label: string
}