import { createReducer, on } from "@ngrx/store";
import { SuperAdminSettingsActions } from ".";
import { Role,Account, User, UserProfile, paginationRecords, IKioskThemeType, IAbility, ICategoryIntegration, IRootTypeWithCategory } from "./interfaces/superadminsettings.interface";
import { IAllModules } from "../../app/settings/services/account-management.service";
export interface SuperAdminSettingsState {
  allRoles:{[key:string]:Role[]}
  allRolesCount:number
  focusedRole:Role
  CRUDStatusMsg:string

  allAccounts:{[key:string]:Account[]}
  allAccountsCount:number
  focusedAccount:Account

  allUsers:{[key:string]:User[]}
  allUsersCount:number
  allUsersCountMain:number
  focusedUserProfile:UserProfile

  pageVisitsRecord:paginationRecords
  kioskThemesTypes:IKioskThemeType
  iskioskThemeUpdated: boolean
  isThemeUpdateLoading:boolean
  loaderForAbility:boolean
  allAbilityCount:number
  allAbilityTogether:IAbility[]
  allModules:IAllModules,
  allCategories:ICategoryIntegration[]
  typeRealtedToCategory:IRootTypeWithCategory
  loaderForPlugin:boolean
}

export const initialState: SuperAdminSettingsState = {
      allRoles:{},
      allRolesCount:null,
      focusedRole:null,
      CRUDStatusMsg:null,

      allAccounts:{},
      allAccountsCount:null,
      focusedAccount:null,

      allUsers:{},
      allUsersCount:null,
      focusedUserProfile:null,
      allUsersCountMain:null,

      pageVisitsRecord:{},
      kioskThemesTypes:{
        light: null,
        dark: null
      },
      iskioskThemeUpdated: false,
      isThemeUpdateLoading:false,
      loaderForAbility:false,
      allAbilityCount:null,
      allAbilityTogether:null,
      allModules:null,
      allCategories:null,
      typeRealtedToCategory:null,
      loaderForPlugin:false
}

export const SuperAdminSettingsReducer = createReducer(
    initialState,
   
  on(SuperAdminSettingsActions.onRolesDataByPageSuccuss, (state,{roles,page}) => {
    return {
        ...state,
        allRoles:{...state.allRoles,[page]:[...roles]}
    }
}),


on(SuperAdminSettingsActions.clearRolesData, (state) => {
  return {
      ...state,
      allRoles:{}
  }
}),

on(SuperAdminSettingsActions.onRolesCountSuccuss, (state,{count}) => {
  return {
      ...state,
      allRolesCount:count
  }
}),


on(SuperAdminSettingsActions.onIndividualRoleSuccuss, (state,{role}) => {
  return {
      ...state,
      focusedRole:role
  }
}),


on(SuperAdminSettingsActions.clearFocusedRole, (state) => {
  return {
      ...state,
      focusedRole:null
  }
}),

on(SuperAdminSettingsActions.onNewRoleSavedSuccuss, (state,{status,currentLimit}) => {

let allRoles = state.allRoles;
const lastIdx=Math.ceil(state.allRolesCount/currentLimit)-1
const newIdx=state.allRolesCount/currentLimit

if(!(state.allRolesCount%currentLimit)){
  //adding New Page
  allRoles={...allRoles,  [newIdx] : [status.data]}
}else{
  //Updating Last Page
  const previosRoles=allRoles[lastIdx] || []
  allRoles={...allRoles,  [lastIdx] : [...previosRoles,status.data]}
}

  return {
      ...state,
      CRUDStatusMsg:status.message,
      allRoles
  }
}),




on(SuperAdminSettingsActions.clearRoleCRUDStatusMsg, (state) => {
  return {
      ...state,
      CRUDStatusMsg:null
  }
}),

on(SuperAdminSettingsActions.onRoleUpdationSuccuss, (state,{status}) => {


  const updatedRole = status.data;
  let updationStatus = false;
  let allRoles = { ...state.allRoles };
  for (let key in allRoles) {
    let rolesByPage: Role[] = allRoles[key].map((role) => {
      if (updatedRole._id === role._id) {
        updationStatus = true;
        return { ...updatedRole };
      }
      return { ...role };
    });

    allRoles = { ...state.allRoles,[key]: [...rolesByPage]};
    if (updationStatus) {
      break;
    }
  }

  
    return {
        ...state,
        CRUDStatusMsg:status.message,
        focusedRole:status.data,
        allRoles
    }
  }),
  
  

on(SuperAdminSettingsActions.onRoleDeletionSuccuss, (state,{status,roleId}) => {

  let allRolesCopy={...state.allRoles}
  let deletionStatus = false;
  for (let key in state.allRoles){
    const rolesData=state.allRoles[key].filter(role =>{
      if (role._id !== roleId) {
        return true;
      }
      deletionStatus = true;
      return false;
    })
    allRolesCopy={...allRolesCopy,[key]:rolesData}

    if (deletionStatus) {
      break;
    }
  }

    return {
        ...state,
        CRUDStatusMsg:status.message,
        allRoles:allRolesCopy
    }
  }),
  
  
 on(SuperAdminSettingsActions.onRoleDeletionFailure,
    SuperAdminSettingsActions.onRolesDataByPageFailure,
    SuperAdminSettingsActions.onRolesCountFailure,
    SuperAdminSettingsActions.onIndividualRoleFailure,
    SuperAdminSettingsActions.onNewRoleSaveFailure,
    SuperAdminSettingsActions.onRoleUpdationFailure,
    SuperAdminSettingsActions.saveAbilityError,

     (state,{err}) => {
    return {
        ...state,
        error:err,
        loaderForAbility:false
    }
  }),


  on(SuperAdminSettingsActions.onAccountsDataByPageSuccuss, (state,{accounts,page}) => {
    return {
        ...state,
        allAccounts:{...state.allAccounts,[page]:[...accounts]}
    }
}),


on(SuperAdminSettingsActions.clearAccountsData, (state) => {
  return {
      ...state,
      allAccounts:{}
  }
}),

on(SuperAdminSettingsActions.onAccountsCountSuccuss, (state,{count}) => {
  return {
      ...state,
      allAccountsCount:count
  }
}),

on(SuperAdminSettingsActions.onIndividualAccountSuccuss, (state,{account}) => {
  return {
      ...state,
      focusedAccount:account
  }
}),


on(SuperAdminSettingsActions.clearFocusedAccount, (state) => {
  return {
      ...state,
      focusedAccount:null
  }
}),


on(SuperAdminSettingsActions.onNewAccountSavedSuccuss, (state,{account,currentLimit}) => {

  let allAccounts = state.allAccounts;
  const lastIdx=Math.ceil(state.allAccountsCount/currentLimit)-1
  const newIdx=state.allAccountsCount/currentLimit
  
  if(!(state.allAccountsCount%currentLimit)){
    //adding New Page
    allAccounts={...allAccounts,  [newIdx] : [account]}
  }else{
    //Updating Last Page
    const previosAccounts=allAccounts[lastIdx] || []
    allAccounts={...allAccounts,  [lastIdx] : [...previosAccounts,account]}
  }
  
    return {
        ...state,
        allAccounts,
        focusedAccount:account
    }
  }),



on(SuperAdminSettingsActions.onAccountUpdationSuccuss, (state,{account}) => {

  const updatedAccount= account;
  let updationStatus = false;
  let allAccounts = { ...state.allAccounts };
  for (let key in allAccounts) {
    let accountsByPage: Account[] = allAccounts[key].map((account) => {
      if (updatedAccount._id === account._id) {
        updationStatus = true;
        return { ...updatedAccount };
      }
      return { ...account };
    });

    allAccounts = { ...state.allAccounts,[key]: [...accountsByPage]};
    if (updationStatus) {
      break;
    }
  }

  
    return {
        ...state,
        focusedAccount:{...account},
        allAccounts
    }
  }),


  on(SuperAdminSettingsActions.onAccountDeletionSuccuss, (state,{status,accountId}) => {
    let allAccountsCopy={...state.allAccounts}
    for (let key in state.allAccounts){
      const accountsData=state.allAccounts[key].filter(account =>account._id!==accountId)
      allAccountsCopy={...allAccountsCopy,[key]:accountsData}
    }
      return {
          ...state,
          CRUDStatusMsg:status?.msg,
          allAccounts:allAccountsCopy
      }
    }),



  on(SuperAdminSettingsActions.onAccountsDataByPageFailure,
    SuperAdminSettingsActions.onAccountsCountFailure,
    SuperAdminSettingsActions.onIndividualAccountFailure,
    SuperAdminSettingsActions.onNewAccountSaveFailure,
    SuperAdminSettingsActions.onAccountUpdationFailure,

     (state,{err}) => {
    return {
        ...state,
        error:err
    }
  }),


  on(SuperAdminSettingsActions.onUsersCountSuccuss, (state,{count}) => {
    return {
        ...state,
        allUsersCount:count,
        allUsersCountMain:count
    }
  }),

  on(SuperAdminSettingsActions.onUsersDataByPageSuccuss, (state,{users,page}) => {
    return {
        ...state,
        allUsers:{...state.allUsers,[page]:[...users]}
    }
}),


on(SuperAdminSettingsActions.clearUsersData, (state) => {
  return {
      ...state,
      allUsers:{}
  }
}),


on(SuperAdminSettingsActions.onIndividualUserSuccuss, (state,{user}) => {
  return {
      ...state,
      focusedUserProfile:user
  }
}),


on(SuperAdminSettingsActions.clearFocusedUser, (state) => {
  return {
      ...state,
      focusedUserProfile:null
  }
}),



on(SuperAdminSettingsActions.onNewUserSavedSuccuss, (state,{status,currentLimit}) => {
  let allUsers = state.allUsers;
  const lastIdx=Math.ceil(state.allUsersCountMain/currentLimit)-1
  const newIdx=state.allUsersCountMain/currentLimit
  
  if(!(state.allUsersCountMain%currentLimit)){
    //adding New Page
    allUsers={...allUsers,  [newIdx] : [status.obj]}
  }else{
    //Updating Last Page
    const previosUsers=allUsers[lastIdx] || []
    allUsers={...allUsers,  [lastIdx] : [...previosUsers,status.obj]}
  }
  
    return {
        ...state,
        CRUDStatusMsg:status.msg,
        allUsers
    }
  }),



on(SuperAdminSettingsActions.onUserUpdationSuccuss, (state,{user}) => {
  const userDetails=user
  let updationStatus = false;
  let allUsers:any = { ...state.allUsers };
  for (let key in allUsers) {
    let usersByPage = allUsers[key].map((user) => {
      if (userDetails._id === user._id) {
        updationStatus = true;
        return { ...userDetails };
      }
      return { ...user };
    });

    allUsers = { ...state.allUsers,[key]: [...usersByPage]};
    if (updationStatus) {
      break;
    }
  }


    return {
        ...state,
        focusedUserProfile:{...userDetails},
        allUsers
    }
  }),

  on(SuperAdminSettingsActions.onUserDeletionSuccuss, (state,{status,userId}) => {

    let allUsersCopy = { ...state.allUsers };
    let deletionStatus = false;
    for (let key in state.allUsers) {
      const usersData = state.allUsers[key].filter((user) => {
        if (user._id !== userId) {
          return true;
        }
        deletionStatus = true;
        return false;
      });
      allUsersCopy = { ...allUsersCopy, [key]: usersData };
      if (deletionStatus) {
        break;
      }
    }                                 
      return {
          ...state,
          CRUDStatusMsg:status?.msg,
          allUsers:allUsersCopy
      }
    }),


  on(SuperAdminSettingsActions.resetUsersCount, (state) => {
    return {
        ...state,
        allUsersCount:null
    }
  }),




  on(SuperAdminSettingsActions.updatePaginationRecords, (state, {page,tabName}) => {

    const previousPages=state.pageVisitsRecord[tabName] || []

    return {
        ...state,
        pageVisitsRecord:{...state.pageVisitsRecord,[tabName]:[...previousPages,page]}
    }
  }),

  on(SuperAdminSettingsActions.resetPaginationRecords, (state, {tabName}) => {

    return {
        ...state,
        pageVisitsRecord:{...state.pageVisitsRecord,[tabName]:[]}
    }
  }),

  on(SuperAdminSettingsActions.getThemeNamesFromThemeTypeSuccess, (state, {res,themeType}) => {
    return {
        ...state,
        kioskThemesTypes:{...state.kioskThemesTypes,[themeType]:res}
    }
  }),

  on(SuperAdminSettingsActions.setNewThemeNamesFromThemeTypeSuccess, (state, {res,themeType}) => {
    return {
        ...state,
        kioskThemesTypes: {...state.kioskThemesTypes, [themeType]:[...state.kioskThemesTypes[themeType],res]}
    }
  }),
  on(SuperAdminSettingsActions.deleteThemesFromThemeIdSuccess, (state, {themeId,themeType}) => {
    let arr = state.kioskThemesTypes[themeType]
    let updatedArr = arr?.filter((e)=>{
      return e._id !== themeId
    })
    return {
        ...state,
        kioskThemesTypes: {...state.kioskThemesTypes, [themeType]:updatedArr}
    }
  }),

  on(SuperAdminSettingsActions.updateThemesFromThemeIdSuccess, (state, {themeId,themeType,payload}) => {
    let arr = structuredClone(state.kioskThemesTypes[themeType])
    arr?.map((e,idx)=>{
      if(e._id === themeId){
        arr[idx] = payload
      }
    })
    return {
        ...state,
        iskioskThemeUpdated: true,
        kioskThemesTypes: {...state.kioskThemesTypes, [themeType]:arr}
    }
  }),
  on(SuperAdminSettingsActions.resetIsKioskThemeUpdate, (state) => {
    return {
        ...state,
        iskioskThemeUpdated: false
    }
  }),

  on(SuperAdminSettingsActions.getAllThemesWithTypeIdSuccess, (state,{res}) => {
    let dark = res?.filter((e)=>{
      return e.themeType === 'dark'
    })
    let light = res?.filter((e)=>{
      return e.themeType === 'light'
    })
    return {
        ...state,
        kioskThemesTypes: {...state.kioskThemesTypes,dark:dark,light:light}
    }
  }),

  // New Ability

  on(SuperAdminSettingsActions.saveAbility,(state)=>{
    return {
      ...state,
      loaderForAbility:true
    }
  }),
  on(SuperAdminSettingsActions.saveAbilitySuccess,(state,{res})=>{
    return {
      ...state,
      loaderForAbility:false,
      allAbilityTogether:[{...res},...state.allAbilityTogether]
    }
  }),
  on(SuperAdminSettingsActions.getAllAbilityCountSuccess,(state,{count})=>{
    return {
      ...state,
      allAbilityCount:count
    }
  }),
  on(SuperAdminSettingsActions.getAllAbilityWithCountSuccess, (state,{res}) => {
    return {
        ...state,
        allAbilityTogether:res
    }
  }),
  on(SuperAdminSettingsActions.deleteAbilitySuccess, (state,{id}) => {
    let allAbilityCopy = state?.allAbilityTogether.filter((el)=>{
      return el?._id !== id
    })
    return{
      ...state,
      allAbilityTogether:[...allAbilityCopy]
    }
  }),
  on(SuperAdminSettingsActions.updateAbilityWithId,(state)=>{
    return {
      ...state,
      loaderForAbility:true
    }
  }),
  on(SuperAdminSettingsActions.updateAbilityWithIdSuccess,(state,{id,res})=>{
    let updatedAbility= [...state?.allAbilityTogether]
    updatedAbility.forEach((el,idx)=>{
      if(el._id==id){
        updatedAbility[idx] = {...res}
      }
    })
    return {
      ...state,
      allAbilityTogether:[...updatedAbility],
      loaderForAbility:false
    }
  }),
  on(SuperAdminSettingsActions.getAllAvailableModulesSuccess,(state ,{res})=>{
    return{
      ...state,
      allModules:res
    }
  }),
  on(SuperAdminSettingsActions.getAllCategoriesForIntegrationSuccess,(state ,{res})=>{
    return{
      ...state,
      allCategories:res
    }
  }),
  on(SuperAdminSettingsActions.getAllTypesWithCategory,(state)=>{
    return{
      ...state,
      loaderForPlugin:true
    }
  }),
  on(SuperAdminSettingsActions.getAllTypesWithCategorySuccess,(state ,{res})=>{
    return{
      ...state,
      typeRealtedToCategory:res,
      loaderForPlugin:false
    }
  })
);