import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'eventsFilterPipe'
})
export class EventsFilterPipe implements PipeTransform {
  transform(items, searchText: string) {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter( item => {
      if (item.description) {
        return item.description.toLowerCase().includes(searchText);
      }
    });
  }
}
