import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet.markercluster';
import {CONSTANT} from '../../../config/constant';
import {
  GeoFenceObject,
  GpsData,
  TrackerIcons,
  VehicleInfo,
  VehicleObject
} from '../../../state/Fleet/models/fleet.models';
import moment from 'moment';
import {LangUtilService} from '../../util/lang-util.service';
import {Store} from '@ngrx/store';
import {
  changePlotGeoFencesStatus,
  getActualPathsForCarrier,
  getExpectedPathsForCarrier,
  loadEventsData,
  resetExpectedAndActualPath,
  resetSelectedVehicle
} from '../../../state/Fleet/fleet.action';
import {IMapUserOptions} from '../../../state/User/interfaces/IUserMap';
import {handleMapResize} from '../../../state/User/user.utilityService';
import {AppState} from '../../../state/app.state';
import {OrderStatusColorValuesService} from '../../../services/order-status-color-values.service';
import {NamingConventionFilterPipe} from '../../../pipes/namingConvention.pipe';
import {CommonService} from '../../../services/common.service';
import {MessageService} from 'primeng/api';
import {EventData} from '../../../state/Fleet/models/custom.types';
import {
  getPopupButtonsAndTip,
  getPopUpHeader,
  showPopupData
} from '../../delivery-managenment-system/services/common.service';

import {dateFormator} from "../../../state/dashboard/dashboard/dashboard.helper.service";

type MarkerDataHolder = {
  markerData: L.Marker,
  carNo: string,
  latlng: L.LatLngExpression,
  isOffline: boolean,
  lastUpdated: Date,
}


@Component({
  selector: 'app-new-leaflet-map',
  templateUrl: './new-leaflet-map.component.html',
  styleUrls: ['./new-leaflet-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewLeafletMapComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() moduleName: string;
  @Input() eventsData: Array<EventData>;
  @Input() recenterMap: boolean;
  @Input() centerToLatLng: L.LatLng;
  @Input() geofences: GeoFenceObject[];
  @Input() plotGeoFenceStatus:boolean
  @Input() markerss: L.Layer[];
  @Input() trackIcons: TrackerIcons;
  @Input() trackZoneIcons: TrackerIcons;
  @Input() selectedVehicle: VehicleObject;
  @Input() selectedGroupName: string;
  @Input() vehicles: VehicleObject[] = [];
  @Input() socketGpsData: GpsData;
  @Input() showRightPanel: boolean;
  @Input() isGeoFence: boolean;
  @Input() tracking: boolean;
  @Input() selectedGeoFence: GeoFenceObject;
  @Input() isAddGeofence: boolean;
  @Input() clearnewGeofence: boolean;
  @Input() showLegendList: boolean;
  @Input() showBtnsList: boolean;
  @Input() orderMarkersDetailsWithoutSelectedVehicle: any;
  @Input() graphClickedEvent: any;
  @Input() userMapOptions: IMapUserOptions;
  @Input() expectedPathLatLngs: L.LatLngExpression[];
  @Input() actualPathLatLngs: L.LatLngExpression[];
  @Output() isGeoFenceEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() markerClickEvent: EventEmitter<VehicleInfo> = new EventEmitter();
  @Output() newGeoFencePolygonEvent: EventEmitter<L.LatLng | L.LatLng[] | L.LatLng[][] | L.LatLng[][][]> = new EventEmitter();
  @Output() clearNewGeofence: EventEmitter<string> = new EventEmitter();
  @Output() geoFenceClickEvent: EventEmitter<geoFenceClickData> = new EventEmitter();
  @Output() doubleClickOnMap: EventEmitter<L.LatLng> = new EventEmitter<L.LatLng>();
  newGeoFencePolygonPoints: Array<L.LatLng> = [];
  newGeoFencePolygon: L.Polygon;
  markers: L.Layer[] = [];
  allMarkers: L.Layer[] = [];
  offlineMarkers: L.Layer[] = [];
  eventMarkers: L.Layer[] = [];
  showEventMarkers?: string;
  onlineMarkers: L.Layer[] = [];
  geoFenceMarkers: L.Layer[] = [];
  map: L.Map;
  zoom: number;
  mapDetails: IMapUserOptions;
  customZoom: number;
  selectedZoom: number;
  options: L.MapOptions = {
    layers: [],
    zoom: 5,
    attributionControl: false,
    zoomControl: false,
    center: L.latLng(null, null)
  };
  minutesAfterDeviceIsOffline = CONSTANT.TIMEINTERVALS.OFFLINEINMIN;
  markersValueWithDeviceId: Record<string, MarkerDataHolder> = {};
  carrierPolylineLocations: { location: L.LatLngExpression, milliSeconds: number }[] = [];
  previousDateForLinePlotting: Date;
  polyLine: L.Polyline;
  trackedVehicleId: string;
  recenterLocation: L.LatLngExpression;
  index = 0;
  expectedRoute = L.polyline([]);
  actualRoute = L.polyline([]);
  sVehicleOderMarkers: L.Layer[] = [];
  legendList = [
    {
      'label': 'Actual Path',
      'color': '#00A3E3',
      'isActive': true,
      'type': 'actual'
    },
    {
      'label': 'Expected Path',
      'color': '#E34000',
      'isActive': false,
      'type': 'expected'
    }
  ];
  displayLegendContainer = false;
  displayPopup = false;
  markersClusterGroup: L.MarkerClusterGroup = (L as any).markerClusterGroup({
    showCoverageOnHover: false,
  });
  moduleTrackIcons: TrackerIcons;

  updateGeoFenceAccess:boolean

  constructor(
    private lang: LangUtilService,
    private store: Store<AppState>,
    private orderStatusColorService: OrderStatusColorValuesService,
    private commonService: CommonService,
    private messageService: MessageService
  ) {
    this.showBtnsList = true;
  }

  ngAfterViewInit(): void {
    this.map?.on('popupclose', () => {
      this.displayPopup = false;
    });
    this.markersClusterGroup?.addTo(this.map)
  }

  ngOnChanges(changes: SimpleChanges) {
    //Somtimes the trackIcons reset back to their original state
    //so when we have trackIcons we assign it to a local variable
    //so as to keep it presisted for the current mount
    //also, need to ensure the trackIcons are populated before we plotOrderMarkers
    if (changes.trackIcons && this.trackIcons) {
      this.moduleTrackIcons = this.trackIcons;
    }

    for (let i = 0; i < Object.keys(changes).length; i++) {
      const propName = Object.keys(changes)[i];
      const change = changes[propName];

      if (propName === 'vehicles') {
        if (change.currentValue !== change.previousValue) {
          if (change.currentValue?.length !== change.previousValue?.length && this.moduleTrackIcons && this.vehicles) {
            this.createDataForCarrierMarkers(this.vehicles);
            this.plotOrderMarkers();
          }
        }
        change.currentValue?.forEach(
          (currentData: VehicleObject, idx: number) => {
            const previousData = change.previousValue ? change.previousValue[idx] : {};
            this.updateMapMarkersOnVehicleDataChange(currentData, previousData);
          }
        );
      }

      if (propName === 'eventsData') {
        if (change.currentValue !== change.previousValue) {
          // this.eventMarkers = [];
          this.createEventMarkers(this.selectedVehicle?._id);
          this.checkEventMarker();
          if (change.currentValue !== null && this.selectedVehicle) {
            const m = this.markersValueWithDeviceId?.[this.selectedVehicle?._id]?.markerData;
            m && this.markersClusterGroup?.zoomToShowLayer(m, function () {
               m.openPopup(m.getLatLng());
            });
          }
        }
      }

      if (propName === 'showRightPanel') {
        if (change.currentValue !== change.previousValue) {
          this.map?.invalidateSize(true);
        }
      }

      if (propName === 'centerToLatLng') {
        if (change.currentValue !== change.previousValue) {
          if (this.map) {
            this.map.flyTo(this.centerToLatLng);
          }
        }
      }

      if (propName === 'userMapOptions') {
        if (change.currentValue !== change.previousValue) {
          this.setUserMapOptions();
        }
      }

      if (propName === 'trackIcons') {
        if (change.currentValue !== change.previousValue && this.allMarkers.length <= 0 && this.vehicles) {
          this.createDataForCarrierMarkers(this.vehicles);
        }
      }

      if (propName === 'selectedGroupName') {
        if (change.currentValue !== change.previousValue) {
          this.store.dispatch(resetSelectedVehicle());

          this.stopTracking(this.trackedVehicleId);
          this.trackedVehicleId = null;
          this.setMarkerByGroup();

          if (this.polyLine && this.polyLine['_map']) {
            this.polyLine.remove();
          }

          for (let i = 0; i < this.sVehicleOderMarkers.length; i++) {
            this.sVehicleOderMarkers[i]?.remove();
          }

          for (let i1 = 0; i1 < this.eventMarkers.length; i1++) {
            const m = this.eventMarkers[i1];
            m.remove();
          }
        }
      }

      if (propName === 'moduleName') {
        if (change.currentValue !== change.previousValue) {
          this.markers = [];
        }
      }

      if (propName === 'geofences') {
        if (change.currentValue !== change.previousValue) {
          this.updateGeoFenceLayer(this.geofences);
          this.updateGeoFenceMapMarkers();
        }
      }
      if (propName === 'plotGeoFenceStatus') {
        if ((change.currentValue !== change.previousValue) && this.plotGeoFenceStatus) {
          this.geoFenceMarkers?.forEach(gfence=>{
            this.map.removeLayer(gfence)
          })
          this.geoFenceMarkers=[]
          this.updateGeoFenceLayer(this.geofences);
          this.updateGeoFenceMapMarkers();
          this.store.dispatch(changePlotGeoFencesStatus())

        }
      }

      if (propName === 'isAddGeofence') {
        if (change.currentValue !== change.previousValue) {
          if (!this.isAddGeofence) {
            this.clearNewGeoFence();
          }
        }
      }

      if (propName === 'orderMarkersDetailsWithoutSelectedVehicle') {
        if (change.currentValue !== change.previousValue) {
          if (this.orderMarkersDetailsWithoutSelectedVehicle) {
            this.plotOrderMarkersWithoutVehicleSelection();
          }
        }
      }
      if (propName === 'graphClickedEvent') {
        if (change.currentValue !== change.previousValue) {
          if (this.graphClickedEvent) {
            this.sendGraphDataToPlotMarker(this.graphClickedEvent);
          }
        }
      }
      if (propName === 'clearnewGeofence') {
        if (change.currentValue !== change.previousValue && this.clearnewGeofence && this.newGeoFencePolygonPoints.length > 0) {
          this.clearNewGeoFence();
          this.clearNewGeofence.emit('clear');
        }
      }

      if (propName === 'selectedGeoFence') {
        if (change.currentValue !== change.previousValue) {
          if (this.selectedGeoFence) {
            this.paneToSelectedGeoFence(this.selectedGeoFence);
          }
        }
      }

      if (this.recenterMap && this.recenterLocation && this.map && this.tracking && this.selectedVehicle) {
        this.map?.panTo(this.recenterLocation);
      }

      if (propName === 'selectedVehicle') {
        if (change.currentValue?._id !== change.previousValue?._id) {
          if (this.selectedVehicle) {
            if (this.selectedVehicle._id !== this.trackedVehicleId) {
              this.switchTracking(this.selectedVehicle, this.trackedVehicleId);
              this.trackedVehicleId = this.selectedVehicle._id;
              this.displayPopup = true;
              this.showEventMarkers = this.showEventMarkers ? this.selectedVehicle._id : undefined;
              this.checkEventMarker();
              this.loadEventMarkerData(this.selectedVehicle);
            }
            this.updateMarkers(this.selectedVehicle);
          } else {
            this.stopTracking(this.trackedVehicleId);
            this.trackedVehicleId = null;
            this.showEventMarkers = null;
            this.checkEventMarker();
            this.plotOrderMarkers();
          }
        }
      }

      if (propName === 'isGeoFence') {
        if (change.currentValue !== change.previousValue) {
          this.updateGeoFenceMapMarkers();
        }
      }

      if (propName === 'socketGpsData') {
        if (change.currentValue !== change.previousValue) {
          this.modifyVehicles();
        }
      }
    }

    if (changes['expectedPathLatLngs']) {
      if (this.legendList?.[1]?.isActive) {
        this.plotPolyLine('#E34000', this.expectedPathLatLngs ?? [], false);
      }
      this.displayLegendContainer = !!(this.expectedPathLatLngs ?? 0);
    }

    if (changes['actualPathLatLngs']) {
      if (this.legendList?.[0]?.isActive) {
        this.plotPolyLine('#00A3E3', this.actualPathLatLngs ?? [], true);
      }
      this.displayLegendContainer = !!(this.actualPathLatLngs ?? 0);
    }
  }

  ngOnDestroy() {
    this.markersClusterGroup.remove()
  }

  loadEventMarkerData(vehicle: VehicleObject) {
    const startDate = moment().subtract(1, 'day').valueOf();
    const endDate = moment().valueOf();
    if (vehicle?._id) {
      this.store.dispatch(loadEventsData({
        startDate: startDate,
        endDate: endDate,
        assetId: vehicle?._id,
        skip: 0,
        limit: 20
      }));
    }
  }

  createEventMarkers(vehicleId: string) {
    if (this.eventsData) {
      for (let i = 0; i < this.eventsData.length; i++) {
        const event = this.eventsData[i];
        if (event.coordinates && event.coordinates.length > 0) {
          const newMarker = L.marker(event.coordinates as L.LatLngExpression, {
              icon: L.icon({
                iconSize: [20, 20],
                iconUrl: this.commonService.getIconsForEvents({name: event.name, status: event.status}),
              }),
              autoPan: true,
              attribution: vehicleId
            }
          );
          this.eventMarkers.push(newMarker);
          const generatedTimeStamp = moment(event.generatedTimeStamp).calendar();
          const tooltipContent =
            `<div style="padding: 10px 15px;">
        <p style="font-weight:600; font-size: 15px;margin: 0;">${event.name.toUpperCase()}</p>
        <p style="font-size: 11px;font-weight: 600;margin: 3px 0;border-radius: 3px;background-color: var(--primary-color);color:white; padding: 3px 8px;width: max-content;">${event.status.toUpperCase()}</p>
        <p style="font-size: 11px;margin: 0;">${generatedTimeStamp}</p>
    </div>`;
          newMarker.bindTooltip(tooltipContent, {
            direction: 'top',
            permanent: false,
            sticky: false,
            offset: [0, -10],
            opacity: 1,
            className: 'leaflet-tooltip-event'
          });
        }
      }
    }
  }

  toggleRecenter() {
    this.recenterMap = !this.recenterMap;
  }

  toggleMapGeofence() {
    this.isGeoFenceEvent.emit(!this.isGeoFence);
  }

  toggleGeofenceUpdate(){
    this.geoFenceMarkers?.forEach(gfence=>{
      this.map.removeLayer(gfence)
    })
    this.geoFenceMarkers=[]
    this.updateGeoFenceLayer(this.geofences);
    this.updateGeoFenceMapMarkers();
  }

  toggleEvents() {

    this.showEventMarkers = this.showEventMarkers ? null : this.selectedVehicle?._id;
    this.checkEventMarker();
  }

  checkEventMarker() {
    const remove = () => {
      for (let i = 0; i < this.eventMarkers.length; i++) {
        const m = this.eventMarkers[i];
        this.map?.removeLayer(m);
      }
    };

    if (this.map) {
      if (this.showEventMarkers === this.eventMarkers?.[0]?.getAttribution()) {
        remove();
        for (let i = 0; i < this.eventMarkers.length; i++) {
          const m = this.eventMarkers[i];
          this.map?.addLayer(m);
        }
        return;
      }

      remove();
    }
  }

  positionMarker(e: L.LatLngExpression, force?: boolean) {
    if (this.recenterMap || force) {
      this.map?.flyTo(e, this.map?.getZoom(), {animate: true});
    }
  }

  plotOrderMarkers() {
    if (this.selectedVehicle?.ordersAssigned && this.selectedVehicle.ordersAssigned?.deliveryOrderStatuses.length > 0) {
      this.plotMarkers(this.selectedVehicle.ordersAssigned);
    }
  }

  plotOrderMarkersWithoutVehicleSelection(){
    this.actualRoute?.remove();
    this.expectedRoute?.remove();
    this.plotMarkers(this.orderMarkersDetailsWithoutSelectedVehicle);
  }


  plotMarkers(ordersAssigned){
    for (let i = 0; i < this.sVehicleOderMarkers.length; i++) {
      const m = this.sVehicleOderMarkers[i];
      m.remove();
    }

    this.sVehicleOderMarkers = [];
    const namingConventionFilterPipe = new NamingConventionFilterPipe();
    const orders = ordersAssigned;
      // Marker for pickup location
      const pl = orders?.pickupLocation;

      if (pl?.lat && pl?.lng && this.map) {
        const icon = this.getCustomIcon('#373fa3', 'P');
        const c = L.circleMarker([pl.lat, pl.lng], {
          radius: 15,
          fill: true,
          attribution: this.selectedVehicle?._id
        });
        const i = L.marker([pl.lat, pl.lng], {
          icon: icon,
         attribution: this.selectedVehicle?._id
        });
        this.sVehicleOderMarkers = [c, i];
        c.addTo(this.map);
        i.bindTooltip(`
          <div class='tooltip-content delivery-centers'>
            <div class='tooltip-header'>
              <div>
                <h6>${pl?.name ?? '-'}</h6>
                <p>${pl?.contactNumber ?? '-'}</p>
                <p>${pl?.address ?? '-'}</p>
              </div>
              <div
                style="background-color: #1d2124"
                class="status"
              >
                ${pl?.status?.toUpperCase() ?? '-'}
              </div>
            </div>
            <div class='tooltip-body'>
              <div class='sensor-pill'>
                Branch Code: ${pl?.branchCode ?? '-'}
              </div>
              <div class='sensor-pill'>
                Auto Assign: ${pl?.autoAssignment ?? '-'}
              </div>
            </div>
          </div>
        `, {
          direction: 'top',
          permanent: false,
          sticky: false,
          offset: [5, -20],
          opacity: 1,
          className: 'leaflet-tooltip-own'
        }).addTo(this.map);
      }

      //Orders point marker logic
      for (let i = 0; i < orders?.deliveryOrderStatuses?.length; i++) {
        const o = orders.deliveryOrderStatuses[i];
        const orderStatus = namingConventionFilterPipe.transform(o.status);

        //Delivery Center tooltip content
        const dcTooltipContent =
          `
          <div class='tooltip-content delivery-centers'>
            <div class='tooltip-header'>
              <div>
                <h6>${o.customer.name}</h6>
                <p>${o.customer.mobileNo}</p>
                <p>ETA: ${this.dateFormatterToText(o.expectedDel.date)} (${o.expectedDel.slot})</p>
              </div>
              <div style="background-color: ${this.orderStatusColorService.getStatusColor(o.status)};" class="status">${orderStatus.toUpperCase()} </div>
            </div>
            <div class='tooltip-body'>
              <div class='sensor-pill'>
                Consignment: ${o.deliverySequence}
              </div>
              <div class='sensor-pill'>
                Order ID: ${o.orderId}
              </div>
            </div>
          </div>
        `;

        if (o?.deliveryLocation?.lat && o?.deliveryLocation?.lng && this.map) {
          const location: L.LatLngExpression = [o.deliveryLocation.lat, o.deliveryLocation.lng];
          const icon = this.getCustomIcon('#373fa3', i + 1);
          const color = this.orderStatusColorService.getStatusColor(o.status);
          const fillColor = this.orderStatusColorService.getStatusBackgroundColor(o.status);
          const c = L.circleMarker(location, {
            radius: 15,
            color: color,
            fillColor: fillColor,
            attribution: this.selectedVehicle?._id
          });
          const ic = L.marker(location, {
            icon: icon,
            attribution: this.selectedVehicle?._id
          });
          this.sVehicleOderMarkers = [...this.sVehicleOderMarkers, c, ic];
          c.addTo(this.map);
          ic.bindTooltip(dcTooltipContent, {
            direction: 'top',
            permanent: false,
            sticky: false,
            offset: [5, -20],
            opacity: 1,
            className: 'leaflet-tooltip-own'
          }).addTo(this.map);
        }
      }

  }

  getCustomIcon(color: string, name: string | number) {
    return L.divIcon({
      html: `<div> <span class='leaflet-circle-marker-txt' style='color: ${color}'>${name} </span> </div>`,
      className: '',
      iconSize: [40, 40],
    });
  }

  legendClicked(l) {
    l.isActive = !l.isActive;
    for (let i = 0; i < this.legendList.length; i++) {
      const item = this.legendList[i];
      if (!item.isActive) {
        if (item.type === 'expected') {
          this.plotPolyLine('#E34000', [], false);

        } else {
          this.plotPolyLine('#00A3E3', [], true);

        }
      } else {
        if (item.type === 'expected') {
          this.plotPolyLine('#E34000', this.expectedPathLatLngs ?? [], false);

        } else {
          this.plotPolyLine('#00A3E3', this.actualPathLatLngs ?? [], true);

        }
      }
    }
  }

  pathPlotCheck() {
    if (this.selectedVehicle?.ordersAssigned) {
      this.store.dispatch(getExpectedPathsForCarrier({
        carrierId: this.selectedVehicle?._id,
        moduleType: this.moduleName
      }));
      this.store.dispatch(getActualPathsForCarrier({
        carrierId: this.selectedVehicle?._id,
        moduleType: this.moduleName
      }));
      this.plotOrderMarkers();
    } else {
      this.store.dispatch(resetExpectedAndActualPath());
    }
  }

  onMapReady(map: L.Map) {
    this.map = map;
    L.control.zoom({position: 'bottomright'}).addTo(map);
    this.setUserMapOptions();
    this.map?.doubleClickZoom.disable();
    this.map?.on('dblclick', async (e) => {
      this.doubleClickOnMap.emit(e.latlng);
    });
    this.geofences &&
    this.updateGeoFenceLayer(this.geofences);
  }

  plotPolyLine(hexColor: string, _route: L.LatLngExpression[], isActualPath: boolean) {
    if (!this.map) {
      return;
    }
    if (isActualPath) {
      if (this.actualRoute && this.actualRoute['_map']) {
        this.actualRoute.setLatLngs(_route);
        this.actualRoute.redraw();
      } else {
        this.actualRoute = L.polyline(_route, {'weight': 4, 'color': hexColor, 'opacity': .9});
        this.map?.addLayer(this.actualRoute);
      }
      if(this.map && this.actualRoute && _route?.length)
      this.map?.fitBounds(this.actualRoute.getBounds());
      return;
    }
    if (this.expectedRoute && this.expectedRoute['_map']) {
      this.expectedRoute.setLatLngs(_route);
      this.expectedRoute.redraw();
    } else {
      this.expectedRoute = L.polyline(_route, {'weight': 4, 'color': hexColor, 'opacity': .9});
      this.map?.addLayer(this.expectedRoute);
    }
  }

  setMarkerByGroup() {
    switch (this.selectedGroupName) {
      case 'online':
        this.markers = this.onlineMarkers;
        break;
      case 'offline':
        this.markers = this.offlineMarkers;
        break;
      case 'all':
        this.markers = this.allMarkers;
        break;
    }
    //Take some time to reset layer off loading event loop
    setTimeout(() => {
      this.markersClusterGroup.clearLayers();
      this.markersClusterGroup.addLayers(this.markers);
    }, 0);

  }

  updateGeoFenceMapMarkers() {
    if (this.map) {
      if (this.isGeoFence) {
        this.geoFenceMarkers.forEach((l) => {
          if (!this.map.hasLayer(l)) {
            this.map.addLayer(l);
          }
        });
      } else {
        this.geoFenceMarkers.forEach(g => g.remove())
      }
    }
  }

  setUserMapOptions() {
    if (this.userMapOptions && this.map) {
      this.mapDetails = this.userMapOptions;
      const latitude = this.mapDetails.centerLocation.coordinates[0] ? this.mapDetails.centerLocation.coordinates[0] : 25.214017;
      const longitude = this.mapDetails.centerLocation.coordinates[1] ? this.mapDetails.centerLocation.coordinates[1] : 55.310220;
      const center = L.latLng(latitude, longitude);
      this.setMap(this.mapDetails);
      this.setZoomLevel();
      this.map?.setView(center, this.selectedZoom);
      this.map?.setZoom(this.selectedZoom);
      this.zoom = this.map?.getZoom();
      this.map?.on('click', (event) => {
        if (this.geofences && this.isAddGeofence) {
          this.addNewPolygonPoints(event.latlng);
        }
      });
      handleMapResize(this.map, 'tracker-map');
    }
  }

  setZoomLevel() {
    this.map?.on('zoom', () => {
      this.customZoom = this.map?.getZoom();
    });
    if (this.mapDetails.zoom && !this.customZoom) {
      this.selectedZoom = this.mapDetails.zoom;
    } else if (this.mapDetails.zoom && this.customZoom) {
      this.selectedZoom = this.customZoom;
    } else {
      this.selectedZoom = 11;
    }
  }

  // SETTING OPTIONS FOR MAP
  setMap(mapSelected) {
    if (mapSelected && mapSelected.tile) {
      const url = mapSelected.tile.url;
      const option = mapSelected.tile.option;
      const mapToView = L.tileLayer(url, option);

      if (mapSelected.tile['google_style']) {
        (L as any).gridLayer.googleMutant({
          type: 'roadmap',
          styles: mapSelected.tile['google_style'],
          maxNativeZoom: 21,
          maxZoom: 21
        }).addTo(this.map);
      } else {
        mapToView.addTo(this.map);
      }

      this.map?.invalidateSize(true);
    }
  }

  updateMapMarkersOnVehicleDataChange(currentData: VehicleObject, previousData: VehicleObject) {
    // const t0 = performance.now();
    if ((currentData?.currentLocation?.latitude === previousData?.currentLocation?.latitude &&
        currentData?.currentLocation?.longitude === previousData?.currentLocation?.longitude) &&
      (currentData?.battery?.batterSaverMode !== previousData?.battery?.batterSaverMode ||
        currentData?.isOffline !== previousData?.isOffline ||
        currentData?.gpsData?.speed !== previousData?.gpsData?.speed ||
        currentData?.gpsData?.batteryLevel !== previousData?.gpsData?.batteryLevel ||
        currentData?.gpsData?.platformInfo?.version !== previousData?.gpsData?.platformInfo?.version ||
        currentData?.gpsData?.sensorData?.humidity !== previousData?.gpsData?.sensorData?.humidity ||
        currentData?.gpsData?.sensorData?.coldChainBox !== previousData?.gpsData?.sensorData?.coldChainBox ||
        currentData?.gpsData?.sensorData?.temperature !== previousData?.gpsData?.sensorData?.temperature)
    ) {

      // console.log('updateMapMarkersOnVehicleDataChange');
      this.updateMarkers(currentData);
      // const t1 = performance.now();

      // console.log(t1 - t0, "performace");
    }
  }

  createDataForCarrierMarkers(vehicles) {
    for (let x = 0; x < vehicles.length; x++) {
      const vehicle = vehicles[x];
      const lastDate = vehicle?.gpsData?.generatedTimeStamp;

      const exists = this.markersValueWithDeviceId?.[vehicle._id];
      if (!exists) {
        if (vehicle?.gpsData?.latitude && vehicle?.gpsData?.longitude && lastDate) {
          this.addCarrierMarker([vehicle?.gpsData?.latitude, vehicle?.gpsData?.longitude], lastDate, vehicle);
        }
      }
    }
    this.setMarkerByGroup();
  }

  addCarrierMarker(location, lastDate, vehicle: VehicleObject) {
    const lastUpdated = new Date(lastDate);
    const differenceInMinutes = moment().diff(moment(lastDate), 'minute');
    const isOffline = differenceInMinutes >= this.minutesAfterDeviceIsOffline;
    let iconPath = isOffline ? this.moduleTrackIcons.offline : this.moduleTrackIcons.online; // '../assets/leaflet-icons/marker-icon.png';

    if (this.selectedVehicle && vehicle._id === this.selectedVehicle._id) {
      iconPath = this.moduleTrackIcons.active;
    }

    const marker = L.marker(location,
      {
        attribution: vehicle._id,
        riseOnHover: true,
        interactive: true,
        icon: L.icon({
          iconSize: [35, 40],
          iconAnchor: [13, 38],
          iconUrl: iconPath,
          shadowUrl: 'leaflet/marker-shadow.png',
          shadowAnchor: [13, 38]
        },),
      }
    );

    this.handleMarkerListeners(marker, vehicle._id);
    this.setTooltipContent(marker, vehicle, isOffline);

    this.markersValueWithDeviceId[vehicle._id] = {
      markerData: marker,
      carNo: vehicle?.registrationNumber,
      latlng: location,
      isOffline: isOffline,
      lastUpdated: lastUpdated,
    };

    this.allMarkers.push(marker);

    if (isOffline) {
      this.offlineMarkers.push(marker);
    } else {
      this.onlineMarkers.push(marker);
    }
    this.markers = this.onlineMarkers;
  }

  handleMarkerListeners(marker: L.Marker, vehicleId: string) {
    const openPopup = (m: L.Marker) => {
      if (!m.isPopupOpen()) {
        this.markersClusterGroup.zoomToShowLayer(m, function () {
          m.openPopup(m.getLatLng());
        });
        // m.openPopup(m.getLatLng());
      }
    };

    marker.on('click', (event) => {
      const unselect = this.selectedVehicle ? this.selectedVehicle._id === vehicleId : false;
      const vehicleInfo: VehicleInfo = {
        selected_vehicle: this.vehicles.find(v => v._id === vehicleId),
        unselect: unselect
      };
      this.markerClickEvent.emit(vehicleInfo);
      this.positionMarker(event.target.getLatLng(), true);
    });


    marker.on('mouseover', () => {
      openPopup(marker);
    });


    marker.on('mouseout', () => {
      if (marker.getAttribution() !== this.selectedVehicle?._id) {
        marker.closePopup();
      }

      if (this.selectedVehicle && this.markersValueWithDeviceId[this.selectedVehicle?._id]) {
        const m = this.markersValueWithDeviceId[this.selectedVehicle?._id].markerData;
        openPopup(m);
      }

    });
  }

  /**
   * @param vehicleData the current geoSpatialData for a vehicle
   * @param socketData latest geoSpatialData pushed by socket for a particular vehicle
   * @returns Whether the lat and lng of the vehicle have been changed
   */
  cordsHaveUpdated(vehicleData, socketData) {
    const latChanged = Number(vehicleData.lat) !== Number(socketData.lat);
    const lngChanged = Number(vehicleData.lng) !== Number(socketData.lng);

    return (latChanged || lngChanged);
  }

  modifyVehicles() {
    const index = this.vehicles?.findIndex((v) => v._id === this.socketGpsData?.trackedAssetId);
    if (index > -1 && this.vehicles[index].gpsData) {
      this.updateMarkers(this.vehicles[index], this.cordsHaveUpdated(this.vehicles[index].gpsData, this.socketGpsData));
      this.map?.invalidateSize(true);
    }
  }

  /**
   * @dev Refer to [update Marker In Cluster](https://github.com/Leaflet/Leaflet.markercluster/issues/57)
   * @param marker The marker reference that needs to be updated in the cluster
   * @param location The new set of lat lng for the marker passed
   */
  updateMarkerInCluster(marker: L.Marker, location: L.LatLngExpression) {
    //Remove marker from cluster
    marker && this.markersClusterGroup?.removeLayer(marker)
    //Update marker lat lngs
    location && marker.setLatLng(location);
    //Add marker to cluster
    marker && this.markersClusterGroup?.addLayer(marker)
  }

  updateMarkers(vehicle: VehicleObject, reRender = false) {
    // TRACKED VEHICLE
    if (this.selectedVehicle && !this.trackedVehicleId) {
      this.trackedVehicleId = this.selectedVehicle._id;
    }
    // LOCATION
    let location: L.LatLngExpression;
    // const latLngObj = latLng(vehicle.gpsData.latitude, vehicle.gpsData.longitude);
    if (vehicle?.gpsData?.latitude && vehicle?.gpsData?.longitude) {
      location = [vehicle.gpsData.latitude, vehicle.gpsData.longitude];
    }

    const lastDate = new Date(vehicle?.gpsData?.generatedTimeStamp);
    const differenceInMinutes = moment().diff(moment(vehicle?.gpsData?.generatedTimeStamp), 'minute');
    const isOffline = differenceInMinutes >= this.minutesAfterDeviceIsOffline;
    const uMarker: L.Marker = this.markersValueWithDeviceId?.[vehicle._id]?.markerData;
    const prevDateOfGpsPlotted = new Date(this.markersValueWithDeviceId?.[vehicle._id]?.lastUpdated)?.getTime();
    const isOldData = lastDate?.getTime() < prevDateOfGpsPlotted;

    if (this.markersValueWithDeviceId?.[vehicle._id]) {
      this.markersValueWithDeviceId[vehicle._id].lastUpdated = lastDate;
      this.markersValueWithDeviceId[vehicle._id].latlng = location;
      this.markersValueWithDeviceId[vehicle._id].isOffline = isOffline;
    }

    if (uMarker) {
      this.setTooltipContent(uMarker, vehicle, isOffline);
      if (this.selectedVehicle && this.selectedVehicle._id === vehicle._id) {
        if (prevDateOfGpsPlotted && !isOldData) {
          let iconPath = this.moduleTrackIcons.online;

          if (this.moduleName === CONSTANT.DMS_MODULES.MMD.MODULE
            && vehicle.assetType === CONSTANT.ASSET_TYPES.ZONE
            && this.trackZoneIcons) {
            iconPath = this.trackZoneIcons.active;
          }

          uMarker.setIcon(L.icon({
            iconSize: [35, 40],
            iconAnchor: [13, 38],
            iconUrl: iconPath, // '../assets/leaflet-icons/marker-icon-active.png',
            shadowUrl: 'leaflet/marker-shadow.png',
            shadowAnchor: [13, 38]
          }));

        //  if (reRender) {
            this.updateMarkerInCluster(uMarker, location);
            this.markersClusterGroup.zoomToShowLayer(uMarker, function () {
              uMarker.openPopup(uMarker.getLatLng());
            });
        //  }

          if (this.map) {
            this.recenterLocation = location;
          }

          this.updatePolyline(location, vehicle?.gpsData?.speed);
          this.previousDateForLinePlotting = new Date(lastDate);
        } else {
          const milliSeconds = new Date(lastDate)?.getTime();
          if ((this.previousDateForLinePlotting?.getTime() !== lastDate?.getTime())) {
            this.carrierPolylineLocations.push({location: location, milliSeconds: milliSeconds});
          }
        }

        if (this.displayPopup) {
          this.markersClusterGroup.zoomToShowLayer(uMarker, function () {
            uMarker.openPopup(uMarker.getLatLng());
          });
          // uMarker.openPopup(location);
        }
        this.positionMarker(location);
      } else {
        let iconPath = isOffline ? this.moduleTrackIcons.offline : this.moduleTrackIcons.online; // '../assets/leaflet-icons/marker-icon.png';
        if (this.moduleName === CONSTANT.DMS_MODULES.MMD.MODULE && vehicle.assetType === CONSTANT.ASSET_TYPES.ZONE && this.trackZoneIcons) {
          const isOffline = differenceInMinutes >= this.minutesAfterDeviceIsOffline;
          iconPath = isOffline ? this.trackZoneIcons.offline : this.trackZoneIcons.online;
        }
        uMarker.setIcon(L.icon({
          iconSize: [35, 40],
          iconAnchor: [13, 38],
          iconUrl: iconPath, // '../assets/leaflet-icons/marker-icon.png',
          shadowUrl: 'leaflet/marker-shadow.png',
          shadowAnchor: [13, 38]
        }));
        if (reRender) {
          this.updateMarkerInCluster(uMarker, location);
        }
      }
    }
    const tempAllMarkers = [];
    const tempOnlineMarkers = [];
    const tempOfflineMarkers = [];

    for (let i = 0; i < Object.keys(this.markersValueWithDeviceId).length; i++) {
      const key = Object.keys(this.markersValueWithDeviceId)[i];
      if (!this.markersValueWithDeviceId[key].isOffline) {
        tempOnlineMarkers.push(this.markersValueWithDeviceId[key]?.markerData);
      } else if (this.markersValueWithDeviceId[key].isOffline) {
        tempOfflineMarkers.push(this.markersValueWithDeviceId[key]?.markerData);
      }
      tempAllMarkers.push(this.markersValueWithDeviceId[key]?.markerData);
    }

    this.allMarkers = tempAllMarkers;
    this.onlineMarkers = tempOnlineMarkers;
    this.offlineMarkers = tempOfflineMarkers;
  }

  updatePolyline(location: L.LatLngExpression, lastDate: string) {
    const milliSeconds = new Date(lastDate)?.getTime();
    this.carrierPolylineLocations.push({location: location, milliSeconds: milliSeconds});
    if (this.polyLine && this.polyLine['_map']) {
      this.polyLine.addLatLng(location);
    } else {
      this.polyLine = L.polyline([location], {'weight': 5, 'color': '#1d3785', 'opacity': .9});
      this.map?.addLayer(this.polyLine);
    }
  }

  switchTracking(selectedVehicle: VehicleObject, trackedVehicleId: string) {
    this.pathPlotCheck();
    this.stopTracking(trackedVehicleId);
    // LOCATION
    if (selectedVehicle?.gpsData?.latitude && selectedVehicle?.gpsData?.longitude) {
      this.positionMarker([selectedVehicle.gpsData.latitude, selectedVehicle.gpsData.longitude], true);
      const m = this.markersValueWithDeviceId?.[selectedVehicle?._id]?.markerData;
      m && this.markersClusterGroup?.zoomToShowLayer(m, function () {
        m.openPopup(m.getLatLng());
      });
      // m?.openPopup();

    } else {
      this.messageService.add({
        key: 'map-notification',
        severity: 'info',
        summary: 'Info',
        detail: `${selectedVehicle.name}: Location not found`
      });
    }
  }

  stopTracking(trackedVehicleId) {
    this.actualRoute.remove();
    this.expectedRoute.remove();
    for (let i = 0; i < this.sVehicleOderMarkers.length; i++) {
      const m = this.sVehicleOderMarkers[i];
      if (this.selectedVehicle && this.selectedVehicle._id !== m.getAttribution()) {
        m.remove();
      }
    }
    this.carrierPolylineLocations = [];
    if (this.polyLine && this.polyLine['_map']) {
      this.polyLine.remove();
    }

    if (trackedVehicleId && this.selectedVehicle?._id !== trackedVehicleId) {
      const markerInstance: L.Marker = this.markersValueWithDeviceId[trackedVehicleId]?.markerData;
      const v = this.vehicles?.find((apiVehicle) => apiVehicle._id === trackedVehicleId);
      const diff = moment().diff(moment(v?.gpsData?.generatedTimeStamp), 'minute');
      markerInstance?.setIcon(L.icon({
        iconSize: [35, 40],
        iconAnchor: [13, 38],
        iconUrl: diff >= 3 ? this.moduleTrackIcons.offline : this.moduleTrackIcons.online, // '../assets/leaflet-icons/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png',
        shadowAnchor: [13, 38]
      }));
      markerInstance?.closePopup();
    }
  }

  setTooltipContent(markerInstance: L.Marker, vehicle: VehicleObject, isOffline: boolean) {
    const temp = vehicle?.gpsData?.sensorData?.temperature ? vehicle?.gpsData?.sensorData?.temperature.toFixed(0) : false;
    const speed = vehicle?.gpsData?.speed ? vehicle?.gpsData?.speed : false;
    const box = vehicle?.gpsData?.sensorData?.coldChainBox ? vehicle?.gpsData?.sensorData?.coldChainBox : false;
    const humidity = vehicle?.gpsData?.sensorData?.humidity ? vehicle?.gpsData?.sensorData?.humidity : false;
    const platforInfo = vehicle?.gpsData?.platformInfo?.version ? vehicle?.gpsData?.platformInfo?.version : false;
    const batteryLevel = vehicle?.gpsData?.batteryLevel ? vehicle?.gpsData?.batteryLevel : false;
    const isBOptimized = vehicle?.battery?.batterSaverMode;

    let c = temp ? `<div class='sensor-pill'>
              <p>Temperature : <b>${temp}</b></p>
            </div>` : '';

    c = humidity ? `${c} <div class='sensor-pill'>
              <p>Humidity : <b>${humidity}</b></p>
            </div>` : c;

    c = box ? `${c}<div class='sensor-pill'>
              <p>Box : <b>${box.toUpperCase()}</b></p>
            </div>` : c;

    c = speed ? `${c}  <div class='sensor-pill'>
              <p>Speed : <b>${speed && speed !== 'NA' ? Math.ceil(speed) : 'NA'}</b></p>
            </div>` : c;

    c = platforInfo ? `${c}
            <div class='sensor-pill'>
              <p>ZenDMS : <b>${platforInfo}</b></p>
            </div>` : c;

    c = batteryLevel ? `${c}
            <div class='sensor-pill'>
              <p>Battery : <b>${batteryLevel}%</b></p>
            </div>` : c;

    c = isBOptimized ? `${c}
            <div class='sensor-pill'>
              <p>Battery Saver on: <b>${isBOptimized}</b></p>
            </div>` : c;

    const tooltipContent =
      `<div class='tooltip-content'>
          <div class='tooltip-header'>
            <div>
              <h6>${vehicle?.name.toUpperCase()}</h6>
              <p>${vehicle?.mobileNo ? vehicle?.mobileNo : ''}</p>
              <p>${this.dateFormatterToText(vehicle?.gpsData?.generatedTimeStamp)}</p>
            </div>
            <div class="${isOffline ? 'status offline' : 'status online'}">
              ${isOffline ? 'OFFLINE' : 'ONLINE'}
            </div>
          </div>
          <div class='tooltip-body'>
            ${c}
          </div>
      </div>`;

    const popup = markerInstance?.getPopup();
    if (popup) {
      markerInstance?.setPopupContent(tooltipContent);
    } else {
      markerInstance?.bindPopup(tooltipContent, {
        offset: [5, -40],
        className: 'leaflet-tooltip-own',
        closeButton: true,
      });
    }
  }

  updateGeoFenceLayer(data: GeoFenceObject[]) {
    if (this.map && data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const geofence = data[i];

        const geoMark = this.geoFenceMarkers.find(g => g.getAttribution() === `geofence_${geofence._id}`);

        if (!geoMark){
          const coordinates = geofence?.location?.coordinates?.map((coordinate) => {
            return L.latLng(coordinate[0], coordinate[1]);
          });

          const geoFencePlot = L.polygon(coordinates, {
            color: 'blue', attribution: `geofence_${geofence._id}`
          });

          this.setTooltipContentGeoFence(geoFencePlot, geofence);
          this.geoFenceMarkers.push(geoFencePlot);

          geoFencePlot.on('click', () => {
            this.geoFenceClickEvent.emit({geoFence:geofence,updateGeoFenceAccess:this.updateGeoFenceAccess});
          });
        }else if (geoMark && geofence.count){
          const gfence: any = this.geoFenceMarkers.filter(g => g.getAttribution() === `geofence_${geofence._id}`)?.[0];
          this.setTooltipContentGeoFence(gfence, geofence);
        }
      }
    }
  }

  addNewPolygonPoints(location: L.LatLng) {
    this.newGeoFencePolygonPoints.push(location);
    if (this.newGeoFencePolygon && this.newGeoFencePolygon['_map']) {
      this.newGeoFencePolygon.addLatLng(location).redraw();
    } else {
      this.newGeoFencePolygon = L.polygon(this.newGeoFencePolygonPoints, {color: 'green'}).addTo(this.map);
    }

    const latlngs: L.LatLng | L.LatLng[] | L.LatLng[][] | L.LatLng[][][] = this.newGeoFencePolygon?.getLatLngs()?.[0];
    this.newGeoFencePolygonEvent.emit(latlngs);
  }

  clearNewGeoFence() {
    if (this.newGeoFencePolygon && this.newGeoFencePolygon['_map']) {
      this.newGeoFencePolygonPoints = [];
      this.newGeoFencePolygon.setLatLngs(this.newGeoFencePolygonPoints).redraw();
    }
  }

  setTooltipContentGeoFence(geoFencePlot: L.Polygon, data: GeoFenceObject) {
    const popupData=[{label:'Number of vehicles: ',value:data?.count || 0}]

    data?.radius && popupData.push({label:'Geo-Fence Radius: ',value:data.radius})

    const popupContent =
      getPopUpHeader(data?.label || '', '') +
      showPopupData(popupData) +
    ((this.updateGeoFenceAccess && data?.radius) ? getPopupButtonsAndTip('Click on Geo-Fence to edit radius',false) : '')

    geoFencePlot.bindPopup(popupContent);
    const [lat,lng]=data?.location?.coordinates?.[0] || []

    geoFencePlot.addEventListener("mouseover",()=>{
      geoFencePlot.openPopup({lng,lat});
    })
    geoFencePlot.addEventListener("mouseout",()=>{
      geoFencePlot.closePopup();
    })
  }

  dateFormatterToText(date) {
    this.lang.updateMomentLanguage(moment);
    return moment(date).calendar();
  }

  paneToSelectedGeoFence(geo: GeoFenceObject) {
    this.map.eachLayer(l => {
      if (l.getAttribution() === `geofence_${geo._id}`) {
        this.map?.fitBounds(l?.['_bounds']);
      }
    });
  }

  sendGraphDataToPlotMarker(foundObject){
    if (foundObject && foundObject?.coordinates?.length > 0) {
      const marker=  L.marker({lat:foundObject?.coordinates[0],lng:foundObject?.coordinates[1]})
      const icon = L.divIcon({
        html: `<div></div>`,
        className: '',
        iconSize: [40, 40]
      });
      marker.setIcon(icon);
      const tempDataArray = []
      const temperature = foundObject?.sensorData?.temperature;
      const humidity = foundObject?.sensorData?.humidity;
      temperature && tempDataArray.push({label:'Temp in °C : ',value:foundObject?.sensorData['temperature'].toFixed(2)})
      humidity && tempDataArray.push({label:'Humidity in % : ',value:foundObject?.sensorData['humidity'].toFixed(2)})
      marker.bindPopup(
        getPopUpHeader('Sensor stats','',dateFormator(foundObject?.updated, 'lll') ) + showPopupData([{label:'Temp in °C : ',value:foundObject?.sensorData['temperature']?.toFixed(2)}])
        , {maxWidth : 1000,offset: [0, -8]});
          setTimeout(()=>{
            marker?.openPopup()
          },500);

      marker.addTo(this.map);
      this.map?.panTo({ lat:foundObject?.coordinates[0],lng:foundObject?.coordinates[1]});
          setTimeout(()=> {
          this.map?.setZoom(17);
          }, 1000);
    } else {
      this.messageService.add({key: 'map-notification',severity:'error', summary:'Error', detail:'Lat Lng not found'});
    }
  }

}


export interface geoFenceClickData{
  geoFence: GeoFenceObject;
  updateGeoFenceAccess:boolean
}
