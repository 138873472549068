import {createAction, props} from '@ngrx/store';
import {TrackerMapIconsSettings} from '../../app/settings/settings-models';
import {GeoFenceCount, GeoFenceObject, GpsData, TrackerIcons, VehicleObject} from './models/fleet.models';
import {LatLngExpression} from 'leaflet';
import {ActiveOrderGroup, EventData, EventStatus, GeoAsset} from './models/custom.types';
import {ILocation} from '../Assets/interfaces/IAsset.types.modal';
import { HistoricalGpsV2, ISensorData, IloadAssetsLinkedData } from './interface';
import { IAllDeliveryData } from '../Assets/interfaces/IAllDeliveries.model';
// import { ILoadSpeedData } from './interface';


export const setSelectedGroup = createAction(
  '[ASSET API] set selected vehicle group',
  props<{ selectedGroupName: string }>()
);

export const updateVehicleGpsData = createAction(
  '[ASSET API] update gps data',
  props<{ socketGpsData: GpsData, module: string }>()
);
export const loadMarkerIcons = createAction(
  '[ASSET API] load Marker Icons',
  props<{ moduleType: string, assetType: string }>()
);

export const loadMarkerIconsSuccess = createAction(
  '[ASSET API] load Marker Icons Success',
  props<{ data: TrackerIcons, module: string }>()
);

export const loadVehicles = createAction(
  '[ASSET API] load vehicles',
  props<{ moduleType: string, isCount: boolean }>()
);

export const loadVehiclesSuccess = createAction(
  '[ASSET API] load vehicles success',
  props<{ all_vehicles: VehicleObject[], module: string }>()
);

export const loadSelectedTrackableVehicles = createAction(
  '[ASSET API] load selected trackable vehicles',
  props<{ moduleType: string, isCount: boolean, fI: string, fE: string, assetIds: string }>()
);

export const loadSelectedTrackableVehiclesSuccess = createAction(
  '[ASSET API] load selected trackable vehicles success',
  props<{ selected_vehicles: VehicleObject[], module: string }>()
);

export const updateVehicleInfo = createAction(
  '[ASSET API] update vehicle Info',
  props<{ assetId: string, module: string }>()
);

export const updateVehicleInfoSuccess = createAction(
  '[ASSET API] update vehicle Info success',
  props<{ selectedVehicle: VehicleObject, module: string }>()
);

export const loadDistanceMoved = createAction(
  '[ASSET API] load distance moved chart data',
  props<{ startDate: number, endDate: number, assetId: string }>()
);

export const loadDistanceMovedSuccess = createAction(
  '[ASSET API] load distance moved chart data success',
  props<{ distanceMovedData: any }>()
);

export const loadSpeedData = createAction(
  '[ASSET API] load speed chart data',
  props<{ startDate: number, endDate: number, assetId: string }>()
);

export const loadSpeedDataSuccess = createAction(
  '[ASSET API] load speed chart data success',
  props<{ speedData: any }>()
);

export const loadFuelData = createAction(
  '[ASSET API] load speed chart data',
  props<{ startDate: number, endDate: number, assetId: string, moduleName: string }>()
);

export const loadFuelDataSuccess = createAction(
  '[ASSET API] load fuel chart data success',
  props<{ fuelData: any, assetId: string, moduleName: string }>()
);

export const loadSensorData = createAction(
  '[ASSET API] load sensor chart data',
  props<{ startDate: number, endDate: number, assetId: string, series: string }>()
);

export const loadSensorDataSuccess = createAction(
  '[ASSET API] load sensor chart data success',
  props<{ sensorData: ISensorData[] }>()
);

export const loadEventsDataCount = createAction(
  '[ASSET API] load events count data',
  props<{ startDate: number, endDate: number, assetId: string, types?: any, status?: any }>()
);

export const loadEventsDataCountSuccess = createAction(
  '[ASSET API] load events data count success',
  props<{ eventsDataCount: { count: number } }>()
);

export const loadEventsData = createAction(
  '[ASSET API] load events data',
  props<{
    startDate: number,
    endDate: number,
    assetId: string,
    skip?: number,
    limit?: number,
    types?: any,
    status?: any
  }>()
);

export const loadEventsDataSuccess = createAction(
  '[ASSET API] load events data success',
  props<{ eventsData: Array<EventData> }>()
);

export const loadEventStatusData = createAction(
  '[ASSET API] load events status data',
  props<{ typeString?: string }>()
);

export const loadEventStatusDataSuccess = createAction(
  '[ASSET API] load events status data success',
  props<{ eventsStatusData: EventStatus }>()
);

export const loadAssetsLinkedData = createAction(
  '[ASSET API] load assets linked data',
  props<{ assetId: string }>()
);

export const loadAssetsLinkedDataSuccess = createAction(
  '[ASSET API] load assets linked data success',
  props<{ assetsLinked: IloadAssetsLinkedData[] }>()
);
export const loadAssignedOrdersGroupData = createAction(
  '[ASSET API] load assigned orders group data',
  props<{ module: string, carrierId?: string }>()
);

// Group api is been used instead of individual
// export const loadAssignedOrdersData = createAction(
//   '[ASSET API] load assigned orders data',
//   props<{ agentId: string, isMinifiedData?: boolean }>()
// );
//
// export const loadAssignedOrdersDataSuccess = createAction(
//   '[ASSET API] load assigned orders data success',
//   props<{ assignedOrders: ActiveOrdersMin[], agentId: string }>()
// );

export const loadAssignedOrdersGroupDataSuccess = createAction(
  '[ASSET API] load assigned orders data success',
  props<{ data: ActiveOrderGroup[], agentId: string, module: string }>()
);

export const loadGeofences = createAction(
  '[ASSET API] load geofences',
  props<({isLoadGeofence : boolean})>()
);

export const loadGeofencesSuccess = createAction(
  '[ASSET API] load geofences success',
  props<{ geofences: GeoFenceObject[] }>()
);

export const loadGeoAssetsById = createAction(
  '[ASSET API] load geo Assets by id',
  props<{ geofenceId: string, module: string }>()
);

export const loadGeoAssetsByIdSuccess = createAction(
  '[ASSET API] load geo assets by id success',
  props<{ geoAssets: GeoAsset[], module: string }>()
);

export const updateGeofences = createAction(
  '[ASSET API] update geofences'
);

export const addGeofence = createAction(
  '[ASSET API] add geofence',
  props<{ newGeofence: any }>()
);

export const deleteGeofence = createAction(
  '[ASSET API] delete geofence',
  props<{ geofenceId: any }>()
);

export const deleteGeofenceSuccess = createAction(
  '[ASSET API] delete geofence success',
);

export const addGeofenceSuccess = createAction(
  '[ASSET API] add geofence success',
);

export const updateGeofencesSuccess = createAction(
  '[ASSET API] update geofences success',
  props<{ geofencesCount: GeoFenceCount[] }>()
);

export const resetTrackState = createAction(
  '[ASSET API] Reset Track state',
);

export const resetSelectedVehicle = createAction(
  '[ASSET API] Reset selected vehicle state',
);

export const getMapIconSettingsByModule = createAction(
  ' [MAP API] get map icon settings data ',
  props<{ moduleType: string }>()
);

export const getMapIconsSettingsSuccess = createAction(
  ' [MAP API] get map icon settings data success ',
  props<{ data: TrackerMapIconsSettings }>()
);

export const updateMapIconsByModuleType = createAction(
  '[ASSET API]  update map icon settings by module type ',
  props<{ moduleType: string, payload: TrackerMapIconsSettings }>()
);


export const updateMapIconSettingsSuccess = createAction(
  '[ASSET API]  update map icon settings by module type sucess ',
  props<{ data: TrackerMapIconsSettings, moduleType: string }>()
);

export const updateMapIconObjectField = createAction(
  '[UPDATE MAPICON FIELD] update the map icons setting by field',
  props<{ updatedValue: string, index: number, feildToUpdate: string }>()
);

export const getActualPathsForCarrier = createAction(
  '[Selected Carrier paths] get actual paths for selected carrier',
  props<{ carrierId: string, moduleType: string, }>()
);

export const getActualPathsForCarrierWithoutApiCall = createAction(
  '[Selected Carrier paths] get actual paths without api call',
  props<{ historicalGpsData: HistoricalGpsV2[], }>()
);

export const getExpectedPathsForCarrierWithoutApiCall = createAction(
  '[Selected Carrier paths] get expected paths without api call',
  props<{ latLngs: LatLngExpression[], }>()
);

export const getActualPathsForCarrierSuccess = createAction(
  '[Selected Carrier paths] get actual paths for selected carrier success',
  props<{ data: LatLngExpression[] }>()
);

export const getExpectedPathsForCarrier = createAction(
  '[Selected Carrier paths] get expected paths for selected carrier',
  props<{ carrierId: string, moduleType: string, }>()
);

export const getExpectedPathsForCarrierSuccess = createAction(
  '[Selected Carrier paths] get edp paths for selected carrier success',
  props<{ data: LatLngExpression[] }>()
);

export const failure = createAction(
  '[Fleet API] Fleet api failed',
  props<{ error: Error }>()
);

export const resetExpectedAndActualPath = createAction(
  '[Selected Carrier paths] reset previously selected carrier paths'
);

export const getLinkedLocations = createAction(
  '[Selected City] get locations linked to city',
  props<{ cityId: string }>()
);

export const getLinkedLocationsSuccess = createAction(
  '[Selected City] get locations linked to city success',
  props<{ data: ILocation[], cityId: string }>()
);

export const getGeoFenceById = createAction(
  '[Update Geo-Fence] get geo fence by id',
  props<{ assetId: string,consignmentId?:string }>()
);

export const updateGeoFenceById = createAction(
  '[Update Geo-Fence] update geo fence by id success',
  props<{ geofence: GeoFenceObject, geofenceId: string,consignmentId:string }>()
);

export const setLocationIdsForGeoFenceFiltering = createAction(
  '[Filter Geo-Fences] filter geofence by location ids',
  props<{ data:IAllDeliveryData[]}>()
);

export const filterGeoFencesForConsignment = createAction(
  '[Filter Geo-Fences] filter geofence fo consignment by location ids',
  props<{ consignmentId: string }>()
);

export const changePlotGeoFencesStatus = createAction(
  '[Plotting Geo-Fence] changing plotting geofence status'
);

export const getCarGpsHistoricalData = createAction(
  '[Timeline module] get Car GPS Historical Data',
  props<{ startDate: any, endDate: any, id: string, fI?: string }>()
);

export const getCarGpsHistoricalDataSuccess = createAction(
  '[Timeline module] get Car GPS Historical Data Success',
  props<{ data: Array<any> }>()
);
export const getCarGpsHistoricalDataFaliure = createAction(
  '[Timeline module] get Car GPS Historical Data Error',
  props<{ err:Error }>()
);