/**
 * Created by chandru on 3/11/21.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';

@Injectable({
  providedIn: "root",
})
export class RoleAssetAuthService {
  constructor(private http: HttpClient, public configService: ConfigService) {}
  
  getUsersCount( isAllAccount: boolean, searchText?: string, role?: string, accountId?: string) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'user/count/';
    queryUrl = queryUrl + '?allAccount=' + isAllAccount;
    if (searchText) {
      queryUrl = queryUrl + '&search=' + searchText;
    }
    if (role) {
      queryUrl = queryUrl + '&role=' + role;
    }
    if (accountId) {
      queryUrl = queryUrl + '&accountId=' + accountId;
    }
    return this.http.get(queryUrl);
  }

  getUsers(skip, limit, isAllAccount: boolean, searchText?: string, role?: string, accountId?: string) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'user' + '?skip=' + skip + '&limit=' + limit + '&allAccount=' + isAllAccount;
    if (searchText) {
      queryUrl = queryUrl + '&search=' + searchText;
    }
    if (role) {
      queryUrl = queryUrl + '&role=' + role;
    }
    if (accountId) {
      queryUrl = queryUrl + '&accountId=' + accountId;
    }
    return this.http.get(queryUrl);
  }

  getAllAccounts(isCount=false, skip?: number, limit?: number) {
    let url =  this.configService.appConfig.appBaseUrl + 'account/'
    if(isCount){
        url = url + '?isCount=' + isCount
    }
    if(!isCount &&  skip!==null && limit){
        url = url + '?skip='+skip+'&limit='+limit
    }
    return this.http.get(url);
  }

  getRoleAssetAuths(accountId, isLinked?, userId?) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'roleAssetAuth/all' + '?accountId=' + accountId;
    if(isLinked) {
        queryUrl = queryUrl + '&isLinked=' + isLinked;
    }
    if (userId) {
      queryUrl = queryUrl + '&userId=' + userId;
    } 
    return this.http.get(queryUrl);
  }
  
  getRoleAssetAuthById(roleAssetAuthId){
    let queryUrl = this.configService.appConfig.appBaseUrl + 'roleAssetAuth/id/' + roleAssetAuthId;

    return this.http.get(queryUrl);
  }

  saveRoleAssetAuthData(roleAuthData) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'roleAssetAuth/save' , roleAuthData);
  }

  updateRoleAssetAuthData(roleAuthData, roleAuthId){
    return this.http.put(this.configService.appConfig.appBaseUrl + 'roleAssetAuth/id/' + roleAuthId, roleAuthData);
  }

  deleteRoleAssetAuthData(roleAuthId){
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'roleAssetAuth/id/' + roleAuthId);
  }

  getAssetAuthByUserId(userId){
    const queryUrl = this.configService.appConfig.appBaseUrl + 'roleAssetAuth/account/userId/' + userId;
    return this.http.get(queryUrl);
  }

  updateRoleAssetAuthDataByUser(roleAuthData, userId){
    return this.http.put(this.configService.appConfig.appBaseUrl + 'roleAssetAuth/account/userId/' + userId, roleAuthData);
  }

  getAssetType(){
    const url = `${this.configService.appConfig.appBaseUrl}assetConfigAccount/authenticatedAssetTypes`;
    return this.http.get(url);
  }

}
