@if (formConfigData.length > 0) {
  <form [formGroup]="form">
    <div class="grid my-1">
      @for (field of formConfigData; track field) {
        <div class="form-group py-2 col-12 md:col-6 lg:col-6 xl:col-6" style="float: left">
          @if ((field.type === 'text') || (field.type === 'textarea') || (field.type === 'number') || (field.type === 'email')) {
            <div>
              <mat-form-field color="accent" class="full-width" appearance="outline">
                <mat-label>{{ field.label }}</mat-label>
                <input pInputText type={{field.type}} placeholder={{field.description}} formControlName={{field.field}}
                       [required]="field.required">
              </mat-form-field>
            </div>
          }
          @if (field.type == 'date') {
            <div>
              <mat-form-field color="accent" class="full-width" appearance="outline">
                <mat-label>{{ field.label }}</mat-label>
                <input formControlName={{field.field}} pInputText type="text" ngxDaterangepickerMd
                       class="ng-date-picker-input"
                       [singleDatePicker]="datePickerVariables.singleDatePicker"
                       [autoApply]="datePickerVariables.autoApply" [locale]="datePickerVariables.locale"
                       [maxDate]="datePickerVariables.maxDate" [minDate]="datePickerVariables.minDate"
                       [showDropdowns]="datePickerVariables.showDropdowns"
                       [required]="field.required" placeholder="{{field.description}}">
              </mat-form-field>
            </div>
          }
          @if (field.type == 'dropDown') {
            <div>
              <mat-form-field color="accent" class="full-width" appearance="outline">
                <mat-label>{{ field.label }}</mat-label>
                <mat-select [disableOptionCentering]="true" placeholder="{{field.label}}"
                            formControlName={{field.field}} [required]="field.required">
                  @for (val of field.value.dropDownValues; track val) {
                    <mat-option [value]="val">{{ val }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          }
        </div>
      }
    </div>
    <!--Assets related-->
    <div class="grid m-1">
      @if (assetData && assetData.assetType && formConfigData.length > 0 && !isInputDisabled && ACTIONS.UPDATE | able: SUBJECT.ASSET) {
        <button mat-raised-button color="primary" class='buttonEnable MR10'
                (click)="submitEditConfig()" [disabled]="!form.valid">{{ 'sys.save-changes' | translate }}
        </button>
      }
      @if (assetData && assetData.assetType && formConfigData.length > 0 && !isInputDisabled && ACTIONS.DELETE | able: SUBJECT.ASSET) {
        <button mat-raised-button color="warn" class='showDeregisterAssetPopupBtn buttonEnable'
                (click)="deregisterSelectedAsset()">{{ 'sys.de-register' | translate }}
        </button>
      }
      <!-- Service related btns -->
      @if (assetData && assetData.serviceType && formConfigData.length > 0 && !isInputDisabled && ACTIONS.UPDATE | able: SUBJECT.SERVICE) {
        <button mat-raised-button color="primary" class='buttonEnable MR10'
                (click)="submitEditConfig()" [disabled]="!form.valid">{{ 'sys.save-changes' | translate }}
        </button>
      }
      @if (assetData && assetData.serviceType && formConfigData.length > 0 && !isInputDisabled && ACTIONS.DELETE | able: SUBJECT.SERVICE) {
        <button mat-raised-button color="warn" class='showDeregisterAssetPopupBtn buttonEnable'
                (click)="deregisterSelectedAsset()">{{ 'sys.de-register' | translate }}
        </button>
      }
    </div>
  </form>
}
