import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-custom-right-static-tooltip',
  templateUrl: './custom-right-static-tooltip.component.html',
  styleUrls: ['./custom-right-static-tooltip.component.scss']
})
export class CustomRightStaticTooltipComponent {
  @Input() tooltipText: string;
  @Input() rightPosition: string;
}
