<div class="plusIcon" id="addAsset">
  <a href="#" onclick="return false;" class="btn-floating btn-lg red waves-effect waves-light plus"><i id="addAssetFabIcon" class="fa fa-plus"></i></a>
</div>

<div class="assetList">
  @for (menuItems of menuListItems; track menuItems) {
    <div>
      <div class="assetLabel-grid"><span class="assetLabel"
                                         [ngStyle]="{'color':menuItems.theme.color.primary}">{{ menuItems.label }}</span><i
        class="arrow right"></i></div>
      <div id="add-new-asset-Button" class="launch-modal" data-toggle="modal" data-target="#notification-event-to-add"
           data-backdrop="static" data-keyboard="false">
        <a href="#" onclick="return false;" id="assetIcon" class="btn-floating btn-lg waves-effect waves-light plus"
           [ngStyle]="{'background-color': menuItems.theme.color.primary}"
           (click)="menuSelected(menuItems)">
          <img [src]="getIcon(menuItems['type'])" alt=""/>
        </a>
      </div>
    </div>
  }
</div>

<div class="addAssetBackground"></div>

