import { Injectable } from "@angular/core";
import { ReportsSubscriptionActions } from ".";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, from, withLatestFrom, catchError } from "rxjs";
import { ReportsSubscriptionService } from "./reports.subscription.service";
import { MessageService } from "primeng/api";
import { Store } from "@ngrx/store";
import { selectAppliedParameters, selectReportSubscriptions } from "./reports.subscription.selector";

@Injectable()
export class ReportsSubscriptionEffects {
  constructor(
    private actions$: Actions,
    private reportsSubscriptionService: ReportsSubscriptionService,
    private messageService: MessageService,
    private store: Store
  ) {}

  changeSubscriptionParmaters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsSubscriptionActions.changeSubscriptionParmaters),
      withLatestFrom(
        this.store.select(selectAppliedParameters({ moduleType: null }))
      ),
      switchMap(([action, appliedFilters]) => {
        return from(
          this.reportsSubscriptionService.storeAppliedFiltersInfo(
            action.event,
            action.assetType,
            action.report,
            action.apiKey,
            appliedFilters?.[action.moduleType]?.["appliedParameters"]
          )
        ).pipe(
          switchMap((changedParmaters) => {
            return [
              ReportsSubscriptionActions.onSuccessOfChangeSubscriptionParmaters(
                {
                  changedParmaters,
                  moduleType: action.moduleType,
                }
              ),
            ];
          })
        );
      })
    )
  );

  subscribeToReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsSubscriptionActions.subscribeToReport),
      withLatestFrom(
        this.store.select(selectAppliedParameters({ moduleType: null }))
      ),
      switchMap(([action, filterData]) => {
        return from(
          this.reportsSubscriptionService.subscribeToReport(
            action.parameters,
            filterData?.[action.moduleType]?.["appliedParameters"],
            action.moduleType,
          )
        ).pipe(
          switchMap(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "success",
              summary: "Success",
              detail: "Subscribed Successfully...!",
            });
            return [
              ReportsSubscriptionActions.onSuccessOfReportSubscription(),
              ReportsSubscriptionActions.getSubscriptions({moduleType:action.moduleType})
            ];
          }),
          catchError(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "error",
              summary: "Error",
              detail: "Error Occured...!",
            });
            return [];
          })
        );
      })
    )
  );

  editSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsSubscriptionActions.editReportSubscription),
      withLatestFrom(
        this.store.select(selectReportSubscriptions({ moduleType: null }))
      ),
      switchMap(([action,allSubscriptions]) => {
        return from(
          this.reportsSubscriptionService.editSubscription(
            action.parameters,
            allSubscriptions?.[action.moduleType]?.['subscriptions']?.[action.parameters.reportName]
            .find(({_id})=>action.subscriptionId===_id),
            action.moduleType
          )
        ).pipe(
          switchMap(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "success",
              summary: "Success",
              detail: "Subscription Edited Successfully...!",
            });
            return [
              ReportsSubscriptionActions.onSuccessOfReportSubscription(),
              ReportsSubscriptionActions.getSubscriptions({moduleType:action.moduleType})
            ];
          }),
          catchError(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "error",
              summary: "Error",
              detail: "Error Occured...!",
            });
            return [];
          })
        );
      })
    )
  );

  getSubscriptionsByReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsSubscriptionActions.getSubscriptions),
      switchMap(({reportKey,moduleType}) => {
        return from(
          this.reportsSubscriptionService.loadSubscriptions(moduleType,reportKey)
        ).pipe(
          switchMap((reportSubscriptions: []) => {
            return [
              ReportsSubscriptionActions.onGettingSubscriptions({reportSubscriptions,moduleType}),
            ];
          }),
          catchError(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "error",
              summary: "Error",
              detail: "Error Occured...!",
            });
            return [];
          })
        );
      })
    )
  );

  removeSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsSubscriptionActions.removeSubscription),
      switchMap(({subscriptionId,moduleType}) => {
        return from(
          this.reportsSubscriptionService.unscubscribe(subscriptionId)
        ).pipe(
          switchMap(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "success",
              summary: "Success",
              detail: "Unsubscribed Successfully...!",
            });
            return [ReportsSubscriptionActions.getSubscriptions({moduleType})];
          }),
          catchError(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "error",
              summary: "Error",
              detail: "Error Occured...!",
            });
            return [];
          })
        );
      })
    )
  );
}