import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../../services/config.service";
import { DateFormatterService } from "../../services/dateformat";
import { of } from "rxjs";
import { cloneDeep } from "lodash";


@Injectable({
  providedIn: "root",
})
export class ReportsSubscriptionService {
  constructor(private http: HttpClient, private configService: ConfigService, private dateFormatter: DateFormatterService) {}

  subscribeToReport(payload,filterData,moduleType:string) {

  const requiredPayload= this.getRequiredSubscriptionParmaters(payload,filterData?.[payload['reportName']],moduleType)

    return this.http.post((this.configService.appConfig.appBaseUrl + "subscriptions"),requiredPayload);
  }

  editSubscription(payload,filterData,moduleType:string) {
     
    console.log(payload,filterData,moduleType)
    const requiredPayload= this.getRequiredSubscriptionParmaters(payload,filterData,moduleType,true)

    const URL =this.configService.appConfig.appBaseUrl + "subscriptions/id/" + filterData['_id']
  
    return this.http.put(URL,requiredPayload);

    }

  loadSubscriptions(moduleType:string,reportKey:string) {
    let url = this.configService.appConfig.appBaseUrl +"subscriptions"

      url += "?moduleType=" + moduleType
    if(reportKey){
      url += "&reportType=" + reportKey;
    }
      return this.http.get(url);
  }

  unscubscribe(subscriptionId:string) {
    const URL =this.configService.appConfig.appBaseUrl + "subscriptions/id/" + subscriptionId
    return this.http.delete(URL);
  }

  storeAppliedFiltersInfo(event,assetType:string,report:string,apiKey:string,appliedFilters) {
    let filterNames = "";
    let filterValues = "";
    if (event.value) {
      if (Array.isArray(event.value)) {
        event.value.forEach((filter, idx) => {
          filterNames += idx === 0 ? "" : ",";
          filterNames += filter["name"] || filter[assetType] || filter["label"];

          filterValues += idx === 0 ? "" : ",";
          filterValues += filter["_id"];
        });
      } else {
        filterNames = event.value['name'] || event.value[assetType] || event.value["label"];
        filterValues = event.value['_id'] || event.value['value'];
      }
    }
    appliedFilters=cloneDeep(appliedFilters) || {}
    appliedFilters[report] = appliedFilters[report] || {};
    appliedFilters[report][assetType] = {
      name: filterNames,
      apiKey,
      apiValue: filterValues
    };

    return of(appliedFilters)
  }

  getRequiredSubscriptionParmaters(parameters,filterData,moduleType:string,isEditSubscription=false){

    const triggerTimeData = this.dateFormatter.getDateComponents(parameters.triggerTimeData) || {}
    // const beforeTimeData = parameters.beforeTimeData ? this.dateFormatter.getDateComponents(parameters.beforeTimeData) : {}

    const time ={h:Number(triggerTimeData['hours']),m:Number(triggerTimeData['minutes'])}
    // const beforeTime ={h:Number(beforeTimeData['hours']),m:Number(beforeTimeData['minutes'])}

    parameters=cloneDeep(parameters)
    
    parameters['time']=time
    // parameters['beforeTime']=beforeTime

    parameters['appliedFilterLabels'] = parameters['appliedFilterLabels'] || {}
    parameters['filter'] = parameters['filter'] || {}

    for(let filter in (filterData || {})){
      parameters['filter'][filterData[filter]['apiKey']]=filterData[filter]['apiValue']
      parameters['appliedFilterLabels'][filter]=filterData[filter]['name']
    }
    const requiredSubscriptionParmaters=
    {
      "subscriptionType": parameters["subscriptionType"],
      "subscriptionModule": parameters["subscriptionModule"],
      "reportType": parameters["reportType"],
      "time": parameters["time"],
      "filter": isEditSubscription ? filterData["filter"] : parameters["filter"],
      "appliedFilterLabels": isEditSubscription ? filterData["appliedFilterLabels"] : parameters["appliedFilterLabels"],
      moduleType
  }

  // if(parameters["beforeTime"]['h']!==null){
    // requiredSubscriptionParmaters["beforeTime"] = parameters["beforeTime"]
  // }

  if(parameters?.["filter"]?.['reportType']!==filterData?.["filter"]?.['reportType']){
    requiredSubscriptionParmaters["filter"] =
    {
      ...(requiredSubscriptionParmaters["filter"] || {}),
      reportType : parameters["filter"]['reportType']
    }
  }

  if(parameters["subscriptionType"]==='webhook'){
    requiredSubscriptionParmaters['apiDetails'] = parameters['apiDetails']
  }else{
    requiredSubscriptionParmaters['emailIds'] = parameters['emailIds']
  }
  return requiredSubscriptionParmaters
  }  
}
