@if (cameraList.length > 0) {
  <div class="W100FL">
    <div class="input-outer-grid PR">
      <p-multiSelect [style]="{ width: '100%'}" optionLabel="name" [options]="cameraList" [(ngModel)]="selectedCameras"
                     (onChange)="cameraSelected()"
                     placeholder="{{ 'app.sidemenu.security.select-cameras' | translate }}" [defaultLabel]="'Choose'"
                     multiple="multiple">
      </p-multiSelect>
    </div>
  </div>
}


<div class="camera-grid-main-container" [ngClass]="{'twoColumn':(selectedCameras.length > 0 && selectedCameras.length < 5),
 'threeColumn':(selectedCameras.length >= 5 && selectedCameras.length < 7), 'fourColumn':(selectedCameras.length >= 7 && selectedCameras.length < 9)}">
  @for (container of videoFeedcontainers; track container) {
    <div cdkDrag class="camera-grid-container">
      <app-video-feed [nameSpace]="container['_id']" [camera]="container"
                      (disConnectedCamera)="disConnectedCameraEvent($event)"></app-video-feed>
  </div>
  }
</div>

<div class="W100FL TAC">@if (!isCamerasNotPresent && !showLoader && selectedCameras.length === 0) {
  <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'app.sidemenu.security.select-cameras' | translate }}
    <span>&rdquo;</span></h3>
}</div>

<div class="W100FL TAC">@if (isCamerasNotPresent && !showLoader) {
  <h3 class="asset-not-found"><span>&ldquo;</span>No cameras<span>&rdquo;</span></h3>
}</div>

@if (showLoader) {
  <div>
  <app-loader></app-loader>
</div>
}
