import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusColorValuesService } from '../services/order-status-color-values.service';

@Pipe({
    name: 'statusToColorPipe',
    standalone: true,
})

export class statusToColorPipe implements PipeTransform {
    constructor(private orderStatusColorService: OrderStatusColorValuesService) { }
    transform(status: string): string {
        return this.orderStatusColorService.getStatusColor(status);
    }
}
