<div class="graph-container">
  <div class="upper-grid">
    <div><span class="title" (click)="titleClicked()" [ngClass]="{'cursorPointer': isHeadingClickable}"
               [ngStyle]="{'color':titleColor}">{{ title }}</span></div>
    <div>@if (showDateLabel) {
      <span class="date lighterColor">{{ date }}</span>
    }</div>
  </div>
  <div class="upper-grid">
    <div><span class="count" [ngStyle]="{'color':titleColor}">{{count && count <= 10 ? '0'+count : count}}</span></div>
  </div>
  <div echarts class="e-chart-grid" [options]="options"></div>
</div>
