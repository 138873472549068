import {createReducer, on} from "@ngrx/store";
import {IAssignmentWindowApiResonse, IOBDSettings} from "../../app/delivery-managenment-system/services/common.service";
import {SettingsActions} from ".";
import {
  IConsignmentAssignForm,
  INotifyRecipientOptions,
  IProgressSettings,
  IQueueSetting,
  IReportSetting,
  IRoutePlanSettings,
  IStatusesAllowed
} from "./interfaces/lmdMmdSettings.interface";
import {
  Field,
  IGenericGroupSettingsForModuleSettings,
  IGenericMetaDataSettingsForModuleSettings,
  IGenericSettingsForModuleSettings,
  Processor,
  propertiesForO2D,
  Section,
  Slot,
  Sort,
  TableFilter
} from "./interfaces/orderSettings.interface";
import {sortTimeSlots} from "./o2dsettings.helper.service";
import {
  IGeofenceSetting,
  RootAllSettingsForDMSWithModule,
  RootGroupsOfSettingsWithModule,
  RootMetadataSettingsForDMS
} from "./interfaces/Interface-processor";
import {IProcessorList} from "./settings.service";
import {CONSTANT} from "../../config/constant";
import {ILMDFormMetaData, IMMDFormMetaData} from "./interfaces/mmd-formSettings.interface";

export interface SettingsState {
  slotSetting: Slot[];
  tableSort: Sort;
  tableFilter: TableFilter;
  tableFields: Field[];
  formSections: Section[];
  processorSetting: Processor;
  properties: propertiesForO2D;
  error: any;
  msg: string;
  showLoader: boolean;
  notifyRecipientOptions: Record<string, INotifyRecipientOptions>
  reportSetting: Record<string, IReportSetting>
  queueSetting: Record<string, IQueueSetting>
  roundTripSetting: Record<string, IRoutePlanSettings>
  obdSetting: IOBDSettings;
  windowAssignmentSetting: IAssignmentWindowApiResonse,
  processorsList: IProcessorList[]
  mmdConsignmentAssignForm: IConsignmentAssignForm,
  mmdConsignmentFormMetaData: IMMDFormMetaData,
  lmdFormMetaData: ILMDFormMetaData
  mmdProgressSettings: IProgressSettings,
  lmdProgressSettings: IProgressSettings

  genericMetaDataSettingsForDms: RootMetadataSettingsForDMS
  genericDMSSettingsWithModuleType: RootAllSettingsForDMSWithModule
  genericSettingUpdateLoader: boolean,
  groupLoaderForDMSSettings: boolean
  geofenceSettings: IGeofenceSetting
  groupsOfSetttingsWithModule: RootGroupsOfSettingsWithModule
  allowedStatusesForModule: Record<string, IStatusesAllowed>;
  routePlanCategoryList: string[];
  genericStateStoreForMetaDataSettings: IGenericMetaDataSettingsForModuleSettings
  genericStateStoreForAllSettings: IGenericSettingsForModuleSettings
  genericStateStoreForSettingsGroups: IGenericGroupSettingsForModuleSettings
}

export const initialState: SettingsState = {
  slotSetting: [],
  tableSort: null,
  tableFilter: null,
  tableFields: [],
  formSections: [],
  processorSetting: null,
  properties: null,
  processorsList: [],
  error: null,
  msg: "",
  showLoader: false,
  notifyRecipientOptions: {
    'lmd': {
      selectedDeliveryStatus: [],
      selectedNotifyMode: [],
      deliveryStatusOptions: [],
      notificationModes: [
        {
          label: "Email",
          id: 'email'
        },
        {
          label: "Sms",
          id: 'sms'
        }
      ],

    },
    'mmd': {
      selectedDeliveryStatus: [],
      selectedNotifyMode: [],
      deliveryStatusOptions: [],
      notificationModes: [
        {
          label: "Email",
          id: 'email'
        },
        {
          label: "Sms",
          id: 'sms'
        }
      ],
    }
  },

  reportSetting: {
    'lmd': null,
    'mmd': null
  },
  queueSetting: {
    'lmd': null,
    'mmd': null
  },
  roundTripSetting: {
    'lmd': null,
    'mmd': null
  },
  obdSetting: null,
  windowAssignmentSetting: null,
  mmdConsignmentAssignForm: null,
  mmdConsignmentFormMetaData: null,
  lmdFormMetaData: null,
  mmdProgressSettings: null,
  lmdProgressSettings: null,
  genericMetaDataSettingsForDms: {
    lmd: null,
    mmd: null
  },
  genericDMSSettingsWithModuleType: {
    lmd: null,
    mmd: null
  },
  genericSettingUpdateLoader: false,
  groupLoaderForDMSSettings: false,
  geofenceSettings: null,
  groupsOfSetttingsWithModule: {
    mmd: null,
    lmd: null
  },
  allowedStatusesForModule: {},
  routePlanCategoryList: null,
  genericStateStoreForMetaDataSettings: null,
  genericStateStoreForAllSettings: null,
  genericStateStoreForSettingsGroups: null


}

export const SettingsReducer = createReducer(
  initialState,
  on(SettingsActions.onDefaultTableSettingsForAnAccountFetchSuccuss, (state, {settings}) => {
    return {
      ...state,
      slotSetting: sortTimeSlots(settings?.order?.slots),
      tableFilter: settings?.order?.table?.filter,
      tableSort: settings?.order?.table?.sort,
      tableFields: settings?.order?.table?.fields,
      formSections: settings?.order?.form?.section,
      processorSetting: settings?.processor,
      properties: settings?.properties
    }
  }),
  on(SettingsActions.onDefaultTableSettingsForAnAccountFetchFailure, (state, error) => {
    return {
      ...state,
      error: error
    }
  }),

  on(SettingsActions.setO2DFormSectionSettingSuccess, (state, {msg, payload}) => {
    return {
      ...state,
      msg: msg,
      formSections: payload

    }
  }),
  on(SettingsActions.setO2DTableFieldSettingSuccess, (state, {msg, payload}) => {
    return {
      ...state,
      msg: msg,
      tableFields: payload

    }
  }),
  on(SettingsActions.setO2DSlotSettingSuccess, (state, {msg, payload}) => {
    return {
      ...state,
      msg: msg,
      showLoader: false,
      slotSetting: payload
    }
  }),

  on(SettingsActions.setO2DSlotSetting, (state) => {
    return {
      ...state,
      showLoader: true
    }
  }),

  on(SettingsActions.getNotifyRecipientOptionsSuccess, (state, {res, moduleType}) => {
    return {
      ...state,
      notifyRecipientOptions: {
        ...state.notifyRecipientOptions,
        [moduleType]: {...state.notifyRecipientOptions[moduleType], ...res}
      }
    }
  }),

  on(SettingsActions.updateNotifyRecipientOptionsSuccess, (state, {res, moduleType}) => {
    return {
      ...state,
      notifyRecipientOptions: {
        ...state.notifyRecipientOptions,
        [moduleType]: {...state.notifyRecipientOptions[moduleType], ...res}
      }
    }
  }),

  on(SettingsActions.getNotifyRecipientOptionsFailure, (state) => {
    return {
      ...state,
    }
  }),

  on(SettingsActions.getReportSettingSuccess, (state, {res, moduleType}) => {
    return {
      ...state,
      reportSetting: {
        [moduleType]: res
      }
    }
  }),

  on(SettingsActions.updateReportSettingSuccess, (state, {res, moduleType}) => {
    return {
      ...state,
      reportSetting: {
        [moduleType]: res
      }
    }
  }),

  on(SettingsActions.getQueueSettingsByModuleSuccess, (state, {res, moduleType}) => {
    console.log(res,moduleType,"This is reducer")
    let updatedQueuSettings = structuredClone(state.queueSetting)
    updatedQueuSettings[moduleType] = res
    return {
       ...state,
       queueSetting : { ...state.queueSetting,
        [moduleType]: res
      }
    }
  }),

  on(SettingsActions.updateQueueSettingSuccess, (state, {res, moduleType}) => {
    return {
       ...state,
       queueSetting :  { ...state.queueSetting,
        [moduleType]: res
      }
    }
  }),

  on(SettingsActions.getRoundTripSettingSuccess, (state, {res, moduleType}) => {
    return {
      ...state,
      roundTripSetting: {
        [moduleType]: res
      }
    }
  }),

  on(SettingsActions.updateGetRoundTripSettingSuccess, (state, {res, moduleType}) => {
    return {
      ...state,
      roundTripSetting: {
        [moduleType]: res
      }
    }
  }),

  on(SettingsActions.getObdSettingSuccess, (state, {res}) => {
    return {
      ...state,
      obdSetting: res
    }
  }),

  on(SettingsActions.updateObdSettingSuccess, (state, {res}) => {
    return {
      ...state,
      obdSetting: res
    }
  }),

  on(SettingsActions.getAssignmentWindownSettingsSuccess, (state, {res}) => {
    return {
      ...state,
      windowAssignmentSetting: res
    }
  }),

  on(SettingsActions.updateAssignmentWindownSettingsSuccess, (state, {res}) => {
    return {
      ...state,
      windowAssignmentSetting: res
    }
  }),

  on(SettingsActions.getAllProcessorsSettingO2DSuccess, (state, {processorList}) => {

    return {...state, processorsList: processorList}
  }),

  on(SettingsActions.setFormSettingsByModuleType, (state, {moduleType, settings}) => {
    if (moduleType === CONSTANT.DMS_MODULES.MMD.MODULE) {
      return {
        ...state,
        mmdConsignmentAssignForm: settings
      }
    } else {
      return {
        ...state
      }
    }
  }),
  on(SettingsActions.setFormMetadata, (state, {formMetaData}) => {
    return {
      ...state,
      mmdConsignmentFormMetaData: formMetaData
    }
  }),

  on(SettingsActions.setLMDFormMetadata, (state, {formMetaData}) => {
    return {
      ...state,
      lmdFormMetaData: formMetaData
    }
  }),

  on(SettingsActions.setProgressSettingsByModuleType, (state, {moduleType, settings}) => {
    if (moduleType === CONSTANT.DMS_MODULES.MMD.MODULE) {
      return {
        ...state,
        mmdProgressSettings: settings
      }
    } else if (moduleType === CONSTANT.DMS_MODULES.LMD.MODULE) {
      return {
        ...state,
        lmdProgressSettings: settings
      }
    }
  }),
  on(SettingsActions.getMetaDataForDMSSettings, (state) => {
    return {
      ...state,
      groupLoaderForDMSSettings: true
    }
  }),
  on(SettingsActions.getMetaDataForDMSSettingsSuccess, (state, {moduleType, settings, groupType}) => {
    if (moduleType === CONSTANT.DMS_MODULES.MMD.MODULE) {
      return {
        ...state,
        genericMetaDataSettingsForDms: {
          ...state.genericMetaDataSettingsForDms,
          mmd: {...state.genericMetaDataSettingsForDms?.mmd, [groupType]: settings}
        },
        groupLoaderForDMSSettings: false
      }
    } else if (moduleType === CONSTANT.DMS_MODULES.LMD.MODULE) {
      return {
        ...state,
        genericMetaDataSettingsForDms: {
          ...state.genericMetaDataSettingsForDms,
          lmd: {...state.genericMetaDataSettingsForDms?.lmd, [groupType]: settings}
        },
        groupLoaderForDMSSettings: false
      }
    }
  }),
  on(SettingsActions.getGroupsOfDMSSettingsByModuleSuccess, (state, {res, module}) => {
    return {
      ...state,
      groupsOfSetttingsWithModule: {...state.groupsOfSetttingsWithModule, [module]: res}
    }
  }),

  on(SettingsActions.getAllDMSSettingsWithModuleTypeSuccess, (state, {moduleType, settings}) => {
    if (moduleType === CONSTANT.DMS_MODULES.MMD.MODULE) {
      return {
        ...state,
        genericDMSSettingsWithModuleType: {...state.genericMetaDataSettingsForDms, mmd: settings}
      }
    } else if (moduleType === CONSTANT.DMS_MODULES.LMD.MODULE) {
      return {
        ...state,
        genericDMSSettingsWithModuleType: {...state.genericMetaDataSettingsForDms, lmd: settings}
      }
    }
  }),
  on(SettingsActions.putAllDMSSettingsWithModuleType, (state) => {
    return {
      ...state,
      genericSettingUpdateLoader: true
    }
  }),
  on(SettingsActions.putAllDMSSettingsWithModuleTypeSuccess, (state, {moduleType, path, payload}) => {
    if (moduleType === CONSTANT.DMS_MODULES.MMD.MODULE) {
      return {
        ...state,
        genericSettingUpdateLoader: false,
        genericDMSSettingsWithModuleType: {
          ...state.genericDMSSettingsWithModuleType,
          mmd: {...state.genericDMSSettingsWithModuleType.mmd, [path]: payload}
        }
      }
    } else if (moduleType === CONSTANT.DMS_MODULES.LMD.MODULE) {
      return {
        ...state,
        genericSettingUpdateLoader: false,
        genericDMSSettingsWithModuleType: {
          ...state.genericDMSSettingsWithModuleType,
          lmd: {...state.genericDMSSettingsWithModuleType.lmd, [path]: payload}
        }
      }
    }
  }),
  on(SettingsActions.getGeofenceSettingsByAssetTypeSuccess, (state, {payload}) => {
    return {
      ...state,
      geofenceSettings: {
        ...state.geofenceSettings,
        radiusByAssetType: payload?.radiusByAssetType,
        inAndOut: payload?.inAndOut
      }
    }
  }),
  on(SettingsActions.putGeofenceSettingsByAssetTypeSuccess, (state, {payload}) => {
    return {
      ...state,
      geofenceSettings: {
        ...state.geofenceSettings,
        radiusByAssetType: payload?.radiusByAssetType,
        inAndOut: payload?.inAndOut
      }
    }
  }),
  on(SettingsActions.errorHandlerForGenricDMSSetting, (state) => {
    return {
      ...state,
      genericSettingUpdateLoader: false
    }
  }),

  on(SettingsActions.getAllowedStatusesSettingsByModuleSuccess, (state, {res, moduleType}) => {
    const result = {};
    res.forEach(obj => {
      for (const key in obj) {
        result[key] = obj[key];
      }
    });

    return {
      ...state,
      allowedStatusesForModule: {
        ...state.allowedStatusesForModule,
        [moduleType]: result
      }
    }
  }),

  on(SettingsActions.getRoutePlanCategorySuccess, (state, {routePlanCategory}) => {
    return {
      ...state,
      routePlanCategoryList: routePlanCategory
    }
  }),


  // Gneric Reducer for module settings

  on(SettingsActions.getMetaDataSettingsForSelectedModule, (state) => {
    return {
      ...state,
      groupLoaderForDMSSettings: true
    }
  }),

  on(SettingsActions.getAllSettingsForSelectedModuleSuccess, (state, {res, module}) => {
    return {
      ...state,
      genericStateStoreForAllSettings: {...state.genericStateStoreForAllSettings, [module]: res},
      groupLoaderForDMSSettings: false

    }
  }),

  on(SettingsActions.getMetaDataSettingsForSelectedModuleSuccess, (state, {res, groupType, module}) => {
    return {
      ...state,
      genericStateStoreForMetaDataSettings: {
        ...state.genericStateStoreForMetaDataSettings,
        [module]: {...state.genericStateStoreForMetaDataSettings?.[module], [groupType]: res}
      },
      groupLoaderForDMSSettings: false
    }
  }),

  on(SettingsActions.getGroupTypesForSelectedModuleSuccess, (state, {res, module}) => {
    return {
      ...state,
      genericStateStoreForSettingsGroups: {...state.genericStateStoreForSettingsGroups, [module]: res},
    }
  }),


  on(SettingsActions.updateAllSettingsForSelectedModule, (state) => {
    return {
      ...state,
      genericSettingUpdateLoader: true
    }
  }),
  on(SettingsActions.updateAllSettingsForSelectedModuleSuccess, (state, {payload, typeId, module}) => {
    if (typeId.includes(".")) {
      const key = typeId.split(".")
      return {
        ...state,
        genericStateStoreForAllSettings: {
          ...state.genericStateStoreForAllSettings,
          [module]: {...state.genericStateStoreForAllSettings?.[module], [key[0]]: {[key[1]]: payload}}
        },
        genericSettingUpdateLoader: false
      }
    }
    return {
      ...state,
      genericStateStoreForAllSettings: {
        ...state.genericStateStoreForAllSettings,
        [module]: {...state.genericStateStoreForAllSettings?.[module], [typeId]: payload}
      },
      genericSettingUpdateLoader: false
    }
  }),
);


