import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {ConfigService} from "services/config.service";
import {CustomTranslateHttpLoader} from "services/custom.translate.http.loader";
import {LOCATION_INITIALIZED} from "@angular/common";
import {Injector} from "@angular/core";

export function HttpLoaderFactory(http: HttpClient, config: ConfigService) {
  return new CustomTranslateHttpLoader(http, config);
}

export function ApplicationInitializerFactory(translate: TranslateService, injector: Injector) {
  return async () => {
    const defaultLang = 'en';
    translate.addLangs(['en', 'de', 'ar', 'fr', 'kannada']);
    translate.setDefaultLang(defaultLang);
    try {
      await new Promise(resolve => {
        translate.use(defaultLang).subscribe(resolve);
      });
    } catch (err) {
      console.error(err);
    }
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    console.log(`Successfully initialized ${defaultLang} language.`);
  };
}
