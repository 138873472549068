import {Component, OnInit, OnChanges, Input, EventEmitter, Output} from '@angular/core';
import {CONSTANT} from '../../../config/constant';
declare var $: any;
import { Subject, Actions} from '../../../auth/rules';

@Component({
  selector: 'app-add-asset-list',
  templateUrl: './add-asset-list.component.html',
  styleUrls: ['./add-asset-list.component.scss']
})
export class AddAssetListComponent implements OnChanges, OnInit {
  @Input() menuListItems: any;
  @Output() onMenuSelected: EventEmitter<any> = new EventEmitter();
  SUBJECT = Subject;
  ACTIONS = Actions;
  constructor() {
  }

  ngOnInit() {
  }
  ngOnChanges() {
  }
  menuSelected(assetConfig) {
    this.onMenuSelected.emit(assetConfig);
    $('html').css('overflow-y', 'hidden');
  }

  getIcon(type) {
    switch (type) {
      case CONSTANT.ASSET_TYPES.CAR:
        return '../../assets/asset-icons/taxi-white.png';
      case CONSTANT.ASSET_TYPES.OBD_DEVICE:
        return '../../assets/asset-icons/obd-white.png';
      case CONSTANT.ASSET_TYPES.DRIVER:
        return '../../assets/asset-icons/driver-white.png';
      case CONSTANT.ASSET_TYPES.BEACON:
        return '../../assets/asset-icons/beacon-white.png';
      case CONSTANT.ASSET_TYPES.RF_ID:
        return '../../assets/asset-icons/rfId-white.png';
      case CONSTANT.ASSET_TYPES.BEACON_GATEWAY:
        return '../../assets/asset-icons/beaconGateWay.png';
      case CONSTANT.ASSET_TYPES.RFID_GATEWAY:
        return '../../assets/asset-icons/rfIdGateWay.png';
      case CONSTANT.ASSET_TYPES.SERVER:
        return '../../assets/asset-icons/server-white.png';
      case CONSTANT.SERVICE_TYPES.GENERAL:
        return '../../assets/asset-icons/general-white.png';
      case CONSTANT.ASSET_TYPES.CAMERA:
        return '../../assets/asset-icons/cameraWhite.png';
      case CONSTANT.ASSET_TYPES.FINGER_PRINT_SENSOR:
        return '../../assets/asset-icons/fingerPrintSensorWhite.png';
      case CONSTANT.ASSET_TYPES.PERSON:
        return '../../assets/asset-icons/personWhite.png';
      case CONSTANT.ASSET_TYPES.DOOR:
        return '../../assets/asset-icons/doorWhite.png';
      case CONSTANT.ASSET_TYPES.NFC_CARD:
        return '../../assets/asset-icons/nfc-card-white.png';
      default:
        return '../../assets/exclamation-white.png';
    }
  }

}
