
import {createReducer, on} from "@ngrx/store";
import {WarehouseActions} from ".";
import { Supply, WarehouseSupplyDetailsResponse } from "./interfaces/warehouse.modal";

export interface WarehouseState {
    supplyRawData: WarehouseSupplyDetailsResponse,
    supplyRawDataOfOrder: WarehouseSupplyDetailsResponse,
    supplyTable: Supply[],
    error: any,
    showLoader : boolean,
  newlyAddedBatches: any,
  batchesSoldQuantityInfo: { allSold?: any[], error?: any[] },
  }

export const initialState:WarehouseState = {
  supplyRawData:{
    data: [],
    pagination: null,
    totalCount: null
  },
  supplyRawDataOfOrder:{
    data: [],
    pagination: null,
    totalCount: null
  },
    supplyTable: [],
     error: null,
     showLoader : false,
  newlyAddedBatches: null,
  batchesSoldQuantityInfo: {}
  };

  export const WarehouseReducer = createReducer(
    initialState,
      on(WarehouseActions.loadSupplyListWithPaginationSuccess,(state, {response})=>{
        return{
          ...state,
          supplyRawData: response
        }
      }),
      on(WarehouseActions.loadSupplyListForOrderWithPaginationSuccess,(state, {response})=>{
        return{
          ...state,
          supplyRawDataOfOrder: response
        }
      }),
      on(WarehouseActions.warehouseFailureMethod,(state, {err})=>{
        return{
          ...state,
          error:err
        }
      }),
      on(WarehouseActions.createMultipleBatchSuccess,(state, {res})=>{
        return{
          ...state,
          newlyAddedBatches:res
        }
      }),
      on(WarehouseActions.createMultipleBatchForMultLocationsSuccess,(state, {res})=>{
        return{
          ...state,
          newlyAddedBatches:res
        }
      }),
    on(WarehouseActions.updateSoldBatchesQuantitySuccess, (state, { res }) => {
      return {
        ...state,
        batchesSoldQuantityInfo: res
      }
    }),
    on(WarehouseActions.loadSelectedSupplyListByIdsSuccess, (state, { response }) => {
      const updatedSupplyRawData = {
        ...state.supplyRawData,
        data: state.supplyRawData.data.map(((supply) => {
          return ((response?.data || []).find(s => s._id === supply._id)) || supply
        }))
      }
      return {
        ...state,
        supplyRawData: updatedSupplyRawData
      }
    }),
  )