@if (showMetabase) {
  <div style="width: 100%; height: 90vh">
    <iframe
      [src]="sanitizer.bypassSecurityTrustResourceUrl(iframeSrcUrl)"
      frameborder="0"
      width="100%"
      height="100%"
      allowtransparency
    ></iframe>
  </div>
}
