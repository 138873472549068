import {Component, OnInit, OnChanges, Input, EventEmitter, Output} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-dropdown-onhover',
  templateUrl: './dropdown-onhover.component.html',
  styleUrls: ['./dropdown-onhover.component.scss']
})
export class DropdownOnhoverComponent implements OnChanges, OnInit {
  @Input() menuListItems: any = [];
  @Input() menuLabel: string;
  @Input() menuLabelMatIcon: string;
  @Input() dropdownMenuWidth: string;
  @Input() dropdownMenuLeftPosition: string;
  @Input() modelIdToOpen: string;
  @Output() onItemSelected: EventEmitter<any> = new EventEmitter();
  constructor() {
    this.modelIdToOpen = '';
  }

  ngOnInit(): void {
    $('.dropdownButton').click(false);
  }
  selectedOption: any;

  dropdownOptions = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' }
  ];



  ngOnChanges() {
  }

  itemSelected(item) {
    this.onItemSelected.emit(item);
  }
}
