import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-line-with-count-chart-card',
  templateUrl: './line-with-count-chart-card.component.html',
  styleUrls: ['./line-with-count-chart-card.component.scss']
})
export class LineWithCountChartCardComponent implements OnInit, OnChanges {

  @Input() chartData: any;
  options = null;

  @Input() title: string;
  @Input() date: string;
  @Input() count: any;
  @Input() isHeadingClickable: boolean;
  @Input() titleColor: string;
  @Input() showDateLabel: boolean;
  @Output() sendHeadingToMainComp = new EventEmitter<string>();

  constructor() {
    this.showDateLabel = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'chartData') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.updateGraph(this.chartData);
        }
      }
    }
  }

  ngOnInit(): void {
  }

  updateGraph(data) {
    this.options = {
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '2%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: data.xAxis,
          show: false,
          axisLine: {
            lineStyle : {
              color : (sessionStorage.getItem('theme')) === 'dark-theme' ? 'white' : 'black'
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          minInterval: 1,
          show: true,
          allowDecimals: false,
          axisLine: {
            lineStyle : {
              color : (sessionStorage.getItem('theme')) === 'dark-theme' ? 'white' : 'black'
            }
          }
        }
      ],
      series: []
    };
    this.options.xAxis = [{
      type: 'category',
      data: data.xAxis,
      show: true,
      axisLine: {
        lineStyle : {
          color : (sessionStorage.getItem('theme')) === 'dark-theme' ? 'white' : 'black'
        }
      }
    }];
    this.options.series = data.series;
  }

  titleClicked() {
    this.sendHeadingToMainComp.emit(this.title);
  }
}
