
<div class="card faceMatchCard" [ngClass]="{
 'whiteListBoxShadow' : showGreenShadow === true,
 'blackListBoxShadow' : showRedShadow === true, 'active' : faceData && faceData['cardClicked']}"
     [matBadge]="badgeContent" matBadgeColor="warn">
  @if (faceData) {
    <div class="grid  compareSec" [ngClass]="{'showPointerCursor': isCardClickable === true}"
         (click)="getClickableContent('image')">
      <span class="match-ratio" [ngClass]="{'pass':'high' === checkMatchConfidentialLevel((faceData.matchScore ? (faceData.matchScore | percent) : 'NA'), ''),
      'check':'mid' === checkMatchConfidentialLevel((faceData.matchScore ? (faceData.matchScore | percent) : 'NA'), ''),
       'fail':'low' === checkMatchConfidentialLevel((faceData.matchScore ? (faceData.matchScore | percent) : 'NA'), '')}"
            [pTooltip]="checkMatchConfidentialLevel((faceData.matchScore ? (faceData.matchScore | percent) : 'NA'), 'toolTip')"
            tooltipPosition="top"></span>
      <div class="col-6 ">
        <div class="grid">
          <div class="card-label col-12">
            <span translate>sys.enrolled</span>
          </div>
          <div class="imgContainer col-12" id="{{'referenceImage'+faceData._id}}"
               [ngStyle]="{'height' : !imagesLoader.faceDataReferenceImage ? '106px' : ''}">
            <img class=""
                 [src]="faceMatchService.getFaceMatchImages(faceData.imageReference.referenceImage, 'referenceImage')"
                 alt="enrolled">
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="grid">
          <div class="card-label col-12">
            <span translate>sys.captured</span>
          </div>
          <div class="imgContainer col-12" id="{{'detectedFace'+faceData._id}}"
               [ngStyle]="{'height' : !imagesLoader.faceDataDetectedFace ? '106px' : ''}">
            <img class=""
                 [src]="faceMatchService.getFaceMatchImages(faceData.imageReference.detectedFace, 'detectedFace')"
                 alt="captured">
          </div>
        </div>
      </div>
    </div>
  }

  <!-- compare Section for card in sideBar starts -->
  @if (cardDatafromSideBar && showContext) {
    <div class="grid compareSec-sideBar pb-0">
      <!--<span class="match-ratio">{{(cardDatafromSideBar.matchScore ? (cardDatafromSideBar.matchScore | percent) : 'NA')}}</span>-->
     <span class="match-ratio" [ngClass]="{'pass':'high' === checkMatchConfidentialLevel((cardDatafromSideBar.matchScore ? (cardDatafromSideBar.matchScore | percent) : 'NA'), ''),
      'check':'mid' === checkMatchConfidentialLevel((cardDatafromSideBar.matchScore ? (cardDatafromSideBar.matchScore | percent) : 'NA'), ''),
       'fail':'low' === checkMatchConfidentialLevel((cardDatafromSideBar.matchScore ? (cardDatafromSideBar.matchScore | percent) : 'NA'), '')}"
           [pTooltip]="checkMatchConfidentialLevel((cardDatafromSideBar.matchScore ? (cardDatafromSideBar.matchScore | percent) : 'NA'), 'toolTip')"
           tooltipPosition="top"></span>
      <div class="col-6">
        <div class="grid justify-content-end">
          <div class="card-label col-12 py-1 px-0">
            <span translate>sys.enrolled</span>
          </div>
          <div class="imgContainer col-12" id="cardDataReferenceImage"
               [ngStyle]="{'height' : !imagesLoader.cardDataReferenceImage ? '310px' : ''}">
            <img src={{cardDataImages.referenceImage}} alt="enrolled">
            <!--<div class='curtain'>
            <div class='shine'></div>
          </div>-->
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="grid justify-content-start">
          <div class="card-label col-12 py-1 px-0">
            <span translate>sys.captured</span>
          </div>
          <div class="imgContainer col-12" id="cardDataDetectedFace"
               [ngStyle]="{'height' : !imagesLoader.cardDataDetectedFace ? '310px' : ''}">
            <img src={{cardDataImages.detectedFace}} alt="captured">
            <!--<div class='curtain'>
            <div class='shine'></div>
          </div>-->
          </div>
        </div>
      </div>
    </div>
  }
  <!-- compare Section for card in sideBar ends -->

  @if (cardDatafromSideBar && showContext) {
    <div class="grid autoSec">
      <div class="col-12">
        <div class="grid">
          <div class="card-label col-12 py-1 px-0">
            <span translate>sys.frame</span>
          </div>
          <div class="imgContainer col-12" id="cardDataFrame"
               [ngStyle]="{'height' : !imagesLoader.cardDataFrame ? '310px' : ''}"
               (click)="sendFrameImgToModal()"
               data-toggle="modal" data-target="#modalForFrame" data-backdrop="static" data-keyboard="false">
            <img src={{cardDataImages.frame}} alt="frame">
          </div>
        </div>
      </div>
    </div>
  }

  <div class="grid mx-0">
    <div class="card-text col-12 px-2">
      <div class="grid mx-0 my-1 mb-2">
        <div class="col-6 pr-0">
          <div class="grid">
            <!--   Name Field For FaceMatch Card in Main (FaceMatch Component)   -->
            <div class="col-12">
              @if (faceData && faceData.assetDetected && faceData.assetDetected.name) {
                <span class="field-value nameFromFaceData showPointerCursor" pTooltip="{{faceData.assetDetected.name}}"
                      (click)="getClickableContent('name')"
                      [ngClass]="faceData['isRegistered'] ? 'clickableContent' : 'unClickableContent'"
                      tooltipPosition="top">
                <!--   data-toggle="modal" data-target="#sidebar-right" data-keyboard="false"-->
                <i class="fas fa-user-alt"></i>
                  {{ faceData.assetDetected.name }}
              </span>
              }
              @if (faceData && faceData.assetDetected && faceData.assetDetected.name && isCopyBtnRequired) {
                <span class="copyIcon"
                      (click)="copyTextToClipBoard(faceData.assetDetected.name, 'main')"
                      pTooltip="Copy name" tooltipPosition="top">
                <i class="fas fa-copy mx-1 float-left"></i>
              </span>
              }
              <!--              <div class="messagePopup nameCopiedToClipBoard" *ngIf= "faceData">-->
              <!--                Name copied to clipboard-->
              <!--              </div>-->
            </div>

            <!--   Name Field For FaceMatch Card in SideBar   -->
            @if (cardDatafromSideBar && cardDatafromSideBar.assetDetected && cardDatafromSideBar.assetDetected.name && showContext) {
              <div class="col-12 pr-0">
            <span class="field-value nameFromcardDataFromSideBar" pTooltip="{{cardDatafromSideBar.assetDetected.name}}"
                  tooltipPosition="top" (click)="getClickableContent('name')">
              <i class="fas fa-user-alt"></i>
              {{ cardDatafromSideBar.assetDetected.name }}
            </span>
                @if (cardDatafromSideBar && cardDatafromSideBar.assetDetected && cardDatafromSideBar.assetDetected.name && isCopyBtnRequired) {
                  <span class="copyIcon"
                        (click)="copyTextToClipBoard(cardDatafromSideBar.assetDetected.name, 'sideBar')"
                        pTooltip="Copy name" tooltipPosition="top">
                <i class="fas fa-copy float-left"></i>
              </span>
                }
              </div>
            }

            <div class="col-12">
              @if (faceData && faceData.camera && faceData.camera.name) {
                <span class="field-value showPointerCursor" pTooltip="{{faceData.camera.name}}" tooltipPosition="top"
                      (click)="getClickableContent('camera')"><i
                  class="fas fa-camera"></i> {{ faceData.camera.name }}</span>
              }

              @if (cardDatafromSideBar && cardDatafromSideBar.camera && cardDatafromSideBar.camera.name && showContext) {
              <span class="field-value" pTooltip="{{cardDatafromSideBar.camera.name}}" tooltipPosition="top"
                    (click)="getClickableContent('camera')"><i
                class="fas fa-camera"></i> {{ cardDatafromSideBar.camera.name }}</span>
              }
            </div>
          </div>
        </div>
        <!--         <div class="col-5 px-0">-->
        <!--           <div class="grid mx-0 my-1 justify-content-end">-->
        <!--             <div class="col-12 text-right">-->
        <!--               <span class="personType" matTooltip="Group " matTooltipPosition="above" *ngIf= "faceData && faceData.assetDetected && faceData.assetDetected.personType">{{faceData.assetDetected.personType}}</span>-->
        <!--               <span class="personType" matTooltip="Group" matTooltipPosition="above" *ngIf = "cardDatafromSideBar && cardDatafromSideBar.assetDetected && cardDatafromSideBar.assetDetected.personType && showContext"-->
        <!--                     [ngStyle]="{'color':cardDatafromSideBar.assetDetected.personType === 'Blacklist' ? 'rgba(255, 0, 0, 0.68)' : 'rgba(46, 128, 36, 0.62)' }">{{cardDatafromSideBar.assetDetected.personType}}</span>-->
        <!--             </div>-->
        <!--           </div>-->
<!--         </div>-->
        @if ((ACTIONS.DELETE | able: SUBJECT.FACE_MATCH) && isDeleteBtnRequired) {
          <div class="col-1 px-0">
    <span (click)="deleteEmbeddingId(faceData)" pTooltip="Delete embedding id" tooltipPosition="after">
      <i class="pi pi-trash MT38 cursorPointer showGenericWarningConfirmationPopupBtn"></i>
    </span>
          </div>
        }
      </div>

      <div class="grid mx-0 mb-1 pt-2 pb-1 date-text border-top">


        @if (faceData && faceData['matchScore']) {
          <div class="col-2 p-0 match-count-bottom">
            <span>{{ faceData.matchScore | percent }}</span>
          </div>
        }
        @if (cardDatafromSideBar && cardDatafromSideBar['matchScore']) {
          <div class="col-2 p-0 match-count-bottom">
            <span>{{ cardDatafromSideBar.matchScore | percent }}</span>
          </div>
        }
        @if (faceData) {
          <div class="date pl-0 text-right lighterColor"
               [ngClass]="faceData && faceData['matchScore'] ? 'col-10 padding-0' : 'col-12'">
            <span
              pTooltip="{{'sys.last-updated' | translate}} : {{dateFormatter.convertDateToSelectedTimezone(faceData['updated'])}}"
              tooltipPosition="top"
              class="dotsOnOverflow">{{ dateFormatter.convertDateToSelectedTimezone(faceData['updated']) }}</span>
          </div>
        }
        @if (cardDatafromSideBar && showContext) {
          <div class="date text-right lighterColor"
               [ngClass]="cardDatafromSideBar && cardDatafromSideBar['matchScore'] ? 'col-10' : 'col-12'">
            <span
              pTooltip="{{'sys.last-updated' | translate}} : {{dateFormatter.convertDateToSelectedTimezone(cardDatafromSideBar['updated'])}}"
              tooltipPosition="top"
              class="dotsOnOverflow">{{ dateFormatter.convertDateToSelectedTimezone(cardDatafromSideBar['updated']) }}</span>
          </div>
        }
      </div>
    </div>
  </div>

  @if ((ACTIONS.DELETE | able: SUBJECT.FACE_MATCH) && isDeleteBtnRequired) {
    <span data-toggle="modal" data-target="#sidebar-false-positive" data-backdrop="static" data-keyboard="false"
          class="falsePositiveBtn float-left" (click)="falsePositionBtnClicked(faceData)">
    <img src="../../../assets/asset-icons/true-false-32.png" class="cursorPointe icon-color small-Icon" pTooltip="False positive remove" tooltipPosition="left">
  </span>
  }

  @if ((ACTIONS.DELETE | able: SUBJECT.FACE_MATCH) && isDeleteBtnRequired) {
    <span class="faceMatchDeleteBtn" pTooltip="Delete face match" tooltipPosition="left"
          (click)="deleteFaceMatch(faceData)">
    <i class="pi pi-trash MT24 cursorPointer showGenericWarningConfirmationPopupBtn"></i>
  </span>
  }
</div>
