import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {LatLngExpression} from 'leaflet';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})

export class PathService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  getPathData(carrierIds: string, moduleType: string, pathType: string): Observable<LatLngExpression[]> {
    const url = `${this.configService.appConfig.appBaseUrl}deliveryManagement/assignment/group/deliveryPath/moduleType/${moduleType}/carrierId/${carrierIds}/?pathType=${pathType}`;
    return this.http.get<LatLngExpression[]>(url);
  }
}
