@if (ACTIONS.READ | able: SUBJECT.UI_SETTINGS) {
  <div>
    <a (click)="onConfigButtonClick($event)" class="layout-config-button" id="layout-config-button"
       style="cursor: pointer">
      <i class="pi pi-cog"></i>
    </a>
    <div (click)="appMain.onConfigClick()" [ngClass]="{'layout-config-active': appMain.configActive}"
         class="layout-config">
      <h5>Menu Type</h5>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.layoutMode" [disabled]="isFormDisabled"
                       inputId="layoutMode1"
                       name="layoutMode" value="static"></p-radioButton>
        <label for="layoutMode1">Static</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.layoutMode" [disabled]="isFormDisabled"
                       inputId="layoutMode2"
                       name="layoutMode" value="overlay"></p-radioButton>
        <label for="layoutMode2">Overlay</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.layoutMode" [disabled]="isFormDisabled"
                       inputId="layoutMode3"
                       name="layoutMode" value="slim"></p-radioButton>
        <label for="layoutMode3">Slim</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" (onClick)="app.profileMode = 'top'"
                       [(ngModel)]="app.layoutMode"
                       [disabled]="isFormDisabled" inputId="layoutMode4" name="layoutMode"
                       value="horizontal"></p-radioButton>
        <label for="layoutMode4">Horizontal</label>
      </div>
      <hr/>
      <h5>Menu Color</h5>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.menuMode" [disabled]="isFormDisabled"
                       inputId="menuMode1"
                       name="menuMode" value="light"></p-radioButton>
        <label for="menuMode1">Light</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.menuMode" [disabled]="isFormDisabled"
                       inputId="menuMode2"
                       name="menuMode" value="dark"></p-radioButton>
        <label for="menuMode2">Dark</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.menuMode" [disabled]="isFormDisabled"
                       inputId="menuMode3"
                       name="menuMode" value="gradient"></p-radioButton>
        <label for="menuMode3">Gradient</label>
      </div>
      <hr/>
      <h5>MegaMenu Color</h5>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.megaMenuMode" [disabled]="isFormDisabled"
                       inputId="megaMenuMode1"
                       name="megaMenuMode" value="light"></p-radioButton>
        <label for="megaMenuMode1">Light</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.megaMenuMode" [disabled]="isFormDisabled"
                       inputId="megaMenuMode2"
                       name="megaMenuMode" value="dark"></p-radioButton>
        <label for="megaMenuMode2">Dark</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.megaMenuMode" [disabled]="isFormDisabled"
                       inputId="megaMenuMode3"
                       name="megaMenuMode" value="gradient"></p-radioButton>
        <label for="megaMenuMode3">Gradient</label>
      </div>
      <hr/>
      <h5>User Menu Type</h5>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.profileMode"
                       [disabled]="(app.layoutMode === 'horizontal') || isFormDisabled"
                       inputId="profileMode1" name="profileMode"
                       value="inline"></p-radioButton>
        <label for="profileMode1">Inline</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.profileMode"
                       [disabled]="(app.layoutMode === 'horizontal') || isFormDisabled"
                       inputId="profileMode2" name="profileMode"
                       value="top"></p-radioButton>
        <label for="profileMode2">Top</label>
      </div>
      <hr/>
      <h5>Input Style</h5>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.inputStyle" [disabled]="isFormDisabled"
                       inputId="inputStyle1"
                       name="inputStyle" value="outlined"></p-radioButton>
        <label for="inputStyle1">Outlined</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton (ngModelChange)="sendUpdatedTheme()" [(ngModel)]="app.inputStyle" [disabled]="isFormDisabled"
                       inputId="inputStyle2"
                       name="inputStyle" value="filled"></p-radioButton>
        <label for="inputStyle2">Filled</label>
      </div>
      <hr/>
      <h5>Ripple Effect</h5>
      <p-inputSwitch (ngModelChange)="sendUpdatedTheme()" (onChange)="onRippleChange($event)"
                     [disabled]="isFormDisabled"
                     [ngModel]="app.ripple"></p-inputSwitch>
      <hr/>
      <h5>Special Themes</h5>
      <div class="layout-themes">
        @for (t of specialThemes; track t) {
          <div>
            <a (click)="updateThemeObj(t.name); sendUpdatedTheme()"
               [ngStyle]="!t.image?{'background-image': 'linear-gradient(to right, ' + t.color1 +','+ t.color2+')', 'opacity': ability.can(ACTIONS.WRITE, SUBJECT.UI_SETTINGS) ? '1' : '0.5'}:{} "
               style="cursor: pointer">
              @if (t.image) {
                <img [alt]="t.name"
                     [ngStyle]="{'opacity': ability.can(ACTIONS.WRITE, SUBJECT.UI_SETTINGS) ? '1' : '0.5'}"
                     src="assets/layout/images/configurator/{{t.image}}.jpg"/>
              }
            </a>
          </div>
        }
      </div>
      <h5>Solid Themes</h5>
      <div class="layout-themes">
        @for (t of themes; track t) {
          <div>
            <a (click)="updateThemeObj(t.name);  sendUpdatedTheme()"
               [ngStyle]="{'background-color': t.color, 'opacity': ability.can(ACTIONS.WRITE, SUBJECT.UI_SETTINGS) ? '1' : '0.5'}"
               style="cursor: pointer">
            </a>
          </div>
        }
      </div>
      <!-- <button pButton (click)='sendUpdatedTheme()'>Update Theme</button> -->
    </div>
  </div>
}
<div id="get-theme-color-div" style="background-color: var(--primary-color)"></div>
