import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationActions } from '.';
import { switchMap, from, catchError, of } from 'rxjs';
import { NotificationService } from './notification.service'
import { MessageService } from 'primeng/api';


@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private messageService: MessageService,
  ) {}

  getAllNotification$ = createEffect(() =>
    this.actions$.pipe(
        ofType(NotificationActions.getAllNotifications),
        switchMap(() => {
            //return from(this.notificationService.effectGetNotifications()).pipe()
            return from(this.notificationService.effectGetNotifications()).pipe(
                switchMap((res) => {
                    return [NotificationActions.allNotificationFetchSuccess({ notifications: res })]
                }),
               // catchError((error) => of(SettingsActions.onDefaultTableSettingsForAnAccountFetchFailure({ err: error })))
            )
        })
    )
  )

  toggleNotificationEnabler$ = createEffect(() =>
  this.actions$.pipe(
      ofType(NotificationActions.toggleNotificationEnabler),
      switchMap((action) => {
          return from(this.notificationService.enableNotifications(action.enableStatus)).pipe(
              switchMap((res) => {
                this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Success',
                    detail: action.enableStatus ? 'Notifications Enabled' : 'Notifications Disabled' });
                  return [NotificationActions.toggleNotificationEnablerSuccess({ res: res })]
              },
              )
              ,catchError((error) => of(NotificationActions.toggleNotificationEnablerError({ error: error })))
          )
      })
  )
)

}