<!-- <div class="wrapper">
  <div id="side-bar-grid">
    <span *ngIf="router.url != '/login' && isUserAuthenticated" style="display: block">
      <app-side-menu (resetSearchTextValue)="resetSearchTextValue($event)"></app-side-menu>
    </span>
  </div>

  <div id="content">
    <div id="header-container" *ngIf="router.url != '/login' && isUserAuthenticated">
      <app-header [resetSearchTextToHeader]="resetSearchTextToHeader" [licenceValidityMessage]="licenceValidityMessage"></app-header>
    </div>
    <div class="container-fluid" id="main-container">
      <router-outlet></router-outlet>
      <div *ngIf="showLoader">
        <app-loader></app-loader>
      </div>
    </div>
  </div>
</div> -->

<app-main></app-main>
<!-- <app-side-menu *ngIf="router.url != '/login' && isUserAuthenticated"></app-side-menu> -->