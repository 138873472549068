import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '../../services/config.service';
import { BatchPayload, WarehouseSupplyDetailsPayload, WarehouseSupplyDetailsResponse } from './interfaces/warehouse.modal';


@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  effectGetSupplyList(obj: WarehouseSupplyDetailsPayload): Observable<WarehouseSupplyDetailsResponse> {
    let queryUrl = `${this.configService.appConfig.appBaseUrl}wms/itemBatch/all/?skip=${obj?.skip ?? 0}&limit=${obj?.limit ?? 20}`;
      if (obj?.startDate) {
        queryUrl = `${queryUrl}&startDate=${obj?.startDate.valueOf()}`;
      }
      if (obj?.endDate) {
        queryUrl = `${queryUrl}&endDate=${obj?.endDate.valueOf()}`;
      }
      if (obj?.orderDocId) {
        queryUrl = `${queryUrl}&orderDocId=${obj?.orderDocId}`;
      }
      if (obj?.expiryInDays) {
        queryUrl = `${queryUrl}&type=${obj?.expiryInDays}`;
      }
      if(obj?.sortByFieldsStr){
        queryUrl = `${queryUrl}&sort=${obj?.sortByFieldsStr}`;
      }
      if(obj?.deliveryStatus){
        queryUrl = `${queryUrl}&deliveryStatus=${obj?.deliveryStatus}`;
      }
      if(obj?.lifeCycleStatus){
        queryUrl = `${queryUrl}&lifeCycleStatus=${obj?.lifeCycleStatus}`;
      }
      if(obj?.searchedText){
        queryUrl = `${queryUrl}&search=${obj?.searchedText}`;
      }
      if(obj?.stockLocationId){
        queryUrl = `${queryUrl}&stockLocationId=${obj?.stockLocationId}`;
      }
      if(obj?.recieverLocationId){
        queryUrl = `${queryUrl}&recieverLocationId=${obj?.recieverLocationId}`;
      }
      if(obj?.assetId){
        queryUrl = `${queryUrl}&assetId=${obj?.assetId}`;
      } 
    if (obj?.ids) {
      queryUrl = `${queryUrl}&ids=${obj?.ids}`;
    } 
         return this.http.get<WarehouseSupplyDetailsResponse>(queryUrl);
  }

  createMultipleBatch(locationId:string, payload: BatchPayload[]) {
    return this.http.post(this.configService.appConfig.appBaseUrl + `wms/itemBatch/batchNo/multiple/locationId/${locationId}`, payload);
  }

  createMultipleBatchesForMultipleLocations(batchesformultiplelocations:{locationId:string, batches: BatchPayload[]}[]) {
    return this.http.post(this.configService.appConfig.appBaseUrl + `wms/itemBatch/batchNo/location/multiple`, batchesformultiplelocations);
  }

  updateSoldBatchesQuantity(batches: { batchId: string, soldQuantity: number }[]) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'wms/itemBatch/stockSold', { batches });
  }


}