import {Component, OnDestroy, OnInit} from "@angular/core";
import {AppMainComponent} from "app/core/main/app.main.component";
import {animate, state, style, transition, trigger,} from "@angular/animations";
import {AccountsService} from "services/accounts.service";
import {Router} from "@angular/router";
import {Ability} from "@casl/ability";
import {Actions, Subject} from "auth/rules";
import {Subscription} from "rxjs";

@Component({
  selector: "app-inline-profile",
  templateUrl: "./app.profile.component.html",
  animations: [
    trigger("menu", [
      state(
        "hiddenAnimated",
        style({
          height: "0px",
        })
      ),
      state(
        "visibleAnimated",
        style({
          height: "*",
        })
      ),
      state(
        "visible",
        style({
          height: "*",
        })
      ),
      state(
        "hidden",
        style({
          height: "0px",
        })
      ),
      transition(
        "visibleAnimated => hiddenAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
      transition(
        "hiddenAnimated => visibleAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
    ]),
  ],
  styleUrls: ['./app.profile.component.scss']
})
export class AppProfileComponent implements OnInit, OnDestroy {
  accountsList = new Array<{ _id: string, name: string, title: string }>();
  activeId: string;
  subs = new Array<Subscription>()

  constructor(
    public app: AppMainComponent,
    private accountsService: AccountsService,
    private router: Router,
    private ability: Ability,
  ) {
  }

  ngOnInit() {
    this.subs.push(
      this.accountsService.getLinkedAccounts().subscribe({
        next: (v) => this.accountsList = v,
        error: console.error,
        complete: console.info
      }),
      this.accountsService.getActiveAccount().subscribe({
        next: (v) => this.activeId = v["_id"],
        error: console.error,
        complete: console.info
      })
    );
  }

  onProfileClick(event: MouseEvent) {
    event.preventDefault();
    this.app.usermenuClick = true;
    this.app.usermenuActive = !this.app.usermenuActive;
  }

  onProfileItemClick(event: Event, item, type = "") {
    event.preventDefault();
    this.app.usermenuClick = true;
    if (this.app.activeProfileItem === item) {
      this.app.activeProfileItem = null;
    } else {
      this.app.activeProfileItem = item;
    }
    switch (type) {
      case "profile":
        this.router.navigate(["config/userProfile"]).then(r => r);
        break;
      case "logout":
        this.app.logout().then(r => r);
        break;
      default:
        break;
    }
  }

  showAccountLevelSettings() {
    return this.ability.can(Actions.READ, Subject.ACCOUNT_SETTINGS)
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
