import { createAction, props } from "@ngrx/store";
import { Assigned, IAssetsLinked, ICountStatus, IDeliveryStatus, IGpsData, IMMDCountStatus, IMmdDeliveredAnalyticsData,
     IMmdDeliveredGroupAnalyticsPayload, IO2ddashboardPayload, IO2DOverviewAnalytics, IO2DOverviewAnalyticsPayload,
      IO2dTemperatureGraph, IO2dtimeSlot, IOrderSource, IQueueInTrend, IRiderWisePerformance, ITatOfOrder, ITemperatureDetails,
      ITotalOrderKiosk, IWarehouseZoneList } from "../interface";
import { IGraphSettings } from "../../../services/graph.config.service";
// for the loader
export const changeShowLoaderValue = createAction(
    '[Effect showLoader] on success showLoaderUpdate',
    props<{show : boolean}>()
)
//o2d dashboard actions
export const getO2dOrderStatsDataRequired = createAction(
    '[Load o2d order stats data API] fetch o2dOrderStatsData',
    props<{obj : IO2ddashboardPayload ; providedOrderStatus : string}>()
)

export const getO2dOrderStatsDataSuccess = createAction(
    '[Effect o2d order API] on success o2dOrderStatsData',
    props<{res:ICountStatus , providedOrderStatus : string}>()
)
export const O2dOrderStatsDataFailureMethod = createAction(
    '[Effect o2d order API] on failure o2dOrderStatsData',
    props<{error : Error}>()
)
export const getOrderByTimeSlotRequired = createAction(
    '[Load o2d order by time slot data API] fetch o2dOrderByTimeSlot',
    props<{obj : IO2ddashboardPayload, title : string,graphSettings?:IGraphSettings}>()
)

export const getOrderByTimeSlotSuccess = createAction(
    '[Effect o2d order by time slot API] on success o2dOrderByTimeSlot',
    props<{res:Record<string,IO2dtimeSlot>| IO2dtimeSlot, title:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getOrderByAssignStatusRequired = createAction(
    '[Load o2d order by assign status data API] fetch o2dOrderByAssignStatus',
    props<{obj : IO2ddashboardPayload, title:string,graphSettings?:IGraphSettings}>()
)

export const getOrderByAssignStatusSuccess = createAction(
    '[Effect o2d order by assign status API] on success o2dOrderByAssignStatus',
    props<{res:Record<string, Record<string, Assigned[]>>,title:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getOrderByDeliveryStatusRequired = createAction(
    '[Load o2d order by delivery status data API] fetch o2dOrderByDeliveryStatus',
    props<{obj : IO2ddashboardPayload , title : string,graphSettings?:IGraphSettings}>()
)

export const getOrderByDeliveryStatusSuccess = createAction(
    '[Effect o2d order by delivery status API] on success o2dOrderByDeliveryStatus',
    props<{res:Record<string, Record<string, IDeliveryStatus>>,title:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getOrderByQueueRequired = createAction(
    '[Load o2d order by Queue status data API] fetch o2dOrderByQueueStatus',
    props<{obj : IO2ddashboardPayload, title :string,orderGraphSettings?:IGraphSettings,deliveryPersonGraphSettings?:IGraphSettings}>()
)

export const getOrderByQueueSuccess = createAction(
    '[Effect o2d order by Queue status API] on success o2dOrderByQueueStatus',
    props<{res:IQueueInTrend,title:string}>()
)
export const getCountInQueueRequired = createAction(
    '[Load o2d Count in queue data API] fetch o2dOrderByQueueCount',
    props<{obj : IO2ddashboardPayload}>()
)

export const getCountInQueueSuccess = createAction(
    '[Effect o2d Count in queue API] on success o2dOrderByQueueCount',
    props<{res:ICountStatus}>()
)
export const getDeliveryInCountRequired = createAction(
    '[Load o2d delivery agent count API] fetch o2dOrderByDeliveryAgentCount',
    props<{obj : IO2ddashboardPayload, title: string,graphSettings?:IGraphSettings}>()
)

export const getDeliveryInCountSuccess = createAction(
    '[Effect o2d delivery agent count API] on success o2dOrderByDeliveryAgentCount',
    props<{res:ICountStatus,title:string}>()
)
export const getRiderWisePerformanceRequired = createAction(
    '[Load o2d rider wise performance API] fetch o2dRiderByPerformance',
    props<{obj : IO2ddashboardPayload,title:string,graphSettings?:IGraphSettings}>()
)

export const getRiderWisePerformanceSuccess = createAction(
    '[Effect o2d rider wise performance API] on success o2dRiderByPerformance',
    props<{res:Record<string,IRiderWisePerformance[]>,title:string}>()
)
export const getO2dTatOfOrderRequired = createAction(
    '[Load o2d tat of order API] fetch o2dOrderByTatOfOrder',
    props<{obj : IO2ddashboardPayload,title:string,graphSettings?:IGraphSettings}>()
)

export const getO2dTatOfOrderSuccess = createAction(
    '[Effect o2d tat of order API] on success o2dOrderByTatOfOrder',
    props<{res:Record<string,ITatOfOrder[]>,title:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getDeliveryTemperatureRequired = createAction(
    '[Load o2d delivery temperature API] fetch o2dOrderByDeliveryTemperature',
    props<{obj : IO2ddashboardPayload,title:string,graphSettings?:IGraphSettings}>()
)

export const getDeliveryTemperatureSuccess = createAction(
    '[Effect o2d delivery temperature API] on success o2dOrderByDeliveryTemperature',
    props<{res:Record<string,IO2dTemperatureGraph[]>,title:string,graphSettings?:IGraphSettings}>()
)
export const resetO2dDashboardState = createAction(
    '[Load reset O2d dashboard state] on success resetO2dDashboarState'
)
//mmd-dashboard actions
export const getMmdOrderStatsDataRequired = createAction(
    '[Load mmd order stats data API] fetch mmdOrderStatsData',
    props<{obj : IO2ddashboardPayload ; providedOrderStatus : string}>()
)

export const getMmdOrderStatsDataSuccess = createAction(
    '[Effect mmd order API] on success mmdOrderStatsData',
    props<{res: IMMDCountStatus[] , providedOrderStatus : string}>()
)
//mmd- total order graph  {
export const getMmdOrderTotalOrderCount = createAction(
    '[Load mmd total order data API] fetch mmdTotalOrderDataCount',
    props<{obj : IO2ddashboardPayload,providedOrderStatus : string}>()
)
export const getOrderByTotalOrderGraphRequired = createAction(
    '[Load mmd order bytotal Order data API] fetch mmdOrderByTotalOrderRequired',
    props<{obj : IO2ddashboardPayload , title : string,graphSettings?:IGraphSettings}>()
)
export const getMmdOrderTotalOrdeGraphCountSuccess = createAction(
    '[Effect mmd Dashboard by delivery status API] on success mmdOrderByDeliveryStatusSuccess',
    props<{res: Record<string,IO2dtimeSlot> | IO2dtimeSlot ,title:string,action:IO2ddashboardPayload,graphSettings?:IGraphSettings,graphKey?:string}>()
) 
//  }
// Record<string, Record<string, IDeliveryStatus>>
export const getMmdDashboardByTimeSlotSuccess = createAction(
    '[Effect mmd dashboard by time slot API] on success mmdOrderByTimeSlot',
    props<{res:Record<string,IO2dtimeSlot> | IO2dtimeSlot,title:string,action:IO2ddashboardPayload}>()
)

export const getMmdDashboardByDeliveryStatusSuccess = createAction(
    '[Effect mmd Dashboard by delivery status API] on success mmdOrderByDeliveryStatus',
    props<{res:Record<string, Record<string, IDeliveryStatus>>,title:string,action:IO2ddashboardPayload}>()
)
export const getMmdDeliveryTemperatureRequired = createAction(
    '[Effect mmd Dashboard temperature status API] fetch temperatureStatus',
    props<{obj : IO2ddashboardPayload ; title : string,graphSettings?:IGraphSettings}>()
)
export const getMmdByTemperatureSuccess = createAction(
    '[Effect mmd order by Temperature status API] on success mmdOrderByTemperatureStatus',
    props<{res:Record<string,ITemperatureDetails[]>,title}>()
)

export const getRoutePlansTempData = createAction(
    '[Load mmd API] route plans temp data',
    props<{obj : IO2ddashboardPayload, titles : Record<string,string>,graphSettings?:Record<string,IGraphSettings>}>()
)

export const getRoutePlansTempDataSuccess = createAction(
    '[Load mmd API] route plans temp data success',
    props<{res, titles : Record<string,string>,graphSettings?:Record<string,IGraphSettings>}>()
)

export const getRoutePlansTempDataFailure = createAction(
    '[Load mmd API] route plans temp data failue',
    props<{error }>()
)


export const getRoutePlansAssignedStatusData = createAction(
    '[Load mmd API] route plans assigned status data',
    props<{obj : IO2ddashboardPayload, title : string,limit:number,graphSettings?:IGraphSettings}>()
)

export const getRoutePlansAssignedStatusCountData = createAction(
    '[Load mmd API] route plans assigned status count data',
    props<{obj : IO2ddashboardPayload, title : string,graphSettings?:IGraphSettings}>()
)

export const getRoutePlansAssignedStatusDataSuccess = createAction(
    '[Load mmd API] route plans assigned status data success',
    props<{res, title : string,graphSettings?:IGraphSettings}>()
)

export const getRoutePlansAssignedStatusDataFailure = createAction(
    '[Load mmd API] route plans assigned status data failue',
    props<{error }>()
)



// export const O2dOrderStatsDataFailureMethod = createAction(
//     '[Effect o2d order API] on failure o2dOrderStatsData',
//     props<{error : Error}>()
// )

//lmd dashboard actions
export const getLmdOrderStatsDataRequired = createAction(
    '[Load lmd order stats data API] fetch lmdOrderStatsData',
    props<{obj : IO2ddashboardPayload ; providedOrderStatus : string}>()
)
export const getLmdOrderStatsDataSuccess = createAction(
    '[Effect lmd order API] on success lmdOrderStatsData',
    props<{res: ICountStatus , providedOrderStatus : string}>()
)
export const getLmdDashboardByTimeSlotSuccess = createAction(
    '[Effect lmd dashboard by time slot API] on success lmdOrderByTimeSlot',
    props<{res:Record<string,IO2dtimeSlot>| IO2dtimeSlot,title:string,graphSettings?: IGraphSettings,graphKey?:string}>()
)
export const getLmdDashboardByAgentAssignedStatus = createAction(
    '[Effect lmd dashboard by agent assigned status API] fetch lmdOrderByTimeSlot',
    props<{obj : IO2ddashboardPayload ; title : string,idToRemove:string[],graphSettings?:IGraphSettings}>()
)
export const getLmdDashboardByAgentAssignedStatusSuccess = createAction(
    '[Effect lmd dashboard by agent assigned status API] on success lmdOrderByTimeSlot',
    props<{res:Record<string, Record<string, Assigned[]>>,title:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdDashboardByDeliveryStatusSuccess = createAction(
    '[Effect lmd Dashboard by delivery status API] on success lmdOrderByDeliveryStatus',
    props<{res:Record<string, Record<string, IDeliveryStatus>>,title,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdOrderByQueueSuccess = createAction(
    '[Effect lmd order by Queue status API] get lmd order by queue success',
    props<{res:IQueueInTrend,title:string,orderGraphSettings?:IGraphSettings,deliveryPersonGraphSettings?:IGraphSettings}>()
)
export const getLmdCountInQueueSuccess = createAction(
    '[Effect lmd Count in queue API] on success lmdOrderByQueueCount',
    props<{res:ICountStatus}>()
)
export const getLmdDeliveryInCountSuccess = createAction(
    '[Effect o2d delivery agent count API] on success o2dOrderByDeliveryAgentCount',
    props<{res:ICountStatus,title:string,graphSettings?:IGraphSettings}>()
)
export const getLmdRiderWisePerformanceSuccess = createAction(
    '[Effect lmd rider wise performance API] on success lmdRiderByPerformance',
    props<{res:Record<string,IRiderWisePerformance[]>,title:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdTatOfOrderSuccess = createAction(
    '[Effect lmd tat of order API] on success lmdOrderByTatOfOrder',
    props<{res:Record<string,ITatOfOrder[]>,title:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdByTemperatureSuccess = createAction(
    '[Effect lmd order by Temperature status API] on success lmdOrderByTemperatureStatus',
    props<{res:Record<string,ITemperatureDetails[]>,title,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdTatOfOrderInPercentageRequired = createAction(
    '[Load o2d tat of order in percentage API] fetch lmdOrderByTatOfOrderInPercentage',
    props<{obj : IO2ddashboardPayload,title:string,graphSettings?:IGraphSettings}>()
)

export const getLmdTatOfOrderInPercentageSuccess = createAction(
    '[Effect o2d tat of order in percentage API] on success lmdOrderByTatOfOrderOInPercentage',
    props<{res:Record<string,ITatOfOrder[]>,title:string,graphSettings?:IGraphSettings}>()
)
//ware house dashboard actions
export const getAllDashboardCititesRequired = createAction(
    '[Effect get all cities API] fetch getAllCitiess',
    props<{zone : string}>()
)
export const getAllDashboardCitiesSuccess = createAction(
    '[Effect get all dashboard cities API] on success getAllCitiess',
    props<{res :IWarehouseZoneList[]}>()
)
export const getAllZoneForWarehouseRequired = createAction(
    '[Effect get all warehouse zones by count API] fetch getAllWarehouseZones',
    props<{zone:string,obj:IO2ddashboardPayload}>()
)
export const getAllZoneForWarehouseSuccess = createAction(
    '[Effect get all  Warehouse zones by count API] on Success getAllWarehouseZones',
    props<{res : IWarehouseZoneList[],ObjGrapg}>()
)
export const getWarehouseDashboardZoneListDataRequired = createAction(
    '[Load warehouse dashboard zonelist API] fetch warehouseZoneList',
    props<{res :IWarehouseZoneList[], obj : IO2ddashboardPayload}>()
)
export const getWarehouseDashboardAllMonitoredCityRequired = createAction(
    '[Effect warehouse dashboard cuity API] fetch warehouseCityList',
    props<{cityId : string,zone:string,obj : IO2ddashboardPayload}>()
)
export const getWarehouseDashboardAllMonitoredCitySuccess = createAction(
    '[Effect warehouse dashboard  city API] on success warehouseCityList',
    props<{locationList: IAssetsLinked[]}>()
)
export const getwarehoueseDashboardLocationrequired = createAction(
    '[Effect warehouse dashboard location city API] fetch warehouseLocationList',
    props<{cityId : string,zone:string,obj : IO2ddashboardPayload}>()
)
export const updateWarehouseZoneEchartGraphWebSocketSuccess=createAction(
    '[Effect warehouse dashboard update zoneEchart using webSocket API] on success warehouseDashboardZoneEchartGraphUpdate',
    props<{gpsData:IGpsData}>()
)
export const updateWarehouseSelectedLocationInState = createAction(
    '[Effect warehouse dashboard update selectedLocation] on success warehouseDashSelectedLocationUpdate',
    props<{selectedLocation : IAssetsLinked}>()
)
//warehouse analytics
export const getWarehouseAnalyticsGraphData = createAction(
    '[Effect warehouse analytics graph data] on fetch warehouseAnalyticsGraphDataRequired',
    props<{obj:any}>()
)
export const getWarehouseAnalyticsGraphDataSuccess = createAction(
    '[Effect warehouse analytics graph data] on success warehouseAnalyticsGraphDataSuccess',
    props<{res:any}>()
)
export const resetWarehouseAnalyticsGraphData = createAction(
    '[Effect warehouse analytics graph data reset] on reset warehouseAnalyticsGraphDataReset',
)

//kiosk dashboardx actions
export const getKioskTotalCountCardDataRequired = createAction(
    '[Load kiosk total cards stats data API] fetch kioskOrderStatsData',
    props<{obj : IO2ddashboardPayload ; providedOrderStatus : string}>()
)
export const getKioskTotalCountCardDataSuccess = createAction(
    '[Effect kiosk total count order API] on success kioskOrderStatsTotalCountData',
    props<{res:Record<string,number> , providedOrderStatus : string}>()
)
export const getKioskByTotalOrderRequired = createAction(
    '[Load kiosk order by total order data API] fetch KioskOrderByTotalOrder',
    props<{obj : IO2ddashboardPayload, title : string,graphSettings?:IGraphSettings}>()
)
export const getKioskByTotalOrderSuccess = createAction(
    '[Load kiosk order by total order API] on Success KioskOrderByTotalOrder',
    props<{res: Record<string,ITotalOrderKiosk[]>, title : string,graphKey?:string,graphSettings?:IGraphSettings}>()
)
export const getKioskByPaidOrderRequired = createAction(
    '[Load kiosk order by paid order data API] fetch KioskOrderByPaidOrder',
    props<{obj : IO2ddashboardPayload, title : string,graphSettings?:IGraphSettings}>()
)
export const getKioskByPaidOrderSuccess = createAction(
    '[Load kiosk order by paid order API] on Success KioskOrderByPaidOrder',
    props<{res: Record<string,ITotalOrderKiosk[]>, title : string,graphKey?:string,graphSettings?:IGraphSettings}>()
)
export const getKioskByDiscardedOrderRequired = createAction(
    '[Load kiosk order by discarded order data API] fetch KioskOrderByDiscardedOrder',
    props<{obj : IO2ddashboardPayload, title : string,graphSettings?:IGraphSettings}>()
)
export const getKioskByDiscardedOrderSuccess = createAction(
    '[Load kiosk order by discarded order API] on Success KioskOrderByDiscardedOrder',
    props<{res: Record<string,ITotalOrderKiosk[]>, title : string,graphKey?:string,graphSettings?:IGraphSettings}>()
)
export const getKioskByRefundedOrderRequired = createAction(
    '[Load kiosk order by refunded order data API] fetch KioskOrderByRefundedOrder',
    props<{obj : IO2ddashboardPayload, title : string,graphSettings?:IGraphSettings}>()
)
export const getKioskByRefundedOrderSuccess = createAction(
    '[Load kiosk order by refunded order API] on Success KioskOrderByRefundedOrder',
    props<{res: Record<string,ITotalOrderKiosk[]>, title : string,graphKey?:string,graphSettings?:IGraphSettings}>()
)
export const getKioskByTotalSalesRequired = createAction(
    '[Load kiosk order by Total sales API] fetch KioskOrderByTotalSaless',
    props<{obj : IO2ddashboardPayload, title : string,fieldName : string,graphSettings?:IGraphSettings}>()
)
export const getKioskByTotalSalesSuccess = createAction(
    '[Load kiosk order by Total sales API] on Success KioskOrderByTotalSales',
    props<{res :Record<string,ITotalOrderKiosk[]>, title : string,fieldName:string,graphKey?:string,graphSettings?:IGraphSettings}>()
)
export const getKioskByTopProductsRequired = createAction(
    '[Load kiosk order by Top Products data API] fetch KioskOrderByTopProducts',
    props<{obj : IO2ddashboardPayload, title : string,graphKey?:string,graphSettings?:IGraphSettings}>()
)
export const getKioskByTopProductsSuccess = createAction(
    '[Load kiosk order by Top Products API] on Success KioskOrderByTopProducts',
    props<{res :Record<string,ITotalOrderKiosk[]>, title : string,graphKey?:string,graphSettings?:IGraphSettings}>()
)
export const getKioskSalesByDineTypes = createAction(
    '[Effect KIOSK Sales API] get kiosk sales by dine types',
    props<{obj : IO2ddashboardPayload, title : string,graphSettings?:IGraphSettings}>()
)


// kiosk analytics
export const getKioskAnalyticsTotalOrdersSuccess = createAction(
    '[Effect get Kiosk analytics Total order API] on success getKioskAnalyticsTotalOrder',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey:string}>()
)
export const getKioskAnalyticsPaidOrderSuccess = createAction(
    '[Effect get Kiosk analytics Paid order API] on success getKioskAnalyticsPaidOrder',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey:string}>()
)
export const getKioskAnalyticsDiscardedOrderSuccess = createAction(
    '[Effect get Kiosk analytics Discarded order API] on success getKioskAnalyticsDiscardedOrder',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey:string}>()
)
    export const getKioskAnalyticsRefundedOrderSuccess = createAction(
        '[Effect get Kiosk analytics Refunded order API] on success getKioskAnalyticsRefundedOrder',
        props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey:string}>()
)
export const getKioskAnalyticsByTotalSalesSuccess = createAction(
    '[Effect get kiosk analyticcs by Total sales API] on Success getKioskAnalyticsTotalSales',
    props<{res :Record<string,ITotalOrderKiosk[]>, title : string,fieldName:string,pillValue : string,graphSettings?:IGraphSettings,graphKey:string}>()
)
export const getKioskAnalyticsByTopProductSuccess = createAction(
    '[Effect get kiosk analyticcs by Top product API] on Success getKioskAnalyticsTopProduct',
    props<{res :Record<string,ITotalOrderKiosk[]>, title : string,pillValue : string,graphSettings?:IGraphSettings,graphKey:string}>()
)

export const getKioskSalesByDineTypesSuccess = createAction(
    '[Effect KIOSK Sales API] get kiosk sales by dine types success',
    props<{res :Record<string,ITotalOrderKiosk[]>, title : string,pillValue : string,graphSettings?:IGraphSettings,graphKey:string}>()
)


// // o2d analytics
export const getO2dOrderAnalyticsByLogicticProviderSuccess = createAction(
    '[Effect get order by logistic Provider API] on success getO2dOrderByLP',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getO2dOrderAnalyticsByCitySuccess = createAction(
    '[Effect get order by City API] on success getO2dOrderByCity',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getO2dOrderAnalyticsByOrderTypeSuccess = createAction(
    '[Effect get order by Order Type API] on success getO2dOrderByOrderType',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getO2dOrderAnalyticsByLocationSuccess = createAction(
    '[Effect get order by Location API] on success getO2dOrderByLocation',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getO2dOrderAnalyticsByOrderValueByLPSuccess = createAction(
    '[Effect get order by order value API] on success getO2dOrderByOrderValueByLP',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getO2dOrderAnalyticsByOrderValueByCitySuccess = createAction(
    '[Effect get order by order value by City API] on success getO2dOrderByOrderValueByCity',
    props<{res:Record<string,ITotalOrderKiosk[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getO2dOrderAnalyticsByTatOfOrderSuccess = createAction(
    '[Effect get order by Tat Of Order API] on success getO2dOrderByTatOfOrder',
    props<{res:Record<string,ITatOfOrder[]>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)


// analytics graph actions
// for Mmd
export const getCountByOrderSourceMmdAnalyticsSuccess = createAction(
    '[Load mmd analytics data API] fetch <MmdAnalyticsByOrderSource>',
    props<{res:Record<string,IO2dtimeSlot> | IO2dtimeSlot,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
) 
export const getMmdAnalyticsByDeliveryStatusSuccess = createAction(
    '[Effect mmd analytics by delivery status API] on success mmdAnalyticsByDeliveryStatus',
    props<{res:Record<string, Record<string, IDeliveryStatus>>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getMmdAnalyticsDeliveryTemperatureRequired = createAction(
    '[Effect mmd Analytics temperature status API] on Success mmdAnalyticstemperatureStatus',
    props<{res:Record<string,ITemperatureDetails[]>,title:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
// for Lmd
export const getCountByOrderSourceLmdAnalyticsSuccess = createAction(
    '[Load lmd analytics data API] on success LmdAnalyticsByOrderSource',
    props<{res:Record<string,IO2dtimeSlot> | IO2dtimeSlot,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getCountByAgentAssignmentLmdAnalyticsSuccess = createAction(
    '[Load lmd analytics data API] on success LmdAnalyticsByAgentAssignedStatus',
    props<{res:Record<string, Record<string, Assigned[]>>,title:string,pillValue : string,idToRemove:string[],graphSettings?:IGraphSettings,graphKey?:string}>()
) 
export const getLmdAnalyticsByDeliveryStatussSuccess = createAction(
    '[Effect lmd analytics by Delivery Status API] on success lmdAnalyticsByDeliveryStatus',
    props<{res:Record<string, Record<string, IDeliveryStatus>>,title:string,pillValue : string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdAnalyticsByQueueSuccess = createAction(
    '[Effect lmd analytics by Queue status API] on success lmdAnalyticsByQueueStatus',
    props<{res:IQueueInTrend,title:string,pillValue:string,orderGraphSettings?:IGraphSettings,deliveryPersonGraphSettings?:IGraphSettings}>()
)
export const getLmdRAnalyticsRiderWisePerformanceSuccess = createAction(
    '[Effect lmd Analytics rider wise performance Api] on success lmdAnalyticsRiderByPerformanceStatus',
    props<{res:Record<string,IRiderWisePerformance[]>,title:string,pillValue:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdAnalyticsByAgentRequired = createAction(
    '[Effect lmd analytics by Agent status API] fetch lmdAnalyticsByAgent',
    props<{obj:IO2ddashboardPayload,title:string,pillValue:string,graphSettings?:IGraphSettings}>()
)
export const getLmdRAnalyticsOrderByAgentSuccess = createAction(
    '[Effect lmd Analytics rider wise performance Api] on success lmdAnalyticsROrderByAgentStatus',
    props<{res: Record<string,ITotalOrderKiosk[]>,title:string,pillValue:string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdAnalyticsTatOfOrderSuccess = createAction(
    '[Effect lmd  Analytics tat of order API] on success lmdAnalyticsOrderByTatOfOrder',
    props<{res:Record<string,ITatOfOrder[]>,title:string,pillValue: string,graphSettings?:IGraphSettings,graphKey?:string}>()
)
export const getLmdRAnalyticsDeliveryTemperatureSuccess = createAction(
    '[Effect lmd Analytics Delivery temp performance Api] on success lmdAnalyticsByDeliveryTemperature',
    props<{res: Record<string,ITemperatureDetails[]>,title:string,pillValue:string,graphKey?:string}>()
)
export const resetGraphTypePillValueRequired = createAction(
    '[Effect reset graph type pill value] on fetch resetGraphTypeValueRequired',
    props<{title:string,pillValue:string,selector : any,moduleName : string,isStacked?:boolean}>()
)
export const resetGraphTypePillValueSuccess = createAction(
    '[Effect reset graph type pill value] on success resetGraphTypeValueSuccess',
    props<{title:string,obj:IOrderSource , moduleName : string}>()
)

// mmd deliver analytics
export const getMmdDeliveredGroupAnalytics = createAction(
    '[Effect mmd API] get mmd delivered group analytics data',
    props<{obj : IMmdDeliveredGroupAnalyticsPayload}>()
)

export const getMmdDeliveredGroupAnalyticsSucces = createAction(
    '[Load mmd API] get mmd delivered group analytics data success',
    props<{res : IMmdDeliveredAnalyticsData}>()
)

// o2d overview analytics
export const getO2dOverviewAnalytics = createAction(
    '[Effect o2d API] get o2d overview analytics data',
    props<{obj : IO2DOverviewAnalyticsPayload, sortOrder:number, sortField:string}>()
)

export const getO2dOverviewAnalyticsSucces = createAction(
    '[Load o2d API] get o2d overview analytics data success',
    props<{res: IO2DOverviewAnalytics}>()
)

export const getO2dOverviewAnalyticsDataWithSkipLimit = createAction(
    '[Load o2d API] get o2d overview analytics data with skip and limit',
    props<{skip: number, limit: number, sortOrder:number, sortField:string, searchedText: string}>()
)

export const getTemperatureAndHumidityGraph = createAction(
    '[Effect DMS API] get temperature and humidity graph',
    props<{startTime, endTime, selectedVIds, series, dateDisplayFormate, selectedVehicles}>()
)

export const getTemperatureAndHumidityGraphSuccess = createAction(
    '[Load DMS API] get temperature and humidity graph success',
    props<{res:any,dateDisplayFormate, selectedVehicles,selectedVIds}>()
)


export const getLMDTemperatureAndHumidityGraph = createAction(
    '[Effect LMD API] get temperature and humidity graph',
    props<{startTime, endTime, carsSelected, series, dateDisplayFormate}>()
)

export const getLMDTemperatureAndHumidityGraphSuccess = createAction(
    '[Load LMD API] get temperature and humidity graph success',
    props<{res:any,dateDisplayFormate}>()
)


export const getDMSVehicleDistanceTravelledGraph = createAction(
    '[Effect DMS API] get DMS vehicle distance travelled graph',
    props<{startTime, endTime, carsSelected,series,title,graphType}>()
)

export const getDMSVehicleDistanceTravelledGraphSuccess = createAction(
    '[Load DMS API] get DMS vehicle distance travelled graph success',
    props<{distanceChartData}>()
)

export const getDMSDistanceTravelledByTimeAndSeriesType = createAction(
    '[Effect DMS API] get DMS distance travelled by time and series type',
    props<{startTime, endTime, carsSelected,series,title,graphType}>()
)

export const getDMSDistanceTravelledByTimeAndSeriesTypeSuccess = createAction(
    '[Load DMS API] get DMS distance travelled by time and series type success',
    props<{distanceChartData,title,series,graphType}>()
)

export const changeDMSVehicleDistanceTravelledGraphType = createAction(
    '[Effect DMS API] change DMS Vehicle Distance Travelled Graph Type',
    props<{graphType:string}>()
)