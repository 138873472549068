<div class="assetListGrid" id="assetListingPopup">

  <div class="grid mx-0 my-1 py-1 justify-content-between align-items-center">

    <!--  Drop-down Menu  -->
    <div class="form-group py-2 col-12 md:col-6 lg:col-6 xl:col-6">

      <p-dropdown class="dropdown col-6 sm:col-3 lg:col-5 xl:col-3" [options]="addableAssetsWithLabelsAndIcons"
                  (onChange)="setSelectedAssetType($event)" [ngStyle]="{'margin-left': '-7px'}"
                  optionLabel="label"
      >
        <ng-template pTemplate="selectedItem"  let-selectediItem>
          <div class="flex align-items-center gap-2 dropown">
            <div class="img-container"
                 [ngStyle]="{'background-color' : selectediItem?.['color'] ? selectediItem?.['color'] : ''}">
              <img src="{{selectediItem?.icon}}" class="asset-icon-color"/>
            </div>
            <div>{{ selectediItem?.['label'] | translate }}</div>
          </div>
        </ng-template>
        <ng-template let-dataObj pTemplate="item">
          <div class="flex align-items-center gap-2 dropown">
            <div [ngStyle]="{'background-color' : dataObj?.['color'] ? dataObj['color'] : ''}" class="img-container">
              <img src="{{dataObj?.icon}}" class="asset-icon-color"/>
            </div>
            <div>{{ dataObj?.label | translate }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <!--  Search Box-->
    <div class="form-group py-2 col-12 md:col-6 lg:col-6 xl:col-6">
      <span class="full-width" [ngClass]="{'p-input-icon-left':!searchForAsset,'p-input-icon-right':searchForAsset}">
        @if (!searchForAsset) {
          <i class="pi pi-search"></i>
        }
        @if (searchForAsset) {
          <i class="pi pi-times cursorPointer" (click)="searchAsset('')"></i>
        }
        <input class="full-width" type="text"
               placeholder="Search for things in {{getLangLabel(defaultAssetForDropDown['assetLabel'])}}"
               [(ngModel)]="searchForAsset" (ngModelChange)="searchAsset($event)" pInputText id="purposeinputtext">
        </span>
    </div>
    <div class="btn-cancel-container">
      <div class="grid mx-0 py-0 px-0 mt-0 asset-buttons-container">
        <div class="col-12">
          <div class="grid mx-0 my-2 px-0 py-0 justify-content-start">
            @if (addAssetsIds.length > 0) {
              <button pButton pRipple type="button" label="{{addAssetsIds.length === 1 ? 'Thing' : 'Things'}}"
                      color="accent" type="button" class="linkBtn showLinkOrAddActionAlertPopupBtn buttonEnable mx-1"
              ><i class="pi pi-plus" style="margin-right: 4px;"></i></button>
            }

            <button pButton pRipple type="button" label="{{'sys.cancel' | translate}}" color="warn"
                    id="assetListBackBtn" class="mx-1 p-button-danger" (click)="resetSearchedAssets(false)"></button>
          </div>
        </div>
      </div>
    </div>

    @if (assetNotFound && defaultAssetForDropDown['assetLabel'] !== '') {
      <div class="grid mx-0 asset-not-found" style="height: 50%"><h3 class="asset-not-found m-auto">
        <span>&ldquo;</span>{{ 'sys.asset-not-found' | translate }}<span>&rdquo;</span></h3></div>
    }
    <div class="grid mx-1 mt-1 assetListContainer"
         [ngStyle]="{'height' : searchedAssetForAdding && searchedAssetForAdding.length !== 0 ? '52%' : '0%'} ">
      @for (asset of searchedAssetForAdding; track asset) {
        <div class="xl:col-6 app-card-div relative">
          <app-thing-card [assetData]="asset" [config]="assetConfig" [assetConfigDetails]="assetConfig"
                          [showCloseBtn]="false" [showGroupBtn]="true" [isLinkable]="true"
                          [isLinkableTooltipValue]="'click to select and add things'"
                          [isCardClickable]="false" [showLinkBtn]="true"
                          (linkAssetDetails)="assetsDetailsForAddingToGroup($event)"
                          [imageDisplayStatus]="(cardsImageDisplayStatus$ | async)?.[asset.assetType]"></app-thing-card>
        </div>
      }
    </div>
  </div>
