import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {forEach, cloneDeep, findIndex} from 'lodash';
import { CONSTANT } from '../../../config/constant';
import { currentActiveId, selectIsSidebarFullScreen} from '../../../state/Sidebar/sidebar.selector';
import {AssetService} from '../../../services/asset.service';
import {RoleAssetAuth} from '../../authorization/role-asset-auth/role-asset-auth.component';
import { RoleAssetAuthService } from '../../../app/authorization/services/role-asset-auth.service';

const SIDEBAR_ID = CONSTANT.SIDEBAR_IDS.ROLE_ASSET_AUTH_MODAL;

@Component({
  selector: 'app-role-asset-auth-form',
  templateUrl: './role-asset-auth-form.component.html',
  styleUrls: ['./role-asset-auth-form.component.scss']
})
export class RoleAssetAuthFormComponent implements OnInit, OnDestroy {
  isSidebarClosed= true;
  sidebarIsFullScreen$ = this.store.select(selectIsSidebarFullScreen);
  @Input() formFormAdmin: boolean;
  @Input() selectedAccountDetails: any;
  @Input() usersForSelectedAcc: any = [];
  @Input() resetRandomNo: number;
  @Input() roleAssetAuthIdToEdit: string;
  @Input() roleAssetAuthDetailsForSelectedRole: RoleAssetAuth[];
  @Output() createModalClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() roleAuthAssetToSaveOrUpdate: EventEmitter<any> = new EventEmitter();
  @Output() selectedRoleEvent: EventEmitter<any> = new EventEmitter();
  public accountLinkedAssetList = [];
  public selectedAsset: any = null;
  roleAlreadyHaveAssetFilter: boolean;

  _form = {
    accountId: null,
    assets: {},
    userIds: [],
    showLinkedAssets:false
  }
  _selectAssetTypes = [];
  _selectAssetTypesObj = {};
  accountName: string = null;
  showCircularLoader: boolean;
  sidebarId = SIDEBAR_ID;
   currentActiveSidebarId$;
   isCurrentIdActive = false;

  constructor(private assetService: AssetService, private store: Store , private roleAssetAuthService : RoleAssetAuthService) { 
    this.formFormAdmin = false;
  }

  ngOnInit(): void {
    this.currentActiveSidebarId$ = this.store.select(currentActiveId).subscribe(res =>{
      if(res === this.sidebarId)
      this.isCurrentIdActive = true;
      else
      this.isCurrentIdActive = false;
    })
  }

  ngOnDestroy(){
    this.currentActiveSidebarId$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'selectedAccountDetails') {
        if (change.currentValue !== change.previousValue) {
          if (this.selectedAccountDetails) {
            this.buildForm();
          }
        }
      }
      if (propName === 'roleAssetAuthDetailsForSelectedRole') {
        if (change.currentValue !== change.previousValue) {
          if (this.roleAssetAuthDetailsForSelectedRole) {
            if(this.roleAssetAuthDetailsForSelectedRole?.['assets']){
              this.roleAlreadyHaveAssetFilter = true;
              this.showCircularLoader = false;
              this._form={...this._form,showLinkedAssets:this.roleAssetAuthDetailsForSelectedRole?.['showLinkedAssets']}
              this.buildSelectAssetTypesForRole(this.roleAssetAuthDetailsForSelectedRole?.['assets']);
            }
          } else{
            this.roleAlreadyHaveAssetFilter = false;
            this.showCircularLoader = false;
            this.reset();
          }
        }
      }
      if (propName === 'resetRandomNo') {
        if (change.currentValue !== change.previousValue) {
          if (this.resetRandomNo) {
            this.reset();
          }
        }
      }
      if (propName === 'roleAssetAuthIdToEdit') {
        if (change.currentValue !== change.previousValue) {
          if (this.roleAssetAuthIdToEdit) {
            this._form.userIds = [];
             this._form.userIds.push(this.roleAssetAuthIdToEdit);
             this.showCircularLoader = false;
          } else {
            this._form.userIds = [];
          }
        }
      }
    }
  }

  buildForm() {
    this.accountName = null;
    this._form.accountId = null;
    this.showCircularLoader = true;
    if(this.selectedAccountDetails){
      this.roleAssetAuthService.getAssetType().subscribe(res=> {
       if(res && res['assetTypes'].length > 0) {
         this.accountLinkedAssetList = res['assetTypes'];
        this.showCircularLoader = false;
       }else {
        this.accountLinkedAssetList = [];
        this.showCircularLoader = false;
       }
      })
      // if( this.selectedAccountDetails.assetTypes && this.selectedAccountDetails.assetTypes.length > 0) {
      //   this.accountLinkedAssetList = [];
      //   const assetListCopy = [];
      //   console.log(this.selectedAccountDetails.assetTypes,'this.selectedAccountDetails.assetTypes')
      //   forEach(this.selectedAccountDetails.assetTypes, function(aType) {
      //     asset[aType] = [[]];
      //     const obj = {
      //       name: ''
      //     }
      //     obj['name'] = aType;
      //     assetListCopy.push(obj);
      //   });
      //   console.log(assetListCopy,'assetlistcopy');
      //   this.accountLinkedAssetList = cloneDeep(assetListCopy);
      // } else {
      //   this.accountLinkedAssetList = [];
      // }
      this._form.accountId = this.selectedAccountDetails._id;
      this.accountName = this.selectedAccountDetails.title
    }
  }

  buildSelectAssetTypesForRole(arr){
    this.showCircularLoader = true;
    const aPromises = []
    forEach(arr, (_, key) => {
      const obj = {
        type: key
      }
      const p = this.setSelectedAssetTypep(obj);
      aPromises.push(p)
    });
    Promise.all(aPromises).then(()=>{{
      this._form.assets = cloneDeep(arr);
      this.showCircularLoader = false;
    }}).catch(()=>{{}})
  }

  setSelectedAssetTypep(aType){
    return new Promise((resolve, reject)=>{
    const type = aType.type;
    const index = findIndex(this._selectAssetTypes, function(o) { return o == type; });

    if(index < 0) {
      this._selectAssetTypes.push(type);
      this.assetService.getAssetByAccountId(this.selectedAccountDetails._id, type, 0, 100)
      .subscribe(data => {
          this._selectAssetTypesObj[type] = data;
       resolve(data)
      }, err =>{
        reject(err);
      })
    }
    })
    
  }

  setSelectedAssetType(aType){
    const type = aType.type;
    const index = findIndex(this._selectAssetTypes, function(o) { return o == type; });

    if(index < 0) {
      this._selectAssetTypes.push(type);
      this.assetService.getAssetByAccountId(this.selectedAccountDetails._id, type, 0, 100).subscribe(data => {
       this._selectAssetTypesObj[type] = data;
       this._form.assets[type] = [];
      })
    }
    
  }

  reset(){
    this._selectAssetTypesObj = {};
    this._selectAssetTypes = [];
    this._form.assets = {};
    this.selectedAsset = null;
  }

  submitDisabled(){
    let disable = true;
    if(this._form.userIds.length > 0 && !this.showCircularLoader){
      disable = false;
    }
    return disable;
  }

  selectedRoleChange(role){
    this.showCircularLoader = true;
    this.selectedRoleEvent.emit(role);
  }

  save(){
    const obj = {
      type: "save",
      payload: this._form
    }

    this.roleAuthAssetToSaveOrUpdate.emit(obj);

  }

  update(){
    const obj = {
      type: "update",
      payload: this._form,
      roleAuthId: this.roleAssetAuthDetailsForSelectedRole?.['_id']
    }
    this.roleAuthAssetToSaveOrUpdate.emit(obj);
  }

  closeBtnClicked() {
    this.reset();
    this._form.userIds = [];
    if(!this.isSidebarClosed)
      this.createModalClosedEvent.emit(true);
    this.isSidebarClosed = true;
  }

  adminSubmitDisabled(){
    let disable = false;
    if (!Object.keys(this._form.assets).length) {
      disable = true;
    }
    return disable;
  }

  getLabel(val){
    const asset = this.accountLinkedAssetList?.find(v => v?.type === val)
    if(asset)
    return asset?.label
    else
    return val
  }

}
