import { createReducer, on } from "@ngrx/store"
import { ETACalculationAction } from "."

export interface ETARequiredState {
    lmd : Record<string, unknown>,
     mmd : Record<string, unknown>
}


export const initialState : ETARequiredState = {
    lmd : {eta: null},
  mmd: { eta: null }
}

// const initialState: ETARequiredState = {
	// lmd: { eta : Record<string> , error : Error },
	// mmd: { eta : undefined },
// };


export const ETARequiredReducer = createReducer(
    initialState,
  on(ETACalculationAction.getETACalculationRequiredSuccess, (state, { response, moduleType }) => {
    if (response?.eta) { 
      return {
        ...state,
        [moduleType]: {
          'eta': response.eta
        }
      }
    }
       
    }),
    on(ETACalculationAction.ETACalculationRequiredFailureMethod, (state)=>{
    return {
        ...state
    }
    }),
    on(ETACalculationAction.updateETACalculationRequiredSuccess,(state, {response,moduleType}) =>{
        return {
            ...state,
             [moduleType] : {
                'eta' : response.eta,
             }
        }
    }),
)