<div class="month-picker-input-wrapper">
  <div class="form-group">
    <span class="p-float-label">
      <!-- <mat-form-field color="accent" class="full-width" appearance="outline" autofill="false"> -->
      <!-- <mat-label>Select Month</mat-label> -->
      <input pInputText id="input-monthPicker" type="text" [(ngModel)]="monthPickerOptions.selectedMonths" (click)="openMonthPicker()" autocomplete="off" inputId="float-label">
    <!-- </mat-form-field> -->
    <label for="float-label">Select Month</label>
  </span>
  </div>

  @if (monthPickerOptions.monthPickerOpened) {
    <div id="monthPicker" tabindex="1" (blur)="onBlur()">
    <div class="topPanel">
      <button class="prevYearButton monthPickerChevron" (click)="decrementYear()" [disabled]="disabledBtns['prev']">
        <i class="arrow left"></i>
      </button>
      <span class="yearLabel">{{ years[currentYearIndex] }}</span>
      <button class="nextYearButton monthPickerChevron" (click)="incrementYear()" [disabled]="disabledBtns['next']">
        <i class="arrow right"></i>
      </button>
    </div>
    <div class="contentPanel">
      @for (month of monthDataSlice; track month; let i = $index) {
        <div (click)="onClick(i)" class="monthItem"
             [ngClass]="{ isEdge: rangeIndexes[0]===globalIndexOffset+i || rangeIndexes[1]===globalIndexOffset+i, notCurrentYear: currentYearIndex===0? i > 11:(i < 6 || i > 17)}">
          <div class="monthItemHighlight"
               [ngClass]="{ inRange: month.isInRange, isLowerEdge: month.isLowerEdge, isUpperEdge: month.isUpperEdge }">
            {{ month.monthName }}
          </div>
        </div>
      }
    </div>
  </div>
  }

</div>
