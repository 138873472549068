import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TooltipModule} from 'primeng/tooltip';

@Component({
  selector: 'app-battery-icon',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './battery-icon.component.html',
  styleUrls: ['./battery-icon.component.scss']
})
export class BatteryIconComponent {
  @Input() public batteryStatus: string;
  @Input() public batteryPercentage: number | undefined;
  @Input() public selected: boolean;

  getToolTipBattery() {
    return `${this.toTitleCase(this.batteryStatus)} ${this.batteryPercentage}%`;
  }

  toTitleCase(str) {
    return str ? str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    ) : '';
  }


  backgroundColorBattery() {
    if (this?.batteryPercentage)
      return this.batteryPercentage > 50 ? "rgb(94, 255, 0)" :
        (this.batteryPercentage > 20 ? "orange" : "red")
    else return "rgb(94, 255, 0)"
  }

}
