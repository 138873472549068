import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "../../services/config.service";
import { ICollaboration, IImageData } from "./interfaces/customer.collaboration.interface";
import { DateFormatterService } from "../../services/dateformat";
import { Observable } from "rxjs";
import { ImageUploadService } from "../../services/image.upload.service";

@Injectable({
  providedIn: "root",
})
export class CustomerCollaborationService {
  constructor(private http: HttpClient, public configService: ConfigService, private dateFormatter: DateFormatterService, private imageUploadService: ImageUploadService) { }

  loadCustomerCollaborations(orderDocId: string) :Observable<ICollaboration[]>{
    return this.http.get<ICollaboration[]>(
      this.configService.appConfig.appBaseUrl +
        "orderToDelivery/custCollab/all?orderDocId=" +
        orderDocId +
        "&isPopulate=true"
    );
  }

  getProductImageUploadUrl(orderDocId: string, productId: string):string {
    return (
      this.configService.appConfig.appBaseUrl +
      "orderToDelivery/custCollab/files/" +
      orderDocId +
      "/" +
      productId
    );
  }

  sendOrderCollaboartionImagesToCustomer(
    orderDocId: string,
  ):Observable<{msg:string}> {
    const url =
      this.configService.appConfig.appBaseUrl +
      "orderToDelivery/custCollab/sendToCustomer/" +
      orderDocId;

    return this.http.put<{msg:string}>(url,{});
  }

  uploadProductImages(imagesToUpload:{url:string}[], orderDocId: string, productId: string) :Observable<IImageData[]>{
    const url = this.getProductImageUploadUrl(orderDocId, productId);
    const formData = new FormData();

    if (Array.isArray(imagesToUpload)) {
      imagesToUpload.forEach((image, index) => {
        let file = this.imageUploadService.dataURLtoBlob(image.url);
        formData.append(`files`, file, `filename_${index}.png`);
      });
    }

    return this.http.post<IImageData[]>(url, formData);
  }

  assignIndicesToImages(collaborations:ICollaboration[]) {
    return collaborations.map((collab, collabIndex) => {
      const productsCopy = collab.products.map((productDetail) => {
        const imagesCopy = productDetail.images.map((image, index) => {
          image.idx = index;
          return image;
        });
        productDetail.images = imagesCopy;
        return productDetail;
      });
      collab.products = productsCopy;
      collab.idx = collabIndex + 1;
      collab.updatedDate = this.dateFormatter.convertDateToSelectedTimezone(collab.updated)
      return collab;
    });
  }

}