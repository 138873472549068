(() => {
  var t = [(t, i, n) => {
      var e = n(1);
      L.MotionMarker = L.Marker.extend({
        options: {
          animate: !0,
          duration: 5e3 - e.reductionDuration,
          followMarker: !1,
          hideMarker: !1,
          rotateMarker: !1,
          rotateAngle: 0,
          speed: 0
        },
        initialize: function (t, i) {
          L.Marker.prototype.initialize.call(this, t[0], i), this._movingEnded = !1, this._prevLatLng = t[0], this._nextLatLng = t[1], this._animate = this.options.animate, this._rotateMarker = this.options.rotateMarker, this.options.speed > 0 ? (this._speed = this.options.speed, this._convertSpeedToDuration()) : this._duration = this.options.duration - e.reductionDuration, this._rotateMarker && (this._rotateAngle = this.options.rotateAngle, this._tempRotateAngle = 0);
        },
        onAdd: function (t) {
          L.Marker.prototype.onAdd.call(this, t), this._nextLatLng && (this._updateTempLatLng(this._prevLatLng, this._nextLatLng), this._animStartTime = performance.now(), this._animStartTimeStamp = performance.now(), this._doAnimation()), this.options.hideMarker && this.hideMarker(!0);
        },
        onRemove: function (t) {
          L.Marker.prototype.onRemove.call(this, t), this._movingEnded || L.Util.cancelAnimFrame(this._frameAnimId), this._movingEnded = !0;
        },
        _interpolatePosition: function (t, i, n, e) {
          var s = e / n;
          return s = (s = s > 0 ? s : 0) > 1 ? 1 : s, L.latLng(t[0] + s * (i[0] - t[0]), t[1] + s * (i[1] - t[1]));
        },
        moveTo: function (t, i = {}) {
          this._nextLatLng = t, this._animStartTime = performance.now(), this._animStartTimeStamp = performance.now(), this._updateTempLatLng(this._prevLatLng, t), i.hasOwnProperty("duration") && (this._duration = i.duration - e.reductionDuration), i.hasOwnProperty("speed") && (this._speed = i.speed, this._convertSpeedToDuration()), this._rotateMarker && this._rotateAngle !== i.rotateAngle && (this._rotateAngle = i.rotateAngle), this._doAnimation();
        },
        _followMarker: function () {
          var t = this._animate ? this._duration / 1e3 : 0;
          this._map.setView(this._nextLatLng, this._map.getZoom(), {
            duration: t + 1,
            animate: !0
          });
        },
        activeFollowMarker: function (t) {
          if (this.options.followMarker = t, this._map && this._nextLatLng && !this._movingEnded) {
            var i = performance.now() - this._animStartTime,
              n = this._interpolatePosition(this._prevLatLng, this._nextLatLng, this.options.duration, i);
            if (t && !this._movingEnded) {
              this._map.setView([n.lat, n.lng], this._map.getZoom(), {
                animate: !1
              });
              var e = this._animate ? this._duration / 1e3 : 0;
              this._map.setView(this._nextLatLng, this._map.getZoom(), {
                duration: e + 1,
                animate: !0
              });
            }
          }
        },
        _updatePrevLatLng: function () {
          this._prevLatLng = this._nextLatLng;
        },
        _convertSpeedToDuration: function () {
          var t = 1e3 * this._speed / 3600,
            i = L.latLng(this._prevLatLng[0], this._prevLatLng[1]).distanceTo({
              lat: this._nextLatLng[0],
              lng: this._nextLatLng[1]
            });
          this._duration = 1e3 * Number((i / t).toFixed(0)) - e.reductionDuration;
        },
        _doRotation: function () {
          this._rotateAngle !== this._tempRotateAngle && (this.options.rotateMarker && (this._icon.childNodes[0].style.transformOrigin = "center", this._icon.childNodes[0].style.transform = "rotate(" + this._rotateAngle + "deg)", this._icon.childNodes[0].style.transition = "transform .2s"), this._tempRotateAngle = this._rotateAngle);
        },
        _doAnimation: function () {
          if (this._movingEnded = !1, this.options.rotateMarker && this._doRotation(), this.options.followMarker && this._followMarker(), !this._animate && !this._movingEnded) {
            this.setLatLng({
              lat: this._nextLatLng[0],
              lng: this._nextLatLng[1]
            }), this._movingEnded = !0, this._updatePrevLatLng();
            return;
          }
          this._frameAnimId = L.Util.requestAnimFrame(function (t) {
            this._makeAnimation(t);
          }, this, !0);
        },
        _makeAnimation: function (t) {
          var i = t - this._animStartTimeStamp,
            n = this._interpolatePosition(this._prevLatLng, this._nextLatLng, this._duration, i);
          i >= this._duration && this.stop(), !this._movingEnded && (this.setLatLng(n), this._frameAnimId = L.Util.requestAnimFrame(this._makeAnimation, this, !1));
        },
        stop: function () {
          !this._movingEnded && (L.Util.cancelAnimFrame(this._frameAnimId), this._movingEnded = !0, this._updatePrevLatLng(), this.setLatLng(this._nextLatLng), this.options.followMarker && this._map.setView(this._nextLatLng, this._map.getZoom(), {
            animate: !1
          }));
        },
        hideMarker: function (t) {
          t ? this._icon.style.display = "none" : t || (this._icon.style.display = "block"), this.options.hideMarker = t;
        },
        activeAnimate: function (t) {
          if (t !== this._animate) {
            var i = performance.now() - this._animStartTimeStamp;
            if (this._animate && !t && !this._movingEnded && this._nextLatLng) {
              this._animate = t, this.stop();
              return;
            }
            if (t && i < this._duration && this._nextLatLng) {
              this._prevLatLng = this._tempPrevLatLng, this._nextLatLng = this._tempNextLatLng, this._animate = !0, this._doAnimation();
              return;
            }
            this._animate = t;
          }
        },
        _updateTempLatLng: function (t, i) {
          this._tempPrevLatLng = t, this._tempNextLatLng = i;
        }
      }), L.motionMarker = function (t, i) {
        return new L.MotionMarker(t, i);
      };
    }, t => {
      t.exports = {
        reductionDuration: 100
      };
    }, () => {
      L.MotionLine = L.Polyline.extend({
        options: {
          animate: !0,
          duration: 5e3
        },
        initialize: function (t, i) {
          L.Polyline.prototype.initialize.call(this, t, i), this._isOnAnimate = !1, this._animate = this.options.animate, this._prevLatLng = t[0], this._nextLatLng = t[1], this._latlngs = [[this._prevLatLng, this._prevLatLng]], this._tempLatLngs || (this._tempLatLngs = L.LineUtil.isFlat(this._latlngs) ? [this._latlngs] : this._latlngs), this._update(), this._play();
        },
        _interpolatePosition: function (t, i, n, e) {
          var s = e / n;
          return s = (s = s > 0 ? s : 0) > 1 ? 1 : s, L.latLng(t[0] + s * (i[0] - t[0]), t[1] + s * (i[1] - t[1]));
        },
        stop: function () {
          this._isOnAnimate && (this._isOnAnimate = !1, L.Util.cancelAnimFrame(this._frameAnimId), this._frameAnimId = null, this._latlngs[0].push(L.latLng(this._nextLatLng[0], this._nextLatLng[1])), this.setLatLngs(this._latlngs));
        },
        _play: function () {
          if (!this._isOnAnimate) {
            if (this._animStartTime = performance.now(), !this._animate) {
              this._latlngs = [[this._prevLatLng, this._nextLatLng]], this.setLatLngs(this._latlngs);
              return;
            }
            return this._isOnAnimate = !0, this._playAnimate(), this;
          }
        },
        _playAnimate: function () {
          var t = performance.now();
          if (this._currentTimelapse = t - this._animStartTime, !this._animate) {
            this.stop();
            return;
          }
          if (this._isOnAnimate) return this._latlngs[0].pop(), this._doAnimate(t);
        },
        _doAnimate: function (t) {
          var i = t - this._animStartTime,
            n = this._interpolatePosition(this._prevLatLng, this._nextLatLng, this.options.duration, i);
          if (this._latlngs[0].push(L.latLng(n.lat, n.lng)), this.setLatLngs(this._latlngs), i >= this.options.duration) {
            this._isOnAnimate = !1;
            return;
          }
          this._frameAnimId = L.Util.requestAnimFrame(this._playAnimate, this);
        },
        activeAnimate: function (t) {
          if (t !== this._animate) {
            var i = performance.now() - this._animStartTime;
            if (t && i < this.options.duration && this._nextLatLng) {
              var n = this._interpolatePosition(this._prevLatLng, this._nextLatLng, this.options.duration, i);
              this._latlngs = [[this._prevLatLng, [n.lat, n.lng]]], this._isOnAnimate = !0, this._animate = !0, this._playAnimate();
            } else this._animate = t;
          }
        }
      }), L.motionLine = function (t, i) {
        return new L.MotionLine(t, i);
      };
    }, (t, i, n) => {
      n(2), n(0), L.MoveMarker = L.FeatureGroup.extend({
        optionsPolyline: {
          animate: !0,
          color: "red",
          weight: 4,
          hidePolylines: !1,
          duration: 5e3,
          removeFirstLines: !1,
          maxLengthLines: 3
        },
        optionsMarker: {
          animate: !0,
          hideMarker: !1,
          followMarker: !1,
          rotateMarker: !1,
          duration: 5e3,
          speed: 0
        },
        optionsGroup: {},
        initialize: function (t, i, n, e) {
          L.FeatureGroup.prototype.initialize.call(this, [], e), this._latLngs = t, L.setOptions(this, {
            optionsPolyline: {
              ...this.optionsPolyline,
              ...i
            },
            optionsMarker: {
              ...this.optionsMarker,
              ...n
            },
            optionsGroup: {
              ...this.optionsGroup,
              ...e
            }
          });
        },
        onAdd: function (t) {
          L.FeatureGroup.prototype.onAdd.call(this, t), this.options.optionsPolyline.hidePolylines || this.setStyleOpacityLines(), 1 === this._latLngs.length ? this._createMarker([this._latLngs[0]]) : this._latLngs.length >= 2 && this._createMarker([this._latLngs[this._latLngs.length - 2], this._latLngs[this._latLngs.length - 1]]), this.options.optionsPolyline.animate || this.addMoreLine(this._latLngs[1], {
            rotateAngle: this.optionsPolyline.rotateAngle,
            animatePolyline: !1
          }), 2 === this._latLngs.length && this.options.optionsPolyline.animate && this.addMoreLine(null, {
            rotateAngle: this.optionsPolyline.rotateAngle,
            animatePolyline: this.optionsPolyline.animate
          });
        },
        _createLinesNoAnimate: function (t) {
          for (var i = 0; i < t.length - 1; i++) {
            var n = L.polyline([t[i], t[i + 1]], this.options.optionsPolyline);
            this.addLayer(n);
          }
        },
        addMoreLine: function (t, i) {
          var n = Object.keys(this._layers).filter(t => {
            if (this._layers[t] instanceof L.MotionLine) return !0;
          });
          this.options.optionsPolyline.removeFirstLines && n.length >= this.options.optionsPolyline.maxLengthLines && this.removeLayer(Number(n[0]));
          var e = this._latLngs.length;
          this._currentInstanceline = L.motionLine(t ? [this._latLngs[e - 1], t] : [this._latLngs[e - 2], this._latLngs[e - 1]], {
            ...this.options.optionsPolyline,
            animate: !!i?.animatePolyline
          }), this.addLayer(this._currentInstanceline), t ? this._latLngs.push(t) : this._latLngs.push(this._latLngs[1]), this.hidePolylines(this.options.optionsPolyline.hidePolylines), this._marker.moveTo(t || this._latLngs[this._latLngs.length - 1], i);
        },
        _createMarker: function (t) {
          this._marker = L.motionMarker(t, this.options.optionsMarker), this.addLayer(this._marker);
        },
        hidePolylines: function (t = this.options.optionsPolyline.hidePolylines) {
          this.options.optionsPolyline.hidePolylines = t, this.options.optionsPolyline.hidePolylines ? this.setStyleHideLines() : this.setStyleOpacityLines();
        },
        getMarker: function () {
          return this._marker;
        },
        getCurrentPolyline: function () {
          return this._currentInstanceline;
        },
        stop: function () {
          this._currentInstanceline instanceof L.MotionLine && this._currentInstanceline.stop(), this._marker instanceof L.MotionMarker && this._marker.stop();
        },
        setStyleOpacityLines: function () {
          var t,
            i = Object.keys(this._layers).filter(t => {
              if (this._layers[t] instanceof L.MotionLine) return !0;
            }),
            n = 1;
          for (var e in this._layers) (t = this._layers[e]) instanceof L.MotionLine && (t.setStyle({
            opacity: n / i.length
          }), n++);
        },
        setStyleHideLines: function () {
          var t;
          for (var i in this._layers) (t = this._layers[i]) instanceof L.MotionLine && t.setStyle({
            opacity: 0
          });
        }
      }), L.moveMarker = function (t, i, n, e) {
        return new L.MoveMarker(t, i, n, e);
      };
    }],
    i = {};
  function n(e) {
    var s = i[e];
    if (void 0 !== s) return s.exports;
    var a = i[e] = {
      exports: {}
    };
    return t[e](a, a.exports, n), a.exports;
  }
  n(0), n(2);
  var e = n(3);
})();