<div class="custom-modal-dialog"></div>
<p-sidebar
  class="info-prime-sidebar"
  position="right"
  (onShow)="isSidebarClosed = false"
  (onHide)="closeFaceMatchModal()"
  [fullScreen]="(sidebarIsFullScreen$ | async)"
  [(visible)]="isCurrentIdActive"
  [dismissible]="false"
>
  <ng-template pTemplate="header">
    <div class="custom-modal-header sidebar-modal-header">
      @if (isFaceMatchCard) {
        <h5 class="custom-modal-title" translate>app.core.face-match.sidebar.face-match-detail</h5>
      }
      @if (isFaceDetectedCard) {
        <h5 class="modal-title" translate>app.core.face-match.sidebar.face-detail</h5>
      }
      @if (isAutoRegisteredFaceCard) {
        <h5 class="modal-title" translate>app.core.face-match.sidebar.auto-reg-detail</h5>
      }
    </div>
  </ng-template>
  <div
    class="custom-modal"
    [ngStyle]="{'display':'block'}"
    [ngClass]="{'custom-modal-view':true}"
    [id]="sidebarId"
  >
    <div class="custom-modal-content">

      <div class="custom-modal-body">
        @if (cardData) {
          <div class="grid justify-content-center">
            <div class="col-12 lg:col-11">
              @if (isFaceMatchCard) {
                <app-face-match-card
                  [isCardClickable]="false"
                  [showContext]="showContext"
                  [cardDatafromSideBar]="cardData"
                  [isCopyBtnRequired]="isCopyBtnRequired"
                  [isDeleteBtnRequired]="isDeleteBtnRequired"
                  (sendFrameImage)="getFrameImgForModal($event)"
                  (sendClickedLocationToSidebar)="getClickedLocationFromCard($event)"
                ></app-face-match-card>
              }
              @if (isFaceDetectedCard) {
                <app-face-detected-card
                  [isCardClickable]="false"
                  [showContext]="showContext"
                  [cardDatafromSideBar]="cardData"
                  (sendFrameImage)="getFrameImgForModal($event)"></app-face-detected-card>
              }
              @if (isAutoRegisteredFaceCard) {
                <app-auto-registered-face-card
                  [isCardClickable]="false"
                  [showContext]="showContext"
                  [cardDatafromSideBar]="cardData"
                  [isCopyBtnRequired]="isCopyBtnRequired"></app-auto-registered-face-card>
              }
            </div>
          </div>
        }
        <!-- <div class="grid justify-content-center" *ngIf="person">
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">

          <app-asset-card [assetData]="person[0]" [isCardHeightFixed]="true" [showCloseBtn]="false" [showLinkBtn]="false"
            [isLinkable]="false" [isCardClickable]="false" [showGroupBtn]="true" [assetConfigDetails]="assetConfigDetails"
            [config]="assetConfigDetails"
          ></app-asset-card>
        </div>
      </div> -->

      </div>
    </div>
  </div>
</p-sidebar>

