import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigService } from '../../../services/config.service';
import { Subject, Actions} from '../../../auth/rules';
import { FaceMatchService } from '../../../services/face-match.service';
import {DateFormatterService} from '../../../services/dateformat';
declare var $: any;

@Component({
  selector: 'app-face-match-card',
  templateUrl: './face-match-card.component.html',
  styleUrls: ['./face-match-card.component.scss']
})
export class FaceMatchCardComponent implements OnInit, OnChanges {

@Input('faceData') faceData: any;
@Input('showContext') showContext;
@Input('cardDatafromSideBar') cardDatafromSideBar;
@Input('isCopyBtnRequired') isCopyBtnRequired;
@Input() isDeleteBtnRequired: boolean;
// @Input() badgeContent;
@Output() sendFrameImage = new EventEmitter<string>();
@Output() faceMatchToMainComponent: EventEmitter<any> = new EventEmitter();
@Output() embeddingIdToMainComponent: EventEmitter<any> = new EventEmitter();
badgeContent;
  clickableProperty: string;

@Input() isCardClickable: boolean;
@Output() sendCardDataAndClickedProperty = new EventEmitter();
@Output() sendClickedLocationToSidebar = new EventEmitter();
@Output() sendClickedLocationToMain = new EventEmitter();
@Output() sendFaceMatchOnFalsePositionBtnClickedToMain: EventEmitter<any> = new EventEmitter();
@Output() unRegisteredFaceEvent: EventEmitter<boolean> = new EventEmitter();
faceDataImages = {
   'referenceImage': null,
   'detectedFace': null
};

cardDataImages = {
  referenceImage: null,
  detectedFace: null,
  frame: null
};

imagesLoader = {
  cardDataReferenceImage: false,
  cardDataDetectedFace: false,
  cardDataFrame: false,
  faceDataReferenceImage: false,
  faceDataDetectedFace: false
};

baseUrl = this.configService.appConfig.appBaseUrl;
showRedShadow = false;
showGreenShadow = false;
frameForModal: string;
SUBJECT = Subject;
ACTIONS = Actions;
  constructor( private configService: ConfigService, public faceMatchService: FaceMatchService, public dateFormatter: DateFormatterService) {
    this.isDeleteBtnRequired = false;
  }
  ngOnInit() {
    if (this.faceData) {
      this.oneTimeImageLoaderInit('referenceImage' + this.faceData._id, 'faceDataReferenceImage');
      this.oneTimeImageLoaderInit('detectedFace' + this.faceData._id, 'faceDataDetectedFace');
      if (this.faceData['assetDetected']) {
        this.faceData.assetDetected.personType && this.faceData.assetDetected.personType === 'Whitelist' ? this.showGreenShadow = true :  this.showGreenShadow = false;
        this.faceData.assetDetected.personType && this.faceData.assetDetected.personType === 'Blacklist' ? this.showRedShadow = true :  this.showRedShadow = false;
        this.faceData['badgeContent'] ? this.badgeContent = this.faceData['badgeContent'] : '' ;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'cardDatafromSideBar') {
        if (change.currentValue !== change.previousValue) {
          this.resetImageVariables();
          this.multipleTimeImageLoaderInit('cardDataReferenceImage');
          this.multipleTimeImageLoaderInit('cardDataDetectedFace');
          this.multipleTimeImageLoaderInit('cardDataFrame');
          setTimeout(() => {
            this.getSliderImages(this.cardDatafromSideBar.imageReference.referenceImage, 'referenceImage');
            this.getSliderImages(this.cardDatafromSideBar.imageReference.detectedFace, 'detectedFace');
            this.getSliderImages(this.cardDatafromSideBar.imageReference.frame, 'frame');
          }, 20);
        }
      }
    }
  }

  sendFrameImgToModal() {
    this.frameForModal = this.cardDatafromSideBar;
    this.sendFrameImage.emit(this.frameForModal);
  }

  getClickableContent(propName) {
    this.clickableProperty = `${propName} clicked`;
    if (this.clickableProperty === 'name clicked' && this.faceData['isAutoRegistered']) {
      return;
    } else {
      this.sendCardDataAndClickedProperty.emit({clickedCardData: this.faceData, clickedProperty: this.clickableProperty});
    }
  }

  copyTextToClipBoard(copiedText: string, clickedLocation: string) {
    if (clickedLocation === 'main') {
      this.sendClickedLocationToMain.emit({clickedLocation: clickedLocation, copiedText: copiedText});
    } else {
      this.sendClickedLocationToSidebar.emit({clickedLocation: clickedLocation, copiedText: copiedText});
    }
  }

  deleteFaceMatch(faceMatch) {
    this.faceMatchToMainComponent.emit(faceMatch);
  }

  deleteEmbeddingId(faceMatch) {
    this.embeddingIdToMainComponent.emit(faceMatch.embeddingId);
  }

  falsePositionBtnClicked(faceMatch) {
    this.sendFaceMatchOnFalsePositionBtnClickedToMain.emit(faceMatch);
  }

  checkMatchConfidentialLevel(match, tooltip) {
    let res = match.split("%");
    let intValue = parseInt(res[0]);
    if (intValue) {
      if (intValue <= 45) {
        if (tooltip === 'toolTip') {
          return 'Confidence low';
        } else {
          return 'low';
        }
      } else if ((intValue > 45) && (intValue <= 52)) {
        if (tooltip === 'toolTip') {
          return 'Confidence medium';
        } else {
          return 'mid';
        }
      } else if (intValue > 52) {
        if (tooltip === 'toolTip') {
          return 'Confidence high';
        } else {
          return 'high';
        }
      }
    }
  }

  getSliderImages(id, type) {
    let _this = this;
    let imgUrl = this.faceMatchService.getFaceMatchImages(id, type);
    // imgUrl += '&date=' + new Date().getTime();
    _this.cardDataImages[type] = imgUrl;
  }

  resetImageVariables() {
    this.cardDataImages.referenceImage = null;
    this.cardDataImages.detectedFace = null;
    this.cardDataImages.frame = null;
    this.imagesLoader.cardDataReferenceImage = false;
    this.imagesLoader.cardDataDetectedFace = false;
    this.imagesLoader.cardDataFrame = false;
  }

  multipleTimeImageLoaderInit(id) {
    let that = this;
    let container = '#' + id + ' img';
    $( "<div class='curtain'> <div class='shine'></div> </div>" ).insertAfter(container);
    $(container).one('lazyloaded load', function() {
      that.imagesLoader[id] = true;
      $(this).parent().find('.curtain').remove();
    });
  }

  oneTimeImageLoaderInit(id, type) {
    let that = this;
    setTimeout(() => {
      let oneTimeImageContainer = $('#' + id + ' img');
      if (oneTimeImageContainer.prop('complete')) {
        that.imagesLoader[type] = true;
        $(this).parent().find('.curtain').remove();
        // already loaded
      } else {
        $( "<div class='curtain'> <div class='shine'></div> </div>" ).insertAfter(oneTimeImageContainer);
        (oneTimeImageContainer).one('lazyloaded load', function() {
          // now it has loaded
          that.imagesLoader[type] = true;
          $(this).parent().find('.curtain').remove();
        });
      }
    }, 10);
  }

}
