import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";


export const customerCollaborations = (state: AppState) =>
  state.customerCollaboration;

export const selectCollabortionData = createSelector(
  customerCollaborations,
  (cc) => cc.collabortionData
);

export const selectProductImagesUploadStatus = createSelector(
  customerCollaborations,
  (cc) => cc.uploadStatus
);

export const selectCustomerNotifiedStatus = createSelector(
  customerCollaborations,
  (cc) => cc.customerNotifiedStatus
);