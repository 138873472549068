import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {AssetService} from '../../../services/asset.service';
import {MapsService} from '../../../services/maps.service';
import {TimelineActions, TimelineSelector} from '../../../../src/state/Timeline';
import {select, Store} from '@ngrx/store';

import L, {control, icon, LatLng, latLng, Layer, Map, marker, tileLayer} from 'leaflet';
import {IMapUserOptions} from '../../../state/User/interfaces/IUserMap';
import {handleMapResize} from '../../../state/User/user.utilityService';
import {getPopUpHeader} from '../../../app/delivery-managenment-system/services/common.service';
// import { MapTiles } from 'src/app/settings/user-profile/user-profile-type';
declare let $: any;

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnChanges {

  _ = _;

  @Input() timelineContentArray: any;
  @Input() timelineObj:any;
  @Input() isAlternateSide: boolean;
  @Input() isCardDesignReq: boolean;
  @Input() firstContentSide: string;
  @Input() mapTiles: IMapUserOptions;

  @Output() sendImageIdToMainComponet: EventEmitter<boolean> = new EventEmitter();
  @Output() sendImageUrlToMainComponet: EventEmitter<boolean> = new EventEmitter();
  @Output() getTimeLineDataWithFields: EventEmitter<string> = new EventEmitter();

  mapToView: any;
  isTimelineDataFiltered:boolean
  timeLineTrackingStatus$:Observable<boolean> = this.store.pipe(select(TimelineSelector.timeLineTrackingStatus))


  constructor(public assetService: AssetService, public mapsService: MapsService, private store: Store) {
    this.isAlternateSide = false;
    this.isCardDesignReq = true;
    this.firstContentSide = 'right';
  }

  selectedtimeLineFields:string = "";

//       map variable
  markers: Layer[] = [];
  map: Map;

  private url: string;
  private option: any;
  options = {
    layers: [],
    zoom: 5,
    zoomControl: false,
    center: latLng(12.9716, 77.5946),
  };
  showMap = false;
  layer: Layer;
  public selectedMap: any;
  public selectedZoom: number;
  public selectedLatitude: number;
  public selectedLongitude: number;
  public center: any;

  status = true;
// -------------Leaflet map-------------
  showHide = true;


// -------------Leaflet map------------


  //----------------list of icons to show on the click event--------
  iconList = {
    //----------if you want to use icon-----------
    // 'Order Created By shopify':'pi-shopping-cart',

    //----------if you want to use Image-----------
    // 'Order Accepted':'./assets/asset-icons/meeting-50-black.png',
    // 'Order Processed By Store':'./assets/asset-icons/meeting-50-black.png',
    // 'Order Sent To Store':'./assets/asset-icons/meeting-50-black.png',
    // 'Order Created By shopify':'./assets/asset-icons/meeting-50-black.png',
    // 'Order Updated Successfully':'./assets/asset-icons/meeting-50-black.png',
    // 'Pick Up Location Un Assigned':'./assets/asset-icons/meeting-50-black.png',
    // 'Pick Up Location Assigned':'./assets/asset-icons/meeting-50-black.png',
    // 'Pick Up Acknowledged To Processor':'./assets/asset-icons/meeting-50-black.png',
    // 'Picked Up':'./assets/asset-icons/delivery-man.png',
    // 'Delivery Acknowledged To Store':'./assets/asset-icons/attendance-new.png',
    // 'Delivered':'./assets/asset-icons/delivery.png',
    // 'Delivery In Progress':'./assets/assets-icon/delivery.png',
    // 'Assigned':'./assets/asset-icons/meeting-50.png',
    // 'Store Accepted Order For Processing':'./assets/asset-icons/o2d.png',
    // 'Lp Assigned':'./assets/asset-icons/personActive.png',
    // 'Lp Cancelled':'./assets/asset-icons/lp_Cancelled.png',
    // 'Cancelled':'./assets/asset-icons/order_Cancelled.png',
    // 'Order Rejected':'./assets/asset-icons/order_Cancelled.png',
    // 'Order Returned':'./assets/asset-icons/OrderReturned.png',
    // 'Picked Up Declined':'./assets/asset-icons/PickedUpDeclined.png',
    // 'In Progress':'./assets/asset-icon/icons8-hourglass(1).gif',
    // 'Accepted':'./assets/asset-icon/connectedIcon.png',
  };
  TimelineLocationMarker = marker([0, 0], {
    icon: icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: '../../assets/leaflet-icons/start-marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png',
    }),
    autoPan: true
  });

  getMapForSelectedUser() {
    if (this.map && this.mapTiles) {
      this.selectedZoom = this.mapTiles?.zoom ?? 11;
      this.selectedLatitude = this.mapTiles?.centerLocation?.coordinates?.[0] ?? 25.214017;
      this.selectedLongitude = this.mapTiles?.centerLocation?.coordinates?.[1] ?? 55.31022;
      this.setMap(this.mapTiles);

      this.center = latLng(this.selectedLatitude, this.selectedLongitude);
      this.map.setView(this.center, this.selectedZoom);
      this.map.setZoom(this.selectedZoom);
      this.map.panTo(new LatLng(this.selectedLatitude, this.selectedLongitude));
    }
  }

  setMap(mapSelected) {
    if (mapSelected && mapSelected.tile) {
      this.url = mapSelected.tile.url;
      this.option = mapSelected.tile.option;
      this.mapToView = tileLayer(this.url, this.option);
      if (mapSelected.tile['google_style']) {
        (L as any).gridLayer.googleMutant({
          type: 'roadmap',
          styles: mapSelected.tile['google_style'],
        }).addTo(this.map);

      } else {
        this.mapToView.addTo(this.map);
      }
      this.map.invalidateSize(true);
    }
  }


  selectedItem = {};

  clickEvent(item) {
    this.status = !this.status;
    this.selectedItem = item;
    this.store.dispatch(TimelineActions.toggleTimelineSelection({selected:!item['selected'], selectedIndex:item.index}));

  }

  onMapReady(map: Map) {
    $('.leaflet-control-attribution').hide();
    this.map = map;
    control.zoom({position: 'bottomright'}).addTo(map);
    handleMapResize(this.map, 'map');
    this.getMapForSelectedUser();
  }

  clickToggle() {
    this.showHide = !this.showHide;
    this.status = !this.status;
    this.store.dispatch(TimelineActions.toggleTimelineSelection({selected:!this.status, selectedIndex:-1}));
  }

  sendImgId(imgId) {
    this.sendImageIdToMainComponet.emit(imgId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let isEventSelectedToOpen = false;
    _.each(this.timelineContentArray, (event) => {
      // this.store.dispatch(TimelineActions.toggleTimelineSelection({selected:false, selectedIndex:Number(i)}));
      if (!isEventSelectedToOpen && (event?.eventDescription || event?.image || event?.eventReason || (event?.contents && event?.contents.length > 0))) {
        // this.store.dispatch(TimelineActions.toggleTimelineSelection({selected:true, selectedIndex:Number(i)}));
        isEventSelectedToOpen = true;
      }
    });
    if (changes['mapTiles']) {
      this.getMapForSelectedUser();
    }

    if (changes['timelineContentArray']) {
      this.showMap= false;
      // this.hideProfileImgModal('#displayMapModal');
    }
  }

  ngOnInit(){
    this.store.pipe(select(TimelineSelector.isTimelineDataFitlered)).subscribe((data)=>{
      this.isTimelineDataFiltered = data;
      if(!this.isTimelineDataFiltered){
        this.selectedtimeLineFields="";
      }
    })

  }

  checkIfImageIsArray(value) {
    return (Array.isArray(value));
  }

  sendImgUrl(imgUrl) {
    this.sendImageUrlToMainComponet.emit(imgUrl);
  }

  onMapIconClick(eventData) {
    let location;
    this.TimelineLocationMarker.remove();
    // $('#displayMapModal').modal('show');
    this.showMap = true;
    const coordinates: any = [];
    if (eventData && eventData?.location && eventData?.location?.lat && eventData?.location?.lng) {
      coordinates.push(eventData?.location.lat);
      coordinates.push(eventData?.location.lng);
      location = new LatLng(coordinates[0], coordinates[1]);
      this.center = latLng(eventData?.location.lat, eventData?.location.lng);

      if (this.map) {
        this.map.setView(this.center, this.selectedZoom);
        this.map.setZoomAround(coordinates, 14);
        this.map.setZoom(this.selectedZoom);
        this.map.panTo(location);
        this.TimelineLocationMarker.setLatLng(coordinates).addTo(this.map);
        this.TimelineLocationMarker.bindPopup( getPopUpHeader('', eventData?.header,eventData?.eventDescription||''),{offset: [0, -30]});
        this.TimelineLocationMarker.on('mouseover',()=>{
        this.TimelineLocationMarker.openPopup()
        })
        this.TimelineLocationMarker?.on('mouseout',()=>{
          this.TimelineLocationMarker.closePopup()
        })

        setTimeout(() => {
          this.map.invalidateSize(true);
        }, 1000)
      }
    }

  }

  // hideProfileImgModal(modalToClose) {
    // $(modalToClose).modal('hide');
    // this.TimelineLocationMarker.remove();

  // }

  getTimelineData(){
    this.getTimeLineDataWithFields.emit(this.selectedtimeLineFields)
  }
  clickTimelineDataRefresh(){
    this.selectedtimeLineFields=""
    this.getTimeLineDataWithFields.emit()
  }
}
