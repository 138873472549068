import {Component, OnInit} from '@angular/core';
import {AccountsService} from 'services/accounts.service';
import {AppComponent} from 'app/app.component';
import {AppMainComponent} from 'app/core/main/app.main.component';
import {IThemeConfigModal} from 'app/core/config/IThemeConfigModal';
import {Actions, Subject} from 'auth/rules';
import {Ability} from '@casl/ability';


@Component({
  selector: 'app-config',
  templateUrl: 'app.config.component.html'
})
export class AppConfigComponent implements OnInit {
  SUBJECT = Subject
  ACTIONS = Actions;
  specialThemes: any[];

  themes: any[];

  themeConfig: IThemeConfigModal = {
    layoutMode: 'static',
    menuMode: 'dark',
    megaMenuMode: "gradient",
    profileMode: 'inline',
    ripple: true,
    theme: 'noir',
    inputStyle: 'outlined'
  }

  constructor(public appMain: AppMainComponent, public app: AppComponent, private accountService: AccountsService, public ability: Ability) {
    this.getThemeConfig()
  }

  isFormDisabled = true;

  ngOnInit() {
    if (this.ability.can(this.ACTIONS.READ, this.SUBJECT.UI_SETTINGS) && this.ability.can(this.ACTIONS.WRITE, this.SUBJECT.UI_SETTINGS)) {
      this.isFormDisabled = false;
    }

    // console.log('app  them config')s
    this.themes = [
      {name: 'blue', color: '#0071bc'},
      {name: 'cyan', color: '#00bfe7'},
      {name: 'green', color: '#4AA564'},
      {name: 'yellow', color: '#F9C642'},
      {name: 'purple', color: '#6A4AA5'},
      {name: 'pink', color: '#9f4488'},
      {name: 'bluegrey', color: '#4B6D7E'},
      {name: 'teal', color: '#07A089'},
      {name: 'orange', color: '#fe875d'},
      {name: 'grey', color: '#5B616B'},
    ];

    this.specialThemes = [
      {name: 'cappuccino', image: 'cappuccino'},
      {name: 'montreal', image: 'montreal'},
      {name: 'hollywood', image: 'hollywood'},
      {name: 'peak', image: 'peak'},
      {name: 'alive', color1: '#CB356B', color2: '#BD3F32'},
      {name: 'emerald', color1: '#348F50', color2: '#56B4D3'},
      {name: 'ash', color1: '#606c88', color2: '#3f4c6b'},
      {name: 'noir', color1: '#4b6cb7', color2: '#182848'},
      {name: 'mantle', color1: '#514A9D', color2: '#24C6DC'},
      {name: 'predawn', color1: '#00223E', color2: '#FFA17F'},
    ];
  }

  changeTheme(theme: string) {
    this.themeConfig.theme = theme;
    this.changeStyleSheetsColor('theme-css', 'theme-' + theme + '.css');
    this.changeStyleSheetsColor('layout-css', 'layout-' + theme + '.css');
    this.setThemeInLocalStorage();
  }

  changeStyleSheetsColor(id: string, value: string) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = value;

    const newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  replaceLink(linkElement: HTMLElement, href: string) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true) as HTMLElement;

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
        //set the meta tag color
        const elem = document.getElementById('get-theme-color-div');
        if (elem) {
          const styles = getComputedStyle(elem);
          const value = styles.backgroundColor;
          document.getElementsByTagName('meta').namedItem('theme-color').setAttribute('content', value.toString());
        }
      });
    }
  }

  onConfigButtonClick(event) {
    this.appMain.configActive = !this.appMain.configActive;
    this.appMain.configClick = true;
    event.preventDefault();
  }

  setThemeInLocalStorage() {
    console.log(`setting ${this.app.theme} from local storage`);
    localStorage.setItem('localTheme', this.app.theme);
  }

  getThemeConfig() {
    this.accountService.getThemeConfig().subscribe((data) => {
      if (data && data['themeConfig'] && data['themeConfig']['theme']) {
        this.setThemeConfig(data['themeConfig'])
        this.changeTheme(data['themeConfig']['theme'])
      } else {
        this.changeTheme(this.app.theme)
      }
    })
  }

  setThemeConfig(data: IThemeConfigModal) {
    const keys = Object.keys(data);
    keys?.forEach((key) => this.app[key] = data[key]);
  }

  updateThemeObj(theme: string) {
    if (this.ability.can(this.ACTIONS.READ, this.SUBJECT.UI_SETTINGS) && this.ability.can(this.ACTIONS.WRITE, this.SUBJECT.UI_SETTINGS)) {
      this.changeTheme(theme)
      this.app.theme = theme
    }
  }

  sendUpdatedTheme() {
    if (this.ability.can(this.ACTIONS.READ, this.SUBJECT.UI_SETTINGS) && this.ability.can(this.ACTIONS.WRITE, this.SUBJECT.UI_SETTINGS)) {
      const themeConfig: IThemeConfigModal = {
        layoutMode: this.app.layoutMode,
        menuMode: this.app.menuMode,
        megaMenuMode: this.app.megaMenuMode,
        profileMode: this.app.layoutMode === 'horizontal' ? 'top' : this.app.profileMode,
        ripple: this.app.ripple,
        theme: this.app.theme,
        inputStyle: this.app.inputStyle
      }
      this.accountService.sendThemeConfig({themeConfig}).subscribe()
    }
  }

  onRippleChange(event) {
    this.appMain.onRippleChange(event);
    this.themeConfig.ripple = event.checked;
  }
}


