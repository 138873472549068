import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "../../../services/config.service";

@Injectable({ providedIn: "root" })
export class KioskMediaManagementService {
  constructor(private http: HttpClient, private configService: ConfigService) {}


  getAllCampaigns(isCount:boolean,skip?:number,limit?:number,cityIds?:string,locationIds?:string,terminalIds?:string) {
    let url = this.configService.appConfig.appBaseUrl + "mediaManagement/campaign?isCount="+isCount;
    if(skip){
      url+= "&skip="+skip;
    }
    if(limit){
      url+= "&limit="+limit;
    }
    if(cityIds){
      url+= "&cityIds="+cityIds;
    }
    if(locationIds){
      url+= "&locationIds="+locationIds;
    }
    if(terminalIds){
      url+= "&terminalIds="+terminalIds;
    }
    return this.http.get(url);
  }

  createNewCampaign(campaign:ICampaign) {
    const url = this.configService.appConfig.appBaseUrl + "mediaManagement/campaign";
    return this.http.post(url,campaign);
  }

  editCampaign(campaign:ICampaign,campaignId:string) {
    const url = this.configService.appConfig.appBaseUrl + "mediaManagement/campaign/id/" + campaignId;
    return this.http.put(url,campaign);
  }

  toggleCampaignEnabling(enabled:boolean,campaignId:string) {
    const url = this.configService.appConfig.appBaseUrl + "mediaManagement/campaign/status/id/" + campaignId;
    return this.http.put(url,{enabled});
  }

  deleteCampaign(campaignId:string) {
    const url = this.configService.appConfig.appBaseUrl + "mediaManagement/campaign/id/"+campaignId;
    return this.http.delete(url);
  }
  updateWallpaper(mediaData:Array<any>,terminalId:string) {
    const url = this.configService.appConfig.appBaseUrl + "mediaManagement/wallpaper/tId/"+terminalId;
    return this.http.put(url,{mediaData});
  }
}


export interface ICampaign{
  name: string;
  mediaData: any[];
  locationIds: string[];
  terminalIds: string[];
  cityIds: string[];
  startDate: number;
  endDate: number
}