import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigService } from '../../../../services/config.service';
import { FaceDetectionService } from '../../../../services/face-detection.service';
import {DateFormatterService} from '../../../../services/dateformat';
declare var $: any;

@Component({
  selector: 'app-face-detected-card',
  templateUrl: './face-detected-card.component.html',
  styleUrls: ['./face-detected-card.component.scss']
})

export class FaceDetectedCardComponent implements OnInit, OnChanges {

  @Input('faceData') faceData: any;
  @Input('showContext') showContext;
  @Input('cardDatafromSideBar') cardDatafromSideBar;
// @Input() badgeContent;
  badgeContent;
  @Output() sendFrameImage = new EventEmitter<string>();
  @Output() cardClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() addDetectedFaceClickEvent: EventEmitter<any> = new EventEmitter();
  @Input() isCardClickable: boolean;
  @Input() showAddVisitorBtn: boolean;
  @Input() showRegisterThingBtn: boolean;
  @Input() visitorsTypeList: any;
  baseUrl = this.configService.appConfig.appBaseUrl;
  @Output() sendCardDataAndClickedProperty = new EventEmitter();
  @Output() sendSelectedMenuItem = new EventEmitter();
  clickableProperty: string;
  showRedShadow = false;
  showGreenShadow = false;
  frameForModal: string;
  faceDataImages = {
    'detectedFace': null
  };

  cardDataImages = {
    detectedFace: null,
    frame: null
  };

  imagesLoader = {
    cardDataDetectedFace: false,
    cardDataFrame: false,
    faceDataDetectedFace: false
  };

  constructor( private configService: ConfigService, public faceDetectionService: FaceDetectionService, public dateFormatter: DateFormatterService) {
    this.showAddVisitorBtn = false;
    this.showRegisterThingBtn = false;
  }
  ngOnInit() {
    if (this.faceData) {
      this.oneTimeImageLoaderInit('detectedFace' + this.faceData._id, 'faceDataDetectedFace');
      // this.getFaceDataImages(this.faceData._id, 'detectedFace');
      if (this.faceData['assetDetected']) {
        this.faceData.assetDetected.personType && this.faceData.assetDetected.personType === 'Whitelist' ? this.showGreenShadow = true :  this.showGreenShadow = false;
        this.faceData.assetDetected.personType && this.faceData.assetDetected.personType === 'Blacklist' ? this.showRedShadow = true :  this.showRedShadow = false;
        this.faceData['badgeContent'] ? this.badgeContent = this.faceData['badgeContent'] : '' ;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'cardDatafromSideBar') {
        if (change.currentValue !== change.previousValue) {
          this.resetImageVariables();
          this.multipleTimeImageLoaderInit('cardDataDetectedFace');
          this.multipleTimeImageLoaderInit('cardDataFrame');
          setTimeout(() => {
            this.getSliderImages(this.cardDatafromSideBar._id, 'detectedFace');
            this.getSliderImages(this.cardDatafromSideBar._id, 'frame');
          }, 20);
          // this.getCardDataImages(this.cardDatafromSideBar._id, 'detectedFace');
          // this.getCardDataImages(this.cardDatafromSideBar._id, 'frame');
        }
      }
    }
  }

  sendCardClickedEvent(faceData) {
    this.cardClickEvent.emit(faceData);
  }

  sendAddDetectedFaceClickEvent(faceData) {
    this.addDetectedFaceClickEvent.emit(faceData);
  }

  sendFrameImgToModal() {
    this.frameForModal = this.cardDatafromSideBar;
    this.sendFrameImage.emit(this.frameForModal);
  }

  getFaceDataImages(id, type) {
    this.faceDetectionService.getImages(id, type)
      .subscribe((res) => {
        const that = this;
        if (res) {
          const reader = new FileReader();
          reader.readAsDataURL( res );
          reader.onload = function (e: any) {
            that.faceDataImages[type] = e.target.result;
          };
        }
      });
  }

  getCardDataImages(id, type) {
    this.faceDetectionService.getImages(id, type)
      .subscribe((res) => {
        const that = this;
        if (res) {
          const reader = new FileReader();
          reader.readAsDataURL( res );
          reader.onload = function (e: any) {
            that.cardDataImages[type] = e.target.result;
          };
        }
      });
  }

  resetImageVariables() {
    this.cardDataImages.detectedFace = null;
    this.cardDataImages.frame = null;
    this.imagesLoader.cardDataDetectedFace = false;
    this.imagesLoader.cardDataFrame = false;
  }

  getSliderImages(id, type) {
    let _this = this;
    let imgUrl = this.faceDetectionService.getFaceDetectionImages(id, type);
    // imgUrl += '&date=' + new Date().getTime();
    _this.cardDataImages[type] = imgUrl;
  }

  multipleTimeImageLoaderInit(id) {
    let that = this;
    let container = '#' + id + ' img';
    $( "<div class='curtain'> <div class='shine'></div> </div>" ).insertAfter(container);
    $(container).one('lazyloaded load', function() {
      that.imagesLoader[id] = true;
      $(this).parent().find('.curtain').remove();
    });
  }

  oneTimeImageLoaderInit(id, type) {
    let that = this;
    setTimeout(() => {
      let oneTimeImageContainer = $('#' + id + ' img');
      if (oneTimeImageContainer.prop('complete')) {
        that.imagesLoader[type] = true;
        $(this).parent().find('.curtain').remove();
        // already loaded
      } else {
        $( "<div class='curtain'> <div class='shine'></div> </div>" ).insertAfter(oneTimeImageContainer);
        (oneTimeImageContainer).one('lazyloaded load', function() {
          // now it has loaded
          that.imagesLoader[type] = true;
          $(this).parent().find('.curtain').remove();
        });
      }
    }, 10);
  }

  getClickableContent(propName) {
    this.clickableProperty = `${propName} clicked`;
    if (this.clickableProperty === 'name clicked' && this.faceData['isAutoRegistered']) {
      return;
    } else {
      this.sendCardDataAndClickedProperty.emit({clickedCardData: this.faceData, clickedProperty: this.clickableProperty});
    }
  }

  openSelectedEventModel(event) {
    let obj = {
      faceData: this.faceData,
      event: event
    };
    this.sendSelectedMenuItem.emit(obj);
  }
}
