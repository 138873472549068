<div class="relative">
  <p-tabMenu [scrollable]="true" [model]="tabMenuItems" [activeItem]="activeItem"
    (activeItemChange)="activeTabChanges($event)">
  </p-tabMenu>


  <div class="tab-table-header-button">

    @if (storeList && storeList.length > 0 && isOnMegaMenu) {
      <p-dropdown (onChange)="isStoreSelected($event)"
                  [(ngModel)]="selectedStore" [options]="storeList | orderBy: 'name'" optionLabel="name" [filter]="true"
                  styleClass="mr-3 mb-3 p-button-sm" class="tab-table-dropdown">
      </p-dropdown>
    }
  </div>

  <!-- <button pButton type="button" label=" Store" icon="pi pi-plus" tooltip="Add new store"
  class="button-container-store-tab position-absolute  end-0 "
  tooltipPosition="top" (click)="addStore()">
</button>  -->
</div>

<router-outlet></router-outlet>
