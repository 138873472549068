import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {BehaviorSubject, Subject} from 'rxjs';

declare let $: any;

@Injectable()
export class MapsService {
  private _map: any;
  public mapReinitializeFlag: Subject<any> = new BehaviorSubject<any>('');
  public activePillValue: Subject<any> = new BehaviorSubject<any>('');
  constructor(private http: HttpClient, public configService: ConfigService) {}
  getMapsName() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'map/name');
  }

  getAllMaps() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'map/');
  }

  getMapDetails(id) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'map/' + id);
  }

  getMapForSelectedUser() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'map/selected/user');
  }

  getMap(): any {
    return this._map;
  }

  setMap(map: any) {
    this._map = map;
  }

  putMapDetails(map) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'user/self/profile/map' , map);
  }

  updateAccountMapDetails(payload) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'account/map' , payload);
  }

  updateSelfAccountMapDetails(map) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'account/self/map' , map);
  }

  reInitializeMap(value) {
    this.mapReinitializeFlag.next(value);
  }

  getActivePill(selectedPill) {
    this.activePillValue.next(selectedPill);
  }

  getDirectionDetails(origin, waypoints, destination) {
    // return this.http.get(this.configService.appConfig.appBaseUrl + 'mapManager/direction/:origin/:destination/?waypoints=12.902813, 77.583590|12.802813, 77.683590');
    return this.http.get(this.configService.appConfig.appBaseUrl + 'mapManager/direction/' + origin + '/' + destination + '/?waypoints=' + waypoints);
  }

  getDirectionRoutePath(url) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'mapManager/direction/' + url);
  }

  getOptimizedDirection(payload){
    return this.http.post(this.configService.appConfig.appBaseUrl + 'mapManager/optimizedDirection' , payload);
  }

  hideTrackableCarsListOnWindowResize() {
    $( window ).resize(function() {
      if ($(window).width() < 992) {
        if ($('.trackable-cars-list').hasClass('panelOpened')) {
          $('.trackable-cars-list').removeClass('panelOpened');
          $('.trackable-cars-list').addClass('panelClosed');
        }
      } else {
        $('.trackable-cars-list').removeClass('panelClosed');
        $('.trackable-cars-list').addClass('panelOpened');
      }
    });
  }

  getMapIconConfigurationSettingsByModuleType(moduleType){
    let url = this.configService.appConfig.appBaseUrl + 'account/trackerModule/settings'
    if(moduleType){
        url = url + '?pReq=moduleDetails.'+ moduleType
    }
    return this.http.get(url)
  }

  updateMapIconConfigurationSettingsByModuleType(moduleType, payload){
    const url = this.configService.appConfig.appBaseUrl + 'account/trackerModule/settings/moduleType/' + moduleType;
        return this.http.put(url, payload)
  }
}
