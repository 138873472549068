import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, from, catchError } from "rxjs";
import { CustomerCollaborationService } from "./customer.collaboration.service";
import { MessageService } from "primeng/api";
import {
  getCustomerCollaborations,
  onGettingCustomerCollaborations,uploadProductImagesOfCollab,onSuccessOfUploadOfProductImagesOfCollab, sendOrderCollabImagesToCustomer, onSuccessOfSendingOrderCollabImagesToCustomer, 
} from "./customer.collaboration.action";
import { IImageData } from "./interfaces/customer.collaboration.interface";

@Injectable()
export class CustomerCollaborationEffects {
  constructor(
    private actions$: Actions,
    private customerCollaborationService: CustomerCollaborationService,
    private messageService: MessageService
  ) {}

  getCustomerCollaborations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCustomerCollaborations),
      switchMap((action) => {
        return from(
          this.customerCollaborationService.loadCustomerCollaborations(
            action.orderId
          )
        ).pipe(
          switchMap((collaborations: []) => {
            return [
              onGettingCustomerCollaborations({
                collaborations:
                  this.customerCollaborationService.assignIndicesToImages(
                    collaborations
                  ),
              }),
            ];
          }),
          catchError(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "error",
              summary: "Error",
              detail: "Error Occured, Please Try Again...!",
            });
            return [];
          })
        );
      })
    )
  );

  uploadProductImagesOfCollab$ = createEffect(() =>
  this.actions$.pipe(
    ofType(uploadProductImagesOfCollab),
    switchMap((action) => {
      return from(
        this.customerCollaborationService.uploadProductImages(
          action.imagesToUpload,
          action.orderId,
          action.productId
        )
      ).pipe(
        switchMap((uploadStatus:IImageData[]) => {
          this.messageService.add({
            key: "global-notification",
            severity: "success",
            summary: "Uploaded",
            detail:'Successfully Uploaded ...!',
          });
          return [
            onSuccessOfUploadOfProductImagesOfCollab({uploadStatus
            }),
          ];
        }),
        catchError(() => {
          this.messageService.add({
            key: "global-notification",
            severity: "error",
            summary: "Error",
            detail: "Error Occured, Please Try Again...!",
          });
          return [];
        })
      );
    })
  )
);

sendOrderCollabImagesToCustomer$ = createEffect(() =>
this.actions$.pipe(
  ofType(sendOrderCollabImagesToCustomer),
  switchMap((action) => {
    return from(
      this.customerCollaborationService.sendOrderCollaboartionImagesToCustomer(
        action.orderId,
      )
    ).pipe(
      switchMap((customerNotifiedStatus:{msg:string}) => {
        this.messageService.add({
          key: "global-notification",
          severity: "success",
          summary: "Notified",
          detail:'Customer Notified...!',
        });
        return [
          onSuccessOfSendingOrderCollabImagesToCustomer({customerNotifiedStatus}),
          getCustomerCollaborations({orderId:action.orderId})
        ];
      }),
      catchError(() => {
        this.messageService.add({
          key: "global-notification",
          severity: "error",
          summary: "Error",
          detail: "Error Occured, Please Try Again...!",
        });
        return [];
      })
    );
  })
)
);

}
