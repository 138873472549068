import { Injectable } from "@angular/core";
import { Actions, createEffect , ofType} from "@ngrx/effects";
import { switchMap ,from, map, catchError, of, tap,mergeMap} from "rxjs";
import { ETACalculationAction } from ".";
import { MessageService } from "primeng/api";
import { EtaCalculationService } from "./etaCalculation.service";

@Injectable()
export class ETACalculationEffect {
    constructor(
        private actions$:Actions,
        private messageService: MessageService,
        private _etaCalculationService : EtaCalculationService
        
    ){}

    getETACalculationRequired$ = createEffect(()=>
        this.actions$.pipe(
            ofType(ETACalculationAction.getETACalculationRequired),
            switchMap((action) => {
                return from(this._etaCalculationService.getMapETAValue(action.moduleType)).pipe(
                    tap(d => !d['eta'] ? this.messageService.add({severity: 'error', data: d['message']}): null),
                  mergeMap((res) => {
                    if (res?.eta) { 
                    return [ ETACalculationAction.getETACalculationRequiredSuccess({ response: res, moduleType: action.moduleType })]
                    }
                    return []
                     }),
                     catchError((error:Error) => of(ETACalculationAction.ETACalculationRequiredFailureMethod({ error }))) 
                     )
            })
        )
    );
    updateETACalculationRequired$ = createEffect(()=>
        this.actions$.pipe(
            ofType(ETACalculationAction.updateETACalculationRequired),
            switchMap((action) => {
                return from(this._etaCalculationService.updateMapETAValue(action.payload,action.moduleType)).pipe(
                    tap(d => !d['eta'] ? this.messageService.add({severity: 'error', data: d['message']}): null ),
                     map((res) => ETACalculationAction.updateETACalculationRequiredSuccess({response:res,moduleType : action.moduleType})),
                     catchError((error:Error) => of(ETACalculationAction.ETACalculationRequiredFailureMethod({ error }))) 
                     )
            })
        )
    )

}

