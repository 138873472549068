import {AppMainComponent} from 'app/core/main/app.main.component';

import {Component, Input, OnInit,} from '@angular/core';
import {Actions, Subject} from 'auth/rules';
import {Ability} from '@casl/ability';
import pk from '../../../package.json';

@Component({
  selector: "app-lower-menu",
  template: `
    <ul class="layout-menu lower-menu">
      @for (item of model; track item; let i = $index) {
        <li
          app-menuitem
          [item]="item"
          [index]="i"
          [root]="true"
        ></li>
      }
      <div class="version">
        <p >{{showIotZenServerVersion()}}</p>
        <p >v-{{versionNumber}}</p>
      </div>
    
    </ul>
    
    `,
  styleUrls: ['./menu/app.menu.component.scss']
})
export class AppLowerMenu implements OnInit {
  model: any[];
  assetTypes = [];
  versionNumber:string = pk.version;

  @Input() iotZenServerVersion:string


  constructor(
    public app: AppMainComponent,
    private ability: Ability
  ) {

  }
  ngOnInit() {
    this.model = [];
    if(this.ability.can(Actions.READ, Subject.COMMUNICATION)){
      const menuObj = {
        label: 'Communication',
        icon: 'pi pi-envelope',
        routerLink: ['/communication'],
      };
      this.model.push(menuObj)
    }
    if(this.ability.can(Actions.READ, Subject.GROUP)){
      const menuObj = {
        label: 'Groups',
        icon: 'pi pi-fw pi-th-large',
        routerLink: ['/groups'],
      };
      this.model.push(menuObj)
    }
    if(this.ability.can(Actions.READ, Subject.SERVICE)){
      const menuObj = {
        label: 'Services',
        icon: 'custom-icon icon-color custom-icon-img service-icon',
        routerLink: ['/services']

      };
      this.model.push(menuObj)
    }
    if(this.ability.can(Actions.READ, Subject.SETTINGS)){
      const menuObj = {
        label: 'Config',
        icon: 'pi pi-fw pi-cog',
        routerLink: ['config'],
        preventExact: true
      };
      this.model.push(menuObj)
    }

  }

  showIotZenServerVersion():string {
    let version=''

    if(this.iotZenServerVersion){
      version = this.iotZenServerVersion
      sessionStorage.setItem('iotZenServerVersion', this.iotZenServerVersion);
    }else{
      version=sessionStorage.getItem('iotZenServerVersion');
    }
    return version ? ('iotzen-'+version) : '.'
  }


}
