<div (leafletMapReady)="onMapReady($event)"
     [leafletOptions]="options"
     [ngClass]="{'cursor-crosshair':isAddGeofence}" class="map-container"
     id='tracker-map'
     leaflet>

  <!-- TOOLBAR -->
  @if (showBtnsList) {
    <div class="recenter-btn">
      <mat-icon (click)="toggleRecenter()" [ngClass]="{'active-setting': recenterMap}" class="toolbar-icon"
                pTooltip="Recenter" tooltipPosition="top">track_changes
      </mat-icon>
      <mat-icon (click)="toggleMapGeofence()" [ngClass]="{'active-setting': isGeoFence}" class="toolbar-icon"
                pTooltip="Geofence" tooltipPosition="top">adjust
      </mat-icon>
      <mat-icon (click)="toggleEvents()" [ngClass]="{'active-setting': showEventMarkers}" class="toolbar-icon"
                [pTooltip]="selectedVehicle?._id ? 'Events (24H)' : 'No Selected carrier'" tooltipPosition="top">event
      </mat-icon>
      <p-toggleButton [(ngModel)]="updateGeoFenceAccess" (ngModelChange)="toggleGeofenceUpdate()"
                      pTooltip="Edit Geo-Fence" [disabled]="!isGeoFence"
                      [style]="{ height: '40px', width: '40px' }"
                      [onIcon]="'pi pi-pencil'"
                      [offIcon]="'pi pi-pencil'"></p-toggleButton>
    </div>
  }

  <!-- LEGEND CONTAINER -->
  @if ((showLegendList || (legendList.length > 0 && displayLegendContainer && selectedVehicle))) {
    <div class="legend-container">
      <ul>
        @for (legend of legendList; track legend) {
          <li (click)="legendClicked(legend);"
              [ngStyle]="{'opacity': legend.isActive ? 1 : 0.4}"
              class="legend-list-container cursorPointer">
            <div class="legend" [ngStyle]="{'background-color': legend.color}"></div>
            <span>{{ legend.label }}</span>
          </li>
        }
      </ul>
    </div>
  }
</div>
<p-toast key="map-notification"></p-toast>
