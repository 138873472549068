import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";

export const reportsSubscription = (state: AppState) =>
  state.reportsSubscription;

export const selectAppliedParameters = ({ moduleType }) =>createSelector(reportsSubscription,
    (state) => state?.[moduleType]?.["appliedParameters"] || state
);

export const selectSubscriptionStatus = createSelector(reportsSubscription,
  (state) => state.successfulSubscriptionIndicator
);

export const selectReportSubscriptions= ({ moduleType }) => createSelector(reportsSubscription,
    (state) => state[moduleType]?.['subscriptions'] || state
);
