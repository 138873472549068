import { createReducer, on } from "@ngrx/store";
import { NotificationActions } from ".";
import { Notifications } from "./interfaces/INotification.modal";

export interface NotificationState {
    notifications: Notifications,
    isBannerInTopPosition:boolean,
    msgBannersCount:number
}

export const initialState: NotificationState = {
    notifications: null,
    isBannerInTopPosition:false,
    msgBannersCount:0
  };

export const NotificationReducer = createReducer(
    initialState,
    on(NotificationActions.allNotificationFetchSuccess, (state, {notifications}) => {
        return {
            ...state,
            notifications: notifications
        }
    }),
    on(NotificationActions.updateNotificationList, (state, {notification}) => {
        const newArr = [notification, ...(state.notifications || [])]
        return {
            ...state,
            notifications: newArr
        }
    }),
    on(NotificationActions.setTopMsgBannersCount, (state, {count:msgBannersCount}) => {
        return {
            ...state,msgBannersCount
        }
    })

);