import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform(array: any[], property: string): any[] {
    if (!array || !property) return array;

    return array.slice().sort((a, b) => {
      if(!a?.[property] || !b?.[property]){
        return array
      }
     return a[property].localeCompare(b[property])
    });
  }
}
