import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";


export const selectSuperAdminSettings = (state: AppState) =>
  state.superAdminSettings;

export const getRolesByPage = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.allRoles
);
export const getRolesCount = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.allRolesCount
);

export const getFocusedRole = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.focusedRole
);

export const getCRUDStatusMsg = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.CRUDStatusMsg
);


export const getAccountsByPage = createSelector(
  selectSuperAdminSettings,
  (settings)=> settings.allAccounts
)
export const getAccountsCount = createSelector(
  selectSuperAdminSettings,
  (settings)=> settings.allAccountsCount
)

export const getFocusedAccount = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.focusedAccount
);

export const getUsersCount = createSelector(
  selectSuperAdminSettings,
  (settings)=>settings.allUsersCount
)

export const getUsersByPage = createSelector(
  selectSuperAdminSettings,
  (settings)=> settings.allUsers
)

export const getFocusedUser = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.focusedUserProfile
);



export const getPageVisitRecord = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.pageVisitsRecord
);

export const getThemeTypesWithThemeName = createSelector(
  selectSuperAdminSettings,
  (settings) =>settings.kioskThemesTypes
);

export const themeDropDownValueDark = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.kioskThemesTypes?.dark
);

export const themeDropDownValueLight = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.kioskThemesTypes?.light
);
export const resetIsKioskThemeUpdated = createSelector(
  selectSuperAdminSettings,
  (settings) => settings.iskioskThemeUpdated
);

export const loaderForAbility = createSelector(
  selectSuperAdminSettings,(settings)=>settings.loaderForAbility
)
export const getAllAbilityCount = createSelector(
  selectSuperAdminSettings,(settings)=>settings.allAbilityCount
)
export const getAllAbilityTogether = createSelector(
  selectSuperAdminSettings,(settings)=>settings.allAbilityTogether
)
export const getAllAvailabeModules = createSelector(
  selectSuperAdminSettings,(settings)=>settings.allModules
)

export const getAllAvailabeCategories = createSelector(
  selectSuperAdminSettings,(settings)=>settings.allCategories
)

export const getAllTypesWithCategorySelector = createSelector(
  selectSuperAdminSettings,(settings)=>settings.typeRealtedToCategory
)

export const selectorLoaderForPlugin = createSelector(
  selectSuperAdminSettings,(settings)=>settings.loaderForPlugin
)




