import {LatLngExpression} from "leaflet";
import {IVisualMap} from "../../app/shared/e-charts/e-charts-data-type";

export interface IO2dDashboard {
  orderStateData: IOrderStatsData,
  o2dGraphs: Record<string, IOrderSource>
}

export interface IMmdDashboard {
  orderStateData: IOrderStatsData,
  mmdGraphs: Record<string, IOrderSource>,
  assignedRoutePlansData?: IAssignedRoutePlan[]
}

export interface ILmdDashboard {
  orderStateData: IOrderStatsData,
  lmdGraphs: Record<string, IOrderSource>
}

export interface IWarehouseDashboard {
  zoneList: IWarehouseZoneList[];
  warehouseDashboardGraph: any;
  warehouseGraphs: any,
  selectedCity?: any,
  selectedLocation?: any,
  locationList?: any[],
  city?: any,

}

export interface IKioskDashboard {
  countCards: ICountCardsForKiosk,
  kioskGraphs: Record<string, IOrderSource>
}

export interface ICountCardsForKiosk {
  total: number;
  paid: number;
  discarded: number;
  refunded: number;
  totalAmount: number;
  netAmount: number;
  taxAmount: number;
}

export interface IOrderStatsData {
  total?: number,
  totalOrder?: number,
  delivered?: number,
  assigned?: number,
  cancelled?: number,
  deliveryPartnerReturnedOrder?: number
  returning_count?: number,
  pending_count?: number,
  completed_count?: number,
  total_count?: number
}

export interface IO2dOrderByTimeSlot {
  "04 A.M - 06 P.M": IO2dtimeSlot
}

export interface IO2dtimeSlot {
  manual: Manual[]
}

export interface Manual {
  date: string
  count: number
  slot: string
  orderSource: string
}

export interface IOrderbyAssignStatus {
  "04 A.M - 06 P.M": IO2dAssignStatus
}

export interface IO2dAssignStatus {
  Assigned?: Assigned[],
}

export interface Assigned {
  date: string
  count: number
  assigned: boolean
  slot: string
  assignedStatus: string
}

export interface IDeliveryStatus {
  count: number,
  date: string,
  deliveryStatus: string,
  slot: string
}


export interface ITemperatureDetails {
  orderId: string
  boxTemp: number
  deliverDateTime: string
}

export interface IO2ddashboardPayload {
  startDate?: object
  endDate?: object
  series?: string
  orderPickUpCityId?: string,
  orderPickUpStoreId?: string
  isDashBoard?: string,
  lpName?: string,
  assetType?: string,
  providedOrderStatus?: string;
  aggregationDate?: string;
  graphKey?: string;
  interval?: number;
  groupByField?: string,
  fieldType?: string,
  pillValue?: string,
  grpId?: string,
}

export interface ICountStatus {
  count: number
}

export interface IMMDCountStatus {
  count: number;
  status: string
}

export interface IQueueInTrend {
  order?: IOrder[]
  deliveryPerson?: IOrder[]
}

export interface IOrder {
  assetType: string
  date: string
  count: number
  formattedDate: string
  timeZoneDate: string
}

export interface IRiderWisePerformance {
  date: string
  delivered: number
  agentId: string
  distanceInKm: number
  agentName: string
  count: number
  deliveryStatus: string
  distanceTravelledInKm: number
}

export interface ITatOfOrder {
  date: string
  count: number
  slot: string
  barName: string
  status: string
}

// export interface IDeliveryPerson {
//   assetType: string
//   date: string
//   count: number
//   formattedDate: string
//   timeZoneDate: string
// }
// export interface IOrderSourceObj {
//   "orderSource": IOrderSource
// }
export interface ILocationList {
  arrayIndex: number
  assetDetails: IWarehouseZoneList
}

export interface IOrderSource {
  counts: ICounts
  graphTypePills: IGraphTypePills
  graphType: string
  countArray?: []
  pills?: IPills
  downloadPills?: IDownloadPills

  // count:number
}

export interface ICounts {
  counts: number
  chartData: IChartData
  show?: boolean
  showLoader?: boolean
  title: string
  graphTypeChangedRandomNumber: number
  colorsArray: Array<string>
  updateValue?: IEChartSeriesUpdate,
}

export interface IEChartSeriesUpdate {
  index: unknown,
  label: unknown,
  value: unknown,
  labelIndex: number,
  values: any
};

export interface IChartData {
  isDataZoomRequired: boolean
  legend: any[]
  series: ISeries[]
  title: string
  type: string
  xAxis: any[]
  legendToDisable?: string,
  visualMap?: IVisualMap,
  cursor?: string
}

export interface ISeries {
  data: any[]
  type: string
  name: string
  stack: boolean
  smooth: boolean
  label: Label
  itemStyle: ItemStyle
  textStyle: TextStyle
}

export interface Label {
  normal: Normal
}

export interface Normal {
  show: boolean
  position: string
}

export interface ItemStyle {
  color: string
  opacity: number
}

export interface TextStyle {
  fontSize: number
}

export interface IGraphTypePills {
  arrayOfGraphTypePills: IArrayOfGraphTypePill[]
}

export interface IPills {
  selected?: string
  arrayOfDurationPills?: IArrayOfGraphTypePill[]
}

export interface IDownloadPills {
  arrayOfDownloadPills: IArrayOfGraphTypePill[]
  selected?: string
}


export interface IArrayOfGraphTypePill {
  id: string
  label: string
  description?: string
  inActiveImgSrc?: string
  activeImgSrc?: string
  active?: boolean
  isStacked?: boolean
  isStackable?: boolean
  isCurrentType?: boolean
  isSelected?: boolean
}

export const echartOptions = {
  chartSeries: "hour",
  type: "line",
};

export const defaultBarChartData = {
  isDataZoomRequired: false,
  legend: [],
  series: [
    {
      data: [],
      type: "bar",
      name: "",
      stack: false,
      smooth: true,
      label: {normal: {show: false, position: "top"}},
      itemStyle: {color: "#8064a2", opacity: 1},
      textStyle: {
        fontSize: 10,
      },
    },
  ],
  title: "",
  type: "bar",
  xAxis: [],
};

export interface IO2dTemperatureGraph {
  date: string
  orderDetails: OrderDetail[]
  slot: string
}

export interface OrderDetail {
  date: string
  orderId: string
  temp: any
}


export interface IWarehouseZoneList {
  _id?: string
  assetType?: string
  name?: string
  accountId?: string
  updated?: string
  created?: string
  __v?: number
  currentLocation?: ICurrentLocation
  geoLocation?: IGeoLocation
  gpsData?: IGpsData
  isOffline?: boolean
  lastLat?: number
  lastLng?: number
  lastlocation?: number[]
  locationLastUpdated?: string
  obdOdoMeter?: any
  odoMeter?: string
  plugged?: IPlugged2
  registrationNumber?: string
  vinNumber?: string
  groups?: any[]
  assetsLinked?: IAssetsLinked[]
  serviceAssets?: any[]
  aProperties?: IAProperties
  city?: string
}

export interface ICurrentLocation {
  latitude?: number
  longitude?: number
}

export interface IGeoLocation {
  type: string
  coordinates: number[]
}

export interface IGpsData {
  lat?: number
  lng?: number
  satellites?: number
  name: string
  lpType: string
  sensorData: ISensorData
  deviceId: string
  timestamp: string
  autoAdd: boolean
  assetToBeLinkedWith: string
  assetType: string
  assetId: string;
  module: IModule
  accountId: string
  generatedTimeStamp: string
  receivedTime: string
  latitude?: number
  longitude?: number
  trackedAssetId: string
  location: ILocation
  plugged: IPlugged
  locationDetails: ILocationDetails
}

export interface ISensorData {
  GSMSignalStrength?: number
  BLEBatteryVoltage?: number
  extVoltage: number
  batteryVoltage?: number
  temperature: number
  humidity: number
  luxometer: number
  coldChainBox: string
}

export interface IModule {
  dms: IDms
}

export interface IDms {
  addToQueue: boolean
}

export interface ILocation {
  type: string
  coordinates: number | undefined[]
  latitude?: any;
  longitude?: any;
}

export interface IPlugged {
  status: string
  updated: string
  changed: boolean
}

export interface ILocationDetails {
  latitude?: number
  longitude?: number
}

export interface IPlugged2 {
  status: string
  updated: string
  changed: boolean
}

export interface IAssetsLinked {
  _id?: string
  assetType?: string
  name?: string
  branchCode?: string
  businessName?: string
  status?: string
  address?: string
  city?: string
  state?: string
  pincode?: string
  lat?: number
  lng?: number
  serviceablePincodes?: string[]
  pinnedSku?: any
  contactNumber?: number
  email?: string
  shopifyId?: any
  autoAssignment?: string
  kiosk?: string
  accountId?: string
  geoLocation?: IGeoLocation2
  updated?: string
  created?: string
  __v?: number
  assetsLinked?: string[]
  groups?: any
  serviceAssets?: any
  "yes/no": any
  isInventory?: boolean
  isZoneMonitored?: boolean
  uniqueId?: string
  isMonitoring?: any
}

export interface IGeoLocation2 {
  type: string
  coordinates: number[]
}

export interface IAProperties {
  isLongStop: boolean
  longStop: ILongStop
}

export interface ILongStop {
  date: string
  status: boolean
}

// export interface ITotalOrderKiosk {
//   "6446510621919c065558f698": N6446510621919c065558f698[]
// }

export interface ITotalOrderKiosk {
  count: number
  label: string
  date: string
  grpId?: string,
  amount?: number,
  formattedDate: string
  timeZoneDate: string
  sumValue?: number
}

export interface IAssignedRoutePlan {
  status: string;
  updated: string;
  created: string;
  deliveryGrpSequence: string;
  routePlan: string;
  carrierName: string;
  temperature: number;
  timeZoneDate: string;
}

export interface IMmdDeliveredGroupAnalyticsPayload {
  startDate: object
  endDate: object
  groupBy: string
  isCount: boolean;
  skip?: number
  limit?: number;
  orderPickUpCityId?: string,
  orderPickUpStoreId?: string,
  category?: string,
  carrierIds?: string,
  sortByFieldsStr?: string,
  deliveryStatus?: string
}

export interface IMmdDeliveredGroupAnalytics {
  data: IMmdDeliveredGroupAnalyticsData[];
  tripCount?: TripCount[];
  deliveryCount?: TripCount[];
  pagination: Pagination;
}

export interface Pagination {
  count: number,
  skip: number,
  limit: number
}

export interface TripCount {
  _id: string
  count: number
  tempCompliantCount?: number
  tempNonCompliantCount: number
}

export interface IMmdDeliveredGroupAnalyticsData {
  details: any[];
  groupedBy: string;
  tempCompliant: number;
  tempNonCompliant: number;
  total: number;
  _id: string;
  totalDeliveryCancelled: number;
  totalDeliveryDelivered: number;
  totalDeliveries: number;
  label?: string;
  pickUpLocation: any;
  pickUpCity: any;
  routePlanCategories: string[]
  routePlanNames: string[]
  routePlanIds: string
  route: IRoute;
}

export interface IMmdDeliveredAnalyticsData {
  tableData: IMmdDeliveredGroupAnalyticsData[];
  pagination: Pagination;
  tripCount?: TripCount[];
  deliveryCount?: TripCount[];
}


export interface IRoute {
  duration: TextWithValue
  distance: TextWithValue
  wayPointDetails: WayPointDetail[]
  pathEncoding: PathEncoding
  latLngs: LatLngExpression[]
  durationinTrafic: TextWithValue
  serviceProvider: string
  routes: any[]
}

export interface WayPointDetail {
  distance: TextWithValue
  duration: TextWithValue
  end_address: string
  end_location: LocationLatLng
  start_address: string
  start_location: LocationLatLng
  traffic_speed_entry: any[]
  via_waypoint: any[]
}

export interface LocationLatLng {
  lat: number
  lng: number
}

export interface PathEncoding {
  points: string
}

export interface TextWithValue {
  text: string
  value: number
}

export interface IO2DOverviewAnalyticsPayload {
  groupType: string,
  startDate: object
  endDate: object
  orderPickUpCityId?: string,
  orderPickUpLocationId?: string,
  lpId?: string,
  filterType?: string,
  reportType: string
}

export interface IO2DOverviewAnalytics {
  data: IO2DOverviewAnalyticsData[]
  headers: Header[]
  orderCount: IO2DOverviewAnalyticsDataOrderCount[]
}

export interface IO2DOverviewAnalyticsData {
  name: string
  created: number
  assigned: number
  inProgress: number
  delivered: number
  deliveryAcknowledged: number
  totalCount: number
  adoptionPercent: string
}

export interface Header {
  header: string
  key: string
  width: number
  type: string
}

export interface IO2DOverviewAnalyticsDataOrderCount {
  orderStatus: string
  count: number
}
