/**
 * Created by chandru on 22/6/19.
 */

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable()
export class MaintenanceService {
  constructor(private http: HttpClient, public configService: ConfigService) { }

  getAllCarLinkedToObdWithDistanceFromMaintenanceBay() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'asset/carsConnected/distanceFromMaintenanceBay/');
  }

  getNotifiedResult(carId, mode) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'car/notify/maintenanceBay/' + carId + '?type=' + mode);
  }
}
