/**
 * Created by chandru on 27/8/18.
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      if (it.hasOwnProperty('assetData')) {
        if (it.assetData.beaconId) {
          return it.assetData.beaconId.toLowerCase().includes(searchText);
        } else if (it.assetData.RFIDId) {
          return it.assetData.RFIDId.toLowerCase().includes(searchText);
        }
      } else if (it.hasOwnProperty('beaconId')) {
        return it.beaconId.toLowerCase().includes(searchText);
      } else if (it.hasOwnProperty('carRegNo')) {
        return it.carRegNo.toLowerCase().includes(searchText);
      }
    });
  }
}
