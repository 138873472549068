import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormService} from '../../../../services/form.service';
import {AssetService} from '../../../../services/asset.service';
import { Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ConfigService} from '../../../../services/config.service';
import {BeaconGatewayService} from '../../../../services/beacon-gateway.service';
import {RfidGatewayService} from '../../../../services/rfid-gateway.service';
import {ServiceLinkableService} from '../../../../services/serviceLinkable.service';
import { Subject,Actions} from '../../../../auth/rules';
declare var $: any;
import {forEach} from 'lodash';
import moment from 'moment-timezone';
import {DateRangePickerService} from '../../../../services/date-range-picker.service';

@Component({
  selector: 'app-asset-edit-from',
  templateUrl: './asset-edit-from.component.html',
  styleUrls: ['./asset-edit-from.component.scss']
})
export class AssetEditFromComponent implements OnChanges, OnInit {
  form: UntypedFormGroup;
  Object = Object;
  defaultFields: string[] = [];
  datePickerVariables = {
    singleDatePicker : this.dateRangePickerService.singleDatePicker_Variabes.singleDatePicker,
    showDropdowns : this.dateRangePickerService.singleDatePicker_Variabes.showDropdowns,
    autoApply: this.dateRangePickerService.singleDatePicker_Variabes.autoApply,
    locale : this.dateRangePickerService.singleDatePicker_Variabes.locale,
    maxDate : this.dateRangePickerService.singleDatePicker_Variabes.getMaxDate(),
    minDate : this.dateRangePickerService.singleDatePicker_Variabes.getMinDate()
  }
  @Input() formConfigData: any = [];
  @Input() assetId: string;
  @Input() assetData: any;
  @Input() resetFormData: number;
  @Input() isEditOptionClicked: boolean;
  @Input() isInputDisabled: boolean;
 // @Output() event: EventEmitter<string> = new EventEmitter<string>();
  @Output() updateAssetCart: EventEmitter<string> = new EventEmitter<string>();
  @Output() deregisterAsset: EventEmitter<any> = new EventEmitter();
  SUBJECT=Subject;
  ACTIONS=Actions;

  constructor(public formService: FormService, public assetService: AssetService, public configService: ConfigService,
              public beaconGatewayService: BeaconGatewayService, public rfidGatewayService: RfidGatewayService,
              private serviceLinkableService: ServiceLinkableService, private fb: UntypedFormBuilder,
              private dateRangePickerService: DateRangePickerService ) {
    this.isInputDisabled = true;
  }

   ngOnChanges(changes: SimpleChanges) {
     for (const propName of Object.keys(changes)) {
       let change = changes[propName];
       if (propName === 'formConfigData') {
         if (change.currentValue !== change.previousValue) {
           if (this.formConfigData && this.formConfigData.length > 0) {
             this.createForm(this.formConfigData, true);
           }
         }
       }
       if (propName === 'resetFormData') {
         if (change.currentValue !== change.previousValue) {
           this.formConfigData = [];
         }
       }
       if (propName === 'isEditOptionClicked') {
         if ((change.previousValue !== undefined) && (change.currentValue !== change.previousValue)) {
           if (this.isEditOptionClicked === true) {
             this.isInputDisabled = false;
             this.disabledAndEnabledFields(this.formConfigData, false);
           } else {
             this.isInputDisabled = true;
             this.disabledAndEnabledFields(this.formConfigData, true);
           }
         }
       }
     }
    }

  submitEditConfig() {
    this.formateDateField(this.getValuesForDefaultFields(this.form.value));
    if (this.assetData.assetType) {
      this.editAssetConfig();
    } else if (this.assetData.serviceType) {
      this.editServiceConfig();
    }
  }

  getValuesForDefaultFields(formValues) {
    let _this = this;
    forEach(this.defaultFields, function(field) {
      formValues[field] = _this.form.get(field).value;
    });
    return formValues;
  }

  formateDateField(formValues) {
    for (let key in formValues) {
      if (formValues[key] && (typeof formValues[key] === 'object')) {
        if (formValues[key]['startDate'] ) {
          formValues[key] = formValues[key]['startDate'];
        }
      }
    }
  }

  editServiceConfig() {
   this.serviceLinkableService.updateServiceById(this.assetId, this.form.value)
     .subscribe((serviceData: any) => {
       this.updateAssetCart.emit(serviceData);
    });
  }

  editAssetConfig() {
    this.assetService.updateAssetsById(this.assetId, this.form.value)
      .subscribe((assetvalues: any) => {
        let url = assetvalues.gatewayProtocol + '://' + assetvalues.gatewayIpAddress + ':' + assetvalues.gatewayPortNumber;
        if (this.configService.appConfig.beaconGateway.BEACON_GATEWAY_ASSET_NAME === assetvalues.assetType){
          this.beaconGatewayService.saveBeaconGatewaySetting(url, this.formService.formatAssetSaveDetails(assetvalues))
            .subscribe((res: any) => {
              this.updateAssetCart.emit(res);
            });
        }
        if (this.configService.appConfig.rfidGateway.RFID_GATEWAY_ASSET_NAME === assetvalues.assetType){
          let url = assetvalues.gatewayProtocol + '://' + assetvalues.gatewayIpAddress + ':' + assetvalues.gatewayPortNumber;
          this.rfidGatewayService.saveRFIDGatewaySetting(url, this.formService.formatAssetSaveDetails(assetvalues))
            .subscribe((res: any) => {
              this.updateAssetCart.emit(res);
            });
        }
        this.updateAssetCart.emit(assetvalues);
      });
  }

  deregisterSelectedAsset() {
    this.deregisterAsset.emit(this.assetId);
  }

  ngOnInit() {
    this.formConfigData = [];
  }

  pushInputValues(value, isDisabled) {
    let _this = this;
    let innerArray: any = [];
    let outerArray: any = [];
    if (value.required) {
      innerArray.push(Validators.required);
    }
    if (value.fieldValue && value.default) {
      _this.defaultFields.push(value.field);
      outerArray.push({'value': value.default, disabled: true});
    } else if (value.fieldValue) {
      outerArray.push({'value': value.fieldValue, disabled: isDisabled});
    } else {
      outerArray.push({'value': null, disabled: isDisabled});
    }
    outerArray.push(innerArray);
    return outerArray;
  }

  pushDateInputValues(value, isDisabled) {
    let _this = this;
    let innerArray: any = [];
    let outerArray: any = [];
    if (value.required) {
      innerArray.push(Validators.required);
    }
    if (value.fieldValue && value.default) {
      _this.defaultFields.push(value.field);
      outerArray.push({'value': {'startDate': moment(value.default), 'endDate': moment(value.default)}, disabled: true});
    } else if (value.fieldValue) {
      outerArray.push({'value': {'startDate': moment(value.fieldValue), 'endDate': moment(value.fieldValue)}, disabled: isDisabled});
    } else {
      outerArray.push({'value': null, disabled: isDisabled});
    }
    outerArray.push(innerArray);
    return outerArray;
  }

  createForm(formConfigData, isDisabled) {
    let _this = this;
    let formObject = {};
    this.defaultFields = [];
    forEach(formConfigData, function(value) {
      switch (value.type) {
        case ('text' || 'textarea' || 'number' || 'email' || 'dropDown'): {
          formObject[value.field] =  _this.pushInputValues(value, isDisabled);
          break;
        }
        case 'date': {
          formObject[value.field] =  _this.pushDateInputValues(value, isDisabled);
          break;
        }
        default: {
          formObject[value.field] =  _this.pushInputValues(value, isDisabled);
          break;
        }
      }
    });
    this.form = this.fb.group(formObject);
  }

  disabledAndEnabledFields(formConfigData, isDisabled: boolean) {
    let _this = this;
    forEach(formConfigData, function(value) {
        if (isDisabled) {
          _this.form.get(value.field).disable();
        } else {
          _this.form.get(value.field).enable();
        }
    });
  }

}
