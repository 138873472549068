@if ((currentActiveSidebarId$ | async) === sidebarId) {
  <div
    class="custom-modal create-modal"
    [ngStyle]="{'display':'block'}"
    [ngClass]="{'custom-modal-view':true}"
    [id]="sidebarId"
  >
    <div class="custom-modal-content">
      <div class="custom-modal-header">
        <div class="grid mx-0">
          <h5 class="modal-title">{{'sys.add' | translate}} {{assetSelectedLabel | translate}}</h5>
          <button type="button" id="sidebar-right-to-add-close" class="close closeCreateModal ml-auto" data-dismiss="modal" (click)="closePopup()"><span aria-hidden="true">&times;</span></button>
        </div>
      </div>
      <div class="custom-modal-body mb-1 mt-2">
        <app-asset-form [formConfigData]="assetFromConfig" [assetSelectedType]="assetSelectedType" (closeModalEvent) ="closePopup()" [isPreFilledRequired] = isPreFilledRequired
                        (savedAssetCard)="savedAssetCard($event)"
                        (assetRegistrationErrorMsg)="assetRegistrationErrorMsg($event)"></app-asset-form>
      </div>
      @if (showCircularLoader) {
        <div>
          <app-circular-loader></app-circular-loader>
        </div>
      }
    </div>
  </div>
}
