import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [],
  imports: [],
  exports: [
    MatIconModule,
  ]
})
export class MaterialModule { }
