import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";


export const selectNotification = (state:AppState) => state.notification


export const getNotificationList = createSelector(
    selectNotification,
    (notification) => notification.notifications
  )

  export const getMsgBannerPosition = createSelector(
    selectNotification,
    (notification) => notification.isBannerInTopPosition
  )  
  export const getMsgBannerCount = createSelector(
    selectNotification,
    (notification) => notification.msgBannersCount
  )  