import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigService} from './config.service';

export class CustomTranslateHttpLoader implements TranslateLoader {

  constructor(private http: HttpClient, private configService: ConfigService) {
  }


  getTranslation(lang: string): Observable<NonNullable<unknown>> {
    const token = localStorage.getItem('token');
    const url = `${this.configService.appConfig.appBaseUrl}languageMeta/id/${lang}`;
    const headers = {
      authorization: token ? token : ''
    };

    return new Observable<any>(s => {
      const lang = localStorage.getItem('language');
      if (lang) {
        s.next(JSON.parse(lang));
      }

      this.http.get(url, {headers: headers}).subscribe(res => {
        localStorage.setItem('language', JSON.stringify(res));
        s.next(res);
        s.complete();
      });
    });
  }
}
