import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
declare  var $: any;
import {forEach, clone} from 'lodash';
import {DateRangePickerService} from '../../../services/date-range-picker.service';


@Component({
 selector: 'app-static-data-dynamic-form',
 templateUrl: './static-data-dynamic-form.component.html',
 styleUrls: ['./static-data-dynamic-form.component.scss']
})
export class StaticDataDynamicFormComponent implements OnInit, OnChanges {


  form: UntypedFormGroup;
  defaultFields: string[] = [];
  hiddenFields: Array<object> = [];
  isFormReadyToDisplay: boolean;
  showCircularLoader: boolean;
  timePickerValidator:any
  datePickerVariables = {
    singleDatePicker : this.dateRangePickerService.singleDatePicker_Variabes.singleDatePicker,
    showDropdowns : this.dateRangePickerService.singleDatePicker_Variabes.showDropdowns,
    autoApply: this.dateRangePickerService.singleDatePicker_Variabes.autoApply,
    locale : this.dateRangePickerService.singleDatePicker_Variabes.locale,
    maxDate : this.dateRangePickerService.singleDatePicker_Variabes.getMaxDate(),
    minDate : this.dateRangePickerService.singleDatePicker_Variabes.getMinDate()
  };
  @Input() isEditForm: boolean;
  @Input() eventToEdit: any;
  @Input() formConfigData: any;
  @Input() resetFormData: number;
  @Output() assetRegistrationErrorMsg: EventEmitter<string> = new EventEmitter();
  @Output() formSubmitHandler: EventEmitter<any> = new EventEmitter();
  constructor( private fb: UntypedFormBuilder,
                private dateRangePickerService: DateRangePickerService) {
    this.isFormReadyToDisplay = false;
    this.showCircularLoader = false;
  }


  ngOnInit(): void {
    this.formConfigData = [];
  }


  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'formConfigData') {
        // this.formConfigData = [];
        if (change.currentValue !== change.previousValue) {
          this.isFormReadyToDisplay = false;
          this.createForm(this.formConfigData);
        }
      }
      if (propName === 'eventToEdit') {
        if (change.currentValue !== change.previousValue) {
          if (this.eventToEdit) {
            this.isFormReadyToDisplay = false;
            this.createForm(this.formConfigData);
          }
        }
      }
      if (propName === 'resetFormData') {
        if (change.currentValue !== change.previousValue) {
          this.isFormReadyToDisplay = false;
          this.showCircularLoader = true;
        }
      }
    }
  }


  setValuesToFields(formObject) {
    const _this = this;
    forEach(formObject, function(_, key) {
      if(key=='startTime' || key=='endTime'){
        _this.form.controls[key].setValue(`${_this.eventToEdit[key].hour}:${_this.eventToEdit[key].minute}`);
      }else{
        _this.form.controls[key].setValue(_this.eventToEdit[key]);
      }
    });
  }


  createForm(formConfigData) {
    let formObject = {};
    this.hiddenFields = [];
    let _this = this;
    forEach(formConfigData, function(value) {
      let innerArray: any = [];
      let outerArray: any = [];
      if (value.required) {
        innerArray.push(Validators.required);
      }
      if (value.defaultValue) {
        outerArray.push({'value': value.defaultValue.value, disabled: false});
      } else {
        outerArray.push(null);
      }
      if (value.type === 'hidden') {
        _this.hiddenFields.push(value);
      }
      outerArray.push(innerArray);
      formObject[value.field] = outerArray;
    });
    this.form = this.fb.group(formObject);
    if (this.isEditForm) {
      this.setValuesToFields(formObject);
    }
    this.isFormReadyToDisplay = true;
    this.showCircularLoader = false;
  }


  submitConfigDetails() {
    //this.formateDateField(this.getValuesForDefaultFields(this.form.value));
    this.setHiddenFieldValues(this.hiddenFields);
    let formValue = clone(this.form.value);
    let formValueObj = {};
    formValueObj['action'] = 'save';
    formValue = {...formValue,
      startTime:{
        hour:parseInt(formValue.startTime.split(":")[0]),
        minute:parseInt(formValue.startTime.split(":")[1]),
        second:0
      },
      endTime:{
        hour:parseInt(formValue.endTime.split(":")[0]),
        minute:parseInt(formValue.endTime.split(":")[1]),
        second:0
      }
    }
    formValueObj['value'] = formValue;
    if (this.isEditForm) {
      formValueObj['action'] = 'update';
    }
    this.formSubmitHandler.emit(formValueObj);
  }


  setHiddenFieldValues(hiddenValues) {
    const _this = this;
    forEach(hiddenValues, function(field: any) {
      let fieldType = field.field;
      _this.form.controls[fieldType].setValue(field.value);
    });
  }


  setStartEndDate(event,label) {
    let time = this.extractTime(event);
    if(label==`endTime`){
      let hr = this.form.controls["startTime"].value ? this.form.controls["startTime"].value.split(":")[0] : 0
      if(parseInt(hr) > time.h){
        this.timePickerValidator = true
      }else{
        this.timePickerValidator = false
      }
    }else{
      let hr = this.form.controls["endTime"].value ? this.form.controls["endTime"].value.split(":")[0] : 0
      if(parseInt(hr) < time.h && hr!==0){
        this.timePickerValidator = true
      }else{
        this.timePickerValidator = false
      }
    }
    this.form.controls[label].setValue(`${time.h}:${time.m}`);
    
  }


  extractTime(e) {
    const hour = e.getHours();
    const min = e.getMinutes()
    return { h: hour<10 ? `0`+hour : hour, m:min<10 ? `0`+min : min };
  }

  convertBackToCalenderFormat(value){
    return `${value.hour}:${value.minute}`
  }


}
