import {Component, OnDestroy, OnInit,} from '@angular/core';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router,} from '@angular/router';
import {ValidityCheckService} from '../services/validity-check.service';
import {LangUtilService} from './util/lang-util.service';
import {Subscription} from 'rxjs';
import {PrimeNGConfig} from 'primeng/api';
import {Ability} from '@casl/ability';
import {Store} from '@ngrx/store';
import {LoginActions} from '../state/Login';
import {AccountLicenceIfoState} from '../state/Login/login.reducer';
import {getUserMap, registerFirebaseToken} from '../state/User/user.action';
import pk from '../../package.json';
import {AppState} from "../state/app.state";
import {getToken} from '../state/Login/login.selector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  version = pk.version;
  title = 'app';
  HTTPActivity: boolean;
  public resetSearchTextToHeader: any;
  public licenceValidityMessage = '';
  showLoader = false;
  authTokenSub: Subscription;
  isUserAuthenticated = false;

  theme = 'noir';

  layoutMode = 'static';

  megaMenuMode = 'gradient';

  menuMode = 'light';

  profileMode = 'inline';

  inputStyle = 'outlined';

  ripple = true;

  constructor(
    public router: Router,
    private lang: LangUtilService,
    private validityCheckService: ValidityCheckService,
    private primengConfig: PrimeNGConfig,
    private ability: Ability,
    private store: Store<AppState>,
    // private update: PwaUpdateService
  ) {
    // this.update.handleUpdate();
    router.events.subscribe((event): void => {
      if (event instanceof RouteConfigLoadStart) {
        this.showLoader = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.showLoader = false;
      }
    });
    if (sessionStorage.getItem('userLanguage')) {
      const session_lang = sessionStorage.getItem('userLanguage');
      this.lang.updateUserLanguage(session_lang);
    }
  }

  ngOnInit() {
    console.log('App -version', this.version);
    if (!sessionStorage.getItem('userRoles')) {
      const userRoles = ['operator'];
      sessionStorage.setItem('userRoles', JSON.stringify(userRoles));
    }

    this.authTokenSub = this.store.select(getToken).subscribe((token) => {
      if (token) {
        this.isUserAuthenticated = true;
      } else {
        this.isUserAuthenticated = !!sessionStorage.getItem('token');
      }

      if (this.isUserAuthenticated) {
        const validitySub = {
          next: (validityData: unknown) => {
            // localStorage.setItem('accountLicenseInfo', JSON.stringify(validityData));
            let accHasLicensing = false;
            let expiryDate = '';
            let licenceExpiryMsg = '';
            let alert = false;
            // localStorage.setItem('accountLicenseInfo', JSON.stringify(response.response['accountLicenseInfo']));
            if (validityData['isAccountHasLicensing']) {
              accHasLicensing = validityData['isAccountHasLicensing'];
              licenceExpiryMsg = validityData['message'];
              expiryDate = validityData['accountLicenseExpiryDate'];
              alert = validityData['accountLicenseExpiryAlert'];
            }
            const accountLicenceInfoState: AccountLicenceIfoState = {
              isAccountHasLicensing: accHasLicensing,
              accountLicenseExpiryAlert: (accHasLicensing ? (alert) : false),
              accountLicenseExpiryDate: expiryDate,
              licenceExpiryMsg: licenceExpiryMsg ? licenceExpiryMsg : ''
            };
            this.store.dispatch(LoginActions.setAccountLicenceAlertInfo({ accountLicenceInfoState }));

            if (validityData['enableSoftWarning']) {
              this.licenceValidityMessage = validityData['message'];
              this.validityCheckService.setValidityMessage(validityData['message']);
            } else {
              this.validityCheckService.setValidityMessage('');
            }
          },
          error: console.error,
          complete: console.log
        }
        this.validityCheckService.getValidityStatus().subscribe(validitySub);

        this.store.dispatch(getUserMap());
        this.store.dispatch(registerFirebaseToken());
      }
    });


    this.primengConfig.ripple = true;
    this.ripple = true

    if (sessionStorage.getItem('rules')) {
      this.ability.update(JSON.parse(sessionStorage.getItem('rules')));
    }
  }

  resetSearchTextValue(url) {
    this.resetSearchTextToHeader = url;
  }

  ngOnDestroy() {
    this.authTokenSub.unsubscribe();
  }
}
