/**
 * Created by chandru on 7/8/18.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable()
export class ExceptionService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  getBeaconException() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'beaconException/');
  }
  saveBeaconException(exceptionBeconIds) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'beaconException/', exceptionBeconIds);
  }
  deregisterBeconException(ids) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'beaconException/' + ids);
  }
}
