import {createReducer, on} from "@ngrx/store";
import {CommunicationActions} from ".";
import { CommunicationDetailsResponse, CommunicationModeCountResponse } from "./interfaces/communication.modal";

export interface CommunicationState {
    communicationDetails: CommunicationDetailsResponse,
    error: any,
    showLoader : boolean,
    communicationModeCountResponse: CommunicationModeCountResponse
  }

export const initialState:CommunicationState = {
    communicationDetails: {
      data : [],
      pagination: null,
     },
     error: null,
     showLoader : false,
     communicationModeCountResponse:[]
  };

  export const CommunicationReducer = createReducer(
    initialState,
      on(CommunicationActions.loadCommunicationListWithPaginationSuccess,(state, {response})=>{
        return{
          ...state,
          communicationDetails: response
        }
      }),
      on(CommunicationActions.loadCommunicationListFailureMethod,(state, {err})=>{
        return{
          ...state,
          error:err
        }
      }),
      on(CommunicationActions.changeShowLoaderValue,(state,{show})=>{
        return {
          ...state,
          showLoader : show
          }
      }),
      on(CommunicationActions.loadCommunicationModeCountSuccess,(state, {response})=>{
        return{
          ...state,
          communicationModeCountResponse: response
        }
      }),
  )