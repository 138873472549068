import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import moment from 'moment-timezone';
declare  var $: any;

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {
  monthPickerOptions = {
    monthPickerOpened: false,
    selectedMonths: ''
  };
  previousValues = {
    monthIndexes: [],
    monthsData: []
  };
  @Output() monthRangeSelected = new EventEmitter<string>();
  currentYearIndex: number;
  years: Array<number>;
  months: Array<string>;
  monthsData: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean
  }>;
  rangeIndexes: Array<number>;
  monthViewSlicesIndexes: Array<number>;
  monthDataSlice: Array<{
    monthName: string,
    monthYear: number,
    isInRange: boolean,
    isLowerEdge: boolean,
    isUpperEdge: boolean
  }>;
  globalIndexOffset: number;

  @Input() initMonthPicker: number;

  constructor() { }

  onClick(indexClicked, isPreviousDataExist?) {
    if (this.rangeIndexes[0] === null) {
      this.rangeIndexes[0] = this.globalIndexOffset + indexClicked;
    } else if (this.rangeIndexes[1] === null) {
      this.monthPickerOptions.monthPickerOpened = false;
      this.rangeIndexes[1] = this.globalIndexOffset + indexClicked;
      this.rangeIndexes.sort((a, b) => a - b);
      this.monthsData.forEach((month, index) => {
        if ((this.rangeIndexes[0] <= index) && (index <= this.rangeIndexes[1])) {
          month.isInRange = true;
        }
        if (this.rangeIndexes[0] === index) {
          month.isLowerEdge = true;
        }
        if (this.rangeIndexes[1] === index) {
          month.isUpperEdge = true;
        }
      });
      let fromMonthYear = this.monthsData[this.rangeIndexes[0]];
      let toMonthYear = this.monthsData[this.rangeIndexes[1]];
      this.monthPickerOptions['selectedMonths'] = `${fromMonthYear.monthName} ${fromMonthYear.monthYear} to ${toMonthYear.monthName} ${toMonthYear.monthYear}`;
      if (!isPreviousDataExist) {
        this.emitData({
          'startDate': {'month': fromMonthYear.monthName, 'year': fromMonthYear.monthYear},
          'endDate': {'month': toMonthYear.monthName, 'year': toMonthYear.monthYear}});
      }
    } else {
      this.copyPreviousSelectedRange(this.rangeIndexes, this.monthsData);
      this.initRangeIndexes();
      this.initMonthsData();
      this.onClick(indexClicked);
      this.sliceDataIntoView();
    }
  }

  copyPreviousSelectedRange(previousArray, previousMonthsData) {
    this.previousValues.monthIndexes = previousArray;
    this.previousValues.monthsData = previousMonthsData;
  }

  onBlur() {
    this.monthPickerOptions.monthPickerOpened = false;
    $('#monthPicker').hide(100);
    if (this.previousValues.monthIndexes.length > 0) {
      this.initRangeIndexes();
      let startMonth = this.previousValues.monthIndexes[0] - this.globalIndexOffset;
      let endMonth = this.previousValues.monthIndexes[1] - this.globalIndexOffset;
      this.onClick(startMonth, true);
      this.onClick(endMonth, true);
      this.monthsData = this.previousValues.monthsData;
    }
  }

  emitData(string) {
    this.monthRangeSelected.emit(string);
  }

  sliceDataIntoView() {
    this.globalIndexOffset = this.monthViewSlicesIndexes[this.currentYearIndex];
    this.monthDataSlice = this.monthsData.slice(this.globalIndexOffset, this.globalIndexOffset + 24);
  }

  incrementYear() {
    if (this.currentYearIndex !== this.years.length - 1) {
      this.currentYearIndex++;
      this.sliceDataIntoView();
    }
    this.checkAndDisableTheArrowBtns();
  }

  decrementYear() {
    if (this.currentYearIndex !== 0) {
      this.currentYearIndex--;
      this.sliceDataIntoView();
    }
    this.checkAndDisableTheArrowBtns();
  }

  disabledBtns = {};

  checkAndDisableTheArrowBtns() {
    this.disabledBtns['prev'] = this.currentYearIndex === 0 ? true : false;
    this.disabledBtns['next'] = this.currentYearIndex === this.years.length - 1;
  }

  initRangeIndexes() {
    this.rangeIndexes = [ null, null ];
  }

  initMonthsData() {
    this.monthsData = new Array();
    this.years.forEach( year => {
      this.months.forEach( month => {
        this.monthsData.push({
          monthName: month,
          monthYear: year,
          isInRange: false,
          isLowerEdge: false,
          isUpperEdge: false
        });
      });
    });
  }

  initYearLabels() {
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    this.years = range(currentYear - 1, currentYear, 1);
  }

  initMonthLabels() {
    this.months = new Array(12).fill(0).map((_, i) => {
      return new Date(`${i + 1}/1`).toLocaleDateString(undefined, {month: 'short'});
    });
  }

  initViewSlices() {
    this.monthViewSlicesIndexes = [];
    this.years.forEach((_, index) => {
      if (index === 0) {
        this.monthViewSlicesIndexes.push(0);
      } else if (index === 1) {
        this.monthViewSlicesIndexes.push(6);
      } else {
        this.monthViewSlicesIndexes.push(this.monthViewSlicesIndexes[index - 1] + 12);
      }
    });
  }

  ngOnInit() {
    this.initializeMonthPicker();
  }

  initializeMonthPicker() {
    this.initYearLabels();
    this.initMonthLabels();
    this.initViewSlices();
    this.initMonthsData();
    this.initRangeIndexes();
    this.currentYearIndex = this.years.findIndex(year => year === (new Date()).getFullYear());
    this.sliceDataIntoView();
    this.getAndhighlightdefaultMonths();
    this.checkAndDisableTheArrowBtns();
  }

  getAndhighlightdefaultMonths() {
    let endMonthYear = {'month': moment().format('MMM')[0].toUpperCase() + moment().format('MMM').slice(1).toLowerCase(), 'year': Number(moment().format('YYYY'))};
    let endMonthIndex = this.monthDataSlice.findIndex(month => month.monthName === endMonthYear.month && month.monthYear === endMonthYear.year);
    let startMonthYear = {'month': moment().subtract(3, 'month').format('MMM')[0].toUpperCase() + moment().subtract(3, 'month').format('MMM').slice(1).toLowerCase(), 'year': Number(moment().subtract(3, 'month').format('YYYY'))};
    let startMonthIndex = this.monthDataSlice.findIndex(month => month.monthName === startMonthYear.month && month.monthYear === startMonthYear.year);
    this.onClick(startMonthIndex);
    this.onClick(endMonthIndex);
  }

  openMonthPicker() {
    this.monthPickerOptions.monthPickerOpened = true;
  }

}
