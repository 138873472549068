import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {ProfileActions} from '.';
import {UserProfile} from '../../app/settings/user-profile/user-profile-type';
import { MessageService } from 'primeng/api';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private API:ApiService,
    private messageService:MessageService
  ) {}

  // Run this code when a getUserProfile action is dispatched
  getUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.getUserProfile),
      switchMap(() => {
        // Call the stores method, convert it to an observable
       return from(this.API.getUserProfile()).pipe(
          // Take the returned value and return a new success action containing the store list
          map((res:UserProfile) => ProfileActions.onUserProfileFetchSuccess({details:res})),
          // Or... if it errors return a new failure action containing the error
          catchError((error:string) => of(ProfileActions.onUserProfileFetchFailure({ error })))
        )
}
      )
    )
  );

  // Run this code when a getUserLang action is dispatched
  getUserLang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.getUserLanguage),
      switchMap(() => {
        // Call the stores method, convert it to an observable
       return from(this.API.getUserLanguage()).pipe(
          // Take the returned value and return a new success action containing the store list
          map((res:any) => ProfileActions.onUserLangFetchSuccess({details:res})),
          // Or... if it errors return a new failure action containing the error
          catchError((error:string) => of(ProfileActions.onUserLangFetchFailure({ error })))
        )
}
      )
    )
  );

  // Run this code when a getAllLang action is dispatched
  getAllLang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.getAllLanguages),
      switchMap(() => {
        // Call the stores method, convert it to an observable
       return from(this.API.getAllLanguages()).pipe(
          // Take the returned value and return a new success action containing the store list
          map((res:any) => ProfileActions.onAllLangFetchSuccess({list:res})),
          // Or... if it errors return a new failure action containing the error
          catchError((error:string) => of(ProfileActions.onAllLangFetchFailure({ error })))
        )
}
      )
    )
  );

  // Run this code when a updatePassword action is dispatched
  updatePWD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.updatePassowrd),
      switchMap((action) => {
        // Call the stores method, convert it to an observable
       return from(this.API.setNewPassword(action.profileId,action.newPassword)).pipe(
        tap(() => {
           let successMsg = action.profileId ? "Password updated successfully...!" :
                                               "Password updated successfully...! Please Login Again!";
      
          this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: successMsg });
        }),
          // Take the returned value and return a new success action containing the store list
          
          map((res:any) => ProfileActions.onUpdatePasswordSuccess({response:res,userId:action.profileId})),
          // Or... if it errors return a new failure action containing the error
          catchError((error:string) => of(ProfileActions.onUpdatePasswordFailure({ error })))
        )
}
      )
    )
  );

  // Run this code when a updateUserDetails action is dispatched
  updateUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.updateUserDetails),
      switchMap((action) => {
        // Call the stores method, convert it to an observable
       return from(this.API.updateUserDetails(action.details)).pipe(
          // Take the returned value and return a new success action containing the store list
          map((res:any) => ProfileActions.onUpdateUserDetailsSuccess({response:res})),
          // Or... if it errors return a new failure action containing the error
          catchError((error:string) => of(ProfileActions.onUpdateUserDetailsFailure({ error })))
        )
}
      )
    )
  );


}
