import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { Router } from "@angular/router";

@Injectable()
export class HTTPListener implements HttpInterceptor {
  // public pendingRequestCount: number = 0;
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get the token from a service
    const token: string = sessionStorage.getItem("token");
    // add it if we have one
    if (token) {
      if (!req.url.includes('maps.googleapis.com/maps/api/geocode/json')) {
        req = req.clone({ headers: req.headers.set('Authorization', token) });
      }
    } else {
      this.router.navigate(['login']);
    }
    if (!req.headers.has('Content-Type')) {
      // req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
    }
    // setting the accept header
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    // return next.handle(req);
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
        if (event.body && event.body.response === 401) {
          // redirect to the login route
          // or show a modal
          this.router.navigate(['login']);
        }

      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {

          // redirect to the login route
          // or show a modal
          this.router.navigate(['login']);
        }
      }
    }));

  }

}
