@if (formConfigData.length > 0) {
  <form [formGroup]="form">
    <div class="grid mx-0 my-2">
      @for (field of formConfigData; track field) {
        <div class="form-group py-2 col-12 md:col-6 lg:col-6 xl:col-6" style="float: left"
             [ngClass]="{'hide': (field.type === 'hidden')}">
          @if ((field.type === 'text') || (field.type === 'textarea') || (field.type === 'number') || (field.type === 'email')) {
            <div>
              <input pInputText type={{field.type}} placeholder="{{field.description | translate}}"
                     formControlName={{field.field}} [required]="field.required">
            </div>
          }
          @if (field.type === 'date') {
            <div>
              <input pInputText type="text" ngxDaterangepickerMd class="ng-date-picker-input"
                     [singleDatePicker]="datePickerVariables.singleDatePicker"
                     [autoApply]="datePickerVariables.autoApply" [maxDate]="datePickerVariables.maxDate"
                     [locale]="datePickerVariables.locale" [showDropdowns]="datePickerVariables.showDropdowns"
                     [minDate]="datePickerVariables.minDate"
                     [required]="field.required" placeholder="{{field.description | translate}}"
                     formControlName={{field.field}}>
            </div>
          }
          @if (field.type === 'dropDown') {
            <div>
              <p-dropdown [options]="field.value.dropDownValues"
                          [placeholder]="field.label | translate"></p-dropdown>
            </div>
          }
        </div>
      }
    </div>
    <div class="grid mx-0">
      <div class="col-12">
        @if (formConfigData.length > 0) {
          <button pButton pRipple class='' [disabled]="!form.valid"
                  (click)="submitConfigDetails()">{{ 'sys.submit' | translate }}
          </button>
        }
      </div>
    </div>
  </form>
}
