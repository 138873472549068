import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PrimeBannerMsgObj, PrimeMsgBanner } from './prime-msg-banner-data-types';



@Component({
  selector: 'app-prime-msg-banner',
  templateUrl: './prime-msg-banner.component.html',
  styleUrls: ['./prime-msg-banner.component.scss']
})
export class PrimeMsgBannerComponent implements  OnChanges {
  @Input() bannerConfig: PrimeMsgBanner;
  @Input() showOnPeak:boolean;

  @Output() emittAction: EventEmitter<string> = new EventEmitter();
  @Output() clearBanner: EventEmitter<string> = new EventEmitter();

  messages: PrimeBannerMsgObj[];

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {

    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'bannerConfig') {
        if (change.currentValue !== change.previousValue) {
          this.messages = this.bannerConfig['bannerMsgs'];
        }
      }
    }
  }

  clearMessage(idx:number){
    this.messages=this.messages.filter(({key},i)=>{
      if(i===idx){
        this.clearBanner.emit(key);
        return false
      }
      return true
    })
  }
  performAction(key:string){
    this.emittAction.emit(key)
  }
}