import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {LinkedAssetIconService} from '../../../services/linked-asset-icon.service';
import {AssetService} from '../../../services/asset.service';
import {forEach, find, remove} from 'lodash';
import {LangUtilService} from '../../util/lang-util.service';
import { assetsCardsImageDisplayStatusSelector } from '../../../state/Assets/assets.selector';
import { Store } from '@ngrx/store';
declare var $: any;

@Component({
  selector: 'app-add-asset-to-group',
  templateUrl: './add-asset-to-group.component.html',
  styleUrls: ['./add-asset-to-group.component.scss']
})
export class AddAssetToGroupComponent implements OnInit, OnChanges {
  @Input() addableAssetTypes: Array<string>;
  @Input() assetConfig: any;
  @Input() resetSearchedAssetOnPlusClick: number;
  @Output() addAssetDetailsToSlider: EventEmitter<any> = new EventEmitter();
  cardsImageDisplayStatus$ = this.store.select(assetsCardsImageDisplayStatusSelector);
  public addableAssetsWithLabelsAndIcons: any;
  defaultAssetForDropDown = {};
  assetTypeForSearch: string = '';
  searchForAsset: string = '';
  searchedAssetForAdding: any = [];
  assetNotFound: boolean = false;
  public addAssetsIds: any = [];
  constructor(private linkedAssetIconService: LinkedAssetIconService, private assetService: AssetService, public lang: LangUtilService,private store:Store) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'addableAssetTypes') {
        if (change.currentValue !== change.previousValue) {
          this.addableAssetsWithLabelsAndIcons = this.getAssetsLabelsWithTypes(this.addableAssetTypes, this.assetConfig);
          if (this.addableAssetsWithLabelsAndIcons.length > 0) {
            this.linkedAssetIconService.getIconAndColorsForLinkedAssets(this.addableAssetsWithLabelsAndIcons, this.assetConfig);
            this.defaultAssetForDropDown['assetLabel'] = this.addableAssetsWithLabelsAndIcons[0]['label'];
            if (this.addableAssetsWithLabelsAndIcons[0] && this.addableAssetsWithLabelsAndIcons[0]['icon'] && this.addableAssetsWithLabelsAndIcons[0] && this.addableAssetsWithLabelsAndIcons[0]['color']) {
              this.defaultAssetForDropDown['icon'] = this.addableAssetsWithLabelsAndIcons[0]['icon'];
              this.defaultAssetForDropDown['color'] = this.addableAssetsWithLabelsAndIcons[0]['color'];
            }
          }
        }
      }
      if (propName === 'resetSearchedAssetOnPlusClick') {
        if (change.currentValue !== change.previousValue) {
          this.resetSearchedAssets(true);
        }
      }
    }
  }

  getAssetsLabelsWithTypes(linkableAssets, allAssetTypes ) {
    let labelObj: any;
    let addableAssetsLabelWithType: any = [];
    forEach(linkableAssets, function(value) {
      labelObj = {};
      labelObj = find(allAssetTypes, function(o) { return o.type === value; });
      if (labelObj) {
        let obj: any = {};
        obj = {
          'type': labelObj.type,
          'label': labelObj.label
        };
        addableAssetsLabelWithType.push(obj);
      }
    });
    return addableAssetsLabelWithType;
  }

  setSelectedAssetType(assetData) {
    const asset=assetData.value
    this.assetTypeForSearch = asset.type;
    this.defaultAssetForDropDown['assetLabel'] = asset.label;
    this.searchForAsset = '';
    this.getLastTenAssetForSelectedType(this.assetTypeForSearch);
  }

  searchAsset(event) {
    this.assetNotFound = false;
    this.searchForAsset = event;
    if (this.searchForAsset) {
      this.assetService.searchForAssetFromassetType(this.searchForAsset, this.assetTypeForSearch, 0, 12)
        .subscribe(searchResult => {
          this.searchedAssetForAdding = searchResult;
          if (this.searchedAssetForAdding.length === 0) {
            this.assetNotFound = true;
          }
          this.addAssetsIds = [];
          // this.searchedAssets.emit(this.searchedAssetForAdding);
        });
    } else {
      this.searchedAssetForAdding = [];
      this.getLastTenAssetForSelectedType(this.assetTypeForSearch);
    }
  }

  getLastTenAssetForSelectedType(assetType) {
    this.assetNotFound = false;
    this.assetService.getAllByType(assetType, 0, 10)
      .subscribe(lastTenAssetOfSpecificType => {
        this.searchedAssetForAdding = lastTenAssetOfSpecificType;
        if (this.searchedAssetForAdding.length === 0) {
          this.assetNotFound = true;
        }
        this.addAssetsIds = [];
        // this.searchedAssets.emit(this.searchedAssetForAdding);
      });
  }

  resetSearchedAssets(getLastTenAssets: boolean) {
    this.searchedAssetForAdding = [];
    this.addAssetsIds = [];
    // this.searchedAssets.emit(this.searchedAssetForAdding);
    this.searchForAsset = '';
    this.assetTypeForSearch = this.addableAssetTypes[0] ? this.addableAssetTypes[0] : '';
    if (this.addableAssetsWithLabelsAndIcons.length > 0) {
      this.defaultAssetForDropDown= this.addableAssetsWithLabelsAndIcons[0]
    }
    if (getLastTenAssets) {
      this.getLastTenAssetForSelectedType(this.assetTypeForSearch);
    }
  }

  assetsDetailsForAddingToGroup(addingAssetsDetails) {
    $('.buttonEnable').prop('disabled', false);
    this.emitSelectedAssetIdsTOSlider(addingAssetsDetails);
  }

  emitSelectedAssetIdsTOSlider(addingAssetsDetails) {
    if (addingAssetsDetails.isAssetSelected === true) {
      let res;
      res = find(this.addAssetsIds, function(o) { return o === addingAssetsDetails._id; });
      if (!res) {
        this.addAssetsIds.push(addingAssetsDetails._id);
      }
    } else if (addingAssetsDetails.isAssetSelected === false) {
      remove(this.addAssetsIds, function(n) {
        return (n === addingAssetsDetails._id);
      });
    }
    this.addAssetDetailsToSlider.emit(this.addAssetsIds);
  }

  getLangLabel(labelCode: string) {
    if (labelCode) {
      return this.lang.getTranslation(labelCode);
    }
  }

}
