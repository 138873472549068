import {ICity, ILocation, ILogisticProvider} from './interfaces/IAsset.types.modal';
import {ITimeSlots} from './interfaces/ITimeSlots.modal';
import {FilterMetadata} from 'primeng/api';
import {IDeliveryCenterAsset} from './interfaces/IAsset.deliveryCenter.modal';
import {AssetsLinked} from './interfaces/Assets.modal';
import { forEach } from 'lodash';
// import { IOderTableHeaderData } from './interfaces/IOrder.table.header';
import { ICategory } from './interfaces/IAsset.category.model';
import { IAllAssignedCarriersData } from './interfaces/IAllAssignedCarriers.model';
import { CarrierName } from './interfaces/IExpansionConsignmentHeader.model';
import { IRoutePlan } from './interfaces/I-RoutePlan.table.header';
// import { IKioskTableHeaderData } from './interfaces/IAsset.kiosk';
export const MOBILE_BREAK_POINT = 481;


export const MOBILE_TABLE_COLUMNS = {
  o2d_order_tab : ['orderId', 'created', 'expectedDeliveryDateTimeDetails', 'orderStatusDetails'],
  o2d_pending_tab: ['orderId', 'created', 'expectedDeliveryDateTimeDetails', 'orderStatusDetails'],
  o2d_processing_tab: ['orderId', 'created', 'expectedDeliveryDateTimeDetails', 'orderStatusDetails'],
  o2d_dispatch_tab: ['orderId', 'created', 'expectedDeliveryDateTimeDetails', 'orderStatusDetails'],
  o2d_progress_tab: ['orderId', 'created', 'expectedDeliveryDateTimeDetails', 'orderStatusDetails'],
  kiosk_order_tab : ['orderId','amount','orderStatusDetails','paymentStatus','action'],
  kiosk_today_order_tab : ['orderId','amount','orderStatusDetails','paymentStatus','action'],
  kiosk_cds_tab : ['orderId','amount','orderStatusDetails','paymentStatus','action'],
  kiosk_kds_tab : ['orderId','amount','orderStatusDetails','paymentStatus','action'],
  mmd_route_plan: ['label','pickupLocation','removeAction'],
  mmd_order_tab : ['orderId','created','expectedDeliveryDateTimeDetails'],
  lmd_order_tab : ['orderId','created','pickupLocation','orderStatus'],
  lmd_order_queue_tab : ['orderId','created','pickupLocation','expectedDeliveryDateTimeDetails'],
  mmd_delivered_tab : ['orderId','deliverySequence','deliveryCenter','orderStatus'],
  lmd_delivered_tab : ['orderId','pickupCity','pickupLocation','orderStatus'],
  mmd_consignment_assign_tab : ['orderId','pickupLocation','expectedDeliveryDateTimeDetails'],
  lmd_order_assign_tab: ['orderId','custDetails','expectedDeliveryDateTimeDetails'],
}

export function updateLogistic(logistic, logisticProvider: ILogisticProvider[]) {
  return handleAvailable('name', '_id', logistic, logisticProvider);
}

export function updatePickUp(pickUp, cities: ICity[]) {
  return handleAvailable('city', '_id', pickUp, cities);
}

export function updateRoutePlans(routePlan, routePlans: IRoutePlan[]) {
  return handleAvailable('label', '_id', routePlan, routePlans);
}

export function updateDeliveryCenter(pickUp, centers: IDeliveryCenterAsset[]) {
  return handleAvailable('name', '_id', pickUp, centers);
}

export function updateLocation(pickLocation, location: ILocation[], filters) {
  pickLocation = {...pickLocation, availableOptions: {}, availableOptionsArray: []};
  let filter = ['all'];

  if (filters && filters?.['pickupCity']?.[0]?.value?.length > 0) {
    filter = filters['pickupCity'][0].value.map(v => v.name);
  }

  for (let i = 0; i < location.length; i++) {
    const l = location[i];
    if (filter.find(r => r === l.city) || filter[0] === 'all') {
      pickLocation.availableOptions[l.city] = {
        name: l.city,
        items: [
          ...pickLocation.availableOptions[l.city]?.items ?? [],
          {filterParameterValue: l._id, name: l.name, city: l.city, isSelected: false}
        ]
      };
    }
  }

  pickLocation.availableOptionsArray = Object.values(pickLocation.availableOptions);

  return pickLocation;
}

export function updateLinkedAssets(linkedData: AssetsLinked[]) {
  let array = []
  array = linkedData.map(r => r.assetDetails);
  return array;
}

export function updateDeliverySlots(timeslots, location: ITimeSlots[]) {
  return handleAvailable('label', 'value', timeslots, location);
}

export function constructPickupCityFilterIfNotPresent(mmdCities: ICity[], filterData?) {
  let filter = {
    'pickupCity': []
  }

  if(filterData && filterData?.['pickupCity']?.[0]?.value?.length > 0){
    filter = filterData;
  } else {
    const cityFilters: FilterMetadata = {
      value: []
    };

    mmdCities.forEach(r => {
      cityFilters.value.push({
        'name': r.city
      });
    })
    filter.pickupCity.push(cityFilters);
  }
  return filter;
}

export function updateDateRangeFieldInConfig(oOUpdated: any, key: any) {
  forEach(oOUpdated, (value, k) => {
    if (value && 'filterMenuType' in value) {
      const fType = value.filterMenuType;
      if (fType === 'dateRange') {
        if(k === key) {
          oOUpdated[k] = {...oOUpdated[k], isDefaultFilter: true};
        } else {
          oOUpdated[k] = {...oOUpdated[k], isDefaultFilter: false};
        }
      }
    }
  });
  return oOUpdated; 
}

type DataHolder = ITimeSlots[] | ILocation[] | ICity[] | ILogisticProvider[] | IDeliveryCenterAsset[] | IRoutePlan[]; 

function handleAvailable(s: string, fs: string, o, d: DataHolder) {
  o = {...o, availableOptions: {}, availableOptionsArray: [], availableOptionsKeysList: []};
  for (let i = 0; i < d.length; i++) {
    const l = d[i];
    if (l) {
      o.availableOptions[l[s]] = {filterParameterValue: l[fs], name: l[s], isSelected: false};
    }
  }

  if (o.availableOptions) {
    o.availableOptionsArray = Object.values(o.availableOptions);
    o.availableOptionsKeysList = Object.keys(o.availableOptions);
  }

  return o;
}

export function handleTableColumnsToDisplay(tableHeaderData:any, fields:unknown) {
  for (const key in tableHeaderData) {
    // const fValue = fields?.find(v => key === v.id)
    const fValue = fields?.[key]
    if(!fValue) {
      delete tableHeaderData[key];
    } else {
      if(fValue.isEnabled) {
        tableHeaderData[key] = {...tableHeaderData[key],isColumnSeen:fValue.showDefault};
      } else {
        delete tableHeaderData[key];
      }
    }
  }
  return tableHeaderData;
}

export function handleTableColumnsToDisplayInMobileView( tableHeaderData: any , fields:unknown, id: string) {
  const mobileColumns = MOBILE_TABLE_COLUMNS[id];
  for (const key in tableHeaderData) {
    // const fValue = fields?.find(v => key === v.id)
    const fValue = fields?.[key]
    if(!fValue) {
      delete tableHeaderData[key];
    } else {
      if(fValue.isEnabled) {
        const isColumnPresentInMobileView = mobileColumns && mobileColumns.includes(key)
        if(mobileColumns){
          tableHeaderData[key] = {...tableHeaderData[key],isColumnSeen:isColumnPresentInMobileView ? fValue.showDefault : false};
        } else {
          tableHeaderData[key] = {...tableHeaderData[key],isColumnSeen: fValue.showDefault};
        }
      } else {
        delete tableHeaderData[key];
      }
    }
  }
  return tableHeaderData;
}

export function setSequence(data: ICategory[]) {
  let cpData: { [key: string]: any; } = data.map((c, index) => {
      let cp = { ...c };
      c?.sequence ? cp.sequence = c?.sequence : cp.sequence = index + 1
      // c.status = true
      return cp
  })

  return cpData

}


export function setReArrangedSequence(data) {
  let cpData = data.map((c, index) => {
    let cp = { ...c,sequence:  index + 1 };
    
    return cp
  })
  return cpData

}


export function moveElement(arr: any[], from: number, to: number): any[] {
  const element = orderByField(arr, 'sequence').splice(from, 1)[0];
  arr.splice(to, 0, element);
  return setReArrangedSequence(arr);
}

function orderByField(array: unknown[], field: string): unknown[] {
  return array.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
}
/**
 * @param objToHydrate Is the object in which carrier is to be hydrated with the api data
 * @param availableOptionsKeyLabel Is the name of the field in data with which the 'updatedAvailableOptionsKeysList' will be populated
 * @param filterParameterValueLabel Is the name of the field in data with which the 'filterParameterValue' will be updated
 * @param data Is the response from the allCarriers api call
 * @returns Updated carrier obj in accordance to the response from api and params passed
 */
export const getHydratedCarrierTableFilter = (objToHydrate: unknown, availableOptionsKeyLabel: string, filterParameterValueLabel: string, data: IAllAssignedCarriersData[]) => {
  const updatedAvailableOptionsKeysList = [];
  const updatedAvailableOptions = {};

  const updatedAvailableOptionsArray = data.map(item => {
    updatedAvailableOptionsKeysList.push(item[availableOptionsKeyLabel])
    updatedAvailableOptions[item[availableOptionsKeyLabel]] = {filterParameterValue:item[filterParameterValueLabel]}

    return {
      ...item, 
      filterParameterValue:item[filterParameterValueLabel]
    }
  })

  const updatedCarrierNameHeaderObj : CarrierName = {
    ...objToHydrate['carrier'],
    availableOptionsArray: updatedAvailableOptionsArray,
    availableOptionsKeysList: updatedAvailableOptionsKeysList,
    availableOptions: updatedAvailableOptions
  }
  return updatedCarrierNameHeaderObj;
}

/**
 * Checks if the current view is a mobile view based on the window width.
 * @returns {boolean} - Returns true if the view is a mobile view, false otherwise.
 */
export function isMobileView() {
  return window.innerWidth < MOBILE_BREAK_POINT;
}
// --------------------------------

export function convertToConstantCase(input: string): string {
  const words = input.split(/(?=[A-Z])/);
  const constantCaseWords = words.map(word => word.toUpperCase());
  return constantCaseWords.join('_');
}

export function groupByCategory(data) {
  const groupedItems = {};
  // Group items by category
  data?.forEach((item, cIdx) => {
    const { category } = item;
    if (!groupedItems[category]) {
      groupedItems[category] = {
        label: category ?? 'Other',
        isSelected : false,
        children: [],
      };
    }
    let obj = {
      filterParameterValue : item?._id,
      isSelected : false,
      label : item?.label,
      parentName : category ?? 'Other',
      key: cIdx + Math.random()
    }
    groupedItems[category].children.push(obj);
  });
  const groupsAndItems = Object.values(groupedItems);
  return groupsAndItems;
}

export function handleTableColumnsToDisplayInMobilewithoutSetting(tableHeaderData : any,id: string){
  const mobileColumns = MOBILE_TABLE_COLUMNS[id];
  for (const key in tableHeaderData) {
    const isColumnPresentInMobileView = mobileColumns && mobileColumns.includes(key);
    if(mobileColumns){
      tableHeaderData[key] = {...tableHeaderData[key],isColumnSeen:isColumnPresentInMobileView ? true : false};
    } else {
      tableHeaderData[key] = {...tableHeaderData[key]};
    }
  }
  return tableHeaderData;
}

export function checkIfFIncludeDoNotHaveShowInCardField(data, fInc) {
  let addedFInc = fInc;
  data?.configuration && Object.entries(data?.configuration).map(entry => {
 //   const key = entry[0];
    const value: any = entry[1];
    if(value?.showInCard){
      const res = fInc?.includes(value?.field);
      if(!res){
        addedFInc = addedFInc+','+value?.field
      }
    }
  });

  return addedFInc;
}


export function handleTableheaderForCustCollab(fields){
  let key = 'customerCollabrationStatus';
  delete fields?.[key]
  return fields
}