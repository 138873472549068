import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommunicationActions } from '.';
import {CommunicationService} from './communication.service';
import { catchError, switchMap, from } from 'rxjs';

@Injectable()
export class CommunicationEffects {
  constructor(
    private actions$: Actions,
    private communicationService:CommunicationService
  ) {}

  getAllNotification$ = createEffect(() =>
    this.actions$.pipe(
        ofType(CommunicationActions.loadCommunicationList),
        switchMap((action) => {
            return from(this.communicationService.effectGetCommunicationDetails(action.payload)).pipe(
                switchMap((res) => {
                    return [CommunicationActions.loadCommunicationListWithPaginationSuccess({ response: res }), CommunicationActions.changeShowLoaderValue({ show : false })]
                }),
                catchError((error) => {
                  return [(CommunicationActions.loadCommunicationListFailureMethod({ err: error })),(CommunicationActions.changeShowLoaderValue({ show : false }))]
                })
            )
        })
    )
  )

  loadCommunicationModeCount$ = createEffect(() =>
    this.actions$.pipe(
        ofType(CommunicationActions.loadCommunicationModeCount),
        switchMap((action) => {
            return from(this.communicationService.effectGetCommunicationDetailsCountByType(action.payload)).pipe(
                switchMap((res) => {
                    return [CommunicationActions.loadCommunicationModeCountSuccess({ response: res }), CommunicationActions.changeShowLoaderValue({ show : false })]
                }),
                catchError((error) => {
                  return [(CommunicationActions.loadCommunicationListFailureMethod({ err: error })),(CommunicationActions.changeShowLoaderValue({ show : false }))]
                })
            )
        })
    )
  )

}