import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable} from "rxjs";
import { IMapUserOptions } from "./interfaces/IUserMap";
import { ConfigService } from "../../services/config.service";
import { initialState } from "./user.reducer";
import { FirebaseService } from "../../services/firebase.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class UserService {
  fbToken: string;
  userAgent;
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private fb: FirebaseService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    console.log('platformId',this.platformId);
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      this.userAgent = window.navigator.userAgent;
    }
   }

  effectMapForSelectedUser(): Observable<IMapUserOptions> {
    const url = `${this.configService.appConfig.appBaseUrl}map/selected/user`;

    return this.http.get<IMapUserOptions>(url);
  }

  reverseGeoCoding({ lat, lng }): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${initialState.googlePlaceApiKey}`;

    return this.http.get<GeocodeResp>(url);
  }


  effectDeleteFirebaseToken() {
    const url = `${this.configService.appConfig.appBaseUrl}firebase/token`
    return this.http.delete(url);
   }

  effectUpdateFirebaseToken() {
    return new Observable((o) => {
      this.fb.getToken().then(s => {
        const url = `${this.configService.appConfig.appBaseUrl}firebase/token`
        console.log(this.userAgent, this.platformId);
        this.http.put(url, { token: s, platformId: this.platformId, userAgent: this.userAgent }).subscribe(s => {
          o.next(s); o.complete(); o.unsubscribe();
        });
      }).catch(error=>{
        o.error(error);
      })
    });
  

    
  }
}

export type GeocodeResp = {
  plus_code: {
    compound_code: string
    global_code: string
  }
  results: Array<{
    address_components: Array<{
      long_name: string
      short_name: string
      types: Array<string>
    }>
    formatted_address: string
    geometry: {
      bounds: {
        northeast: {
          lat: number
          lng: number
        }
        southwest: {
          lat: number
          lng: number
        }
      }
      location: {
        lat: number
        lng: number
      }
      location_type: string
      viewport: {
        northeast: {
          lat: number
          lng: number
        }
        southwest: {
          lat: number
          lng: number
        }
      }
    }
    place_id: string
    plus_code?: {
      compound_code: string
      global_code: string
    }
    types: Array<string>
  }>
  status: string
}