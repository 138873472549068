import { createAction, props } from "@ngrx/store";
import { ICollaboration, IImageData } from "./interfaces/customer.collaboration.interface";

export const getCustomerCollaborations = createAction(
    '[customer collaboration] get customer collaborations for order',
    props<{orderId:string}>()
)

export const onGettingCustomerCollaborations = createAction(
    '[customer collaboration] get customer collaborations for order success',
    props<{collaborations:ICollaboration[]}>()
)

export const uploadProductImagesOfCollab = createAction(
    '[customer collaboration] upload product images of collab',
    props<{imagesToUpload:{url:string}[]; orderId: string; productId: string}>()
)

export const onSuccessOfUploadOfProductImagesOfCollab = createAction(
    '[customer collaboration] upload product images of collab success',
    props<{uploadStatus:IImageData[]}>()
)

export const sendOrderCollabImagesToCustomer = createAction(
    '[customer collaboration] send order collab images to customer',
    props<{orderId: string;}>()
)

export const onSuccessOfSendingOrderCollabImagesToCustomer = createAction(
    '[customer collaboration] send order collab images to customer success',
    props<{customerNotifiedStatus:{msg:string}}>()
)