import {Component, OnInit} from "@angular/core";
import {AppComponent} from "app/app.component";
import {Subscription} from "rxjs";
import {AppMainComponent} from "app/core/main/app.main.component";
import {ConfigService} from "services/config.service";
import {LoginServiceService} from "services/login-service.service";
import {AccountsService} from "services/accounts.service";


@Component({
  selector: "app-topbar",
  templateUrl: "./app.topbar.component.html",
  styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {
  logo: string;
  iotzenLogoSrcUrl: string;
  landingPageUrl: string;
  accountsList: any;
  activeId: string;
  activeAccSub: Subscription
  accountsSub: Subscription;

  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    private configService: ConfigService,
    private loginServiceService: LoginServiceService,
    private accountsService: AccountsService,
  ) {
    this.iotzenLogoSrcUrl = this.configService.appConfig.iotzenLogoUrl;
  }

  ngOnInit() {
    if (sessionStorage.getItem("token")) {
      //   console.log(sessionStorage.getItem("token"));
      const accountDetails = JSON.parse(sessionStorage.getItem("account"));
      if (accountDetails && accountDetails['assetTypes']) {
        this.buildRoutesForAssetTypes(accountDetails["assetTypes"]);
      }
    }


    this.getLogo();

    //multi accounts
    this.accountsSub = this.accountsService
      .getLinkedAccounts()
      .subscribe((data) => {
        this.accountsList = data;
      },);

    this.activeAccSub = this.accountsService
      .getActiveAccount()
      .subscribe((data) => {
        this.activeId = data["_id"];
      });

    // this.store.dispatch(SocketActions.gpsData())

    // this.store.dispatch(SocketActions.lmdDeviceLiveStatus())

    // this.store.select(SocketSelectors.getGPSSocketData).subscribe(data => {
    //   // console.warn(data,'sockets ngrxs')
    // })

    this.landingPageUrl = window.location.href;

  }


  buildRoutesForAssetTypes(assetTypes) {
    this.loginServiceService.buildRoutesForAssetTypes(assetTypes);
  }


  getLogo() {
    this.loginServiceService.getLogo().subscribe(
      (logo) => {
        if (logo && logo["images"] && logo["images"]["logo"]) {
          this.logo = logo["images"]["logo"]["data"];
        } else {
          this.logo = null;
        }
      },
    );
  }

}
