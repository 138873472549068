import { createReducer, on } from "@ngrx/store";
import { setSelectedIdToRowHighlight, setSidebarSizeState, setSidebarState } from "./sidebar.action";


const initialState: SidebarState = {
    splitState: false,
    currentActiveId: '',
    selectedIdToHighlightRow : '',
    fullScreenSidebar: (window.innerWidth < 991),
}

export const SidebarReducer = createReducer(initialState,
    on(setSidebarState, (state, {splitState, currentActiveId})=>({
        ...state,
        splitState,
        currentActiveId
    })),
    on(setSidebarSizeState, (state, {isFullScreen})=>({
        ...state, 
        fullScreenSidebar: isFullScreen 
    })),
    on(setSelectedIdToRowHighlight, (state, {selectedIdToHighlightRow})=>({
        ...state, 
        selectedIdToHighlightRow: selectedIdToHighlightRow 
    })),
)

export type SidebarState = {
    splitState: boolean;
    currentActiveId: string;
    selectedIdToHighlightRow:string;
    fullScreenSidebar: boolean;
}