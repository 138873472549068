import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';


import { WebcamImage, WebcamModule, WebcamInitError, WebcamUtil } from 'ngx-webcam';

import { FormsModule } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-camera-module',
  standalone: true,
  imports: [FormsModule, WebcamModule],
  templateUrl: './camera-module.component.html',
  styleUrls: ['./camera-module.component.scss']
})
export class CameraModuleComponent {
  public webcamImage: WebcamImage | undefined;
  public errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();
  multipleWebcamsAvailable: boolean | undefined;
  imageList: { url: string, imgData: unknown }[] = [];
  @Output() imageArray: EventEmitter<{ url: string, imgData: unknown }[]> = new EventEmitter();

  @Input() removeImage: number = 0
  @Input() showCameraModule: boolean = false 
  @Input() width = 300
  @Input() height = 500;
  
  @Input() isMobileView: boolean = false

  base64Image: string ='';



  constructor() { }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
    if (this.isMobileView) { 
      this.width = 400
      this.height = 500
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['removeImage']?.currentValue !== changes?.['removeImage']?.previousValue) {

      this.imageList = [];
      this.imageArray.emit([...this.imageList]);

    }

  }
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    // this.base64Image =  this.webcamImage.imageAsDataUrl.split(',')[1];
    this.base64Image = this.webcamImage.imageAsDataUrl
    this.imageList = [{ url: this.webcamImage.imageAsDataUrl, imgData: this.webcamImage.imageAsDataUrl }];
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.imageArray.emit([...this.imageList])

  }

}
