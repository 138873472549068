import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IsActiveMatchOptions, NavigationEnd, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {MenuService} from 'app/core/menu/app.menu.service';
import {AppMainComponent} from 'app/core/main/app.main.component';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-menuitem]',
  /* eslint-enable @angular-eslint/component-selector */
  template: `
    <ng-container>
      @if ((!item.routerLink || item.items) && item.visible !== false) {
        <a [attr.href]="item.url" (click)="itemClick($event)"
          (mouseenter)="onMouseEnter()" (keydown.enter)="itemClick($event)"
          [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" pRipple [pTooltip]="item.title">
          <i class="layout-menuitem-icon" [ngClass]="item.icon"></i>
          <span>{{item.label}}</span>
          @if (item.badge) {
            <span class="menuitem-badge" [ngClass]="item.badgeStyleClass">{{item.badge}}</span>
          }
          @if (item.items) {
            <i class="pi pi-fw pi-angle-down layout-menuitem-toggler"></i>
          }
        </a>
      }
      @if ((item.routerLink && !item.items) && item.visible !== false) {
        <a (click)="itemClick($event)" (mouseenter)="onMouseEnter()"
          [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink"
          [routerLinkActiveOptions]="{exact: !item.preventExact}" [attr.target]="item.target" [attr.tabindex]="0"
          [ngClass]="item.class"
          pRipple>
          @if (!app.isSlim()) {
            <i class="layout-menuitem-icon" [ngClass]="item.icon"></i>
          }
          @if (app.isSlim()) {
            <i class="layout-menuitem-icon" [ngClass]="item.icon" [pTooltip]="item.label"></i>
          }
          <span>{{item.label}}</span>
          @if (item.badge) {
            <span class="menuitem-badge" [ngClass]="item.badgeStyleClass">{{item.badge}}</span>
          }
          @if (item.items) {
            <i class="pi pi-fw pi-angle-down layout-menuitem-toggler"></i>
          }
        </a>
      }
      @if (item.items && item.visible !== false) {
        <div class="submenu-arrow"></div>
      }
      <ul
        [@children]="((app.isSlim()||app.isHorizontal()) && root) ? (active ? 'visible' : 'hidden') :
				(active ? 'visibleAnimated' : 'hiddenAnimated')">
        @for (child of item.items; track child; let i = $index) {
          <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        }
      </ul>
    </ng-container>
    `,
  host: {
    '[class.active-menuitem]': 'active'
  },
  animations: [
    trigger('children', [
      state('void', style({
        height: '0px',
        opacity: 0
      })),
      state('hiddenAnimated', style({
        height: '0px',
        opacity: 0
      })),
      state('visibleAnimated', style({
        height: '*',
        opacity: 1
      })),
      state('visible', style({
        height: '*',
        'z-index': 100,
        opacity: 1
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*',
        opacity: 0
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => visibleAnimated, visibleAnimated => void',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppMenuitemComponent implements OnInit, OnDestroy {

  @Input() item: any;

  @Input() index: number;

  @Input() root: boolean;

  @Input() parentKey: string;

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key: string;

  constructor(public app: AppMainComponent, public router: Router, private menuService: MenuService) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(key => {
      // deactivate current active menu
      if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
        this.active = false;
      }
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.app.isSlim() || this.app.isHorizontal()) {
          this.active = false;
        } else {
          if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
          } else {
            this.active = false;
          }
        }
      });
  }

  ngOnInit() {
    if (!(this.app.isSlim() || this.app.isHorizontal()) && this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }

    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
  }

  updateActiveStateFromRoute() {
    let options: IsActiveMatchOptions;

    if (!this.item.items && !this.item.preventExact) {
      options = {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'};
    } else {
      options = {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'};
    }

    this.active = this.router.isActive(this.item.routerLink[0], options);

    // this.active
  }

  itemClick(event: Event) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    // navigate with hover in horizontal mode
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }

    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      this.item.command({originalEvent: event, item: this.item});
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;

      // reset horizontal menu
      if (this.app.isSlim() || this.app.isHorizontal()) {
        this.menuService.reset();
      }

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter() {
    // activate item on hover
    if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim()) && this.app.isDesktop()) {
      this.menuService.onMenuStateChange(this.key);
      this.active = true;
    }
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
