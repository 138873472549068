/**
 * Created by chandru on 22/8/19.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable()
export class SubscriptionService {
  constructor(private http: HttpClient, public configService: ConfigService) {}
    getAllRuleEngine() {
       return this.http.get(this.configService.appConfig.appBaseUrl + 'ruleEngine/all/');
    }
    updateUserSubscription(data) {
      return this.http.put(this.configService.appConfig.appBaseUrl + 'user/subscription/add/', data);
    }
    unsubscribeTheExistingSubscription(data) {
      return this.http.put(this.configService.appConfig.appBaseUrl + 'user/subscription/remove/', data);
    }
}
