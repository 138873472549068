import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { IAbility, IRootCategoryInterface, IRootTypeWithCategory } from './interfaces/superadminsettings.interface';
import { DownloadUtilService } from '../../app/util/download-util.service';
import { IAccountsForKiosk } from '../../app/settings/kiosk-settings-management/kiosk-settings-management.component';


@Injectable({
  providedIn: 'root'
})
export class SuperAdminSettingsService {

  constructor(private http: HttpClient, private configService: ConfigService,private downloadService: DownloadUtilService) { }

  getRoles(skip:number, limit:number){
    let url = this.configService.appConfig.appBaseUrl + 'role/' 
    if(skip!==null && limit){
        url += '?skip='+skip+'&limit='+limit
    }
    return this.http.get(url);
  }

  getCountOfRoles(){
    const url = this.configService.appConfig.appBaseUrl + "role/"+"?isCount=" + true;
    return this.http.get(url);
  }

  getIndividualRoleData(name) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'role/' + name);
  }

  saveNewRole(roleData) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'role' , roleData);
  }

  updateRole(roleId, roleData) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'role/' + roleId , roleData);
  }

  deleteRole(roleId) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'role/' + roleId);
  }


  getAccounts(skip:number, limit:number){
    let url =  this.configService.appConfig.appBaseUrl + 'account/'
    if(skip!==null && limit){
        url += '?skip='+skip+'&limit='+limit
    }
    return this.http.get(url);
  }

  getCountOfAccounts(){
    const url = this.configService.appConfig.appBaseUrl + "account/"+"?isCount=" + true;
    return this.http.get(url);
  }

  getIndividualAccountData(accountId) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'account/id/' + accountId);
  }

  updateAccount(accountData) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'account', accountData);
  }

  saveNewAccount(accountData) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'account/', accountData);
  }

  deleteAccount(accountID) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'account/deleteById/' + accountID);
  }


  getCountOfUsers(allCount:boolean, searchText: string, role: string, accountId: string,isAccountLevel) {

    if(isAccountLevel){
      let queryUrl = this.configService.appConfig.appBaseUrl + 'user/selfAccount/?isCount=true'
      if (searchText) {
        queryUrl += "&search=" + searchText;
      }
      return this.http.get(queryUrl);
    }

    let queryUrl = this.configService.appConfig.appBaseUrl + "user/count/";
    queryUrl += "?allAccount=" + allCount;
    if (searchText) {
      queryUrl += "&search=" + searchText;
    }
    if (role) {
      queryUrl += "&role=" + role;
    }
    if (accountId) {
      queryUrl += "&accountId=" + accountId;
    }
    return this.http.get(queryUrl);
  }

  getUsers(skip:number, limit:number, isAllAccount: boolean, searchText: string, role: string, accountId: string,isAccountLevel) {
    if (isAccountLevel) {
      let queryUrl  = this.configService.appConfig.appBaseUrl + "user/selfAccount/?isCount=false";
          queryUrl += "&skip=" + skip + "&limit=" + limit;
      if (searchText) {
        queryUrl += "&search=" + searchText;
      }
      return this.http.get(queryUrl);
    }


    let queryUrl = this.configService.appConfig.appBaseUrl + 'user' + '?skip=' + skip + '&limit=' + limit + '&allAccount=' + isAllAccount;
    if (searchText) {
      queryUrl += "&search=" + searchText;
    }
    if (role) {
      queryUrl += "&role=" + role;
    }
    if (accountId) {
      queryUrl += "&accountId=" + accountId;
    }
    return this.http.get(queryUrl);
  }

  getIndividualUserData(userId:string) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'user/profile/' + userId);
  }

  updateUser(userId:string, userData) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'user/profile/' + userId, userData);
  }

  saveNewUser(userData,isAccountLevel?) {
    let URL  = this.configService.appConfig.appBaseUrl + "user";
        URL += isAccountLevel ? "/newUser" : '';

    return this.http.post(URL, userData);
  }

  deleteUser(userID) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'user/profile/' + userID);
  }

  getThemeName(themeType:string) {
    return this.http.get(this.configService.appConfig.appBaseUrl + `settings/all/query/themeType:${themeType},module:kiosk`);
  }
  getUniqueThemeName(data) {
    return this.http.get(this.configService.appConfig.appBaseUrl + `settings/theme/isThemeExists/name/${data.themeName}/type/${data.themeType}`);
  }
  saveNewThemesWithThemeName(payload){
    return this.http.post(this.configService.appConfig.appBaseUrl + `settings/kiosk/theme`,payload);
  }
  deleteThemeWithThemeID(themeId){
    return this.http.delete(this.configService.appConfig.appBaseUrl + `settings/id/${themeId}`);
  }
  updateThemeWithThemeID(themeId,payload){
    return this.http.put(this.configService.appConfig.appBaseUrl + `settings/id/${themeId}`,payload);
  }
  getAllThemes() {
    return this.http.get(this.configService.appConfig.appBaseUrl + `settings/all/query/typeId:theme,module:kiosk`);
  }
  saveAbility(payload:IAbility){
    return this.http.post<IAbility>(`${this.configService.appConfig.appBaseUrl}authAbility/save`,payload );
  }
  getAllAbility(skip:number=0,limit:number){
    return this.http.get<IAbility[]>(`${this.configService.appConfig.appBaseUrl}authAbility/all?skip=${skip}&limit=${limit}` );
  }
  getCountOfAbility(){
    const url = this.configService.appConfig.appBaseUrl + "authAbility/all"+"?isCount=" + true;
    return this.http.get(url);
  }
  deleteAbilityWithId(id:string){
    const url = this.configService.appConfig.appBaseUrl + "authAbility/id/"+id
    return this.http.delete(url);
  }

  getAbilityWithId(id:string){
    const url = this.configService.appConfig.appBaseUrl + "authAbility/id/"+id
    return this.http.get(url);
  }
  updateAbilityWithId(id:string,payload:IAbility){
    const url = this.configService.appConfig.appBaseUrl + "authAbility/id/"+id
    return this.http.put(url,payload);
  }
  getAllAvailabeModules() { 
    let url = `${this.configService.appConfig.appBaseUrl}account/allModules`
    return this.http.get<unknown>(url)
  }
  async downloadSampleOfKioskProducts(){
    let url = this.configService.appConfig.appBaseUrl + `sok/products/sampleExcel`
    return await this.downloadService.downloadFilesViaObservable(url, 'Products_Sample.xlsx');
  }
  getAllAccountsForKiosk(){
    let url = this.configService.appConfig.appBaseUrl + `account/moduleType/kiosk`
    return this.http.get<IAccountsForKiosk[]>(url)
  }
  getAllCategoriesForIntegration(){
    return this.http.get<IRootCategoryInterface>(this.configService.appConfig.appBaseUrl + 'pluginMeta/allCategories');
  }
  getAllTypeWithCategories(type:string){
    return this.http.get<IRootTypeWithCategory>(this.configService.appConfig.appBaseUrl + 'pluginMeta/allTypes?module=plugin&categories='+type);
  }

  getaAllPluginInfo(accountId:string,type:string){
    return this.http.get(this.configService.appConfig.appBaseUrl + `pluginManager?accountId=${accountId}&type=${type}`)
  }
  getAllPluginBasedOnCategory(id:string,category:string){
    return this.http.get(this.configService.appConfig.appBaseUrl + `pluginManager?accountId=${id}&categories=${category}`)
  }


}