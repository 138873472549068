<div class="layout-topbar">
  <a [href]="landingPageUrl" class="logo" pRipple>
    @if (logo) {
      <img alt="logo" class="logo" height="100%" src="data:image/jpg;base64,{{logo}}" width="100%">
    }
  </a>
  <a (click)="appMain.onMenuButtonClick($event)" href="#" id="menu-button" pRipple>
    <i class="pi pi-align-left"></i>
  </a>

  <a (click)="appMain.onMegaMenuButtonClick($event)" href="#" id="layout-megamenu-button" pRipple>
    <i class="pi pi-angle-down"></i>
    <span class="mega-menu-title">Things</span>
  </a>

  <a (click)="appMain.onTopbarSupportButtonClick($event)"
     href="#" id="topbar-support-button"
     [ngClass]="(app.profileMode==='top' || appMain.isHorizontal()) ? 'supportNextToProfile' : 'supportAtProfile'"
     pRipple pTooltip="Help" tooltipPosition="bottom">
    <p-avatar label="" shape="circle" size="large"
              styleClass="mr-2 textSupport"><i class="pi pi-comments"></i></p-avatar>
  </a>

  @if (app.profileMode === 'top' || appMain.isHorizontal()) {
    <a (click)="appMain.onTopbarMenuButtonClick($event)"
       href="#" id="topbar-menu-button"
       pRipple [ngClass]="{'topProfile' : (app.profileMode==='top' || appMain.isHorizontal())}">
      <p-avatar [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" label="" shape="circle" size="large"
                styleClass="mr-2">{{ this.appMain.roleAlphabet }}
      </p-avatar>
      <!-- <span>Pauline Harrell</span> -->
    </a>
  }

  <ul [ngClass]="{'usermenu-active': appMain.topbarMenuActive}" class="usermenu usermenu-overlay fadeInDown"
      id="topbar-usermenu">
    <li #profile [ngClass]="{'menuitem-active':appMain.activeTopbarItem === profile}">
      <a (click)="appMain.onTopbarItemClick($event,profile,'profile')" href="#">
        <i class="pi pi-fw pi-user"></i>
        <span class="topbar-item-name">Profile</span>
      </a>
    </li>
    <li #accounts [ngClass]="{'menuitem-active':appMain.activeTopbarItem === accounts}">
      <a (click)="appMain.onTopbarItemClick($event,accounts,'accounts')" href="#" pRipple>
        <i class="pi pi-fw pi-cog"></i>
        <span class="topbar-item-name">Accounts</span>
        <i class="layout-menuitem-toggler pi pi-fw pi-angle-down"></i>
        <p-confirmPopup key="topbar-popup"></p-confirmPopup>
      </a>
      <ul>
        @for (item of accountsList; track item) {
          <li [ngClass]="{'active-menuitem':activeId===item['_id']}" role="menuitem">
            <a (click)="appMain.onTopbarSubItemClick($event,'accounts',item['_id'],'topbar-popup')"
               [ngClass]="{'active-menuitem':activeId===item['_id']}"
               href="#"
               pRipple>
              <i class="pi pi-fw pi-id-card"></i>
              <span>{{ item.name }}</span>
            </a>
          </li>
        }
      </ul>
    </li>
    <li #notifications [ngClass]="{'menuitem-active':appMain.activeTopbarItem === notifications}">
      <a (click)="appMain.onTopbarItemClick($event,notifications,'notification')" href="#" pRipple>
        <i class="pi pi-fw pi-bell"></i>
        <span class="topbar-item-name">Notifications</span>
      </a>
    </li>
    <li #logout [ngClass]="{'menuitem-active':appMain.activeTopbarItem === logout}">
      <a (click)="appMain.onTopbarItemClick($event,logout,'logout')" href="#" pRipple>
        <i class="pi pi-sign-out"></i>
        <span class="topbar-item-name">Logout</span>
      </a>
    </li>

  </ul>

  <a (click)="appMain.onRightPanelButtonClick($event)" href="#" id="right-sidebar-button" pRipple>
    <i class="pi pi-ellipsis-v"></i>
  </a>
</div>
