import { Component, OnInit } from '@angular/core';
import {SearchService} from '../../../services/search.service';
import { AssetService } from '../../../services/asset.service';
import {CONSTANT} from '../../../config/constant';
declare var $: any;

@Component({
  selector: 'app-camera-wall',
  templateUrl: './camera-wall.component.html',
  styleUrls: ['./camera-wall.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CameraWallComponent implements OnInit {
  cameras: string[] = ['liveStreaming_Cam4', 'liveStreaming_Cam3', 'liveStreaming_Cam1', 'liveStreaming_Cam2', 'Camera_5', 'Camera_6', 'Camera_7', 'Camera_8'];
  selectedCameras: string[];
  cameraList: any = [];
  videoFeedcontainers: string[];
  isCamerasNotPresent: boolean;
  showLoader: boolean;
  constructor(public searchService: SearchService, private assetService: AssetService) {
    this.searchService.showSearchBar(false);
    this.selectedCameras = [];
    this.isCamerasNotPresent = false;
    this.showLoader = true;
  }

  ngOnInit(): void {
    this.getCameraList();
  }

  cameraSelected() {
    this.videoFeedcontainers = this.selectedCameras;
  }

  disConnectedCameraEvent(camera) {
    console.log('disConnectedCameraEvent', camera);
    /*let selectedCameras = this.selectedCameras;
    _.remove(selectedCameras, function(n) {
      return n === camera;
    });
    console.log(selectedCameras);
    this.selectedCameras = selectedCameras;
    this.videoFeedcontainers = this.selectedCameras;*/
  }

  getCameraList() {
    this.assetService.getCountByTypes(CONSTANT.ASSET_TYPES.CAMERA).subscribe((count) => {
      if (count['count']) {
        const cameraCount = count['count'];
        this.assetService.getAllByType(CONSTANT.ASSET_TYPES.CAMERA, 0, cameraCount).subscribe((res) => {
          this.showLoader = false;
          this.cameraList = res;
        });
      } else {
         this.showLoader = false;
         this.isCamerasNotPresent = true;
      }
    }, (() => {
      this.showLoader = false;
      this.isCamerasNotPresent = true;
    }));
  }

}
