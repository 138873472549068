<app-notification-event-create-form
  [formData]="notificationEventFormData"
  [formConfiguration]="notificationEventConfig"
  [resetForm]="resetAddAssetFormValue"
  [isEventSelectedToEdit]="isEventSelectedToEdit"
  [eventToEdit]="eventToEdit"
  [showUpdateBtn]="showUpdateBtn"
  [isCloneBtnReq]="isCloneBtnReq"
  [selectedAccountId]="selectedAccountId"
  (formSubmitTrigger)="onNotificationEventSubmit($event)"
  (resetOnModelClosed)="resetOnModelClosed($event)">
</app-notification-event-create-form>
