import { createReducer, on } from "@ngrx/store";
import { ReportsSubscriptionActions } from ".";
// import { ISubscription } from "./interfaces/reports.subscription.interface";
export interface ReportsSubscriptionState {
  successfulSubscriptionIndicator: number;
}

export const initialState: ReportsSubscriptionState = {
  successfulSubscriptionIndicator: null,
};

export const ReportsSubscriptionReducer = createReducer(
  initialState,
  on(ReportsSubscriptionActions.onSuccessOfChangeSubscriptionParmaters,
    (state, { moduleType, changedParmaters }) => {
      return {
        ...state,
        [moduleType]: {
          ...(state[moduleType] || {}),
          appliedParameters: changedParmaters
        },
      };
    }
  ),
  on(ReportsSubscriptionActions.onSuccessOfReportSubscription,
    (state) => {
      return {
        ...state,
        successfulSubscriptionIndicator:Math.random()*100
      };
    }
  ),
  on(ReportsSubscriptionActions.onGettingSubscriptions,
    (state, { moduleType,reportSubscriptions }) => {

      const subscriptions:any= {}
      reportSubscriptions.forEach((subscription)=>{
        if(subscriptions[subscription['reportType']]){
          subscriptions[subscription['reportType']].push(subscription)
        }else{
          subscriptions[subscription['reportType']] = [subscription]
        }
      })

      return {
        ...state,
        [moduleType] : {
          ...(state[moduleType] || {}), subscriptions
        }
      };
    }
  )
);