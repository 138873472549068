
<div class="card faceDetectedCard faceDetails-toggler" [ngClass]="{'showPointerCursor':isCardClickable === true, 'active' : faceData && faceData['cardClicked']}">
  <div class="grid compareSec" *ngIf= "faceData" (click) = "sendCardClickedEvent(faceData)">
    <!--<span class="match-ratio">{{(faceData.matchScore ? (faceData.matchScore | percent) : 'NA')}}</span>-->
    <div class="col-12 py-0 px-3">
      <div class="grid">
        <!--<div class="card-label col-12">
          <span>Face Detected</span>
        </div>-->
        <div class="imgContainer col-12" id="{{'detectedFace'+faceData._id}}" [ngStyle]="{'height' : !imagesLoader.faceDataDetectedFace ? '233px' : ''}">
          <img [src]="faceDetectionService.getFaceDetectionImages(faceData._id, 'detectedFace')" alt="img-2" (click)="getClickableContent('image')">
        </div>
      </div>
    </div>
    <!--<div class="col-6">
      <div class="grid">
        <div class="card-label col-12">
          <span>Enrolled</span>
        </div>
        <div class="imgContainer col-12">
          <img [src]="getImageSource(faceData._id,'referenceImage')" alt="img-1">
        </div>
      </div>
    </div>-->
  </div>

  <!-- compareSec for card in sideBar starts -->
  <div class="grid compareSec-sideBar" *ngIf = "cardDatafromSideBar && showContext">
    <!--<span class="match-ratio">{{(cardDatafromSideBar.matchScore ? (cardDatafromSideBar.matchScore | percent) : 'NA')}}</span>-->
    <div class="col-6">
      <div class="grid justify-content-start">
        <!--<div class="card-label col-12 py-1 px-0">
          <span>Face Detected</span>
        </div>-->
        <div class="imgContainer col-12" id="cardDataDetectedFace" [ngStyle]="{'height' : !imagesLoader.cardDataDetectedFace ? '310px' : ''}">
          <img src={{cardDataImages.detectedFace}} alt="img-2">
        </div>
      </div>
    </div>
    <div class="col-6">
      <!--<div class="grid justify-content-end">
        <div class="card-label col-12 py-1">
          <span>Enrolled</span>
        </div>
        <div class="imgContainer col-12">
          <img [src]="getImageSource(cardDatafromSideBar._id,'referenceImage')" alt="img-1" *ngIf = "cardDatafromSideBar && showContext">
        </div>
      </div>-->
    </div>
  </div>
  <!-- compareSec for card in sideBar ends -->

  <div class="grid autoSec" *ngIf = "cardDatafromSideBar && showContext">
    <div class="col-12">
      <div class="grid">
        <div class="mid-cont col-12">Frame</div>
        <div class="imgContainer col-12" id="cardDataFrame"  [ngStyle]="{'height' : !imagesLoader.cardDataFrame ? '310px' : ''}"
             (click) = "sendFrameImgToModal()"
             data-toggle="modal" data-target="#modalForFrame" data-backdrop="static" data-dismiss="modal" data-keyboard="false">
          <img src={{cardDataImages.frame}} alt="img-3">
        </div>
      </div>
    </div>
  </div>

  <div class="grid mx-0">
    <div class="card-text col-12 py-0 px-3">
      <div class="grid mx-0 my-1 mb-2">
        <div class="col-12 px-3">
          <div class="grid">
            <div class="col-12 px-3">
              <span class="field-value" (click)="getClickableContent('name')" pTooltip="{{faceData.assetDetected.name}}" tooltipPosition="top" *ngIf= "faceData && faceData.assetDetected && faceData.assetDetected.name" (click) = "sendCardClickedEvent(faceData)"><i class="fas fa-user-alt"></i> {{faceData.assetDetected.name}}</span>
              <span class="field-value" (click)="getClickableContent('name')" pTooltip="{{cardDatafromSideBar.assetDetected.name}}" tooltipPosition="top" *ngIf = "cardDatafromSideBar && cardDatafromSideBar.assetDetected && cardDatafromSideBar.assetDetected.name && showContext"><i class="fas fa-user-alt"></i> {{cardDatafromSideBar.assetDetected.name}}</span>
            </div>
            <div class="col-12">
              <span class="field-value" (click)="getClickableContent('camera')" pTooltip="{{faceData.camera.name}}" tooltipPosition="top" *ngIf= "faceData && faceData.camera && faceData.camera.name" (click) = "sendCardClickedEvent(faceData)"><i class="fas fa-camera"></i> {{faceData.camera.name}}</span>
              <span class="field-value" (click)="getClickableContent('camera')" pTooltip="{{cardDatafromSideBar.camera.name}}" tooltipPosition="top" *ngIf = "cardDatafromSideBar && cardDatafromSideBar.camera && cardDatafromSideBar.camera.name && showContext"><i class="fas fa-camera"></i> {{cardDatafromSideBar.camera.name}}</span>
            </div>
            <div class="col-12" *ngIf="showAddVisitorBtn">
              <div class="grid MT5">
                <div class="col-6" (click) = "sendCardClickedEvent(faceData)"></div>
                <div class="col-6">
                  <button pButton type="button" label="visitor" icon="pi pi-plus" color="primary" class='buttonEnable FR p-button-sm' data-keyboard="false" *ngIf= "faceData" (click)="sendAddDetectedFaceClickEvent(faceData)" 
                    pTooltip="Add visitor" tooltipPosition="top"></button>
                </div>
              </div>
            </div>

            <div class="col-12 MT5" *ngIf="showRegisterThingBtn">
                <app-dropdown-onhover [menuListItems]="visitorsTypeList" [menuLabel]="'Register'" [menuLabelMatIcon]="'add'" [modelIdToOpen]="'notification-event-to-add'"
                                      [dropdownMenuWidth]="'100%'" [dropdownMenuLeftPosition]="'-106px'" (onItemSelected)="openSelectedEventModel($event)"></app-dropdown-onhover>
            </div>
          </div>
        </div>
        <div class="col-6 px-0">
          <!--<div class="grid mx-0 my-1 justify-content-end">
            <div class="col-12 text-right">
              <span class="personType" matTooltip="Group " matTooltipPosition="above" *ngIf= "faceData && faceData.assetDetected && faceData.assetDetected.personType">{{faceData.assetDetected.personType}}</span>
              <span class="personType" matTooltip="Group" matTooltipPosition="above" *ngIf = "cardDatafromSideBar && cardDatafromSideBar.assetDetected && cardDatafromSideBar.assetDetected.personType && showContext"
                    [ngStyle]="{'color':cardDatafromSideBar.assetDetected.personType === 'Blacklist' ? 'rgba(255, 0, 0, 0.68)' : 'rgba(46, 128, 36, 0.62)' }">{{cardDatafromSideBar.assetDetected.personType}}</span>
            </div>
          </div>-->

        </div>
      </div>
      <div class="grid mx-0 mt-3">
        <div class="col-12 date-text text-right py-2 border-top">
          <span class ="date lighterColor dotsOnOverflow" (click) = "sendCardClickedEvent(faceData)" pTooltip="{{'sys.last-updated' | translate}} : {{dateFormatter.convertDateToSelectedTimezone(faceData['updated'])}}" tooltipPosition="top"*ngIf= "faceData">{{dateFormatter.convertDateToSelectedTimezone(faceData['updated'])}}</span>
          <span class ="date lighterColor dotsOnOverflow" pTooltip="{{'sys.last-updated' | translate}} : {{dateFormatter.convertDateToSelectedTimezone(cardDatafromSideBar['updated'])}}" tooltipPosition="top" *ngIf = "cardDatafromSideBar && showContext">{{dateFormatter.convertDateToSelectedTimezone(cardDatafromSideBar['updated'])}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--    Card for sideBar starts-->
<!-- <div class="card border" *ngIf="cardDatafromSideBar">
  <div class="grid compareSec">
    <span class="match-ratio">100%</span>
    <div class="col-6">
      <div class="grid">
        <div class="left-cont col-12">Enrolled</div>
        <div class="imgContainer col-12">
          <img src="{{cardDatafromSideBar.enrolledImg}}" alt="img-1">
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="grid">
        <div class="right-cont col-12">Captured</div>
        <div class="imgContainer col-12">
          <img src="{{cardDatafromSideBar.capturedImg}}" alt="img-2">
        </div>
      </div>
    </div>
  </div>
  <div class="grid autoSec">
    <div class="col-12">
      <div class="grid">
        <div class="mid-cont col-12 ">Context</div>
        <div class="imgContainer col-12">
          <img src="{{cardDatafromSideBar.contextImg}}" alt="img-3">
        </div>
      </div>
    </div>
    <div class="card-text col-12">
      <div class="grid">
        <div class="col-12">
          <span class="field-label">PersonName </span>
          <span class="field-value">{{cardDatafromSideBar.name}}</span>
        </div>

        <div class="col-12">
          <span class="field-label">Camera </span>
          <span class="field-value">{{cardDatafromSideBar.camera}}</span>
        </div>

        <div class="col-12 date-text">
          <span class ="date" matTooltip="Last updated time" matTooltipPosition="above">Aug 28,2019, 11:12:23 AM</span>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!--    Card for sideBar ends-->


