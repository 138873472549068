import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {SearchService} from '../../../services/search.service';
import moment from 'moment-timezone';
import {LangUtilService} from '../../util/lang-util.service';
import {NamingConventionFilterPipe} from '../../../pipes/namingConvention.pipe';
import {DateFormatterService} from '../../../services/dateformat';
import {CONSTANT} from '../../../config/constant';

declare let $: any;

const MMD_MODULE_NAME = CONSTANT.DMS_MODULES.MMD.MODULE;

@Component({
  selector: 'app-trackable-cars-list',
  templateUrl: './trackable-cars-list.component.html',
  styleUrls: ['./trackable-cars-list.component.scss']
})
export class TrackableCarsListComponent implements OnInit, OnChanges, OnDestroy {
  private searchTextSub: Subscription;
  public searchForTrackedCar: string;
  resetSearchTextValue: number;
  @Input() trackableCarsListForSidePanel: Array<any> = [];
  @Input() showCircularLoader: boolean;
  @Input() showIgnitionStatus: boolean;
  @Input() showSpeed: boolean;
  @Input() markersValueWithDeviceId: object;
  @Input() openTrackableCarsListIfClosed: number;
  @Input() searchPlaceholder: string;
  @Input() linkedAssetsTooltip: string;
  @Input() moduleName: string;
  @Output() openRightPanel: EventEmitter<any> = new EventEmitter();
  @Output() sendSelectedCarEvent: EventEmitter<any> = new EventEmitter();
  @Output() sendOrderListClickedEvent: EventEmitter<any> = new EventEmitter();
  constructor(private searchService: SearchService, private lang: LangUtilService, private dateFormatter: DateFormatterService) {
    this.showIgnitionStatus = false;
    this.showSpeed = true;
  }

  ngOnInit() {
    this.resetSearchTextValue = (Math.random());
    this.searchTextSub = this.searchService.searchText.subscribe(event => {
      if (event.id === 'carSearch') {
        this.searchForTrackedCar = event.text;
      }
    });
    this.searchService.resetSearchTextInSearchBox();
    this.searchService.sendSearchText('');
    this.searchService.sendSearchPlaceholderValue(this.searchPlaceholder);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'openTrackableCarsListIfClosed') {
        if (change.currentValue !== change.previousValue) {
          if (this.openTrackableCarsListIfClosed) {
            this.openCarPanelIfClosed();
          }
        }
      }
    }
  }

  getSelectedCar(car) {
    if(car  && car.activeAssignments){
      car['activeOrderAssignments'] = car.activeAssignments;
    }
    this.sendSelectedCarEvent.emit(car);
  }

  toggleCarsList() {
    $('.trackable-cars-list').toggleClass('panelOpened panelClosed');
    $('.list-toggle-button').children('.arrow-icon').toggleClass('fa-chevron-left fa-chevron-right');
  }

  roundOff(value) {
    return Math.round(value);
  }

  dateFormatterToText(date) {
    this.lang.updateMomentLanguage(moment);
    return moment(date).calendar();
  }

  openCarPanelIfClosed() {
    const trackableCarList = '.trackable-cars-list';
    const listToggleBtn = '.list-toggle-button';
    if ($(trackableCarList).hasClass('panelClosed')) {
      $(trackableCarList).removeClass('panelClosed').addClass('panelOpened');
      if ($(listToggleBtn).children('.arrow-icon').hasClass('fa-chevron-right')) {
        $(listToggleBtn).children('.arrow-icon').removeClass('fa-chevron-right').addClass('fa-chevron-left');
      }
    }
  }

  ngOnDestroy() {
    this.searchTextSub.unsubscribe();
  }

  getLangLabel(labelCode: string) {
    return this.lang.getTranslation(labelCode);
  }

  orderListClicked() {
   // this.sendOrderListClickedEvent.emit(true);
  }

  buildOrderListTooltip(assignment) {
    const filterPipe = new NamingConventionFilterPipe();
    const deliveryStatus = filterPipe.transform(assignment.deliveryStatus ? assignment.deliveryStatus : '-');

    let oLabel = 'Order';
    let cLabel = 'Cust Name';
    let cMLabel = 'Cust Mobile';

    if (this.moduleName && (this.moduleName === MMD_MODULE_NAME)) {
      oLabel = 'Consignment';
      cLabel = 'app.dms.delivery-center.label';
      cMLabel = 'Mobile';
    }

    return `${oLabel} ID : ${assignment.orderId ? assignment.orderId : '-'}
    ${cLabel} : ${assignment.customerDetails.firstName ? assignment.customerDetails.firstName + ' ' + (assignment.customerDetails.lastName ? assignment.customerDetails.lastName : '') : '-'}
    ${cMLabel} : ${assignment.customerDetails.mobileNumber ? assignment.customerDetails.mobileNumber : assignment.customerDetails.mobileNo ? assignment.customerDetails.mobileNo : '-'}
    Exp. Del. : ${assignment.expectedDeliveryDateTime.dateTime ? (this.dateFormatter.convertDateToSelectedTimezone(assignment.expectedDeliveryDateTime.dateTime, 'DD-MM-YYYY')) + (assignment.expectedDeliveryDateTime.time ?  ', ' + assignment.expectedDeliveryDateTime.time : '') : '-'}
    Status : ${deliveryStatus}`;
  }

  openRightPanelEmit(car){
    this.getSelectedCar(car);
    this.openRightPanel.emit();
  }
}
