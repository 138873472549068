/**
 * Created by suhas on 2/5/19.
 */

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'trackerCarFilterPipe'
})
export class TrackerCarFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( item => {
      if (item && item.registrationNumber || item.Name) {
        if (item.registrationNumber.toString().toLowerCase().includes(searchText) === true) {
          item['filteredBy'] = 'registrationNumber';
          return item;
        } else if (item.Name) {
          if (item.Name.toString().toLowerCase().includes(searchText) === true) {
            item['filteredBy'] = 'Name';
            return item;
          }
        }
      }
    });
  }
}
