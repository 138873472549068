import _, { cloneDeep, forEach, groupBy, keys, map, sortBy, uniq } from "lodash";
import moment from "moment";
import { EChartSeries, EChartSeriesUpdate } from "../../../app/shared/e-charts/e-charts-data-type";
import { Assigned, IChartData, IDeliveryStatus, IEChartSeriesUpdate, IO2dTemperatureGraph, IO2dtimeSlot, IOrderSource, IQueueInTrend, IRiderWisePerformance, ITatOfOrder, ITemperatureDetails } from "../interface";
import { IGraphSettings } from "../../../services/graph.config.service";
export const visualMaps = {
  top: 50,
  right: 10,
  pieces: [
    {
      gt: -60,
      lte: -25,
      color: '#002fff'
    },
    {
      gt: -25,
      lte: -15,
      color: '#0083ff'
    },
    {
      gt: -15,
      lte: -5,
      color: '#00c2ff'
    },
    {
      gt: -5,
      lte: 0,
      color: '#41ebff'
    },
    {
      gt: 0,
      lte: 5,
      color: '#ffa400'
    },
    {
      gt: 5,
      lte: 10,
      color: '#ff8b00'
    },
    {
      gt: 10,
      lte: 15,
      color: '#ff6c00'
    },
    {
      gt: 15,
      lte: 20,
      color: '#ff4e00'
    },
    {
      gt: 20,
      color: '#ff1e00'
    },
  ],
  outOfRange: {
    color: '#ff1e00'
  },
  show : false
};
const defaultSeriesObj =[{
  data: [],
  type: "bar",
  name: "",
  stack: false,
  smooth: true,
  label: { normal: { show: false, position: "top" } },
  itemStyle: { color: "#8064a2", opacity: 1 },
  textStyle: {
    fontSize: 10,
  }
}]
const defaultBarChartData: IChartData = {
    isDataZoomRequired: false,
    legend: [],
    series: [
      {
        data: [],
        type: "bar",
        name: "",
        stack: false,
        smooth: true,
        label: { normal: { show: false, position: "top" } },
        itemStyle: { color: "#8064a2", opacity: 1 },
        textStyle: {
          fontSize: 10,
        },
      },
    ],
    title: "",
    type: "bar",
    xAxis: [],
    visualMap : null,
    legendToDisable : 'Humidity in %'
  };
   const eChartIndividualupdateValue: IEChartSeriesUpdate = {
    index: null,
    label: null,
    value: null,
    labelIndex: 0,
    values: []
};
export const graphAnalyticsData = {
  graph: {
    carsSelected: [],
    dateRange: {
      start: new Date(),
      end: new Date()
    },
    distanceTravelled: [],
    distanceTravelled1: [],
    displayedColumns: ['regNo', 'distanceInString']
  }
}
export const updateValue: EChartSeriesUpdate = {
  index: null,
  label: null,
  value: null,
};
export const boxStatusGraph = {
  temperature: {
      chartData: {},
      show: true,
      showLoader: false,
      title: 'Temperature',
      updateValue: updateValue,
      graphType: 'line',
  },
  humidity: {
      chartData: {},
      show: true,
      showLoader: false,
      title: 'Humidity',
      updateValue: updateValue,
      graphType: 'line'
  }
}

export let orderSource: IOrderSource = {
    counts: {
      counts: 0,
      chartData: defaultBarChartData,
      show: true,
      showLoader: false,
      title: "",
      graphTypeChangedRandomNumber: 0,
      colorsArray: ["#4F81BD", "#9bbb59", "#c0504d", "#9A60B4", "#F29546"],
      updateValue : eChartIndividualupdateValue,
    },
    pills: {
      arrayOfDurationPills: [
        {
          id: 'hour', 
          label: 'Hour',
          isSelected:false
        },
        {
          id: "day",
          label: "Day",
          isSelected:false
        },
        {
          id: "month",
          label: "Month",
          isSelected:false
        },
        {
          id: "year",
          label: "Year",
          isSelected:false
        },
        ],
        selected: "hour",
  },
    graphTypePills: {
      arrayOfGraphTypePills: [
        {
          id: "bar",
          label: "Bar",
          isStacked:false,
          isStackable:true,
          isCurrentType:false,
          description:"sys.graph-type-bar",
          inActiveImgSrc:
            sessionStorage.getItem("theme") === "dark-theme"
              ? "../../../assets/asset-icons/bar-chart-white-24.png"
              : "../../../assets/asset-icons/bar-chart-black-24.png",
          activeImgSrc:
            sessionStorage.getItem("theme") === "dark-theme"
              ? "../../../assets/asset-icons/bar-chart-black-24.png"
              : "../../../assets/asset-icons/bar-chart-white-24.png",
        },
        {
          id: "line",
          label: "Line",
          isStacked:false,
          isStackable:true,
          isCurrentType:false,
          // description: langg.getTranslation("sys.graph-type-line"),
          inActiveImgSrc:
            sessionStorage.getItem("theme") === "dark-theme"
              ? "../../../assets/asset-icons/line-chart-white-24.png"
              : "../../../assets/asset-icons/line-chart-black-24.png",
          activeImgSrc:
            sessionStorage.getItem("theme") === "dark-theme"
              ? "../../../assets/asset-icons/line-chart-black-24.png"
              : "../../../assets/asset-icons/line-chart-white-24.png",
        },
        {
          id: "pie",
          label: "Pie",
          isStacked:false,
          isStackable:false,
          isCurrentType:false,
          // description: this.lang.getTranslation("sys.graph-type-pie"),
          inActiveImgSrc:
            sessionStorage.getItem("theme") === "dark-theme"
              ? "../../../assets/asset-icons/pie-chart-white-24.png"
              : "../../../assets/asset-icons/pie-chart-black-24.png",
          activeImgSrc:
            sessionStorage.getItem("theme") === "dark-theme"
              ? "../../../assets/asset-icons/pie-chart-black-24.png"
              : "../../../assets/asset-icons/pie-chart-white-24.png",
        },
      ],
    },
    downloadPills: {
      arrayOfDownloadPills: [
        {
          id: "xl",
          label: "xl format",
          // description: this.lang.getTranslation("sys.xl"),
          inActiveImgSrc:
            sessionStorage.getItem("theme") === "dark-theme"
              ? "../../../assets/asset-icons/xls-white-24.png"
              : "../../../assets/asset-icons/xls-black-24.png",
          activeImgSrc:
            sessionStorage.getItem("theme") === "dark-theme"
              ? "../../../assets/asset-icons/xls-black-24.png"
              : "../../../assets/asset-icons/xls-white-24.png",
        }
      ],
      selected: "xl",
    },
    graphType: "bar",
    countArray : []
    // count: 0
  };
   const echartOptions = {
    chartSeries: "hour",
    type: "line",
  };

let orderSourceCopy:IOrderSource;
type responseType = Record<string, IO2dtimeSlot> | Record<string, Record<string, Assigned[]>> | Record<string, Record<string, IDeliveryStatus>>
|Record<string, ITatOfOrder[]>| IO2dtimeSlot;


const assignSelectedFields = (arrayOfGraphTypePills,graphSettings:IGraphSettings)=>{
  return  arrayOfGraphTypePills.map(graph=>{
    return {
     ...graph,
     isStacked:graphSettings[`isStacked_${graph.id}`],
     isCurrentType:graph.id===graphSettings.graphType
   }
   })
}

export function getCountsByOrderSource(res:responseType,title:string,key1:string,key2:string,graphSettings:IGraphSettings) {
  const cursor = 'default';
  orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0}
  orderSourceCopy = {...orderSource}
  orderSource.counts = {...orderSource.counts};
  if(graphSettings.graphType){
    echartOptions.type = graphSettings.graphType;
    orderSource.graphType = graphSettings.graphType;
  }
    orderSource.counts = {...orderSource.counts, title : title};
     if(Object.keys(res)?.length > 0){
       let stateData:Object = structuredClone(res);
      let resultData= Object.values(stateData)
       if(title !== 'TAT of Order'){
         stateData = getFormattedResult(resultData);
       }
         else{
           stateData = {...resultData}
         }

         orderSource.graphTypePills = 
         {
           ...orderSource.graphTypePills,
           arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
         }

         if (stateData) {
          let allSeries;
             if(title !== 'TAT of Order'){
               allSeries = Object.values(stateData);
             }
            else{
              allSeries = Object.keys(stateData).map(key => stateData[key]);
            }
            if (allSeries.length > 0) {
              let chartData = {
                series: [],
                xAxis: [],
                legend: [],
                type: echartOptions.type,
              };
             Object.values(stateData).map(ee=>{
              chartData.legend= ee[key2];
            });
             let allCounts = [];
             allSeries.forEach(seriesArray => {
               let dataObj = {};
               dataObj = JSON.parse(
                 JSON.stringify(defaultBarChartData.series[0])
               );
               seriesArray.forEach(seriesObj => {
                 let formattedDate = getFormattedTimeSlot(
                   seriesObj[key1]
                 );
                 if(seriesObj[key1] && chartData["xAxis"].indexOf(formattedDate) === -1){
                   chartData["xAxis"].push(formattedDate)
                 }
                 let targetIndex = chartData["xAxis"].indexOf(formattedDate);
                 if(seriesObj["count"]){
                   (dataObj["data"][targetIndex] = seriesObj["count"])
                 }
                 allCounts.push(seriesObj["count"]);
                 if(seriesObj[key2]){
                   dataObj["name"] = seriesObj[key2];
                   dataObj["itemStyle"]["color"] = getColors(getFormattedLabel(seriesObj[key2]));

                 }
                 dataObj["type"] = orderSource.graphType;
                 dataObj["stack"] = graphSettings[`isStacked_${graphSettings.graphType}`];
               });
               chartData.series.push(dataObj);
             });
             let chartDataToDraw = drawBarChart(chartData);
             orderSource.counts = {...orderSource.counts, counts:_.sum(allCounts)};
             orderSource.counts = {...orderSource.counts, chartData:{...chartDataToDraw,cursor}};
             if (chartData && chartData.type === "pie") {
              buildGraphTypePillValueToGetData(chartData.type,);
            }
           } else {
             orderSource.counts = {...orderSource.counts, chartData:{...defaultBarChartData,cursor}};
             orderSource.counts = {...orderSource.counts, counts:0};
           }
         } else {
           orderSource.counts = {...orderSource.counts, chartData:{...defaultBarChartData,cursor}};
           orderSource.counts = {...orderSource.counts, counts:0};
         }
         let order = {...orderSource}
         orderSource = {...orderSourceCopy};
         orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
         return order
     }else{
       orderSource.counts = {...orderSource.counts, showLoader : false,chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0}
       return  {...orderSource,
        graphTypePills:{
         ...orderSource.graphTypePills,
         arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
       }
      }
  
     }
}
// type some = Record<string,Record<string,IOrder>>

export function getOrdersCountInQueue(result:IQueueInTrend,title : string,pillValue,graphSettings:IGraphSettings) {
  orderSourceCopy = {...orderSource}
  orderSource.counts = {...orderSource.counts, title : title};
  orderSource.graphType = graphSettings.graphType
  if(Object.keys(result)?.length > 0) {
  let allSeries = Object.values(result);
  if (allSeries.length > 0) {
    let chartData = {
      series: [],
      xAxis: [],
      legend: [],
      type: orderSource.graphType,
    };
    chartData.legend = Object.keys(result);
    allSeries.forEach((seriesArray: Array<any>) => {
      let dataObj = {};
      dataObj = JSON.parse(JSON.stringify(defaultBarChartData.series[0]));
      seriesArray?.forEach(seriesObj => {
        let formattedDate = getFormattedDate(seriesObj["timeZoneDate"],pillValue  || orderSource?.pills?.selected);
        if(seriesObj["timeZoneDate"] && chartData["xAxis"].indexOf(formattedDate) === -1){
          chartData["xAxis"].push(formattedDate)
        }

        let targetIndex = chartData["xAxis"].indexOf(formattedDate);
        if(seriesObj["count"] || seriesObj["count"] === 0){
          (dataObj["data"][targetIndex] = seriesObj["count"])
        }
        if(seriesObj["assetType"]){
          (dataObj["name"] = seriesObj["assetType"]);
          (dataObj["itemStyle"]["color"] = getColors(seriesObj["assetType"]))
        }
        dataObj["type"] = orderSource.graphType;
        dataObj["stack"] = graphSettings[`isStacked_${graphSettings.graphType}`];
      });
      chartData.series.push(dataObj);
    });
    let chartDataToDraw = drawBarChart(chartData);
    orderSource.counts.chartData = chartDataToDraw;
    if (chartData && chartData.type === "pie") {
      buildGraphTypePillValueToGetData(chartData.type,);
    }
  } else {
    orderSource.counts.chartData = defaultBarChartData;
  }
  orderSource.graphTypePills = 
   {
     ...orderSource.graphTypePills,
     arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
    }
  let order = {...orderSource}
  orderSource = {...orderSourceCopy};
  orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
  return order
} else{
  orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0}
  return orderSource
}
}

function getFormattedDate(date,selectedSeries?) {
  let series = selectedSeries ? selectedSeries : echartOptions.chartSeries;
  switch (series) {
    case "hour":
      return moment(date).format("h:mm a");
    case "day":
      return moment(date).format("D-M-YY");
    case "month":
      return moment(date).format("MMM");
    case "year":
      return moment(date).format("YYYY");
  }
}

export function getOrderTemperatureDetails(result:Record<string,IO2dTemperatureGraph[]>,title:string,graphType?:string){
  orderSourceCopy = {...orderSource}
  orderSource.counts = {...orderSource.counts, title : title};
  if(graphType){
    echartOptions.type = graphType;
    orderSource.graphType = graphType;
  }
  if(Object.keys(result)?.length > 0){
        let resultData = Object.values(result);
        result = {};
        resultData.forEach((resultArray: Array<any>)=>{
          resultArray.forEach(resultObject =>{
            if (resultObject["orderDetails"][0]["orderId"]) {
              let orderId = resultObject["orderDetails"][0]["orderId"];
              if (result[orderId]) {
                result[orderId].push(resultObject);
              } else {
                result[orderId] = [];
                result[orderId].push(resultObject);
              }
            }
          });
        });

        if (result) {
          let allSeries = Object.values(result);
          if (allSeries.length > 0) {
            let chartData = {
              series: [],
              xAxis: [],
              legend: [],
              type: orderSource.graphType,
            };
            allSeries.forEach((seriesArray: Array<any>) => {
              let dataObj = {};
              dataObj = JSON.parse(
                JSON.stringify(defaultBarChartData.series[0])
              );
              let index = allSeries.indexOf(seriesArray);
              seriesArray.forEach(seriesObj=>{
                let formattedDate = getFormattedTimeSlot(seriesObj["slot"]);
                if(seriesObj["slot"] && chartData["xAxis"].indexOf(formattedDate) === -1){
                  chartData["xAxis"].push(formattedDate)
                }
                let targetIndex = chartData["xAxis"].indexOf(formattedDate);
                if(seriesObj["orderDetails"] && seriesObj["orderDetails"][0]["temp"]){
                  (dataObj["data"][targetIndex] = trimDecimalValue(seriesObj["orderDetails"][0]["temp"]))
                }
                if(seriesObj["orderDetails"] && seriesObj["orderDetails"][0]["orderId"]){
                  (dataObj["name"] = seriesObj["orderDetails"][0]["orderId"])
                }

                dataObj["itemStyle"]["color"] =
                  orderSource.counts.colorsArray[index];
                dataObj["type"] = orderSource.graphType;
              });
              chartData.series.push(dataObj);
            });
            let chartDataToDraw = drawBarChart(chartData);
            orderSource.counts.chartData = chartDataToDraw;
          } else {
            orderSource.counts.chartData = defaultBarChartData;
          }
        } else {
          orderSource.counts.chartData = defaultBarChartData;
        }
        let order = {...orderSource}
        orderSource = {...orderSourceCopy};
         orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
        return order ;
  } else{
    orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0}
    return orderSource
  }
}
function trimDecimalValue(value) {
  if (value) {
    return value.toFixed(2);
  } else {
    return value;
  }
}

export function getQueueData(result:IQueueInTrend) {

    let ordersCountsInQueue = {};
    let agentsInQueue = {};
    if (result && Object.keys(result).length > 0) {
      ordersCountsInQueue["order"] = result["order"]
        ? result["order"]
        : null;
      agentsInQueue["deliveryPerson"] = result["deliveryPerson"]
        ? result["deliveryPerson"]
        : null;
    }
    return {ordersCountsInQueue,agentsInQueue}

}

export function getFormattedTimeSlot(slot:string) {
    let finalTime = ``;
    if (slot) {
      const aTimeS = slot.split('-');
      const cTimes = [];
      let zValue = [];
      forEach(aTimeS, timeS => {
        timeS = timeS.trim();
        const aTimes = timeS.split(' ');
        if (aTimes && aTimes.length > 1 && aTimes[0] && aTimes[1]) {
          const time = aTimes[0];
          const z = aTimes[1].slice(0, 1).toLowerCase();
          zValue.push(z);
          cTimes.push(time);
        }
      })
      if ( cTimes && cTimes.length === 2 && cTimes[0] && cTimes[1]) {
        const time1 = cTimes[0];
        const time2 = cTimes[1];
        zValue = uniq(zValue);
        if (zValue && zValue.length === 2) {
          finalTime = `${time1} ${zValue[0]} - ${time2} ${zValue[1]}`;
        } else if (zValue && zValue.length === 1) {
          finalTime = `${time1} - ${time2} ${zValue[0]}`;
        } else {
          finalTime = `${time1} - ${time2}`;
        }
      }
    }
    return finalTime;
  }
export function getRiderWisePerformance(res:Record<string, IRiderWisePerformance[]>,title:string,pillValue,graphSettings?:IGraphSettings) {
  orderSourceCopy = {...orderSource}
  orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false,title : title}
  orderSource.graphType = graphSettings.graphType
  if(Object.keys(res)?.length > 0) {
    let result = {...res};
    if(pillValue){
      orderSource.pills = {...orderSource.pills,selected : pillValue}
    }
      const ordersArray = ["delivered", "deliveryPartnerReturnedOrder"];
      let resultData = Object.values(result);
      resultData=resultData.map((dataArray: Array<any>) => {
        let testObj = {};
        dataArray.forEach((dataObject) => {
          if (ordersArray.indexOf(dataObject["deliveryStatus"]) >= 0) {
            if (
              dataObject["distanceTravelledInKm"] ||
              dataObject["distanceTravelledInKm"] === 0
            ) {
              testObj["agentName"] = dataObject["agentName"];
              testObj["agentId"] = dataObject["agentId"];
              testObj["deliveryStatus"] = "distanceTravelledInKm";
              testObj["count"]
                ? (testObj["count"] += Math.floor(
                    dataObject["distanceTravelledInKm"]
                  ))
                : (testObj["count"] = Math.floor(
                    dataObject["distanceTravelledInKm"]
                  ));
            }
          }
        });
        // Object.keys(testObj).length > 0 ?   dataArray.push(testObj) : "";
        if(Object.keys(testObj).length > 0){
          // dataArray.push(testObj);
          // dataArray = [...dataArray,testObj]
          return [...dataArray,testObj]
        }
        // Object.keys(testObj).length > 0 ?   dataArray = [...dataArray,testObj] : "";
      });
      let flattenArray = _.flatten(resultData);
      let filteredArray = [];
      flattenArray.forEach(dataObj=>{
        if (
          dataObj['deliveryStatus'] === "delivered" ||
          dataObj['deliveryStatus'] === "deliveryPartnerReturnedOrder"
        ) {
          let indexOfOrdersObj = _.findIndex(filteredArray, {
            agentName: dataObj['agentName'],
            deliveryStatus: "orders",
          });
          if (indexOfOrdersObj >= 0) {
            filteredArray[indexOfOrdersObj].count += dataObj['count'];
          } else {
           dataObj = {...dataObj,deliveryStatus : "orders"};
          //  dataObj['deliveryStatus'] = "orders";
            filteredArray.push(dataObj);
          }
        }
        if (dataObj['deliveryStatus'] === "distanceTravelledInKm") {
          let indexOfOrdersObj = _.findIndex(filteredArray, {
            agentName: dataObj['agentName'],
            deliveryStatus: "orders",
          });
          indexOfOrdersObj >= 0 ? filteredArray.push(dataObj) : "";
        }
      });
      result = _.groupBy(filteredArray, "deliveryStatus");
      if (result) {
        let allSeries = Object.values(result);
        if (allSeries.length > 0) {
          let chartData = {
            series: [],
            xAxis: [],
            legend: [],
            type: orderSource.graphType,
          };
          chartData.legend = Object.keys(result);
          // chartData.legend = Object.keys(result).map(getFormattedLabel)
          chartData.legend =  _.map(Object.keys(result),getFormattedLabel);

           allSeries.forEach((seriesArray: Array<any>) => {
            let dataObj = {};
            dataObj = JSON.parse(
              JSON.stringify(defaultBarChartData.series[0])
            );
            seriesArray.forEach((seriesObj) => {
              let formattedDate = seriesObj["agentName"];
              if(seriesObj["agentName"] && chartData["xAxis"].indexOf(formattedDate) === -1){
                chartData["xAxis"].push(formattedDate)
              }
              let targetIndex = chartData["xAxis"].indexOf(formattedDate);
              if(seriesObj["count"] || seriesObj["count"] === 0){
                (dataObj["data"][targetIndex] = seriesObj["count"])
              }
              if(seriesObj["deliveryStatus"]){
                (dataObj["name"] = getFormattedLabel(seriesObj["deliveryStatus"]));
                (dataObj["itemStyle"]["color"] = getColors(getFormattedLabel(seriesObj["deliveryStatus"])))
              }
              dataObj["type"] =  orderSource.graphType;
              dataObj["stack"] = graphSettings[`isStacked_${graphSettings.graphType}`];
            });
            chartData.series.push(dataObj);
          });
          let chartDataToDraw = drawBarChart(chartData);
         orderSource.counts.chartData = chartDataToDraw;
         if (chartData && chartData.type === "pie") {
          buildGraphTypePillValueToGetData(chartData.type);
        }
        } else {
          orderSource.counts.chartData = defaultBarChartData;
          orderSource.counts.counts = 0;
        }
      } else {
        orderSource.counts.chartData = defaultBarChartData;
        orderSource.counts.counts = 0;
      }
      orderSource.graphTypePills = 
      {
        ...orderSource.graphTypePills,
        arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
      }
      let order = {...orderSource}
      orderSource = {...orderSourceCopy};
      orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
     
      return order
    }else{
      orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0}
      orderSource.graphTypePills = 
         {
           ...orderSource.graphTypePills,
           arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
         }
      return orderSource
    }
}

export function lmdTatOfOrderInPercentage(result:Record<string,ITatOfOrder[]>,title:string,graphType){
  orderSourceCopy = {...orderSource};
  orderSource.graphType = graphType
  if (result) {
    orderSource.counts = {...orderSource.counts, title : title};
    const allSeries = Object.values(result);
    if (allSeries.length > 0) {
     const chartData = {
        series: [],
        xAxis: [],
        legend: [],
        type: orderSource.graphType,
      };
      chartData.legend = Object.keys(result);
      const allCounts = [];
     allSeries.forEach ((seriesArray)=> {
        let dataObj = {};
        dataObj = JSON.parse(
          JSON.stringify(defaultBarChartData.series[0])
        );
        seriesArray.forEach((seriesObj)=> {
          const formattedDate = getFormattedTimeSlot(
            seriesObj['barName']
          );
          seriesObj["barName"] &&
          chartData["xAxis"].indexOf(formattedDate) === -1
            ? chartData["xAxis"].push(formattedDate)
            : "";
          const targetIndex = chartData['xAxis'].indexOf(formattedDate);
          seriesObj["count"] || seriesObj["count"] === 0
            ? (dataObj["data"][targetIndex] = seriesObj["count"])
            : "";
          allCounts.push(seriesObj["count"]);
          seriesObj["status"]
            ? (dataObj["name"] = seriesObj["status"])
            : "";
          seriesObj["status"]
            ? (dataObj["itemStyle"]["color"] = getColors(
                seriesObj["status"]
              ))
            : "";
          dataObj["type"] = orderSource.graphType;
          dataObj["stack"] = true;
        });
        chartData.series.push(dataObj);
      });
      const chartDataToDraw = drawBarChart(chartData);
      orderSource.counts.chartData = chartDataToDraw;
      if (chartData && chartData.type === 'pie') {
        buildGraphTypePillValueToGetData(graphType ? graphType : chartData.type);
      }   
    } else {
      orderSource.counts.chartData = defaultBarChartData;
        orderSource.counts.counts = 0;
    }
  } else {
    orderSource.counts.chartData = defaultBarChartData;
      orderSource.counts.counts = 0;
  }
  let order = {...orderSource}
  orderSource = {...orderSourceCopy};
  orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
  return order
}

export function getFormattedResult(resultData) {
    let resObj = {};
    resultData.forEach((obj) => {
      _.transform(
        obj,
        (_, value, key) => {
          (resObj[key] &&  Array.isArray(resObj[key])) ? resObj[key].push(value[0]) : (resObj[key] = value);
        },
        {}
      );
    });
   let resObject = [];
   resObject = Object.values(resObj);
   resObject.forEach(function(value,key){
    resObject[key] = _.orderBy(value, ["date"], ["asc"]);
    })
    return Object.keys(resObject).length > 0 ? resObject : null;
  }

  export function mmdTemperatureDetails(res: Record<string, ITemperatureDetails[]>,title:string,graphType?: string,pillValue?:string){
  orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
  orderSource.counts = {...orderSource.counts, title : title};
  if(pillValue){
    orderSource.pills = {...orderSource.pills, selected : pillValue ? pillValue : 'days'};
    }
  orderSourceCopy = {...orderSource};
  const dataForTabularFormat = []
  const tooltipNumberOfInlineValues = 4
  if(Object.keys(res)?.length > 0) {
    let obj1: any = {};
    let obj2: any = {};
    let result = {...res};
    if(graphType){
      orderSource.graphType = graphType
    }
     let resultt = Object.values(result);
     if(resultt){
    resultt.forEach((value) => {
      obj1 = _.groupBy(value, "orderId");
      obj2 = _.merge(obj1, obj2);
    });
  }
    const allSeries = Object.values(obj2);
    if (allSeries.length > 0) {
      const chartData = {
        series: [],
        xAxis: [],
        legend: [],
        type: orderSource.graphType,
      };
      const allCounts = [];
       allSeries.forEach((seriesArray: any) => {
        let dataObj = {};
        dataObj = JSON.parse(
          JSON.stringify(defaultBarChartData.series[0])
        );
        seriesArray.forEach((seriesObj: any) => {
          const formattedDate = getFormattedDate(
            seriesObj['deliverDateTime'],pillValue ? pillValue : orderSource.pills.selected
          );
          if(seriesObj["deliverDateTime"] && chartData["xAxis"].indexOf(formattedDate) === -1){
            chartData["xAxis"].push(formattedDate)
          }
          const targetIndex = chartData['xAxis'].indexOf(formattedDate);
          if(seriesObj["boxTemp"]){
            dataForTabularFormat[targetIndex] = {
              key: formattedDate,
              data:[...(dataForTabularFormat?.[targetIndex]?.data || []),seriesObj]
            };
            (dataObj["data"][targetIndex] = parseFloat(seriesObj["boxTemp"].toFixed(2)))
          }

          allCounts.push(seriesObj["boxTemp"]);
          if(seriesObj["orderId"]){
            (dataObj["name"] = getFormattedLabel(seriesObj["orderId"]));
            (dataObj["itemStyle"]["color"] = getColors(getFormattedLabel(seriesObj["orderId"])))
          }

          dataObj["type"] = orderSource.graphType;
        });
        chartData.series.push(dataObj);
      });
      const chartDataToDraw = drawBarChart(chartData);
      chartDataToDraw['tooltipNumberOfInlineValues'] = tooltipNumberOfInlineValues
      orderSource.counts.chartData = chartDataToDraw;
    } else {
      const ChartData = JSON.parse(
        JSON.stringify(defaultBarChartData)
      );
      ChartData['tooltipNumberOfInlineValues'] = tooltipNumberOfInlineValues
      orderSource.counts.chartData = ChartData;
    }
    let order = {...orderSource}
    orderSource = {...orderSourceCopy};
    orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
    order['dataForTabularFormat'] = dataForTabularFormat
    return order
  }else{
    orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0}
    orderSource['dataForTabularFormat'] = dataForTabularFormat
    return orderSource
  }
  }

  export function drawBarChart(chartData) {
    let defaultChartData = JSON.parse(JSON.stringify(defaultBarChartData));
    defaultChartData.series = chartData.series;
    defaultChartData.xAxis = chartData.xAxis;
    defaultChartData.legend = chartData.legend;
    defaultChartData.type = chartData.type;
    return defaultChartData;
  }

  export function plotGraph(result, zone,zoneEChartGraphs) {
    let zoneEChartGraph = {...zoneEChartGraphs}
    let resultt = [...result];
    let zonee = {...zone};
    if (resultt && resultt.length>0) {
      let groupByDate = groupBy(resultt, 'updated');
          let data = groupByDate;
          let yAxisKeys =  keys(data);
          let yAxis = []
          let xAxisByStatus = {
            temp: [],
            humidity: []
          };
          // forEach(yAxisKeys, function(dateString, index) {
            yAxisKeys.forEach((dateString, index) => {
            let allStatusDetails = data[dateString];
            allStatusDetails.forEach((countByStatus)=>{
            // forEach(allStatusDetails, function(countByStatus) {
              if (countByStatus) {
                xAxisByStatus.temp.push(countByStatus.temperature  || countByStatus.temperature === 0 ? countByStatus.temperature.toFixed(2) : null)
                xAxisByStatus.humidity.push (countByStatus.humidity  || countByStatus.humidity === 0  ? countByStatus.humidity.toFixed(2) : null)
                yAxis.push(dateFormator(dateString, 'lll'));
                if(index === yAxisKeys.length-1) {
                     zoneEChartGraph[zonee._id].countArray = [];
                     updateGraphCount(zoneEChartGraph,countByStatus.temperature  || countByStatus.temperature === 0 ? countByStatus.temperature.toFixed(2) : null, zonee._id, dateFormator(dateString, 'lll'), 'Temperature', '°C')
                     updateGraphCount(zoneEChartGraph,countByStatus.humidity  || countByStatus.humidity === 0  ? countByStatus.humidity.toFixed(2) : null, zonee._id, dateFormator(dateString, 'lll'), 'Humidity', '%')
                }
              }
            });
          });
          zoneEChartGraph = drawEChars(yAxis, xAxisByStatus, zonee,zoneEChartGraph);

  } else {
    let xAxisByStatus = {
      temp: [],
      humidity: []
    };
    let yAxis = [];
    zoneEChartGraph = updateGraphCount(zoneEChartGraph,'NA', zonee['_id'], '-', 'Temperature', '')
    zoneEChartGraph = updateGraphCount(zoneEChartGraph,'NA', zonee['_id'], '-', 'Humidity', '')
    zoneEChartGraph = drawEChars(yAxis, xAxisByStatus, zonee,zoneEChartGraph);
  }
  return zoneEChartGraph
}
   function updateGraphCount(zoneEChartGraph,value, trackedAssetId, subTitle, tooltip, unit){
  let countObj = {
    count: null,
    tooltip:'',
    subTitle: '',
    unit: ''
   }
  countObj.count = value;
  countObj.tooltip = tooltip;
  countObj.subTitle = subTitle;
  countObj.unit = unit;
  zoneEChartGraph[trackedAssetId].countArray.push(countObj);
  return zoneEChartGraph;
}
export function dateFormator(date, format?) {
  const datee = date;
  let dateFormate = moment(datee).format('MMMM Do YYYY, h:mm a');
  if (format) {
    if (format === 'date') {
      dateFormate = moment(datee).format('MMMM Do YYYY, h:mm a');
    } else if (format === 'time') {
      dateFormate = moment(datee).format('h:mm:ss a');
    } else if (format === 'lll') {
      dateFormate = moment(datee).format('LLL');
    }
  }
  return dateFormate;
}
function drawEChars(label, series, zone,zoneEChartGraph) {
  let assetId = zone._id;
  let type = 'line';
  zoneEChartGraph[assetId].title = zone.name ? zone.name : '';
  zoneEChartGraph[assetId].counts.chartData.type = type;
  let legend = keys(series);

  zoneEChartGraph[assetId].counts.chartData.legend = legend.map((seriesName)=>{
    let seriesNameStr: string;
    switch (seriesName) {
      case 'temp':
        seriesNameStr = 'Temp in °C';
        break;
      case 'humidity':
        seriesNameStr = 'Humidity in %';
        break;
    }
    return seriesNameStr;
  });

  zoneEChartGraph[assetId].counts.chartData.legendToDisable = 'Humidity in %'
  zoneEChartGraph[assetId].counts.chartData.visualMap = visualMaps;
  zoneEChartGraph[assetId].counts.chartData.series = map(legend, function(seriesName) {
    let eChartSeries: EChartSeries;
    let seriesNameStr: string;
    switch (seriesName) {
      case 'temp':
        seriesNameStr = 'Temp in °C';
        break;
      case 'humidity':
        seriesNameStr = 'Humidity in %';
        break;
    }
    eChartSeries = {
      data: series[seriesName],
      type: type,
      name: seriesNameStr,
      stack: false,
      smooth: false,
      label: {normal: {show: true, position: 'top'}},
    };
    return eChartSeries;
  });

  zoneEChartGraph={...zoneEChartGraph,
    assetId: {...zoneEChartGraph[assetId] , counts : {...zoneEChartGraph[assetId].counts , chartData : {...zoneEChartGraph[assetId].counts.chartData, visualMap : visualMaps}}}
  }

  zoneEChartGraph[assetId].counts.chartData.xAxis = label;
  zoneEChartGraph[assetId].counts.chartData = {...zoneEChartGraph[assetId].counts.chartData};
  return zoneEChartGraph;
}

export function updateSpecificGraphOnGpsData(gpsData,zoneEChartGraph) {
  let zoneEChart = {...zoneEChartGraph};
  const trackedAssetId = gpsData?.assetId
  // zoneEChart[trackedAssetId] = {...zoneEChart[trackedAssetId]}
  if(trackedAssetId && zoneEChart[trackedAssetId]) {
    zoneEChart[trackedAssetId] = {...zoneEChart[trackedAssetId] , counts : {...zoneEChart[trackedAssetId].counts},countArray : [...zoneEChart[trackedAssetId].countArray]}
    zoneEChart[trackedAssetId].countArray = [];
     let update: IEChartSeriesUpdate = {
        index: null,
        label: null,
        value: null,
        labelIndex: 0,
        values: []
     };

     update.label = (dateFormator(gpsData.generatedTimeStamp, 'lll'))
     // temp
     if(gpsData.sensorData.temperature || gpsData.sensorData.temperature == 0) {
      update.values.push((gpsData.sensorData.temperature).toFixed(2));
      zoneEChart =  updateGraphCount(zoneEChart,(gpsData.sensorData.temperature).toFixed(2), trackedAssetId, update.label, 'Temperature', '°C');
     } else {
       update.values.push(null);
     }

     // humidity
     if(gpsData.sensorData.humidity || gpsData.sensorData.humidity == 0) {
      update.values.push((gpsData.sensorData.humidity).toFixed(2));
      zoneEChart = updateGraphCount(zoneEChart,(gpsData.sensorData.humidity).toFixed(2), trackedAssetId, update.label, 'Humidity', '%');
     } else {
       update.values.push(null);
     }
     zoneEChart[trackedAssetId]['counts'] = {...zoneEChart[trackedAssetId]['counts']};
     zoneEChart[trackedAssetId]['counts']['updateValue'] = {...update};
     return zoneEChart
  }
 }

  export function getColors(status) {
    switch (status) {
      case "Assigned":
        return "#9bbb59";
      case "Unassigned":
        return "#c0504d";
      case "Delivered":
        return "#249225";
      case "Rejected":
        return "#9A60B4";
      case "Cancelled":
        return "#c0504d";
      case "Manual":
        return "#4F81BD";
      case "Shopify":
        return "#F29546";
      case "order":
        return "#4F81BD";
      case "deliveryPerson":
        return "#9bbb59";
      case "orders":
        return "#4F81BD";
      case "distanceTravelledInKm":
        return "#F29546";
      case "WTAT":
        return "#9bbb59";
      case "ATAT":
        return "#F29546";
      default:
        return "";
    }
  }
  function getFormattedLabel(value) {
    switch (value) {
      case "delivered":
        return "Delivered";
      case "inProgress":
        return "In Progress";
      case "cancelled":
        return "Cancelled";
      case "notStarted":
        return "Not Started";
      case "Un Assigned":
        return "Unassigned";
      case "Assigned":
        return "Assigned";
      case "assigned":
        return "Assigned";
      case "pickedUp":
        return "Picked Up";
      case "deliveryPartnerDeclinedPickedUp":
        return "Pickup Declined";
      case "deliveryPartnerRejectedOrder":
        return "Rejected";
      case "deliveryPartnerAcceptedOrder":
        return "Accepted";
      case "deliveryPartnerReturnedOrder":
        return "Returned";
      case "manual":
        return "Manual";
      case "shopify":
        return "Shopify";
      case "arrived":
        return "Arrived";
      case "autoCompleted":
        return "Auto Completed";
      case "manuallyCompleted":
        return "Manually Completed";
      case "deliveryAcknowledged":
        return "Delivery Acknowledged";
      case "timeBoundCompleted":
        return "Time Bound Completed";
      default:
        return value;
    }
  }

   export function buildChartDataForGraph(result, title : string,graphSettings:IGraphSettings,fieldName? : string,pillValue? : string) {
    orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
    orderSourceCopy = {...orderSource};
    if(pillValue){
    orderSource.pills = {...orderSource.pills, selected : pillValue ? pillValue : 'days'};
    }
     orderSource.counts = {...orderSource.counts, title : title};
     orderSource.graphType = graphSettings.graphType;
      const legends = [];
      const gropuIds = _.keys(result);
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
            result[key]?.[0]?.['label'] ? legends.push(result[key]?.[0]?.['label']) : ''
        }
      }
      let resultt = {...result};
      const allSeries = Object.values(resultt);
      const chartData = {
        series: [],
        xAxis: [],
        legend: [],
        type: graphSettings.graphType ? graphSettings.graphType : orderSource.graphType,
      };
      const fName = fieldName ? fieldName : 'count';
      chartData.legend = legends;
      let xAxis = [];
      const yAxis = [];
      gropuIds.forEach(id => {
        const values = resultt[id];
        values.forEach(val => {
          const date = val && val.date;
          const legend = val && val.label;
          const formattedDate = getFormattedDate(
            date, orderSource.pills.selected
          );
          const ms = new Date(date).getTime();
          const label = val.label ? val.label : (val.status ? val.status : '');
          const count = val?.[fName];
          const sumValue = val.sumValue;
          xAxis.push({ formattedDate, ms })
          yAxis.push({ legend, ms, count, sumValue, label, date })
        })
      })
      xAxis = _.sortBy(xAxis, 'ms');
      chartData.xAxis = _.uniq(_.keys(_.groupBy(xAxis, 'formattedDate')))
      const xAxisLength = xAxis.length;
      const ySerieData = {};
      yAxis.forEach((value)=>{
        const legend = value.legend;
        const label = value.label;
        const date = value.date;
        const formattedDate = getFormattedDate(
          date, orderSource.pills.selected
        );
        const indexOfDate = chartData.xAxis.indexOf(formattedDate);
        if (!ySerieData[legend]) {
          ySerieData[legend] = {};
        }
        if (!ySerieData[legend]['data']) {
          ySerieData[legend]['data'] = _.fill(ySerieData[legend]['data'], "", 0, xAxisLength)
        }
        ySerieData[legend]['data'][indexOfDate] = value['count'];
        ySerieData[legend]["type"] = graphSettings.graphType ? graphSettings.graphType : orderSource.graphType;
        ySerieData[legend]["name"] = legend;
        ySerieData[legend]["stack"] = graphSettings[`isStacked_${graphSettings.graphType}`];
        ySerieData[legend]["itemStyle"] =
        {
          color: getColors(
            getFormattedLabel(label)
          )
        }
      })
      chartData.series = chartData.legend.map(legend => {
        return ySerieData[legend]
      })
      orderSource.graphTypePills = 
      {
        ...orderSource.graphTypePills,
        arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
      }
      if(chartData.series.length > 0){
        chartData.series[0]["type"] = graphSettings.graphType ? graphSettings.graphType : orderSource.graphType;
      }
      if (allSeries.length > 0) {
        const chartDataToDraw = drawBarChart(chartData);
        orderSource.counts.chartData = chartDataToDraw;
        if (chartData && chartData.type === 'pie') {
          buildGraphTypePillValueToGetData(graphSettings.graphType ? graphSettings.graphType : chartData.type);
        }
      } else {
        orderSource.counts.chartData = defaultBarChartData;
      }
      let order = {...orderSource}
      orderSource = {...orderSourceCopy};
      orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
      return order
    }

    function buildGraphTypePillValueToGetData(graphType: string,tooltipData=null,legendOrientation='horizontal') {
      if (orderSource.counts.chartData) {
        let chartData: any;
        let newPieData: any = [];
        chartData = orderSource.counts.chartData;
        if (chartData.series && chartData.series.length > 0) {
            (chartData.series).forEach((value)=> {
            if (value.type) {
              value.type = graphType;
            }
            if (graphType === 'pie') {
              if (value.data) {
                let dataObj = {
                  value: null, name: null
                };
                let sum = _.sum(value.data);
                dataObj.value = sum || tooltipData?.routePlan?.unassigned?.length || 0;
                dataObj.name = value.name;
                newPieData.push({...dataObj,addDataForTooltip:tooltipData,legendOrientation});
              }
            }
          });
        }
        let pieDataObj = {
          data: [...newPieData]
        }
        chartData['pieChartData'] = pieDataObj;
        orderSource.graphType = graphType;
        chartData.type = graphType;
        orderSource.counts.chartData = chartData;
        orderSource["counts"].graphTypeChangedRandomNumber = Math.random();
      }
    }

    export function buildChartDataForGraphIfDashboardFalse(result,title, graphSettings:IGraphSettings,fieldName : string,selectedType : string) {
       const cursor = 'default'; //

      if(result?.pending?.length){
        let resultCopy = {};
        for (let key in result) {
          if (key === "pending") {
            resultCopy = { ...resultCopy, progress: result["pending"] };
          }else{
            resultCopy = { ...resultCopy, [key]: result[key] };
          }
        }
        result={...resultCopy}
      }
      orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
      orderSourceCopy = {...orderSource}
      if(selectedType){
        orderSource.pills = {...orderSource.pills, selected : selectedType ? selectedType : 'day'}
      }
      orderSource.counts = {...orderSource.counts, title : title};
     orderSource.graphType = graphSettings.graphType;
      orderSource.graphTypePills = 
      {
        ...orderSource.graphTypePills,
        arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
      }

      let allSeries = Object.values(result);
      let chartData = {
        series: [],
        xAxis: [],
        legend: [],
        type: orderSource.graphType,
      };
      let fName = fieldName ? fieldName : "count";
      chartData.legend = _.keys(result);
      let xAxis = [];
      let yAxis = [];
      let allCounts = []
      chartData.legend.forEach((legend)=>{
        let values = result[legend];
        values.forEach((val)=>{
          let date = val && val?.date;
          let formattedDate = getFormattedDate(
            date,
            orderSource.pills.selected
          );
          let ms = new Date(date).getTime();
          let label = val?.label;
          let count = val?.count;
           allCounts.push(val?.count);
          let sumValue = val?.sumValue;
          xAxis.push({ formattedDate, ms });
          yAxis.push({ legend, ms, count, sumValue, label, date });
        });
      });
      xAxis = _.sortBy(xAxis, "ms");
      chartData.xAxis = _.uniq(_.keys(_.groupBy(xAxis, "formattedDate")));
      let xAxisLength = xAxis.length;
      let ySerieData = {};
      _.each(yAxis, (value) => {
        let legend = value?.legend;
        let label = value?.label;
        let date = value?.date;
        let formattedDate = getFormattedDate(
          date,
          orderSource.pills.selected
        );
        let indexOfDate = chartData.xAxis.indexOf(formattedDate);
        if (!ySerieData[legend]) {
          ySerieData[legend] = {};
        }
        if (!ySerieData[legend]["data"]) {
          ySerieData[legend]["data"] = _.fill(
            ySerieData[legend]["data"],
            "",
            0,
            xAxisLength
          );
        }
        ySerieData[legend]["stack"] = graphSettings[`isStacked_${graphSettings.graphType}`];
        ySerieData[legend]["data"][indexOfDate] = value[fName];
        ySerieData[legend]["type"] = orderSource.graphType;
        ySerieData[legend]["name"] = legend;
        ySerieData[legend]["itemStyle"] = {
          color: getColors(getFormattedLabel(label)),
        };
      });
      chartData.series = _.map(chartData.legend, (legend) => {
        return ySerieData[legend];
      });
      if (allSeries.length > 0) {
        let chartDataToDraw = drawBarChart(chartData);
        orderSource.counts = {...orderSource.counts, counts:_.sum(allCounts)};
        orderSource.counts.chartData = {...chartDataToDraw,cursor};
        if (chartData && chartData.type === "pie") {
         buildGraphTypePillValueToGetData(chartData.type);
        }
      } else {
        orderSource.counts.chartData = {...defaultBarChartData,cursor};
      }
      let order = {...orderSource}
      orderSource = {...orderSourceCopy};
      orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
      return order
    }


    export function buildChartDataForRoutePlanTemp(result,title, graphType : string,fieldName? : string,selectedType? : string) {


      orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
      orderSourceCopy = {...orderSource}
      if(selectedType){
        orderSource.pills = {...orderSource.pills, selected : selectedType ? selectedType : 'day'}
      }
      orderSource.counts = {...orderSource.counts, title : title};
     orderSource.graphType = graphType
      let allSeries = Object.values(result);

      let chartData = {
        series: [],
        xAxis: [],
        legend: [],
        type: orderSource.graphType,
      };

      fieldName

      let xAxis = [];
      let arr =[];
        let tempData:any=[]
        const tooltipData ={}
        let count = 0 ;
        result?.forEach((val)=>{
          if(val?.routePlan && val.status==='pending'){
            xAxis=[...xAxis,val?.routePlan]
            // tempData=[...tempData,{value:(val?.temperature || 0),itemStyle: {
            //   color: `#${Math.random().toString(16).slice(2, 8)}`
            // }}]
            tooltipData[val.routePlan]={
              Id:val.delvieryGrpSequence,
              temperature:(val?.temperature || 0).toFixed(2),
              vehicle:val?.carrierName
            }
            let array = []
            array[count] = val?.temperature || 0
              tempData = {
              data : array ,
              type : orderSource.graphType,
              name : val?.routePlan,
              addDataForTooltip:tooltipData,
              itemStyle: {
                  color: `#${Math.random().toString(16).slice(2, 8)}`
                }
            }
            arr.push(tempData);
            count++;
          }
        });
      chartData.legend = [...xAxis]
      chartData.xAxis = [...xAxis]
      chartData.series = [...arr];
      
      if (allSeries.length > 0) {
        let chartDataToDraw = drawBarChart(chartData);
        orderSource.counts = {...orderSource.counts, counts:(chartData?.xAxis?.length || 0)};
        orderSource.counts.chartData = chartDataToDraw;
        if (chartData && chartData.type === "pie") {
          buildGraphTypePillValueToGetData(chartData.type);
         }
      } else {
        orderSource.counts.chartData = defaultBarChartData;
      }
      let order = {...orderSource}
      orderSource = {...orderSourceCopy};
      orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
      return order
    }


   export function getOrdersByDeliveryStatus(result,title:string,graphSettings:IGraphSettings,pillValue:string,key1:string,key2:string,passTooltipData) {
    orderSourceCopy = {...orderSource}
    orderSource.pills = {...orderSource.pills, selected : pillValue ? pillValue : 'days'};
    if(graphSettings.graphType){
    orderSource.graphType = graphSettings.graphType
    }
    orderSource.counts = {...orderSource.counts, title : title};
    orderSource.graphTypePills = 
    {
      ...orderSource.graphTypePills,
      arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
    }
            let allSeries = Object.values(result);
            if (allSeries.length > 0) {
              let chartData = {
                series: [],
                xAxis: [],
                legend: [],
                type:orderSource.graphType
              };
              chartData.legend = Object.keys(result);
              chartData.legend = _.map(Object.keys(result),getFormattedLabel);
              let allCounts = [];
              allSeries.forEach((seriesArray:Array<any>)=>{
                let dataObj = {};
                dataObj = JSON.parse(
                  JSON.stringify(defaultBarChartData.series[0])
                );
                seriesArray.forEach((seriesObj)=>{
                  let formattedDate = getFormattedDate(
                    seriesObj[key1],
                    orderSource.pills.selected
                  );
                  seriesObj[key1] &&
                  chartData["xAxis"].indexOf(formattedDate) === -1
                    ? chartData["xAxis"].push(formattedDate)
                    : "";
                  let targetIndex = chartData["xAxis"].indexOf(formattedDate);
                  seriesObj["count"]
                    ? (dataObj["data"][targetIndex] = seriesObj["count"])
                    : "";
                  allCounts.push(seriesObj["count"]);
                  seriesObj[key2]
                    ? (dataObj["name"] = getFormattedLabel(
                        seriesObj[key2]
                      ))
                    : "";
                  seriesObj[key2]
                    ? (dataObj["itemStyle"]["color"] = getColors(
                        getFormattedLabel(seriesObj[key2])
                      ))
                    : "";
                  dataObj["type"] = orderSource.graphType;
                  dataObj["stack"] = graphSettings[`isStacked_${graphSettings.graphType}`];
                });
                chartData.series.push(dataObj);
              });
              let chartDataToDraw = drawBarChart(chartData);
              orderSource.counts.chartData = chartDataToDraw;
              const tooltipData ={}
              if(passTooltipData){
                for(let key in result){
                  result?.[key]?.forEach(rplan=>{
                    const k=key==='pending' ? 'progress' : key
                    tooltipData['routePlan']={...(tooltipData['routePlan'] || {}),[k]:[...(tooltipData?.['routePlan']?.[k] || []),(rplan?.routePlan + ` (${rplan.delvieryGrpSequence})`)]}
                  })
                }
              }
              if (chartData && chartData.type === "pie") {
               buildGraphTypePillValueToGetData(
                  chartData.type,(passTooltipData && tooltipData)
                );
              }
            } else {
              let ChartData = JSON.parse(
                JSON.stringify(defaultBarChartData)
              );
             orderSource.counts.chartData = ChartData;
             orderSource.counts.chartData.type = orderSource?.graphType;
             orderSource?.counts?.chartData?.series,orderSource?.counts?.chartData?.type==='pie' &&
             buildGraphTypePillValueToGetData('pie',false);
            }
            let order = {...orderSource}
            orderSource = {...orderSourceCopy};
            orderSource.counts = {...orderSource.counts, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
            return order
    }

    export function getRoutePlansAssignedStatusGraph({assigned,all},title:string,graphSettings:IGraphSettings,pillValue:string,key1:string,key2:string,passTooltipData=null) {


      let result= {}
      
      assigned?.forEach(rplan=>{
      if(!(result?.['assigned'] || []).filter(routeplan=>routeplan.routePlanId===rplan.routePlanId)?.length){
        const modifiedData={       
          ...rplan, 
          "count": (result?.['assigned']?.length + 1)  || 1,
           "deliveryStatus": 'assigned',
         }
          result['assigned']=[...(result['assigned'] || []),modifiedData]
      }
      });
  
      const allRoutePlans = all?.map(rplan => {
        if(!(result?.['assigned'] || []).filter(routeplan=>routeplan.routePlanId===rplan._id)?.length){
          const modifiedData={       
            ...rplan, 
            "count": (result?.['unassigned']?.length + 1)  || 1,
             "deliveryStatus": 'unassigned',
             routePlan:rplan?.label|| ''
          }
            result['unassigned']=[...(result['unassigned'] || []),modifiedData]
          return { ...rplan, isAssigned: false }
        }
        return { ...rplan, isAssigned: true }
      });


      orderSourceCopy = {...orderSource}
      orderSource.pills = {...orderSource.pills, selected : pillValue ? pillValue : 'day'};
      if(graphSettings.graphType){
      orderSource.graphType = graphSettings.graphType
      }
      orderSource.graphTypePills = 
      {
        ...orderSource.graphTypePills,
        arrayOfGraphTypePills:assignSelectedFields(orderSource.graphTypePills.arrayOfGraphTypePills,graphSettings)
      }
      orderSource.counts = {...orderSource.counts, title : title};
              let allSeries = Object.values(result);
              let total=0
              if (allSeries.length > 0) {
                let chartData = {
                  series: [],
                  xAxis: [],
                  legend: [],
                  type:orderSource.graphType
                };
                chartData.legend = Object.keys(result);
                // chartData.legend = _.map(Object.keys(result),getFormattedLabel);
                let allCounts = [];
                allSeries.forEach((seriesArray:Array<any>)=>{
                  let dataObj = {};
                  dataObj = JSON.parse(
                    JSON.stringify(defaultBarChartData.series[0])
                  );
                  seriesArray.forEach((seriesObj)=>{
                    let formattedDate = getFormattedDate(
                      seriesObj[key1],
                      orderSource.pills.selected
                    );
                    seriesObj[key1] &&
                    chartData["xAxis"].indexOf(formattedDate) === -1
                      ? chartData["xAxis"].push(formattedDate)
                      : "";
                    let targetIndex = chartData["xAxis"].indexOf(formattedDate);
                    seriesObj["count"]
                    ? (dataObj["data"][targetIndex] = seriesObj["count"])
                    : "";
                    allCounts.push(seriesObj["count"]);
                    seriesObj[key2]
                      ? (dataObj["name"] = seriesObj[key2])
                      : "";
                    seriesObj[key2]
                      ? (dataObj["itemStyle"]["color"] = getColors(
                          getFormattedLabel(seriesObj[key2])
                        ))
                      : "";
                    dataObj["type"] = orderSource.graphType;
                    dataObj["stack"] = graphSettings[`isStacked_${graphSettings.graphType}`];
                  });
                  chartData.series.push(dataObj);
                });
                let chartDataToDraw = drawBarChart(chartData);
                orderSource.counts.chartData = chartDataToDraw;
                const tooltipData ={}
                  for(let key in result){
                     total += result?.[key]?.length || 0;
                    passTooltipData && result?.[key]?.forEach((rplan,i)=>{
                      if(i>70){
                        return;
                      }
                      tooltipData['routePlan']={...(tooltipData['routePlan'] || {}),[key]:[...(tooltipData?.['routePlan']?.[key] || []),(rplan?.routePlan)]}
                    })
                  }
                
                if (chartData && chartData.type === "pie") {
                 buildGraphTypePillValueToGetData(
                    chartData.type,(passTooltipData && tooltipData),'vertical'
                  );
                }
              } else {
                let ChartData = JSON.parse(
                  JSON.stringify(defaultBarChartData)
                );
               orderSource.counts.chartData = ChartData;
               orderSource.counts.chartData.type = orderSource.graphType;
               orderSource?.counts?.chartData?.series,orderSource?.counts?.chartData?.type==='pie' &&
               buildGraphTypePillValueToGetData('pie',false);
               total = 0
              }
              let order = {...orderSource,counts:{...orderSource.counts,counts:total,show:true}}
              orderSource = {...orderSourceCopy};
              orderSource.counts = {...orderSource.counts,show:true, chartData:{... orderSource.counts.chartData , xAxis : [],series : defaultSeriesObj,legendToDisable: ""},counts:0,showLoader : false}
      return { allRoutePlans, result: order }
      }

     export function plotSpecsGraph(specsChartData) {

      let boxStatusGraphs = {...boxStatusGraph , temperature : {...boxStatusGraph.temperature}, humidity : {...boxStatusGraph.humidity}};
    const letAllStats =keys(boxStatusGraphs);
    letAllStats.forEach((stats) => {
      const groupedStatData = [];
      specsChartData.forEach((data)=>{
            groupedStatData.push(data[stats]);
          });
          const series = [];
          let xAxis = [];
          const legends = [];
          groupedStatData.forEach((data)=> {
            if (data.xAxis && data.xAxis.length > 0) {
              if (xAxis.length === 0) {
                xAxis = data.xAxis;
              } else if (xAxis.length < data.xAxis.length) {
                xAxis = data.xAxis;
              }
            }
            series.push(data.series);
            legends.push(data.legend);
            boxStatusGraphs[stats].chartData = {
              title: boxStatusGraphs[stats].title,
              type: boxStatusGraphs[stats].graphType,
              series: series,
              isDataZoomRequired: true,
              xAxis: xAxis,
              legend: legends
            };
            boxStatusGraphs[stats].showLoader = false;
          });

    });
    return boxStatusGraphs;
    }

    export function removeArrayOfGraphTypePills(idsToRemove:string[],res:IOrderSource){
      let response = {...res, graphTypePills : {...res?.graphTypePills, arrayOfGraphTypePills : [...res?.graphTypePills?.arrayOfGraphTypePills]}}
      let result = response?.graphTypePills?.arrayOfGraphTypePills?.filter(item => !idsToRemove.includes(item.id));
      response.graphTypePills.arrayOfGraphTypePills = result
      return response;
    }


    export const extractTableDataForRoutePlansAssignedStatusGraph = (event,data) =>{
     const title = data?.counts?.title + ' - ' + event?.name
      const graphData = [];
     data.data?.forEach((r)=>{
       if (event.name === 'assigned' ? r.isAssigned : !r.isAssigned) {
         const obj = {
           label:r?.label,
           pickUpCity: r?.pickUpCity?.city,
           pickUpLocation: r?.pickUpLocation?.name,
           created: r?.created,
           deliveryCount : r?.deliveryCenter?.length
         }
         graphData.push(obj);
       }
     })
     return {
      title,graphData
     }
    }

    export const extractTableDataForRoutePlansStatusGraph = (event,data) =>{
      const title = data?.counts?.title + ' - ' + event?.name
      const graphData = [];
      let fieldName:string = event?.name;
      if(event?.name === "progress"){
        fieldName = 'pending';
      }

      data.data?.forEach((r)=>{
        if (r?.status === fieldName) {
          const obj = {
            label:r?.routePlan,
            carrierName:r?.carrierName,
            deliveryGroupSequence:r?.delvieryGrpSequence,
            created: r?.created,
          }
          graphData.push(obj);
        }
      })
      return {
       title,graphData
      }
     }

     export const extractTableDataForInProgressRoutePlans =  (event,data) =>{
      const title = data?.counts?.title + ' - ' + event?.name
      const graphData = [];
      data.data?.forEach((r)=>{
        if(r.status==='pending'){
          const obj = {
            label:r?.routePlan,
            carrierName:r?.carrierName,
            deliveryGroupSequence:r?.delvieryGrpSequence,
            created: r?.created,
            temperature: r?.temperature?.toFixed(2),
          }
          graphData.push(obj);
        }
      })
      return {
       title,graphData
      }
     }

     export const extractTableDataForConsignmentByDeliveryStatus =  (data) =>{
      const title = data?.counts?.title
      const graphData = [];
      
      Object.keys(data.data)?.forEach((key)=>{
        const labelData={
          delivered:'Delivered',
          timeBoundCompleted:'TimeBound Completed',
          inProgress:"In Progress",
          pickedUp:'Picked Up',
          cancelled:"Cancelled",
          manuallyCompleted:"Manually Completed",
          forceDelivered: "Force Delivered"
        }
          const obj = {
            status:labelData[key],
            total:(data.data[key] || []).reduce((accumulator, currentValue) => {
              return accumulator + currentValue.count;
          }, 0)
          }
          graphData.push(obj);
    
      })
      return {
       title,graphData
      }
     }

     export const extractTableDataForConsignmentByDeliveryTemperature =  (tempGraphData) =>{
      const title = tempGraphData?.counts?.title
      const graphData = [];
      tempGraphData?.dataForTabularFormat?.forEach(({key,data},idx)=>{
        const representative = {
          label:'modified_' + key.split('-').join('/'),
        }
        data.forEach((val)=>{
          graphData.push({
            label:val.temp,
            temperature:val.boxTemp,
            orderId:val.orderId,
            representative,
            key:idx.toString()
          });
        })
    })
      return {
       title,graphData
      }
     }


    //  KIOSK Dashboard Table Data Conversion Functions 

     export const extractTableDataForKioskTopFiveProdcuts =  (data) =>{
      const title = data?.counts?.title
      const graphData = [];
      Object.keys(data.data)?.forEach((key)=>{
          const obj = {
            product:data?.data?.[key]?.[0]?.label || '',
            orders:data?.data?.[key]?.[0]?.count || ''
          }
          graphData.push(obj);
    
      })
      return {
       title,graphData
      }
     }

     export const extractTableDataForKioskOrdersByStatus =  (data,statusKey:string,valueKey:string,timeKey:string) =>{
      const title = data?.counts?.title
      const graphData = [];
      Object.keys(data.data)?.forEach((key,idx)=>{
          const representative = {
            label:data?.data?.[key]?.[0]?.['label'],
            total:(data.data[key] || []).reduce((accumulator, currentValue) => {
              return accumulator + currentValue[valueKey];
          }, 0)
          }
          data.data[key].forEach((val)=>{
            graphData.push({
              [timeKey]:val.timeZoneDate,
              [statusKey]:val[valueKey],
              representative,
              key:idx.toString()
            });
          })
      })
      return {
       title,graphData
      }
     }

     export const findIndividualNameFormArr = (routePlans, routePlanId, fieldName?) => {
      const index =  routePlans.findIndex(r => {
        return r._id === routePlanId
      }) 
      if(index > -1) {
        if(fieldName){
          return routePlans[index]?.[fieldName];
        }
        else {
          return routePlans[index];
        }
      } else {
        return null
      }
      
    }

    export function searchInArrayByField(arrayOfObjects, searchTerm, field) {
      if (searchTerm.trim() === '') {
        // If search term is empty, show all items
        return arrayOfObjects;
      } else {
        // Perform search based on name field
        return arrayOfObjects.filter(obj => 
          obj?.[field]?.toLowerCase()?.includes(searchTerm.toLowerCase())
        );
      }
    }

    export function sortArrayByField(arrayOfObjects, field, sortOrder){
      if(sortOrder === 1){    //ascending sorting
        arrayOfObjects.sort((a, b) => {
          if (a?.[field] < b?.[field]) {
            return -1;
          }
          if (a?.[field] > b?.[field]) {
            return 1;
          }
          return 0;
        });
      } else if(sortOrder === -1) {  //descending sorting
        arrayOfObjects.sort((a, b) => {
          if (a?.[field] > b?.[field]) {
            return -1;
          }
          if (a?.[field] < b?.[field]) {
            return 1;
          }
          return 0;
        });
      }
      
      return arrayOfObjects;
    }




  



    export const plotTemperatureAndHumidityGraph = (
      data,
      dateDisplayFormate, 
      selectedVehicles,
      selectedVIds:string,
      selectedVehiclesSensorData,
      boxStatusGraph
    ) =>{

        selectedVehicles.forEach(vehicle => {
          const carLiveData = {
            temperature: {
              data: [],
              label: [],
              colour: 'red',
            },
            humidity: {
              data: [],
              label: [],
              colour: 'red',
            },
          };

          selectedVehiclesSensorData = {...selectedVehiclesSensorData,[vehicle?._id] : carLiveData}
        });

        const vehicleIds = selectedVIds.split(',');

        if (data && data.length > 0) {
          for (let i = 0; i <= data.length - 1; i++) {

            const dataIds = [];
            const dateValue = dateFormator(data[i]?.date, dateDisplayFormate)

            data[i]?.sensorData.forEach(sensData => {
              if (sensData.assetId) {
                selectedVehiclesSensorData[sensData.assetId].temperature.data.push(Math.round(sensData.temperature));
                selectedVehiclesSensorData[sensData.assetId].temperature.label.push(dateValue);
                selectedVehiclesSensorData[sensData.assetId].humidity.data.push(Math.round(sensData.humidity));
                selectedVehiclesSensorData[sensData.assetId].humidity.label.push(dateValue);
                dataIds.push(sensData.assetId);
              }
            });

            if (dataIds.length < vehicleIds.length) {
              vehicleIds.forEach(id => {
                if (!dataIds.includes(id)) {
                  selectedVehiclesSensorData[id].temperature.data.push(null);
                  selectedVehiclesSensorData[id].temperature.label.push(dateValue);
                  selectedVehiclesSensorData[id].humidity.data.push(null);
                  selectedVehiclesSensorData[id].humidity.label.push(dateValue);
                }
              });
            }
          }
        }

        const chartData = [];


        selectedVehicles.forEach(vehicle => {
          const chartsFinalData = {};
          (keys(boxStatusGraph) || []).forEach((statsType)=>{
            const historicalData = selectedVehiclesSensorData[vehicle?._id][statsType];
            const legend = vehicle?.registrationNumber || vehicle?.name
            const chartSeries: EChartSeries = {
              type: 'line',
              stack: false,
              smooth: false,
              label: { normal: { show: false, position: 'top' } },
              areaStyle: { opacity: 0.005 },
              itemStyle: {
                colour: null
              },
              name: legend,
              data: [],
              connectNulls: true,
            };
            if (historicalData) {
              chartSeries.name = legend;
              chartSeries.data = historicalData.data;
              chartSeries.itemStyle.colour = historicalData.name;
            }

            const chartSpecsData = {
              title: boxStatusGraph[statsType].title,
              type: 'line',
              series: chartSeries,
              legend: legend,
              xAxis: historicalData.label
            };
            chartsFinalData[statsType] = chartSpecsData;
          });

          chartData.push(chartsFinalData);
        });
        return {
          boxStatusGraph:plotSpecsGraph(chartData),
          selectedVehiclesSensorData:selectedVehiclesSensorData
        };

    }

    export const getTotalDistanceTravelledForMultipleCars=({carSelected,res},groupByRegNumber,graphAnalyticsData) => {
      const registrationNumber = carSelected?.registrationNumber || carSelected?.name
          if (res && res.totalDistance) {
            let totalDistance;
            const isNumber = !isNaN(res.totalDistance);
            if (isNumber) {
              totalDistance = cloneDeep(Math.round(res.totalDistance));
            } else {
              totalDistance = res.totalDistance;
            }
            const distanceTravelledObj1 = {
              distanceTravelled: totalDistance,
              registrationNumber: registrationNumber,
              unit: res?.unit ? res.unit : ''
            };
            groupByRegNumber = {...groupByRegNumber,[carSelected['_id']]:distanceTravelledObj1}
            graphAnalyticsData = {...graphAnalyticsData,graph:{...(graphAnalyticsData?.graph || {}),distanceTravelled:[...(graphAnalyticsData?.graph?.distanceTravelled || []),distanceTravelledObj1]}}
          } else {
            const distanceTravelledObj = {
              distanceTravelled: 'NA',
              registrationNumber: registrationNumber,
              unit: 'Km'
            };
            groupByRegNumber = {...groupByRegNumber,[carSelected['_id']]:distanceTravelledObj}
            graphAnalyticsData = {...graphAnalyticsData,graph:{...(graphAnalyticsData?.graph || {}),distanceTravelled:[...(graphAnalyticsData?.graph?.distanceTravelled || []),distanceTravelledObj]}}
          }

          return {groupByRegNumber,graphAnalyticsData}
    }



    export const plotMMDDistanceTravelledByTimeAndSeriesType =(result,carDistanceMovedStats,title:string,series:string,graphType:string)=>{
      let modcarDistanceMovedStats = {
        ...carDistanceMovedStats,
        distanceMoved: {
          ...carDistanceMovedStats.distanceMoved,
          chartData: {
            ...carDistanceMovedStats.distanceMoved.chartData,
            legends: [], series: [], xAxis: []
          }, title
        },
        pills:{...orderSource.pills,selected:series},
        graphTypePills:{
          arrayOfGraphTypePills:(orderSource?.graphTypePills?.arrayOfGraphTypePills || []).map((type) => {
            return { ...type, isCurrentType: type.id === graphType }
          }).filter(({id})=>(id!=='pie'))
        },
        graphType
      }

      result.forEach(({carSelected,res:data})=>{
        if (data && data.length > 0) {
          let carDistanceMovedArray: Array<any> = [];
          carDistanceMovedArray = sortBy(data, [ (o)=> {
            return o.milliseconds;
          }]);
         
          const carDistanceMovedData = {
            distanceMoved: {
              data: [],
              label: [],
              colour: 'red',
            }
          };
          let totalDistanceTravelled = 0;
          for (let i = 0; i <= carDistanceMovedArray.length - 1; i++) {
            const value = carDistanceMovedArray[i];
            if (value.timeZoneDate) {
              totalDistanceTravelled = totalDistanceTravelled + value.distanceTravelled;
              carDistanceMovedData.distanceMoved.data.push(Math.round(value.distanceTravelled));
              carDistanceMovedData.distanceMoved.label.push(value.timeZoneDate);
  
            }
            if (i === carDistanceMovedArray.length - 1) {
              const chartSeries: EChartSeries = {
                name: carSelected.registrationNumber || carSelected.name,
                type: modcarDistanceMovedStats.graphType,
                data: null,
                stack: false,
                smooth: true,
                label: { normal: { show: false, position: 'top' } },
                areaStyle: { opacity: 0.005 },
                itemStyle: {
                  colour: 'red'
                }
              };
              const statsType = 'distanceMoved';
              const historicalData = carDistanceMovedData['distanceMoved'];
  
              if (historicalData) {
                chartSeries.data = historicalData.data;
              }
  
              const chartData = {
                title: modcarDistanceMovedStats[statsType].title,
                type: modcarDistanceMovedStats.graphType,
                series: chartSeries,
                isDataZoomRequired: true,
                xAxis: [],
                legend: carSelected.registrationNumber || carSelected.name
              };
              if (historicalData) {
                chartData.xAxis = historicalData.label;
              }
              modcarDistanceMovedStats = plotDistanceGraph(chartData,modcarDistanceMovedStats);
            }
          }

        } else {
          const chartSeries: EChartSeries = {
            name: carSelected.registrationNumber || carSelected.name,
            type: modcarDistanceMovedStats.graphType,
            data: null,
            stack: false,
            smooth: true,
            label: { normal: { show: false, position: 'top' } },
            areaStyle: { opacity: 0.005 },
            itemStyle: {
              colour: 'red'
            }
          };
          const chartData = {
            title: modcarDistanceMovedStats['distanceMoved'].title,
            type: modcarDistanceMovedStats.graphType,
            series: chartSeries,
            isDataZoomRequired: true,
            xAxis: [],
            legend: carSelected.registrationNumber || carSelected.name
          };
          modcarDistanceMovedStats = plotDistanceGraph(chartData,modcarDistanceMovedStats);
        }
      })
     return {...modcarDistanceMovedStats,isAnalytics:true,hideCount:true,counts:modcarDistanceMovedStats.distanceMoved}
    }

   export const  mapValuesToKeys=(combinedArray, seriesArray, xAxisArray) =>{
      const valueToKeyMap = {};
      xAxisArray.forEach((value, index) => {
        valueToKeyMap[value] = seriesArray[index];
      });
      const result = combinedArray.map((value) => {
        const key = valueToKeyMap[value];
        return key !== undefined ? key : (key === 0 ? 0 : null);
      });
  
      return result;
    }

    export const plotDistanceGraph =(distanceChartData,carDistanceMovedStats) => {



      const statsType = 'distanceMoved';
      let series = carDistanceMovedStats?.distanceMoved?.chartData?.series || [];
      let legends = carDistanceMovedStats?.distanceMoved?.chartData?.legend || [];
      const xAxisArray = [...(carDistanceMovedStats?.distanceMoved?.chartData?.xAxisUnMod || []),...(distanceChartData.xAxis)];
      const combinedXAxis = [...new Set(xAxisArray)];
      
      combinedXAxis.sort();
        const tempArr = mapValuesToKeys(combinedXAxis, distanceChartData?.series?.data, distanceChartData?.xAxis);
        distanceChartData.series.data = tempArr

        series = [...series,distanceChartData?.series]
        legends = [...legends,distanceChartData?.legend]

        
      carDistanceMovedStats = {...carDistanceMovedStats,
        distanceMoved:{...(carDistanceMovedStats.distanceMoved || {}),chartData:{
          title: carDistanceMovedStats[statsType].title,
          graphTypeChangedRandomNumber:Math.random()*1000,
          type: carDistanceMovedStats.graphType,
          series: series,
          isDataZoomRequired: true,
          xAxis: combinedXAxis.map((value) => dateFormator(value, 'date')).filter(val=>val!=="Invalid date"),
          xAxisUnMod:combinedXAxis,
          legend: legends
        }}
      }
      carDistanceMovedStats['distanceMoved'].showLoader = false;
      return carDistanceMovedStats
    }

    export const buildTempertureAndBuildForLMDGraphs = (dateDisplayFormate,tempHumData) =>{


      let boxStatusGraphs = { ...boxStatusGraph, temperature: { ...boxStatusGraph.temperature }, humidity: { ...boxStatusGraph.humidity } };

      const plotSpecsGraphForLMD = (specsChartData) => {

        const letAllStats = keys(boxStatusGraphs);
        letAllStats.forEach((stats) => {
          const groupedStatData = [];
          groupedStatData.push(specsChartData[stats]);
          const series = boxStatusGraphs[stats].chartData.series || [];
          let xAxis = boxStatusGraphs[stats].chartData.xAxis || [];
          const legends = boxStatusGraphs[stats].chartData.legend || [];
          groupedStatData.forEach((data) => {
            if (data.xAxis && data.xAxis.length > 0) {
              if (xAxis.length === 0) {
                xAxis = data.xAxis;
              } else if (xAxis.length < data.xAxis.length) {
                xAxis = data.xAxis;
              }
            }
            series.push(data.series);
            legends.push(data.legend);
            boxStatusGraphs[stats].chartData = {
              title: boxStatusGraphs[stats].title,
              type: boxStatusGraphs[stats].graphType,
              series: series,
              isDataZoomRequired: true,
              xAxis: xAxis,
              legend: legends
            };
            boxStatusGraphs[stats].showLoader = false;
          });
        });

        return boxStatusGraphs
      }

      tempHumData.forEach(({ res: data, carSelected }) => {

        const carLiveData = {
          temperature: {
            data: [],
            label: [],
            colour: 'red',
            name: 'Temperature'

          },
          humidity: {
            data: [],
            label: [],
            colour: 'red',
            name: 'Humidity'
          },
        }

        if (data && data.length > 0) {

          let carGpsData: any;
          carGpsData = data;
          for (let i = 0; i <= carGpsData.length - 1; i++) {
            const value = carGpsData[i];
            if (value.temperature !== null && value.updated) {
              carLiveData.temperature.data.push(Math.round(value.temperature));
              carLiveData.temperature.label.push(dateFormator(value.updated, dateDisplayFormate));
            }
            if (value.humidity !== null && value.updated) {
              carLiveData.humidity.data.push(Math.round(value.humidity));
              carLiveData.humidity.label.push(dateFormator(value.updated, dateDisplayFormate));
            }

            if (i === carGpsData.length - 1) {
              const allStats = keys(boxStatusGraph);
              const chartsFinalData = {};
              allStats.forEach((statsType) => {
                const historicalData = carLiveData[statsType];
                const chartSeries: EChartSeries = {
                  type: 'line',
                  stack: false,
                  smooth: false,
                  label: { normal: { show: false, position: 'top' } },
                  areaStyle: { opacity: 0.005 },
                  itemStyle: {
                    colour: null
                  },
                  name: carSelected?.name,
                  data: []
                };
                if (historicalData) {
                  chartSeries.name = carSelected?.name;
                  chartSeries.data = historicalData.data;
                  chartSeries.itemStyle.colour = historicalData.name;
                }

                const chartSpecsData = {
                  title: boxStatusGraph[statsType].title,
                  type: 'line',
                  series: chartSeries,
                  legend: carSelected?.name,
                  xAxis: historicalData.label

                };
                chartsFinalData[statsType] = chartSpecsData;
              });
              boxStatusGraphs = {...plotSpecsGraphForLMD(chartsFinalData)};
            }


          }


        }
        else {
          const allStats = keys(boxStatusGraph);
          const chartsFinalData = {};
          allStats.forEach((statsType) => {
            const historicalData = carLiveData[statsType];
            const chartSeries: EChartSeries = {
              type: 'line',
              stack: false,
              smooth: false,
              label: { normal: { show: false, position: 'top' } },
              areaStyle: { opacity: 0.005 },
              itemStyle: {
                colour: null
              },
              name: carSelected?.name,
              data: []
            };
            if (historicalData) {
              chartSeries.name = carSelected?.name;
              chartSeries.data = historicalData.data;
              chartSeries.itemStyle.colour = historicalData.name;
            }
            const chartSpecsData = {
              title: boxStatusGraph[statsType].title,
              type: 'line',
              series: chartSeries,
              legend: carSelected?.name,
              xAxis: historicalData.label

            };
            chartsFinalData[statsType] = chartSpecsData;
          });
          boxStatusGraphs = {...boxStatusGraphs,...plotSpecsGraphForLMD(chartsFinalData)};
        }

      })

      return boxStatusGraphs
    }


    export const changeDMSVehicleDistanceTravelledGraphType = (graphType:string,carDistanceMovedStats)=>{

      if (carDistanceMovedStats.distanceMoved.chartData) {
        const chartData = {
          ...carDistanceMovedStats.distanceMoved.chartData,
          type: graphType, graphTypeChangedRandomNumber: Math.random(),
          series: (carDistanceMovedStats.distanceMoved.chartData.series || []).map(value =>
            value.type ? { ...value, type: graphType } : value
          )
        };
        carDistanceMovedStats = {
          ...carDistanceMovedStats,
          graphType: graphType, distanceMoved: {
            ...carDistanceMovedStats.distanceMoved,
            chartData: chartData, graphTypeChangedRandomNumber: Math.random()
          },
          graphTypePills: {
            arrayOfGraphTypePills: carDistanceMovedStats.graphTypePills.arrayOfGraphTypePills.map((type) => {
              return { ...type, isCurrentType: type.id === graphType }
            })
          }
        }
        carDistanceMovedStats.counts = carDistanceMovedStats.distanceMoved
      }
      return carDistanceMovedStats
    }