import {NgModule} from '@angular/core';
import {MaterialModule} from '../shared/material.module';
import {SharedModule} from '../shared/shared.module';
import {PanelMenuModule} from 'primeng/panelmenu';

import * as Sentry from '@sentry/angular';

@NgModule({
  declarations: [
  ],
  imports: [
    Sentry.TraceModule,
    MaterialModule,
    SharedModule,
    PanelMenuModule
  ],
  exports: [
    MaterialModule,
    SharedModule,
  ]
})
export class CoreModule { }
