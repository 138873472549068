import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "../../services/config.service";
import { IetaResponse } from "./interfaces/IetaResponse";

@Injectable({
    providedIn : 'root'
})
export class EtaCalculationService {
    base = this.configService.appConfig.appBaseUrl;

    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ){ }
     
    getMapETAValue(moduleType){
        return this.http.get<IetaResponse>(`${this.base}deliveryManagement/settings?pReq=${moduleType}.map`);
    }
    
    updateMapETAValue(payload,moduleType){
      const url = `${this.base}deliveryManagement/settings/map/moduleType/${moduleType}`;
      return this.http.put<IetaResponse>(url,payload);
    }
    
}