import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {CONSTANT} from '../../../config/constant';
import { Store } from '@ngrx/store';
import { currentActiveId, selectIsSidebarFullScreen} from '../../../state/Sidebar/sidebar.selector';

const SIDEBAR_ID = CONSTANT.SIDEBAR_IDS.FACE_MATCH_SIDEBAR;

@Component({
  selector: 'app-face-match-sidebar',
  templateUrl: './face-match-sidebar.component.html',
  styleUrls: ['./face-match-sidebar.component.scss']
})
export class FaceMatchSidebarComponent implements OnInit, OnDestroy {
  showContext = true;
  personList: any;
  person;
  isSidebarClosed= true;
  sidebarIsFullScreen$ = this.store.select(selectIsSidebarFullScreen);
  sidebarId = SIDEBAR_ID;
   currentActiveSidebarId$;
   isCurrentIdActive = false;

  @Input() cardData: any;
  @Input() assetConfigDetails: any;
  @Input() isFaceMatchCard: boolean;
  @Input() isFaceDetectedCard: boolean;
  @Input() isAutoRegisteredFaceCard: boolean;
  @Input() isCopyBtnRequired: boolean;
  @Input() isDeleteBtnRequired: boolean;
  @Output() sendFrameImgToFaceMatch = new EventEmitter <string>();
  @Output() sendClickedLocationAndTextToFaceMatch = new EventEmitter();
  @Output() toggleActiveClassEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Output() sendCLickedLocationToFaceMatch = new EventEmitter <string>();
  constructor( private store: Store) { }

  ngOnInit(): void {
    this.currentActiveSidebarId$ = this.store.select(currentActiveId).subscribe(res =>{
      if(res === this.sidebarId)
      this.isCurrentIdActive = true;
      else
      this.isCurrentIdActive = false;
    })
  }

  ngOnDestroy(){
    this.currentActiveSidebarId$?.unsubscribe();
  }

  getClickableProperty() {
    // console.log(event);
  }

  getFrameImgForModal(event) {
    this.sendFrameImgToFaceMatch.emit(event);
  }

  getClickedLocationFromCard(event) {
    this.sendClickedLocationAndTextToFaceMatch.emit(event);
  }

  closeFaceMatchModal() {
    if(!this.isSidebarClosed){
      this.toggleActiveClassEvent.emit(false);
    }
    this.isSidebarClosed = true;
  }
}
