@if (showCameraModule) {
  <div class="webcam-container">
    <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)" (initError)="handleInitError($event)"
            [width]="width" [height]="height">
    </webcam>
    <span class="capture-button" (click)="triggerSnapshot()">
      <img
        src="https://img.icons8.com/external-anggara-glyph-anggara-putra/64/null/external-camera-social-media-interface-anggara-glyph-anggara-putra.png"/>
    </span>
  </div>
}
