import { createAction, props } from "@ngrx/store";
import { IAbility, Account, IKioskThemeName, IKioskThemeType, Role, User, UserProfile, ICategoryIntegration, IRootTypeWithCategory } from "./interfaces/superadminsettings.interface";
import { ThemeObj } from "../../app/settings/kiosk-settings-management/kiosk-settings-management.component";
import { IAllModules } from "../../app/settings/services/account-management.service";

export const getRolesCount = createAction(
    '[superadmin roles] get roles count'
)

export const onRolesCountSuccuss = createAction(
    '[superadmin roles] on roles count succuss',
    props<{ count:number }>()
)

export const onRolesCountFailure = createAction(
    '[superadmin roles] on roles count API failure',
    props<{err}>()
)

export const getRolesDataByPage = createAction(
    '[superadmin roles] get roles data by page',
    props<{skip:number, limit:number,page:number}>()
)

export const onRolesDataByPageSuccuss = createAction(
    '[superadmin roles] on roles data by page succuss',
    props<{ roles:Role[],page:number}>()
)

export const onRolesDataByPageFailure = createAction(
    '[superadmin roles] on roles data by page API failure',
    props<{err}>()
)

export const clearRolesData = createAction(
    '[superadmin roles] clear roles data by page'
)
export const clearAbilityData = createAction(
    '[superadmin roles] clear ability data by page'
)




export const getIndividualRole = createAction(
    '[superadmin roles] get individual role',
    props<{name:string}>()
)

export const onIndividualRoleSuccuss = createAction(
    '[superadmin roles] on individual role succuss',
    props<{ role:Role }>()
)

export const onIndividualRoleFailure = createAction(
    '[superadmin roles] on individual role API failure',
    props<{err}>()
)

export const clearFocusedRole = createAction(
    '[superadmin roles] clear focused role'
)



export const saveNewRole = createAction(
    '[superadmin roles] save new role',
    props<{role:Role,currentLimit:number}>()
)

export const onNewRoleSavedSuccuss = createAction(
    '[superadmin roles] on new role save succuss',
    props<{ status:{message:string,data:Role},currentLimit:number }>()
)

export const onNewRoleSaveFailure = createAction(
    '[superadmin roles] on new role save failure',
    props<{err}>()
)

export const clearRoleCRUDStatusMsg = createAction(
    '[superadmin roles] clear role crud status msg'
)

export const updateRole = createAction(
    '[superadmin roles] update role',
    props<{roleId:string, roleData:Role}>()
)

export const onRoleUpdationSuccuss = createAction(
    '[superadmin roles] on role update succuss',
    props<{ status:{message:string,data:Role} }>()
)

export const onRoleUpdationFailure = createAction(
    '[superadmin roles] on role update failure',
    props<{err}>()
)

export const deleteRole = createAction(
    '[superadmin roles] delete role',
    props<{roleId:string,currentLimit:number}>()
)

export const onRoleDeletionSuccuss = createAction(
    '[superadmin roles] on role delete succuss',
    props<{ status:{message:string,data:Role},roleId:string ,currentLimit:number}>()
)

export const onRoleDeletionFailure = createAction(
    '[superadmin roles] on role delete failure',
    props<{err}>()
)





export const getAccountsCount = createAction(
    '[superadmin accounts] get accounts count'
)

export const onAccountsCountSuccuss = createAction(
    '[superadmin accounts] on accounts count succuss',
    props<{ count:number }>()
)

export const onAccountsCountFailure = createAction(
    '[superadmin accounts] on accounts count API failure',
    props<{err}>()
)

export const getAccountsDataByPage = createAction(
    '[superadmin accounts] get accounts data by page',
    props<{skip:number, limit:number,page:number}>()
)

export const onAccountsDataByPageSuccuss = createAction(
    '[superadmin accounts] on accounts data by page succuss',
    props<{ accounts:Account[],page:number}>()
)

export const onAccountsDataByPageFailure = createAction(
    '[superadmin accounts] on accounts data by page API failure',
    props<{err}>()
)

export const clearAccountsData = createAction(
    '[superadmin accounts] clear accounts data by page'
)

export const getIndividualAccount = createAction(
    '[superadmin accounts] get individual account',
    props<{accountId:string}>()
)

export const onIndividualAccountSuccuss = createAction(
    '[superadmin accounts] on individual account succuss',
    props<{ account:Account }>()
)

export const onIndividualAccountFailure = createAction(
    '[superadmin accounts] on individual account API failure',
    props<{err}>()
)

export const clearFocusedAccount = createAction(
    '[superadmin account] clear focused account'
)

export const updateAccount = createAction(
    '[superadmin accounts] update account',
    props<{accountData:Account}>()
)

export const onAccountUpdationSuccuss = createAction(
    '[superadmin accounts] on account update succuss',
    props<{ account:Account}>()
)

export const onAccountUpdationFailure = createAction(
    '[superadmin accounts] on account update failure',
    props<{err}>()
)


export const saveNewAccount = createAction(
    '[superadmin accounts] save new account',
    props<{account:Account,currentLimit:number}>()
)

export const onNewAccountSavedSuccuss = createAction(
    '[superadmin accounts] on new account save succuss',
    props<{  account:Account,currentLimit:number }>()
)

export const onNewAccountSaveFailure = createAction(
    '[superadmin accounts] on new account save failure',
    props<{err}>()
)


export const deleteAccount = createAction(
    '[superadmin accounts] delete account',
    props<{accountId:string,currentLimit:number}>()
)

export const onAccountDeletionSuccuss = createAction(
    '[superadmin accounts] on account delete succuss',
    props<{ status:{msg:string,data:Account},accountId:string ,currentLimit:number}>()
)

export const onAccountDeletionFailure = createAction(
    '[superadmin accounts] on account delete failure',
    props<{err}>()
)







export const getUsersCount = createAction(
    '[superadmin users] get users count',
    props<{allCount:boolean,searchText?:string, rolesArrayToSting?:string, accountId?:string,isAccountLevel?}>()
)

export const resetUsersCount = createAction(
    '[superadmin users] reset users count'
)

export const onUsersCountSuccuss = createAction(
    '[superadmin users] on users count succuss',
    props<{ count:number }>()
)

export const onUsersCountFailure = createAction(
    '[superadmin users] on users count API failure',
    props<{err}>()
)

export const getUsersDataByPage = createAction(
    '[superadmin users] get users data by page',
    props<{skip:number, limit:number, isAllAccount: boolean, searchText?: string, role?: string, accountId?: string,page:number,isAccountLevel?:boolean}>()
)

export const onUsersDataByPageSuccuss = createAction(
    '[superadmin users] on users data by page succuss',
    props<{ users:User[],page:number}>()
)

export const onUsersDataByPageFailure = createAction(
    '[superadmin users] on users data by page API failure',
    props<{err}>()
)

export const clearUsersData = createAction(
    '[superadmin users] clear users data by page'
)


export const getIndividualUser = createAction(
    '[superadmin users] get individual user',
    props<{userId:string}>()
)

export const onIndividualUserSuccuss = createAction(
    '[superadmin users] on individual user succuss',
    props<{ user:UserProfile }>()
)

export const onIndividualUserFailure = createAction(
    '[superadmin users] on individual user API failure',
    props<{err}>()
)

export const clearFocusedUser = createAction(
    '[superadmin user] clear focused user'
)

export const updateUser = createAction(
    '[superadmin users] update user',
    props<{userId:string,userData:User}>()
)

export const onUserUpdationSuccuss = createAction(
    '[superadmin users] on user update succuss',
    props<{user:UserProfile,updatedData:User}>()
)

export const onUserUpdationFailure = createAction(
    '[superadmin users] on user update failure',
    props<{err}>()
)


export const saveNewUser = createAction(
    '[superadmin users] save new user',
    props<{user:User,currentLimit:number,isAccountLevel?:boolean}>()
)

export const onNewUserSavedSuccuss = createAction(
    '[superadmin users] on new user save succuss',
    props<{ status:{msg:string,obj:User},currentLimit:number }>()
)

export const onNewUserSaveFailure = createAction(
    '[superadmin users] on new user save failure',
    props<{err}>()
)


export const deleteUser = createAction(
    '[superadmin users] delete user',
    props<{userId:string,currentLimit:number}>()
)

export const onUserDeletionSuccuss = createAction(
    '[superadmin users] on user delete succuss',
    props<{ status:{msg:string,data:User},userId:string ,currentLimit:number}>()
)

export const onUserDeletionFailure = createAction(
    '[superadmin users] on user delete failure',
    props<{err}>()
)


export const updatePaginationRecords = createAction(
    '[superadmin] update pagination records',
    props<{tabName:string,page:number}>()
)

export const resetPaginationRecords = createAction(
    '[superadmin] reset pagination records',
    props<{tabName:string}>()
)

export const getThemeNamesFromThemeType = createAction(
    '[superadmin] get themes from theme type',
    props<{themeType:string}>()
)
export const getThemeNamesFromThemeTypeSuccess = createAction(
    '[superadmin] get themes from theme type success',
    props<{res:IKioskThemeType,themeType:string}>()
)

export const setNewThemeNamesFromThemeType = createAction(
    '[superadmin] set themes from theme type',
    props<{payload:ThemeObj}>()
)
export const setNewThemeNamesFromThemeTypeSuccess = createAction(
    '[superadmin] set themes from theme type success',
    props<{res:unknown,themeType:string}>()
)
export const deleteThemesFromThemeId = createAction(
    '[superadmin] delete themes from theme id',
    props<{themeId:string,themeType:string}>()
)

export const deleteThemesFromThemeIdSuccess = createAction(
    '[superadmin] delete themes from theme id success',
    props<{themeId:string,themeType:string}>()
)

export const updateThemesFromThemeId = createAction(
    '[superadmin] update themes from theme id ',
    props<{themeId:string,themeType:string,payload:IKioskThemeName}>()
)

export const updateThemesFromThemeIdSuccess = createAction(
    '[superadmin] update themes from theme id success',
    props<{themeId:string,themeType:string,payload:IKioskThemeName}>()
)

export const resetIsKioskThemeUpdate = createAction(
    '[superadmin] reset kiosk theme update'
)


export const getAllThemesWithTypeId = createAction(
    '[superadmin] all themes from theme id'
    // props<{themeId:string}>()
)

export const getAllThemesWithTypeIdSuccess = createAction(
    '[superadmin] all kiosk theme from id success',
    props<{res:IKioskThemeName[]}>()
)

export const saveAbility = createAction(
    '[superadmin] save ability',
    props<{payload:IAbility}>()
)

export const saveAbilitySuccess = createAction(
    '[superadmin] save ability success',
    props<{res:IAbility}>()
)
export const saveAbilityError = createAction(
    '[superadmin] save ability faliure',
    props<{err}>()
)

export const getAllAbilityCount = createAction(
    '[superadmin] get all  ability count'
)
export const getAllAbilityCountSuccess = createAction(
    '[superadmin] get all  ability count success',
    props<{count:number}>()
)
export const deleteAbility = createAction(
    '[superadmin] delete ability',
    props<{id:string}>()
)
export const deleteAbilitySuccess = createAction(
    '[superadmin] delete ability success',
    props<{id:string}>()
)
export const getAbilityTogether = createAction(
    '[superadmin] get ability',
    props<{id:string,page:number,currentLimit:number}>()
)
export const getAbilityTogetherSuccess = createAction(
    '[superadmin] get ability success',
    props<{id:string,page:number,currentLimit:number}>()
)

export const getAllAbilityWithCount = createAction(
    '[superadmin] get all ability with count ',
    props<{limit:number}>()
)
export const getAllAbilityWithCountSuccess = createAction(
    '[superadmin] get all ability with count success ',
    props<{res:IAbility[]}>()
)
export const getAbilityWithId = createAction(
    '[superadmin] get  ability with id ',
    props<{id:string}>()
)
export const getAbilityWithIdSuccess = createAction(
    '[superadmin] get  ability with id success ',
    props<{res:IAbility}>()
)
export const updateAbilityWithId = createAction(
    '[superadmin] update  ability with id ',
    props<{id:string,payload:IAbility}>()
)
export const updateAbilityWithIdSuccess = createAction(
    '[superadmin] update  ability with id success ',
    props<{id:string,res:IAbility}>()
)
export const getAllAvailableModules = createAction(
    '[superadmin] get all availabel modules'
)
export const getAllAvailableModulesSuccess = createAction(
    '[superadmin] get all availabel modules success',
    props<{res:IAllModules}>()
)

export const getAllCategoriesForIntegration = createAction(
    '[superadmin] get all category integration'
)

export const getAllCategoriesForIntegrationSuccess = createAction(
    '[superadmin] get all category integration success',
    props<{res:ICategoryIntegration[]}>()
)

export const getAllTypesWithCategory = createAction(
    '[superadmin] get all types with category',
    props<{category:string}>()
)

export const getAllTypesWithCategorySuccess = createAction(
    '[superadmin] get all ctypes with category success',
    props<{res:IRootTypeWithCategory}>()
)
