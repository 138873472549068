import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { FaceDetectionService } from '../../../services/face-detection.service';
import { AssetService } from '../../../services/asset.service';
import { AssetConfigService } from '../../../services/asset-config.service';
import {SearchService} from '../../../services/search.service';
import {AttendanceService} from '../../../services/attendance.service';
import {CONSTANT} from '../../../config/constant';
import moment from 'moment-timezone';
import {AssetCountType} from '../../asset-list/asset-count-type';
declare let $: any;
import {forEach, filter} from 'lodash';
import {SplitScreenService} from '../../../services/split-screen.service';
import {LangUtilService} from '../../util/lang-util.service';
import {SetCssPropertiesService} from '../../../services/set-css-properties.service';
import { FaceMatchService } from '../../../services/face-match.service';
import { selectSidebarState } from '../../../state/Sidebar/sidebar.selector';
import { Store } from '@ngrx/store';
import { SocketActions } from '../../../state/Socket';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-face-detected',
  templateUrl: './face-detected.component.html',
  styleUrls: ['./face-detected.component.scss']
})

export class FaceDetectedComponent implements OnInit, OnDestroy {
  @Input() showAddVisitorBtn: boolean;
  @Input() showRegisterThingBtn: boolean;
  storageName = 'face_detected'
  public lastRefreshed: any = new Date();
  isFaceMatchNotFound: boolean;
  isBuildVisitorFormInitiated: boolean;
  securityFaceDetectedNameSpace: string;
  faceDetectionSocketConnection = {};
  faceDetails: any = [];
  showLoader: boolean;
  assetConfigDetails: any;
  filterFields: any = {
    faceMask: 'all',
    assetId: '',
    searchedFaceName: '',
    selectedCameraId: '',
    isFilterApplied: true,
    isFilterFieldChanged: false,
    filterClickedIndex: 0
  };
  // {name: 'Todd Phillips', camera: 'Floor-1', enrolledImg: '../../../assets/FaceMatch-Images/todd-enrolled.jpeg', capturedImg: '../../../assets/FaceMatch-Images/todd-captured.png', contextImg: '../../../assets/FaceMatch-Images/todd-context.png'},
  // {name: 'David Fincher', camera: 'Main',  enrolledImg: '../../../assets/FaceMatch-Images/david-enrolled.jpeg', capturedImg: '../../../assets/FaceMatch-Images/david-captured.png', contextImg: '../../../assets/FaceMatch-Images/david-context.png'},
  // {name: 'Quentin tarantino', camera: 'basement', enrolledImg: '../../../assets/FaceMatch-Images/quentin-enrolled.jpeg', capturedImg: '../../../assets/FaceMatch-Images/quentin-captured.png', contextImg: '../../../assets/FaceMatch-Images/quentin-context.png'},
  // {name: 'David Fincher', camera: 'Main',  enrolledImg: '../../../assets/FaceMatch-Images/david-enrolled.jpeg', capturedImg: '../../../assets/FaceMatch-Images/david-captured.png', contextImg: '../../../assets/FaceMatch-Images/david-context.png'},
  // {name: 'Steven Spielberg', camera: 'Floor-5',  enrolledImg: '../../../assets/FaceMatch-Images/steven-enrolled.jpeg', capturedImg: '../../../assets/FaceMatch-Images/steven-captured.png', contextImg: '../../../assets/FaceMatch-Images/steven-context.png'},
  // {name: 'Christopher Nolan', camera: 'Floor-5',  enrolledImg: '../../../assets/FaceMatch-Images/nolan-enrolled.jpeg', capturedImg: '../../../assets/FaceMatch-Images/nolan-captured.png', contextImg: '../../../assets/FaceMatch-Images/nolan-context.png'},
  // {name: 'Steven Spielberg', camera: 'Floor-5',  enrolledImg: '../../../assets/FaceMatch-Images/steven-enrolled.jpeg', capturedImg: '../../../assets/FaceMatch-Images/steven-captured.png', contextImg: '../../../assets/FaceMatch-Images/steven-context.png'},
  // ];

  dataToSidebar: any;
  showSlider: boolean;
  personList: any = [];
  displayDropDown = false;
  public pagination: any;
  public pageNumber: any;
  public skip: number;
  public limit: number;
  public inputArr: any = [];
  private badgeRefreshIntervalId = null;
  // mapSpecificRanges = {
  //   'Today': [new moment().startOf('days'), new moment()],
  //   'Yesterday': [new moment().subtract(1, 'days').startOf('days'), new moment().subtract(1, 'days').endOf('days')]
  // };
  dateRangePickerOptions = {
    dateLimit: 1,
    ranges: this.lang.graphSpecificRanges_today_yesterday,
    alwaysShowCalendars: true,
    timePicker: true,
    timePickerIncrement: 10,
    maxDate: moment(),
    showCustomRangeLabel: true
  };

  public analyticsData: any = {
    startDate: moment().subtract(29, 'days').startOf('days'),
    endDate: moment()
  };
  cameraList: any;
  personSelected = false;
  personSelectedValue = '';
  splitScreenActive$ = this.store.select(selectSidebarState);
  frameImgForModal: any;
  private searchPlaceholder: string;
  employeeList: any[];
  actionSuccessMessage: string;
  faceMaskOptionList = [
    {label: 'ALL', id: 'all'},
    {label: 'With Mask', id: 'true'},
    {label: 'Without Mask', id: 'false'}
  ];
  showClickedAssetDetails: boolean;
  showCircularLoaderValue: boolean;
  clickedProperty: string;
  assetDetailsToRightSideBar: any;
  selectedData: any;

  public assetCountByType: any = [];
  public selectedAssetConfigDetails: any;
  formData: any;
  public resetAddAssetFormValue: number;
  visitorsTypeList: any = [];
  public assetRegisterErrorMessages: any = [];
  constructor(private store: Store,
              public faceDetectionService: FaceDetectionService,
              public faceMatchService: FaceMatchService,
              private assetService: AssetService,
              private assetConfigService: AssetConfigService,
              private searchService: SearchService,
              private lang: LangUtilService,private messageService: MessageService,
              private splitScreenService: SplitScreenService,
              private attendanceService: AttendanceService, private setCssProperties: SetCssPropertiesService) {
    this.securityFaceDetectedNameSpace = CONSTANT.SOCKET.SECURITY.NAMESPACE.SECURITY_FACE_DETECTED_LIVE;
    this.pageNumber = 1;
    this.pagination = {
      'currentPage' : 1,
      'totalNoOfRecords': 0,
      'recordsPerPage' : 18,
      'pageCountValue' : 0,
    };
    this.doSearchTextResetAndOtherOperations();
    this.searchPlaceholder = this.lang.getTranslation('sys.search')+' '+ this.lang.getTranslation('app.sidemenu.security.face-match');
    this.showLoader = false;
     // this.splitScreenActive = false;
    this.isBuildVisitorFormInitiated = false;
    this.employeeList = [];
  }
  first: number = 0;

  rows: number;

  // autoDetectNewCards() {
  //   let i;
  //   for (i of this.faceDetails) {
  //     i['badgeContent'] = this.parseDateAndGetbadgeContent(i['updated']);
  //   }
  // }

  private doSearchTextResetAndOtherOperations() {
    this.searchService.showSearchBar(false);
    this.searchService.resetSearchTextInSearchBox();
    this.searchService.sendSearchText('');
    this.searchService.sendSearchPlaceholderValue(this.searchPlaceholder);
  }

  parseDateAndGetbadgeContent(dateFromSocketData) {
    let currentDate;
    currentDate = new Date();
    let updatedSocketDate;
    updatedSocketDate = new Date(dateFromSocketData);
    let dateDiff;
    dateDiff = currentDate - updatedSocketDate;
    const mins = Math.floor(dateDiff / 60000);
    let badgeContent;
    (mins >= 5) ? badgeContent = '' : badgeContent = 'N';
    return badgeContent;
  }

  getPersonListForNameFilterOptions(personName) {
    if (personName.length >= 3) {
      this.faceDetectionService.getAllFaceMatchCardsDataOnSearch(personName)
        .subscribe((result) => {
          if (result) {
            this.personList = result;
            this.displayDropDown = true;
          } else {
            this.displayDropDown = false;
          }
        });
    } else {
      this.displayDropDown = false;
      this.filterFields.assetId = '';
    }
  }

  getselectedPersonName(filteredPerson) {
    this.personSelected = true;
    this.filterFields.searchedFaceName = filteredPerson['name'];
    this.personSelectedValue = filteredPerson['name'];
    this.filterFields.assetId = filteredPerson['_id'];
    this.displayDropDown = false;
  }

  onNameFilterBlur() {
    this.personSelected === true ? this.filterFields.searchedFaceName = this.personSelectedValue : this.filterFields.searchedFaceName = '';
    if (this.displayDropDown === true) {
      this.displayDropDown = false;
    }
    this.personSelectedValue = '';
  }

  getCameraList() {
    this.assetService.getCountByTypes(CONSTANT.ASSET_TYPES.CAMERA).subscribe((result) => {
      const cameraCount = result['count'];
      this.assetService.getAllByType(CONSTANT.ASSET_TYPES.CAMERA, 0, cameraCount).subscribe((res) => {
        this.cameraList = res;
      });
    }, () => {});
  }

  getselectedCamera(event) {
    this.filterFields.selectedCameraId = event['value'];
  }

  cardClick(faceData) {
    this.dataToSidebar = faceData;
    this.dataToSidebar = this.splitScreenService.addActiveClass(this.dataToSidebar, this.faceDetails, '_id');
    this.showSlider = true;
    this.splitScreenService.scrollToSelectedCard();
    // this.handleSplitScreen('#sidebar-faceMatch');

  }

  addDetectedFaceClick(faceData) {
    this.dataToSidebar = faceData;
    this.isBuildVisitorFormInitiated = !this.isBuildVisitorFormInitiated;
    this.selectedData = faceData;
    this.handleSplitScreen('#visitor-create-modal');
    //this.splitScreenService.addOrRemoveSplitScreen('addSplitScreen', '#visitor-create-modal');
    // // this.splitScreenActive = true;
  }

  toggleActiveClassEvent() {
    this.splitScreenService.removeActiveClassForAllCards(this.faceDetails);
     // this.splitScreenActive = false;
    this.dataToSidebar = null;
    this.splitScreenService.hideModalAndRemoveSplitScreen();
  }

  refreshBtnClicked() {
    this.resetFilterFields();
    this.getTotalFaceMatchCount();
  }

  getTotalFaceMatchCount() {
    this.getPaginationCountWithoutFilters();
  }

  getAllDataByPageNo(currentPage) {
    /*if (this.filterFields.isFilterApplied) {
      this.getDataByPageNoWithFilters(currentPage);
    } else {
      this.getDataByPageNoWithoutFilters(currentPage);
    }*/
    this.getDataByPageNoWithoutFilters(currentPage);
  }

  getDataByPageNoWithoutFilters(currentPage) {
    let recordsPerPage;
    let skip;
    let faceMask;
    this.pageNumber = currentPage + 1;
    if (currentPage === 0) {
      skip = 0;
      recordsPerPage = this.pagination.recordsPerPage;
    } else {
      skip = (currentPage) * this.pagination.recordsPerPage;
      recordsPerPage = this.pagination.recordsPerPage;
    }
    this.showLoader = true;
    faceMask = this.filterFields.faceMask;
    if (faceMask === 'all') {
      faceMask = '';
    }
    this.faceDetectionService.getFaceMatchCardsBasedOnRange(skip, recordsPerPage, faceMask).subscribe((data) => {
      this.faceDetails = data;
      this.faceDetails.length > 0 ? this.isFaceMatchNotFound = false : this.isFaceMatchNotFound = true;
      this.toggleSplitScreenFunction();
      let i;
      for ( i of this.faceDetails) {
        let dataDate;
        dataDate = i['updated'];
        i['badgeContent'] = this.parseDateAndGetbadgeContent(dataDate);
      }
      this.showLoader = false;
    });
  }

  getDataByPageNoWithFilters(currentPage) {
    let recordsPerPage;
    let skip;
    let startDate: Date;
    let endDate: Date;
    // let name: string;
    // let assetId: string;
    // assetId = this.filterFields.assetId;
    let camera: string;
    startDate = this.analyticsData.startDate;
    endDate = this.analyticsData.endDate;
    camera = this.filterFields.selectedCameraId;
    this.pageNumber = currentPage.pageIndex + 1;
    if (currentPage.pageIndex === 0) {
      skip = 0;
      recordsPerPage = this.pagination.recordsPerPage;
    } else {
      skip = (currentPage.pageIndex) * this.pagination.recordsPerPage;
      recordsPerPage = this.pagination.recordsPerPage;
    }
    this.showLoader = true;
    this.faceDetectionService.getFaceMatchCardsBasedOnRangeWithFilters(skip, recordsPerPage, startDate, endDate, camera).subscribe(result => {
      this.faceDetails = result;
      this.faceDetails.length > 0 ? this.isFaceMatchNotFound = false : this.isFaceMatchNotFound = true;
      this.toggleSplitScreenFunction();
      let i;
      for ( i of this.faceDetails) {
        let dataDate;
        dataDate = i['updated'];
        i['badgeContent'] = this.parseDateAndGetbadgeContent(dataDate);
      }
      this.showLoader = false;
    });
  }

  getPageCount() {
    this.inputArr = [];
    this.pageNumber = 1;
    this.pagination.pageCountValue = Math.ceil((this.pagination.totalNoOfRecords) / this.pagination.recordsPerPage);
    for (let i = 1; i <= this.pagination.pageCountValue; i++) {
      this.inputArr[i] = i;
    }
  }

  getPaginationCountWithoutFilters() {
    let faceMask;
    faceMask = this.filterFields.faceMask;
    if (faceMask === 'all') {
      faceMask = '';
    }
    this.faceDetectionService.getFaceMatchCardsCount(faceMask).subscribe((result) => {
      if (result && result['count']) {
        this.pagination.totalNoOfRecords = result['count'];
        const obj: any = {};
        obj.pageIndex = 0;
        this.getPageCount();
        this.getDataByPageNoWithoutFilters(obj);
        this.lastRefreshed = new Date();
      } else {
        this.faceDetails = [];
        this.isFaceMatchNotFound = true;
        this.showLoader = false;
      }
    });
  }

  public updatePageNumber(index: number): void {
    this.pageNumber = index;
  }

  filterBtnClicked() {
    this.filterFields.filterClickedIndex = this.filterFields.filterClickedIndex + 1;
    if ((this.filterFields.filterClickedIndex % 2 === 0) && !this.filterFields.isFilterApplied) {
      this.searchService.showSearchBar(true);
    } else {
      this.searchService.showSearchBar(false);
    }
  }

  filterFieldsApply() {
    this.filterFields.isFilterApplied = true;
    this.filterFields.isFilterFieldChanged = false;
    // this.isReportsNotFound = false;
    this.getTotalFaceMatchCount();
  }

  resetFilterBtn() {
    this.filterFields.isFilterFieldChanged = true;
  }

  resetFilterFields() {
    if (this.filterFields.isFilterApplied) {
      this.getPaginationCountWithoutFilters();
    }
    this.filterFields.searchedFaceName = '';
    this.filterFields.assetId = '';
    this.filterFields.selectedCameraId = '';
    this.filterFields.isFilterApplied = false;
    this.filterFields.isFilterFieldChanged = true;
  }

  getFrameFromSideBar(event) {
    this.frameImgForModal = event;
  }

  ngOnInit() {
    this.getAssetIndividualCounts();
    this.store.dispatch(SocketActions.updateSocketDataRequired({
      nameSpace: this.securityFaceDetectedNameSpace,
      topicName: CONSTANT.SOCKET.SECURITY.TOPICS.FACE_DETECTED_ALL,
      key: this.storageName,
      callback: this.getFaceDetectedSocketData,
    }));

    this.assetConfigService.getAssetsConfig()
      .then(assetConfig => {
        this.assetConfigDetails = assetConfig;
      });
    this.showLoader = true;
    // this.getCameraList();

    this.getTotalFaceMatchCount();
    // this.badgeRefreshIntervalId = interval(600000).subscribe(() => {
    //   this.autoDetectNewCards();
    // });
    this.showSlider = false;
    this.rows =  this.pagination.recordsPerPage;
  }

  ngOnDestroy() {
    clearInterval(this.badgeRefreshIntervalId);
     // this.splitScreenActive = false;
    this.store.dispatch(SocketActions.disconnect({
      nsp: this.securityFaceDetectedNameSpace,
      topic: CONSTANT.SOCKET.SECURITY.TOPICS.FACE_DETECTED_ALL,
      key: this.storageName
    }));
    this.splitScreenService.hideModalAndRemoveSplitScreen();
  }

  getFaceDetectedSocketData = (faceDetectedData)=>{
    const newFaceDetected = JSON.parse(faceDetectedData);
    // Checking the newly added faceMatchcards for time difference.
    newFaceDetected['badgeContent'] = this.parseDateAndGetbadgeContent(newFaceDetected.updated);

    if (this.filterFields.faceMask === 'true' && newFaceDetected.faceMaskFound === true) {
      this.faceDetails.unshift(newFaceDetected);
      this.faceDetails.pop();
    } else if (this.filterFields.faceMask === 'false' && newFaceDetected.faceMaskFound === false) {
      this.faceDetails.unshift(newFaceDetected);
      this.faceDetails.pop();
    } else if (this.filterFields.faceMask === 'all') {
      this.faceDetails.unshift(newFaceDetected);
      this.faceDetails.pop();
    }
    // Looping the existing faceMatchcards and checking for time difference
    let i;
    for ( i of this.faceDetails) {
      let dataDate;
      dataDate = i['updated'];
      i['badgeContent'] = this.parseDateAndGetbadgeContent(dataDate);
    }
  }
  toggleSplitScreenFunction() {
     // this.splitScreenActive = this.splitScreenService.ToggleSplitScreen();
    if (this.dataToSidebar) {
      this.splitScreenService.addActiveClass(this.dataToSidebar, this.faceDetails, '_id');
    }
  }

  createModalClosedEvent(){
     this.splitScreenService.closeCreateModal();
    $('#sidebar-faceMatch').hide(100);
  }

  saveVisitor(visitor) {
    visitor['faceDetectionId'] = this.dataToSidebar._id;
    this.attendanceService.addVisitor(visitor).subscribe((res: any) => {
      this.actionSuccessMessage = '';
      if (res && res.msg) {
        this.actionSuccessMessage = res.msg;
        this.messageService.add({ key: 'face-detected-toast', severity: 'success', summary: 'Success', detail: this.actionSuccessMessage  });
         this.splitScreenService.closeCreateModal();
      }
    });
  }

  getSelectedFaceMaskOption() {
    // this.filterFields.faceMask = option;
    this.getPaginationCountWithoutFilters();
  }

  getCardDataAndClickedProperty(event) {
    this.showSlider = true;
    this.showClickedAssetDetails = true;
    this.clickedProperty = event.clickedProperty;
    this.selectedData = event.clickedCardData;
    this.assetDetailsToRightSideBar = null;
    if (this.clickedProperty === 'image clicked') {
      $('#sidebar-right').hide(100);
      this.handleSplitScreen('#sidebar-faceMatch');
    } else if (this.clickedProperty === 'camera clicked') {
      $('#sidebar-faceMatch').hide(100);
      this.showCircularLoaderValue = true;
      if (this.selectedData && this.selectedData['camera']) {
        this.handleSplitScreen('#sidebar-right');
        this.faceMatchService.getAssetDetailsById(this.selectedData['camera']['_id'])
          .subscribe(res => {
            if (res) {
              this.assetDetailsToRightSideBar = res;
              this.showCircularLoaderValue = false;
            } else {
              this.showClickedAssetDetails = false;
              this.showCircularLoaderValue = false;
            }
          });

      } else {
        this.showClickedAssetDetails = false;
        this.showCircularLoaderValue = false;
         // this.splitScreenActive = false;
        this.splitScreenService.removeActiveClassForAllCards(this.faceDetails);
        this.splitScreenService.hideModalAndRemoveSplitScreen();
        this.setCssProperties.showMessagePopup('cameraNotRegistered');
      }
    } else if (this.clickedProperty === 'name clicked') {
      $('#sidebar-faceMatch').hide(100);
      this.showCircularLoaderValue = true;
      if (this.selectedData && this.selectedData['assetDetected']['_id']) {
        this.handleSplitScreen('#sidebar-right');
        this.faceMatchService.getAssetDetailsById(this.selectedData['assetDetected']['_id'])
          .subscribe(res => {
            if (res) {
              this.assetDetailsToRightSideBar = res;
              this.showCircularLoaderValue = false;
            } else {
              this.showClickedAssetDetails = false;
              this.showCircularLoaderValue = false;
            }
          });
      } else {
        this.showClickedAssetDetails = false;
        this.showCircularLoaderValue = false;
         // this.splitScreenActive = false;
        this.splitScreenService.removeActiveClassForAllCards(this.faceDetails);
        this.splitScreenService.hideModalAndRemoveSplitScreen();
        this.setCssProperties.showMessagePopup('faceNotRegistered');
      }
    }
  }

  handleSplitScreen(targetModal) {
     // this.splitScreenActive = true;
    this.splitScreenService.scrollToSelectedCard();
    this.selectedData = this.splitScreenService.addActiveClass(this.selectedData, this.faceDetails, '_id');
    this.splitScreenService.addOrRemoveSplitScreen('addSplitScreen', targetModal);
  }

  getAssetIndividualCounts() {
    this.assetService.getAssetCount()
      .subscribe((assetIndividualCount: AssetCountType) => {
        if (assetIndividualCount['count'] && assetIndividualCount['count'].length > 0) {
          this.assetCountByType = assetIndividualCount['count'];
          this.buildVisitorsTypeList(this.assetCountByType);
        }
      });
  }

  buildVisitorsTypeList(assetCountByType) {
    const that = this;
    forEach(assetCountByType, function(value) {
      if (value.config && value.config.fileUpload && value.config.fileUpload.profilePhoto && value.config.fileUpload.profilePhoto.faceMatch) {
        that.visitorsTypeList.push(value);
      }
    });
  }

  addNewAsset(selectedAsset) {
    this.showSlider = false;
    this.selectedData = selectedAsset['faceData'];
    this.handleSplitScreen('#notification-event-to-add');
    // // this.splitScreenActive = true;
    //this.splitScreenService.addOrRemoveSplitScreen('addSplitScreen', '#notification-event-to-add');

    const filteredArray = filter(this.assetCountByType, function(item) {
      return item['label'] === selectedAsset['event']['label'];
    });
    this.selectedAssetConfigDetails = filteredArray[0];
    this.formData = this.selectedAssetConfigDetails['configuration'];
    this.resetAddAssetFormValue = Math.random();
  }

  onNotificationEventSubmit(event) {
    if (event.action === 'save') {
       this.saveAsset(event.value);
    }
  }

  resetOnModelClosed() {
     this.splitScreenService.closeCreateModal();
    this.splitScreenService.removeActiveClassForAllCards(this.faceDetails);
  }

  saveAsset(formData) {
    this.assetService.saveAssetDetails(formData)
      .subscribe((savedAssetValue: any) => {
          this.savedAssetCardToAssetList();
          $('#notification-event-to-add').modal('hide');
          this.assetService.registerDetectedFaceImage(savedAssetValue._id, this.selectedData._id)
            .subscribe((res: any) => {
              this.actionSuccessMessage = '';
              if (res && res.msg) {
                this.actionSuccessMessage = res.msg;
                this.messageService.add({ key: 'face-detected-toast', severity: 'success', summary: 'Success', detail: this.actionSuccessMessage  });
              }
          });
        },
        err => {
          if (err.status === 405 ) {
            this.assetRegistrationErrorMsgToAssetList(err['error']['message']);
            this.messageService.add({ key: 'face-detected-toast', severity: 'error', summary: 'Error', detail: err?.error?.message });
          }
        });
  }

  savedAssetCardToAssetList() {
    this.setCssProperties.showMessagePopup('savedAssetSuccessfully');
     this.splitScreenService.closeCreateModal();
  }

  assetRegistrationErrorMsgToAssetList(errMsg: string) {
    this.assetRegisterErrorMessages = [];
    this.assetRegisterErrorMessages.push(errMsg);
    document.getElementById('assetRegisterErrorMsgBtn').click();
    $('#sidebar-right-to-add').modal('hide');
  }

  onPageChange(event) {
    this.rows = event.rows;
    this.getAllDataByPageNo(event.page);
  }
}
