import {Component, Input, OnInit, Output ,EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {AssetService} from "../../../../services/asset.service";
import { Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ConfigService} from "../../../../services/config.service";
import {BeaconGatewayService} from "../../../../services/beacon-gateway.service";
import {RfidGatewayService} from "../../../../services/rfid-gateway.service";
import {ServiceLinkableService} from '../../../../services/serviceLinkable.service';
declare  var $: any;
import {forEach} from 'lodash';
import {DateRangePickerService} from '../../../../services/date-range-picker.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.scss']
})


export class AssetFormComponent implements OnChanges, OnInit {
  Object = Object;
  form: UntypedFormGroup;
  defaultFields: string[] = [];
  datePickerVariables = {
    singleDatePicker : this.dateRangePickerService.singleDatePicker_Variabes.singleDatePicker,
    showDropdowns : this.dateRangePickerService.singleDatePicker_Variabes.showDropdowns,
    autoApply: this.dateRangePickerService.singleDatePicker_Variabes.autoApply,
    locale : this.dateRangePickerService.singleDatePicker_Variabes.locale,
    maxDate : this.dateRangePickerService.singleDatePicker_Variabes.getMaxDate(),
    minDate : this.dateRangePickerService.singleDatePicker_Variabes.getMinDate()
  }
  @Input() formConfigData: any;
  @Input() assetSelectedType: any;
  @Input() isPreFilledRequired: boolean;
  @Output() savedAssetCard: EventEmitter<any> = new EventEmitter();
  @Output() assetRegistrationErrorMsg: EventEmitter<string> = new EventEmitter();
  @Output() closeModalEvent: EventEmitter<boolean> = new EventEmitter();
  constructor(public assetService: AssetService,
              public configService: ConfigService,
              public beaconGatewayService: BeaconGatewayService,
              public rfidGatewayService: RfidGatewayService,
              private serviceLinkableService: ServiceLinkableService, private fb: UntypedFormBuilder,
              private dateRangePickerService: DateRangePickerService) {

  }
  submitConfigDetails() {
    //this.getValuesForDefaultFields(this.form.value);
    this.formateDateField(this.getValuesForDefaultFields(this.form.value));
    if (this.assetSelectedType && this.assetSelectedType === 'general') {
      this.saveServiceAssetDetails();
    } else {
      this.saveAssetDetails();
    }
  }

  getValuesForDefaultFields(formValues) {
    let _this = this;
    forEach(this.defaultFields, function(field) {
      formValues[field] = _this.form.get(field).value;
    });
    return formValues;
  }

  formateDateField(formValues) {
    for (let key in formValues) {
      if (formValues[key] && (typeof formValues[key] === 'object')) {
        if (formValues[key]['startDate']) {
          formValues[key] = formValues[key]['startDate'];
        }
      }
    }
  }

  saveServiceAssetDetails() {
    this.form.value['serviceType'] = this.assetSelectedType;
    this.serviceLinkableService.saveServiceAssetDetails(this.form.value)
      .subscribe((savedServiceAssetValue: any) => {
        // $('#sidebar-right-to-add').modal('hide');
        // $('html').css('overflow-y', 'auto');
        this.closeModalEvent.emit(true);
        this.savedAssetCard.emit(savedServiceAssetValue);
      },
        err => {
          if (err.status === 405 ) {
            this.assetRegistrationErrorMsg.emit(err['error']['message']);
          }
        });
  }

  saveAssetDetails() {
    this.form.value['assetType'] = this.assetSelectedType;
    this.assetService.saveAssetDetails(this.form.value)
      .subscribe((savedAssetValue: any) => {
          // $('#sidebar-right-to-add').modal('hide');
          // $('html').css('overflow-y', 'auto');
        this.closeModalEvent.emit(true);
          if (this.configService.appConfig.beaconGateway.BEACON_GATEWAY_ASSET_NAME === this.assetSelectedType) {
            let url = savedAssetValue.gatewayProtocol + '://' + savedAssetValue.gatewayIpAddress + ':' + savedAssetValue.gatewayPortNumber;
            this.beaconGatewayService.saveBeaconGatewaySetting(url, this.form.value)
              .subscribe((res: any) => {
                  this.savedAssetCard.emit(res);
                },
                err => {
                  this.savedAssetCard.emit(err);
                });
          }
          if (this.configService.appConfig.rfidGateway.RFID_GATEWAY_ASSET_NAME === this.assetSelectedType) {
            let url = savedAssetValue.gatewayProtocol + '://' + savedAssetValue.gatewayIpAddress + ':' + savedAssetValue.gatewayPortNumber;
            this.rfidGatewayService.saveRFIDGatewaySetting(url, this.form.value)
              .subscribe((res: any) => {
                  this.savedAssetCard.emit(res);
                },
                err => {
                  this.savedAssetCard.emit(err);
                });
          }
          this.savedAssetCard.emit(savedAssetValue);
        },
        err => {
          if (err.status === 405 ) {
            this.assetRegistrationErrorMsg.emit(err['error']['message']);
          }
        });
  }

  ngOnInit() {
    this.formConfigData = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'formConfigData') {
        if (change.currentValue !== change.previousValue) {
          if (this.isPreFilledRequired) {
            this.preFillTheForm(this.formConfigData, false);
          } else {
            this.createForm(this.formConfigData);
          }
        }
      }
    }
  }

  preFillTheForm(formConfigData, isDisabled) {
    let _this = this;
    let formObject = {};
    this.defaultFields = [];
    forEach(formConfigData, function(value) {
      switch (value.type) {
        case ('text' || 'textarea' || 'number' || 'email' || 'dropDown'): {
          formObject[value.field] =  _this.pushInputValues(value, isDisabled);
          break;
        }
        case 'date': {
          formObject[value.field] =  _this.pushDateInputValues(value, isDisabled);
          break;
        }
        default: {
          formObject[value.field] =  _this.pushInputValues(value, isDisabled);
          break;
        }
      }
    });
    this.form = this.fb.group(formObject);
  }

  pushInputValues(value, isDisabled) {
    let _this = this;
    let innerArray: any = [];
    let outerArray: any = [];
    if (value.required) {
      innerArray.push(Validators.required);
    }
    if (value.fieldValue && value.default) {
      _this.defaultFields.push(value.field);
      outerArray.push({'value': value.default, disabled: true});
    } else if (value.fieldValue) {
      outerArray.push({'value': value.fieldValue, disabled: isDisabled});
    } else {
      outerArray.push({'value': null, disabled: isDisabled});
    }
    outerArray.push(innerArray);
    return outerArray;
  }
  pushDateInputValues(value, isDisabled) {
    let _this = this;
    let innerArray: any = [];
    let outerArray: any = [];
    if (value.required) {
      innerArray.push(Validators.required);
    }
    if (value.fieldValue && value.default) {
      _this.defaultFields.push(value.field);
      outerArray.push({'value': {'startDate': moment(value.default), 'endDate': moment(value.default)}, disabled: true});
    } else if (value.fieldValue) {
      outerArray.push({'value': {'startDate': moment(value.fieldValue), 'endDate': moment(value.fieldValue)}, disabled: isDisabled});
    } else {
      outerArray.push({'value': null, disabled: isDisabled});
    }
    outerArray.push(innerArray);
    return outerArray;
  }

  createForm(formConfigData) {
    let formObject = {};
    this.defaultFields = [];
    let _this = this;
    forEach(formConfigData, function(value) {
      let innerArray: any = [];
      let outerArray: any = [];
      if (value.required) {
        innerArray.push(Validators.required);
      }
      if (value.default) {
        _this.defaultFields.push(value.field);
        outerArray.push({'value': value.default, disabled: true});
      } else {
        outerArray.push(null);
      }
      outerArray.push(innerArray);
      formObject[value.field] = outerArray;
    });
    this.form = this.fb.group(formObject);
  }

}
