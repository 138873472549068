import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";

export const selectSettings = (state:AppState) => state.settings

export const getSlotSettingsData = createSelector(
    selectSettings,
    (settings) => settings.slotSetting
)

export const getSlotSettingsLoader = createSelector(
    selectSettings,
    (settings) => settings.showLoader
)


export const getTableFilterDate = createSelector(
    selectSettings,
    (settings) => settings.tableFilter
)

export const getTableSortField = createSelector(
    selectSettings,
    (settings) => settings.tableSort
)

export const getTableFields = createSelector(
    selectSettings,
    (settings) => settings.tableFields
)

export const getFormSectionsToDisplay = createSelector(
    selectSettings,
    (settings) => settings.formSections
)
export const getSuccessMessage = createSelector(
    selectSettings,
    (settings) => settings.msg
)
export const getNotifyRecipientOptionsSelector = createSelector(
    selectSettings,
    (settings) => settings.notifyRecipientOptions
)

export const getReportSettingSelector = createSelector(
    selectSettings,
    (settings)=> settings.reportSetting
)
export const getQueueSettingSelector = createSelector(
    selectSettings,
    (settings)=> settings.queueSetting
)
export const getRoundTripSettingSelector = createSelector(
    selectSettings,
    (settings)=> settings.roundTripSetting
)
export const getObdSettingSelector = createSelector(
    selectSettings,
    (settings)=> settings.obdSetting
)
export const getAssignmentWindowSettingSelector = createSelector(
    selectSettings,
    (settings)=> settings.windowAssignmentSetting
)
export const getMmdConsignmentAssignFormSelector = createSelector(
    selectSettings,
    (settings)=> settings.mmdConsignmentAssignForm
)
// export function slotSetting(slotSetting: any): import("rxjs").OperatorFunction<object, any> {
//   throw new Error('Function not implemented.');
// }

export const selectorO2DProcessorSettings = createSelector(selectSettings,
  (state) => state.processorSetting)

  export const selectorO2DPropertiesSettings = createSelector(selectSettings,
    (state) => state.properties)

export const selectProcessorsList = createSelector(
  selectSettings,
  (state) => state.processorsList
)

export const selectorFormMetaData = createSelector(selectSettings,
  (state) => state.mmdConsignmentFormMetaData)

  export const selectorFormMetaDataForLMD = createSelector(selectSettings,
    (state) => state.lmdFormMetaData)

export const selectorMMDProgressData = createSelector(selectSettings,
    (state) => state.mmdProgressSettings)


export const selectorLMDProgressData = createSelector(selectSettings,
    (state) => state.lmdProgressSettings)

export const getMetaDataForDMSSettingsSelector = createSelector(selectSettings,
    (state)=> state.genericMetaDataSettingsForDms
)

export const getAllSettingsForDMSSettingsSelector = createSelector(selectSettings,
    (state)=> state.genericDMSSettingsWithModuleType
)
export const showLoaderForDMSGenericSetting = createSelector(
    selectSettings,
    (state)=>state.genericSettingUpdateLoader
  )

export const getGeofenceSettings = createSelector(
    selectSettings,
    (state)=>state.geofenceSettings
)
export const getGroupsSettingsWithModule = createSelector(
    selectSettings,
    (state)=>state.groupsOfSetttingsWithModule
)
export const groupLoaderForGroupDMSSettings = createSelector(
    selectSettings,
    (state)=>state.groupLoaderForDMSSettings
)

export const allowedStatusesSettingsByModuleSelector = ({moduleType})=> createSelector(
    selectSettings, (state) => state.allowedStatusesForModule?.[moduleType]
)

export const allowedStatusesSettingsSelector = createSelector(
    selectSettings,
    (state)=>state.allowedStatusesForModule
)

export const selectorRoutePlanCategoryList = createSelector(
  selectSettings, 
  (state) => state.routePlanCategoryList
)

export const getTableActionSettings = createSelector(
    selectSettings,
    (state)=> state.genericStateStoreForAllSettings?.['o2d']?.tableActions
)

// Generic Selectors for module settings

export const getAllSettingsForSelectedModuleFromSelector = ({moduleType}) => createSelector(
    selectSettings,
    (state)=>state.genericStateStoreForAllSettings?.[moduleType]
)

export const getAllSettingsForAllModuleFromSelector = createSelector(
    selectSettings,
    (state)=>state.genericStateStoreForAllSettings
)


export const getMetaDataSettingsForAllModuleFromSelector = createSelector(
    selectSettings,
    (state)=>state.genericStateStoreForMetaDataSettings
)

export const getMetaDataSettingsForSelectedModuleFromSelector = ({moduleType}) => createSelector(
    selectSettings,
    (state)=>state.genericStateStoreForMetaDataSettings?.[moduleType]
)

export const getGroupTypesForSelectedModuleFromSelector = ({moduleType}) => createSelector(
    selectSettings,
    (state)=>state.genericStateStoreForSettingsGroups?.[moduleType]
)

export const getGroupTypesForSelectedAllFromSelector = createSelector(
    selectSettings,
    (state)=>state.genericStateStoreForSettingsGroups
)
