// import { communicationSelector } from "@ngrx/store";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";

export const selectCommunication = (state:AppState) => state.communication

export const getCommunicationDetails = createSelector(
  selectCommunication,
    (communication) => communication.communicationDetails
)

export const getAnyError = createSelector(
  selectCommunication, 
  (communication)=>communication.error
)

export const showLoader = createSelector(
  selectCommunication, 
  (communication)=>communication.showLoader
)

export const getCommunicationModeCountResponse = createSelector(
  selectCommunication,
    (communication) => communication.communicationModeCountResponse
)