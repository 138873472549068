<!-- <div class="searchBoxGrid">
<div class="md-form">
  <span (click)="clearSearchedText()" *ngIf="searchText" class="cursorPointer">
    <i class="pi pi-times clear"></i>
  </span>
  <span>
    <i class="pi pi-search"></i>
  </span>
  <input (ngModelChange)="searchForSelectedText($event)" [(ngModel)]="searchText" class="form-control searckBox" id="searchBox"
    name="searchForAsset" placeholder={{getLangLabelForSearch(placeholderValue)}}
    type="text">
</div>
</div> -->
<!-- <div class="searchBoxGrid">
  <span class="p-input-icon-left p-input-icon-right full-width">
    <i class="pi pi-search icon-hard"></i>
    <input [(ngModel)]="searchText" class="form-control searckBox" id="searchBox" (input)="handleInputChange($event)"
           name="searchForAsset" placeholder={{getLangLabelForSearch(placeholderValue)}}
           type="text">
    @if (searchText) {
      <i (click)="clearSearchedText()" class="pi pi-times clear icon-hard" style="cursor: pointer;"></i>
    }
  </span>

</div>
  @if (searchText && searchDurationModel && showSearchDuration$ | async) {
  <div>
    <span>Searched for last {{searchDurationModel}} days</span>
    <i (click)="confirm($event)" class="pi pi-pencil" style="font-size: 14px;cursor: pointer;"></i>
    <i (click)="clearSearchedDuration()" class="pi pi-times clear icon-hard" style="cursor: pointer;"></i>
    <p-confirmPopup key="duration-search-popup">
      <ng-template pTemplate="content" let-message>
        <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border p-3">
          <span class="p-float-label">
            <input type="number" id="searchDuration" pInputText placeholder="Search Duration in days"
              [(ngModel)]="searchDurationModel" />
            <label for="searchDuration">Search Duration In Days</label>
          </span>
        </div>
      </ng-template>
    </p-confirmPopup>
  </div>
  } -->
  
<p-inputGroup class="flex">
    <div class="input-wrapper">
      <input type="text" pInputText name="searchForAsset" placeholder={{getLangLabelForSearch(placeholderValue)}}
        id="searchBox" class="inputSearch" [(ngModel)]="searchText" (input)="handleInputChange($event)" />
      @if (searchText) {
      <i (click)=" clearSearchedText()" class="pi pi-times clear-icon"></i>
      <small class="search-info">Searched For {{searchDurationModel ? 'Last '+ searchDurationModel + ' Days' :'All Time'}}</small>
    }
  </div>
  @if (showSearchDuration$ | async) {
    <p-inputGroup class="flex">
      <p-dropdown [options]="filterValues" (onChange)="handleFilterChange($event)" [group]="true"
        [(ngModel)]="searchDurationModel" optionLabel="label" optionValue="id" [appendTo]="'body'">
        <ng-template pTemplate="selectedItem">
          <i class="pi pi-sliders-h filterIcon" style="font-size: 14px;"></i>
          <label style="margin-left: 5px;cursor:pointer" class="labelDays">{{searchDurationModel ? searchDurationModel + ' Days' :
            'All'}}</label>
        </ng-template>
      </p-dropdown>
    </p-inputGroup>
  }
</p-inputGroup>