import {Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DateFormatterService} from '../../../services/dateformat';
import {LangUtilService} from '../../util/lang-util.service';
import {CONSTANT} from '../../../config/constant';

@Component({
  selector: 'app-details-card',
  templateUrl: './details-card.component.html',
  styleUrls: ['./details-card.component.scss']
})
export class DetailsCardComponent implements OnInit, OnChanges {
  @Input() cardData: any;
  @Input() fieldsToBeDisplayed: any;
  @Input() cardLabel: string;
  @Input() showDeleteButton: boolean;
  @Input() cardClickable: boolean;
  @Input() imageCard: boolean;
  @Input() showToggleBtn: boolean;
  @Input() showLock: boolean = false
  @Output() editCardDataEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteBtnClickedEvent: EventEmitter<any> = new EventEmitter();
  @Output() cardDetailsWithStatusEnabledToParent: EventEmitter<any> = new EventEmitter();
  @Output() missingFieldsForPopup: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedId:string;
  isStatusEnabled: boolean;
  constructor(public dateFormatter: DateFormatterService, private lang: LangUtilService) {
    this.isStatusEnabled = false;
    this.showToggleBtn = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'cardData') {
        if (change.currentValue !== change.previousValue) {
          if (this.cardData) {
            this.cardLabel = this.cardData['cardLabel'] ? this.cardData['cardLabel'] :  this.cardData[this.cardLabel];
            if (this.cardData.status) {
              this.checkStatus(this.cardData.status);
            }
          }
        }
      }
      // if (propName === 'cardLabel') {
      //   if (this.cardData) {
      //     this.cardLabel = this.cardData[this.cardLabel];
      //   }
      // }
    }
  }

  getLocaleValue(field){
    return this.lang.getTranslation('sys.' + field);
  }

  formateCamelCaseToSentence(camelCase) {
    const str = camelCase.toString();

    // adding space between strings
    const result = str.replace(/([a-z])([A-Z])/g, '$1 $2');

    // converting first character to uppercase and join it to the final string
    const final = result.charAt(0).toUpperCase() + result.slice(1);
    return final;
  }

  checkStatus(status) {
    if (status === 'enable') {
      this.isStatusEnabled = true;
    } else {
      this.isStatusEnabled = false;
    }
  }

  editCardData() {
    if (this.cardClickable) {
      this.editCardDataEvent.emit(this.cardData);
    }
  }
  deleteBtnClicked() {
    this.deleteBtnClickedEvent.emit(this.cardData);
  }
  checkType(field) {
    return Array.isArray(field) ? 'array' : typeof(field);
  }

  statusToggle(event) {
    let eventObj = {
      event: null,
      checked: null
    };
    eventObj.event = this.cardData;
    eventObj.checked = event.checked;
    this.cardDetailsWithStatusEnabledToParent.emit(eventObj);
  }

  getIcon(type) {
    switch (type) {
      case CONSTANT.ASSET_TYPES.CAR:
        return '../../assets/asset-icons/taxi.png';
      case CONSTANT.ASSET_TYPES.OBD_DEVICE:
        return '../../assets/asset-icons/obd.png';
      case CONSTANT.ASSET_TYPES.DRIVER:
        return '../../assets/asset-icons/driver.png';
      case CONSTANT.ASSET_TYPES.BEACON:
        return '../../assets/asset-icons/beacon.png';
      case CONSTANT.ASSET_TYPES.RF_ID:
        return '../../assets/asset-icons/rfId.png';
      case CONSTANT.ASSET_TYPES.BEACON_GATEWAY:
        return '../../assets/asset-icons/beaconGateWay.png';
      case CONSTANT.ASSET_TYPES.RFID_GATEWAY:
        return '../../assets/asset-icons/rfIdGateWay.png';
      case CONSTANT.ASSET_TYPES.SERVER:
        return '../../assets/asset-icons/server.png';
      case CONSTANT.ASSET_TYPES.CAMERA:
        return '../../assets/asset-icons/cameraActive.png';
      case CONSTANT.ASSET_TYPES.FINGER_PRINT_SENSOR:
        return '../../assets/asset-icons/fingerPrintSensorActive.png';
      case CONSTANT.ASSET_TYPES.PERSON:
        return '../../assets/asset-icons/personActive.png';
      case CONSTANT.ASSET_TYPES.DOOR:
        return '../../assets/asset-icons/doorActive.png';
      case CONSTANT.ASSET_TYPES.NFC_CARD:
        return '../../assets/asset-icons/nfc-card.png';
      default:
        return '../../assets/exclamation-white.png';
    }
  }


  ngOnInit(): void {
  }

  getLangLabel(labelCode: string) {
    return this.lang.getTranslation(labelCode);
  }

  emitMissingFields(missingFieldsObj) {
    this.missingFieldsForPopup.emit(missingFieldsObj);
  }
}
