import { Slot } from "./interfaces/orderSettings.interface"
import moment from "moment"


//Function for sorting

export function sortTimeSlots(arr:Slot[]){
  if (arr?.length > 1) {
    let clonedArr = [...arr]

    clonedArr.sort((a:Slot,b:Slot)=>{
        let startTimeA:any = moment(a.value.split(" - ")[0],'hh A')
        let startTimeB:any = moment(b.value.split(" - ")[0],'hh A')
        return startTimeA - startTimeB
    })
    return clonedArr
  }else{
    return arr
  }
}

export function toTitleCase(str: string) {
  if (typeof str == 'string') { 

    if (str?.length > 0) {
      
      const words = str.toLowerCase().split(' ');
      const titleCaseWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
        
      });
      
      return titleCaseWords.join(' ');
    }
  }
}










