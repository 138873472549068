import { createAction, props} from "@ngrx/store";
import { CommunicationDetailsPayload, CommunicationDetailsResponse, CommunicationModeCountResponse } from "./interfaces/communication.modal";
  
export const loadCommunicationList = createAction(
    "[Communication Store API] load communication list",
    props<{ payload: CommunicationDetailsPayload }>()
);

export const loadCommunicationListWithPaginationSuccess = createAction(
    "[Communication Module] load communication list and pagination success",
    props<{ response: CommunicationDetailsResponse }>()
)

export const loadCommunicationListFailureMethod = createAction(
    '[Effect Communication Store API] on failure loadCommunicationListFailure',
    props<{err : Error}>()
);

export const loadCommunicationModeCount = createAction(
    "[Communication Store API] load communication mode count",
    props<{ payload: CommunicationDetailsPayload }>()
);

export const loadCommunicationModeCountSuccess = createAction(
    "[Communication Module] load communication mode count success",
    props<{ response: CommunicationModeCountResponse }>()
)

export const changeShowLoaderValue = createAction(
    '[Effect communication showLoader] on success showLoaderUpdate',
    props<{show : boolean}>()
)