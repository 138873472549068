import {AppState} from '../app.state';


export const getTimeLineContent = (state: AppState) => state.timeline.timelineData;

export const timelineLoader = (state: AppState) => state.timeline.timelineLoading;

export const timeLineTrackingStatus = (state: AppState) => state.timeline.isTrackingEnabled;

export const isTimelineDataFitlered = (state: AppState) => state.timeline.isDataFiltered;
// export const selectAssignedCarrierData = (state: AppState) => state?.timeline?.assignedCarrierData;
