<div (click)="onLayoutClick()" [ngClass]="{'layout-wrapper-overlay-sidebar': isOverlay(),
                 'layout-wrapper-slim-sidebar': isSlim(),
                 'layout-wrapper-horizontal-sidebar': isHorizontal(),
                 'layout-wrapper-overlay-sidebar-active': overlayMenuActive,
                 'layout-wrapper-sidebar-inactive': staticMenuDesktopInactive,
                 'layout-wrapper-sidebar-mobile-active': staticMenuMobileActive,
                 'p-ripple-disabled': !app.ripple,
                 'p-input-filled': app.inputStyle === 'filled'}"
     class="layout-wrapper"
>
  @if (app.router.url != '/login' && app.isUserAuthenticated) {
    <app-topbar
    ></app-topbar>
  }

  @if (app.router.url != '/login' && app.isUserAuthenticated) {
    <app-megamenu
    ></app-megamenu>
  }

  @if (app.router.url != '/login' && app.isUserAuthenticated) {
    <div
      class="layout-sidebar"
      (click)="onMenuClick()"
    [ngClass]="{
      'layout-sidebar-dark': app.menuMode !== 'light',
      'layout-sidebar-gradient': app.menuMode === 'gradient'
    }"
    >
      <div class="sidebar-scroll-content">
        <div [ngClass]="{ 'scroll-side-menu': !(isSlim() || isHorizontal()) }">
          <div>
            @if (app.profileMode == 'inline' && !isHorizontal()) {
              <app-inline-profile
              ></app-inline-profile>
            }
          </div>
          <div style="flex-grow: 1">
            <app-menu></app-menu>
          </div>
          @if (!isHorizontal()) {
            <div class="lower-menu">
              <app-lower-menu [iotZenServerVersion]="iotZenServerVersion$ | async"></app-lower-menu>
            </div>
          }
        </div>
      </div>
      <!-- <div class="powerBy">
      <div class="iotZen-menu-logo-grid"><img class="iotZen-menu-logo" src="../assets/iot_zen_logo.png" width="30px" height="45px"></div>
      <div class="powerByText">IoTZen</div>
    </div> -->
  </div>
  }

  @if (app.router.url != '/login' && app.isUserAuthenticated) {
  <app-rightpanel
    [pushNotificationsEnabled]="(pushNotificationsEnabled$ | async)"
  >
  </app-rightpanel>
  }
  <div class="layout-main"
       [ngClass]="{login: app.router.url === '/login' || !app.isUserAuthenticated}">

    <!--Banner Message  -->
    <!-- {{accountLicenseExpiryAlert | async}}
    {{tempMsg | async}} -->
    @if ((this.input_configurations?.bannerMsgs?.length) && (app.router.url != '/login' && app.isUserAuthenticated)) {
      <div>
      <app-prime-msg-banner [bannerConfig]="input_configurations" [showOnPeak]="msgBannerPosition$ | async" (emittAction)="recieveAction($event)" (clearBanner)="removeMsgBanner($event)">
      </app-prime-msg-banner>
    </div>
    }


    @if (app.router.url != '/login' && app.isUserAuthenticated) {
    <app-breadcrumb
      [userDetails]="userDetails" [triggerSupportPopup]="triggerSupportPopup"></app-breadcrumb>
    }
    <!-- <div class="notificationMenuBtn" data-toggle="modal" data-target="#notificationModal" data-backdrop="static" data-keyboard="false"
    matTooltip="{{'sys.notification' | translate}}" matTooltipPosition="left">
    <i class="zmdi zmdi-notifications-none notification-icon"  matBadgeColor="warn"></i>
  </div> -->

    <div class="layout-main-content" [ngClass]="{'split-view-active':splitMainLayout}" id="layout-main-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  @if (app.router.url != '/login' && app.isUserAuthenticated) {
  <app-config
  ></app-config>
  }
</div>

<!-- <app-notification [notificationObject]="geoFenceNotificationObj" (isModelCloseBtnClicked)="checkIsModelCloseBtnClicked($event)"></app-notification> -->
<ng-container>
  <p-toast key="global-notification"></p-toast>
</ng-container>



@if (kioskSubMsg) {
  <p-dialog (onHide)="qrCodeImageUrl=''" class="qr-code-payment" [(visible)]="showQRCodeDialog" [modal]="true"
            [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <div class="header-container">
        <p class="header">Payment for Kiosk Subscription</p>
        @if (kioskSubAmount && qrCodeImageUrl) {
          <p class="sub-header">Due Amount: ₹{{ kioskSubAmount }}</p>
        }
      </div>
    </ng-template>
    <div class="qr-container">
      <img  [src]="qrCodeImageUrl"/>
      @if (!qrCodeImageUrl) {
        <div>
          <app-circular-loader></app-circular-loader>
        </div>
      }
    </div>
  </p-dialog>
}
