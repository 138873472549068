<div [ngClass]="{'selected': selected}" [pTooltip]="getToolTipBattery()" class="main-battery" tooltipPosition="top">
  @if (batteryStatus === 'charging') {
    <i class="pi pi-bolt"></i>
  }
  <div class="batteryContainer">
    <div class="batteryOuter">
      <div [ngStyle]="{'background-color': backgroundColorBattery() , 'width':batteryPercentage+'%' }"
           id="batteryLevel"></div>
    </div>
    <div class="batteryBump"></div>
  </div>
</div>
