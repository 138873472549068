import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '../../services/config.service';
import { Notifications } from './interfaces/INotification.modal';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  effectGetNotifications(): Observable<Notifications>{
    const url = `${this.configService.appConfig.appBaseUrl}notifications`;
         return this.http.get<Notifications>(url);
  }


  enableNotifications(status){
    const url = this.configService.appConfig.appBaseUrl+ 'notifications/status'
    return this.http.put(url,{"pushNotification": status});
  }

  customerSupport(body){
    const url = this.configService.appConfig.appBaseUrl+ 'customer/support'
    return this.http.post(url, body);
  }

}