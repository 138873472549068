<div class="custom-modal-dialog"></div>
<p-sidebar 
  class="info-prime-sidebar" 
  position="right" 
  (onShow)="isSidebarClosed = false" 
  (onHide)="closeBtnClicked()" 
  [fullScreen]="(sidebarIsFullScreen$ | async)" 
  [(visible)]="isCurrentIdActive"
  [dismissible]="false"
>

  <ng-template pTemplate="header">
    <div class="custom-modal-header">
      <h5 class="custom-modal-title uppercase" translate>add visitor</h5>
    </div>
  </ng-template>
  <div 
    class="custom-modal create-modal" 
    [ngStyle]="{'display':'block'}" 
    [ngClass]="{'custom-modal-view':true}"  
    [id]="sidebarId"
  >
    <div class="custom-modal-content">
      <div class="custom-modal-body" >
        <form [formGroup]="visitorForm">
          <div class="grid">
            <div  class="form-group py-2 col-12 md:col-6 lg:col-6 xl:col-6">
              <span class="p-float-label full-width">
                  <input class="full-width" type="text" id="nameinputtext" pInputText required formControlName="name"> 
                  <label for="nameinputtext">Name</label>
              </span>
            </div>
            <div class="form-group py-2 col-12 md:col-6 lg:col-6 xl:col-6">
              <span class="p-float-label full-width">
                <p-dropdown showClear="true" class="full-width" formControlName="visiting" inputId="employeedropdown" [autoDisplayFirst]="false" [options]="employeeList" filterBy="name" filter="true">
                  <ng-template pTemplate="selectedItem" let-selectedItem>
                    <div>
                      <img id="{{selectedItem._id}}" class="dropdownMenu-image mr-2" [src]="assetService.getRegisteredFaceThumbnail(selectedItem._id)" alt="img" />
                      <span>{{selectedItem['name']}}</span>
                    </div>
                  </ng-template>
                  <ng-template let-dataObj pTemplate="item">
                    <div class="full-width">
                      <img id="{{dataObj._id}}" class="dropdownMenu-image mr-2" [src]="assetService.getRegisteredFaceThumbnail(dataObj._id)" alt="img" />
                      <span>{{dataObj['name']}}</span>
                    </div>
                  </ng-template>
                </p-dropdown>
                <label for="employeedropdown">Host employee</label>
              </span>
            </div>
            <div  class="form-group py-2 col-12 md:col-6 lg:col-6 xl:col-6">
              <span class="p-float-label full-width">
                <input class="full-width" type="text" id="purposeinputtext" pInputText required formControlName="purposeOfVisit"> 
                <label for="purposeinputtext">Purpose of visit</label>
            </span>
            </div>
          </div>
          <button pButton type="button" label="Submit" class="MT10" color="primary" [disabled]="!visitorForm.valid" (click)="saveVisitor()"></button>     

        </form>

      </div>
    </div>
  </div>
</p-sidebar>