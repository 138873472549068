import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CONSTANT} from '../../../config/constant';
import {AssetService} from '../../../services/asset.service';
import {SearchService} from '../../../services/search.service';
declare let $: any;
import {Subscription} from 'rxjs';
import { currentActiveId, selectIsSidebarFullScreen} from '../../../state/Sidebar/sidebar.selector';
import { Store } from '@ngrx/store';
const SIDEBAR_ID = CONSTANT.SIDEBAR_IDS.VISITOR_CREATE_MODAL;

@Component({
  selector: 'app-visitor-form',
  templateUrl: './visitor-form.component.html',
  styleUrls: ['./visitor-form.component.scss']
})
export class VisitorFormComponent implements OnInit, OnChanges, OnDestroy {
  isSidebarClosed= true;
  sidebarIsFullScreen$ = this.store.select(selectIsSidebarFullScreen);
  @Input() isBuildUserFormInitiated: boolean;
  @Output() createModalClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() visitorDetailsEvent: EventEmitter<object> = new EventEmitter();

  sidebarId = SIDEBAR_ID;
   currentActiveSidebarId$;
  employeeList: any[];
  visitorForm: UntypedFormGroup;
   searchTextSub: Subscription;
   isCurrentIdActive = false;

  constructor(private store: Store,private fb: UntypedFormBuilder, public assetService: AssetService, private searchService: SearchService) {
    this.employeeList = [];
  }

  ngOnInit(): void {
    this.currentActiveSidebarId$ = this.store.select(currentActiveId).subscribe(res =>{
      if(res === this.sidebarId)
      this.isCurrentIdActive = true;
      else
      this.isCurrentIdActive = false;
    })
    this.getAllEmployees();
    this.searchTextSub = this.searchService.searchText.subscribe(event => {
      if (event && event['id'] === 'employee') {
        this.getSearchedEmployeeEvent(event.text);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'isBuildUserFormInitiated') {
        if (change.currentValue !== change.previousValue) {
          this.buildForm();
        }
      }
    }
  }

  ngOnDestroy(){
    this.currentActiveSidebarId$?.unsubscribe();
  }

  buildForm() {
    this.visitorForm = this.fb.group({
      name: ['', [Validators.required]],
      visiting: ['', [Validators.required]],
      purposeOfVisit: ['', [Validators.required]],
    });
  }

  closeBtnClicked() {
    if(!this.isSidebarClosed){
      this.createModalClosedEvent.emit(true);
    }
    this.isSidebarClosed = true;
  }

  saveVisitor() {
    console.log(this.visitorForm.value);
    this.visitorDetailsEvent.emit(this.visitorForm.value);
  }

  getAllEmployees() {
    this.assetService.getCountByTypes(CONSTANT.ASSET_TYPES.PERSON)
      .subscribe((res: any[]) => {
        if (res['count']) {
          this.assetService.getAllByType(CONSTANT.ASSET_TYPES.PERSON, 0, 20)
            .subscribe((data: any[]) => {
              this.employeeList = data;
            });
        }
      }, () => {});
  }

  getSearchedEmployeeEvent(event) {
    if (event === '') {
      this.getAllEmployees();
    } else {
      this.getSearchedEmployeeList(event, CONSTANT.ASSET_TYPES.PERSON, 0, 20);
    }
  }
  getSearchedEmployeeList(searchForAsset, assetTypes, skip, limit) {
    this.assetService.getAssetOnSearch(searchForAsset, assetTypes, skip, limit)
      .subscribe((result: any[]) => {
        this.employeeList = result;
      });
  }

}
