<div class="container-fluid px-0 services-content-wrapper">
  <div class="grid mt-3">
    <div class="col-12 py-0 px-0 header">
      <div class="grid mx-0 px-4 header-inner-grid align-items-center justify-content-between" style="height: 100%">
        <div class="bread-crumb asset-list-breadcrumb col-12 sm:col-6 cpl-lg-8">
        </div>

        <div class="col-12 sm:col-6 lg:col-4 py-0 header-buttons-container">
          <div class="grid mx-0 justify-content-end align-items-center">
            <div id="inspection-analytics-filter-btn" class="MR10"
                 [pTooltip]="filterFields.isFilterApplied ? 'Filter applied' : 'Filter list'" tooltipPosition="left">
              <i class="pi pi-filter inner-container-header-btn PR">
                <span [ngClass]="{'filter-applied' : filterFields.isFilterApplied}"></span>
              </i>
            </div>
            <div id="refreshButton" class="header-btn" (click)="refreshBtnClicked();">
              <i class="pi pi-refresh inner-container-header-btn"
                 pTooltip="{{'sys.last-refreshed' | translate}} : {{lastRefreshed | date:'short'}}"
                 tooltipPosition="after"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid mx-0">
    <div class="col-12" id="inspection-analytics-filter-container">
      <div class="grid">
        <div class="map-header-container mx-4 PR">

          <div class="input-outer-grid PR">
            <!-- <mat-form-field color="accent" class="full-width" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select placeholder="Type"
              (ngModelChange) = "getSelectedFaceMaskOption($event)"
              [(ngModel)]="filterFields.faceMask">
              <mat-option *ngFor="let type of faceMaskOptionList" [value]="type['id']" >{{type['label']}}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <span class="p-float-label">
            <p-dropdown [options]="faceMaskOptionList" [(ngModel)]="filterFields.faceMask" optionLabel="label"
                        (ngModelChange)="getSelectedFaceMaskOption()" optionValue="id"
                        inputId="float-label"></p-dropdown>
            <label for="float-label">Type</label>
          </span>
        </div>

      </div>
    </div>
  </div>
</div>



<div class="grid faceMatchCards-Container mx-0">
  @for (faceData of faceDetails; track faceData) {
    <div class="faceMatchCard-deck my-2 mx-0 px-3"
         [ngClass]="(splitScreenActive$ | async) ? 'lg:col-12 xl:col-4' :'sm:col-6 md:col-4 lg:col-3 xl:col-2'">
      <app-face-detected-card [isCardClickable]="true" [showAddVisitorBtn]="showAddVisitorBtn"
                              [showRegisterThingBtn]="showRegisterThingBtn" [faceData]="faceData"
                              (cardClickEvent)="cardClick($event)"
                              (sendCardDataAndClickedProperty)="getCardDataAndClickedProperty($event)"
                              [visitorsTypeList]="visitorsTypeList"
                              (sendSelectedMenuItem)="addNewAsset($event)"
                              (addDetectedFaceClickEvent)="addDetectedFaceClick($event)">
      </app-face-detected-card>
    </div>
  }
</div>
  <div>@if (isFaceMatchNotFound) {
    <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'app.sidemenu.security.face-detection-not-found' | translate }}
      <span>&rdquo;</span></h3>
  }</div>

  @if (faceDetails.length > 0) {
    <div class="md:col-12  col-12 flex justify-content-center" style="margin-top: 10px">
      @if (faceDetails.length > 0) {
        <div class="md:col-12  col-12 flex justify-content-center MT20">
          @if (pagination.totalNoOfRecords > 0) {
            <p-paginator (onPageChange)="onPageChange($event)"
                         [first]="first" [rows]="pagination.recordsPerPage"
                         [totalRecords]="pagination.totalNoOfRecords"></p-paginator>
          }
        </div>
      }
    </div>
  }

  @if (showSlider) {
    <div>
      <app-face-match-sidebar [isFaceDetectedCard]="true"
                              [assetConfigDetails]="assetConfigDetails"
                              [cardData]="dataToSidebar"
                              (sendFrameImgToFaceMatch)="getFrameFromSideBar($event)"
                              (toggleActiveClassEvent)="toggleActiveClassEvent()"
      ></app-face-match-sidebar>
    </div>
  }

<app-visitor-form [isBuildUserFormInitiated]="isBuildVisitorFormInitiated"
                  (createModalClosedEvent)="createModalClosedEvent(); toggleActiveClassEvent()"
                  (visitorDetailsEvent)="saveVisitor($event)"></app-visitor-form>

  @if (showLoader) {
    <div>
      <app-loader></app-loader>
    </div>
  }

<div>
  <app-right-sidebar [assetData]="assetDetailsToRightSideBar" [assetConfigDetails]="assetConfigDetails"
                     [isAssetDetailsInReadMode]=true [showCircularLoaderValue]="showCircularLoaderValue"
                     (toggleActiveClassEvent)="toggleActiveClassEvent()" [isEventSelectedToEdit]="true"
                     [eventToEdit]="assetDetailsToRightSideBar"></app-right-sidebar>
</div>

<app-notification-event-create-form
  [formData]="formData"
  [formConfiguration]="selectedAssetConfigDetails"
  [resetForm]="resetAddAssetFormValue"
  [isEventSelectedToEdit]="false"
  (formSubmitTrigger)="onNotificationEventSubmit($event)"
  (resetOnModelClosed)="resetOnModelClosed()">
</app-notification-event-create-form>

<div class="messagePopup faceNotRegistered">
  Face Not Registered
</div>
<div class="messagePopup cameraNotRegistered">
  Camera Not Registered
</div>

  <button class="btn showErrorMsgBtn" id="assetRegisterErrorMsgBtn" style="display: none"></button>
  <div class="messagePopup savedAssetSuccessfully" translate>
    app.asset-list.asset-saved
  </div>

</div>

@if (frameImgForModal) {
  <div class="modal fade" id="modalForFrame" tabindex="-1" role="dialog">
    <div class="modal-dialog modalForFrame" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Frame</h5>
          <span class="modal-close-btn float-right" data-dismiss="modal">X</span>
        </div>
        <div class="modal-body">
          <div class="frame-container grid mx-0" data-dismiss="modal">
            <img [src]="faceDetectionService.getFaceDetectionImages(frameImgForModal._id, 'frame')" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
}

<p-toast key="face-detected-toast"></p-toast>
