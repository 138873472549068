import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigService} from '../../services/config.service';
import {TimeLineContentResponse, TrackOrder, UpdatedTimeline} from './interfaces/timeline.modal';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  constructor(private http: HttpClient, private configService: ConfigService) {
  }

  getTimeLineContent(asset): Observable<TimeLineContentResponse[]> {
    const queryParam = asset.name;
    const paramValue = asset.value;
    let queryUrl = '';
    if (asset.type) {
      queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/timeline/?${queryParam}=${paramValue}&types=${asset.type}`;
    } else {
      queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/timeline/?${queryParam}=${paramValue}`;

    }
    return this.http.get<TimeLineContentResponse[]>(queryUrl);
  }

  getTimeLineTrackingStatus(orderId): Observable<TrackOrder> {

    const queryUrl = `${this.configService.appConfig.appBaseUrl}track/order/isTracking/v2/${orderId}`;

    return this.http.get<TrackOrder>(queryUrl);
  }

  getUpdatedTimeline(id, type, orderId): Observable<UpdatedTimeline> {
    const queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/updateTrackableLpOrder/timeline`;
    console.log(id, type, orderId);
    const body = {
      'lpType': type,
      'lpId': id,
      'orderId': orderId
    };
    console.log(body);
    return this.http.put<UpdatedTimeline>(queryUrl, body);
  }


}
