import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
// import {log} from "util";
import {FormService} from '../../../services/form.service';
import {AssetService} from '../../../services/asset.service';
import {ServiceLinkableService} from '../../../services/serviceLinkable.service';
declare let $: any;
import * as _ from "lodash";
import { Store } from '@ngrx/store';
import { CONSTANT } from '../../../config/constant';
import { currentActiveId} from '../../../state/Sidebar/sidebar.selector';
const SIDEBAR_ID = CONSTANT.SIDEBAR_IDS.RIGHT_SIDEBAR_TO_ADD;

@Component({
  selector: 'app-right-sider-to-add',
  templateUrl: './right-sider-to-add.component.html',
  styleUrls: ['./right-sider-to-add.component.scss']
})
export class RightSiderToAddComponent implements OnChanges, OnInit {
assetSelectedType: string;
sidebarId = SIDEBAR_ID;
 currentActiveSidebarId$ = this.store.select(currentActiveId);
assetSelectedLabel: string;
@Input() selectedAssetConfigDetails: any;
@Input() resetAddAssetFormValue: number;
@Input() isPreFilledRequired: boolean;
@Output() savedAssetCardToAssetList: EventEmitter<any> = new EventEmitter();
@Output() assetRegistrationErrorMsgToAssetList: EventEmitter<string> = new EventEmitter();
@Output() createModalClosedEvent: EventEmitter<boolean> = new EventEmitter();
  public output: any;
  public assetFromConfig: any;
  public showCircularLoader: boolean;
  constructor(private store: Store, public assetService: AssetService, public formService: FormService, private serviceLinkableService: ServiceLinkableService) { }


  ngOnInit() {
     
  }
  ngOnChanges(changes: SimpleChanges) {
    for(const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'resetAddAssetFormValue') {
        if (change.currentValue !== change.previousValue) {
          this.assetFromConfig = [];
          this.showCircularLoader = true;
          if (this.selectedAssetConfigDetails) {
            console.log(this.selectedAssetConfigDetails, 'config details')
            this.assetSelectedType = this.selectedAssetConfigDetails.type;
            this.assetSelectedLabel = this.selectedAssetConfigDetails.assetLabel.toLowerCase();
          }
          if (this.assetSelectedType && this.assetSelectedType === 'general') {
            this.fetchConfigBasedOnServiceAssetType();
          } else {
            this.fetchConfigBasedOnType();
          }
        }
      }
    }
  }

  closePopup() {
    $('html').css('overflow-y', 'auto');
    this.createModalClosedEvent.emit(true);
  }
  fetchConfigBasedOnType() {
    this.assetService.getAssetConfig(this.assetSelectedType)
      .subscribe((data: any) => {
        console.log(data, 'data')
        if (this.isPreFilledRequired) {
          let configData = [];
          configData = this.formService.formatAssetConfig(data.configuration);
          this.assetFromConfig = this.addfieldValueToConfigData(configData);
          // _.forEach(configData, (config) => {
          //   config['fieldValue'] = this.selectedAssetConfigDetails[config['field']];
          // });
          // this.assetFromConfig = configData;
        } else {
          this.assetFromConfig = this.formService.formatAssetConfig(data.configuration);
        }
        this.showCircularLoader = false;
      });
  }

  fetchConfigBasedOnServiceAssetType() {
    this.serviceLinkableService.getServiceAssetConfig(this.assetSelectedType)
      .subscribe((config: any) => {
        if (this.isPreFilledRequired) {
          let configData = [];
          configData = this.formService.formatAssetConfig(config.configuration);
          this.assetFromConfig = this.addfieldValueToConfigData(configData);
        } else {
          this.assetFromConfig = this.formService.formatAssetConfig(config.configuration);
        }
        this.showCircularLoader = false;
      });
  }

  addfieldValueToConfigData(configData) {
    _.forEach(configData, (config) => {
      config['fieldValue'] = this.selectedAssetConfigDetails[config['field']];
    });
    return configData;
  }

  savedAssetCard(assetValue) {
    this.savedAssetCardToAssetList.emit(assetValue);
  }

  assetRegistrationErrorMsg(errMsg: string) {
    this.assetRegistrationErrorMsgToAssetList.emit(errMsg);
  }


}
