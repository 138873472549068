

<!-- prime card -->
@if (cardData) {
  <div class="mb-3 p-details-card" [ngClass]="{'active' : selectedId === cardData._id, 'cardClickable': cardClickable}">
    <p-card >
      @if (!(imageCard)) {
        <ng-template pTemplate="header">
          <div class="card-header flex justify-content-between">
            @if (cardLabel) {
              <h5 class="card-title TTC" [pTooltip]="cardLabel" tooltipPosition="left"
                  (click)="editCardData()">{{ cardLabel }}</h5>
            }
            @if (showToggleBtn) {
              <div class="switch-theme-button">
                <p-inputSwitch class="float-right" color="primary" [(ngModel)]="isStatusEnabled"
                               (onChange)="statusToggle($event)"
                               pTooltip="{{isStatusEnabled ? 'Enabled' : 'Disabled'}}" tooltipPosition="top">
                </p-inputSwitch>
              </div>
            }
            @if (showLock) {
              <div class="switch-theme-button">
                <span class="lock-button">
                  <i class="pi pi-lock"></i>
                </span>
              </div>
            }
          </div>
        </ng-template>
      }
      <!-- prime card body content for text -->
      @if (!(imageCard)) {
        <div class="card-body border-bottom" (click)="editCardData()">
          @for (field of fieldsToBeDisplayed; track field) {
            <div class="card-text mb-2">
              @if (checkType(cardData[field]) === 'object') {
                <div>
                  <span class="w-full flex justify-content-start mr-0">
                    <span class="field-label" pTooltip="{{getLangLabel('sys.'+field) | translate}}"
                          tooltipPosition="left">{{ getLangLabel('sys.' + field) | translate }}
                    </span>
                    <span class="colonx ml-1">:</span>
                  </span>
                  <span class="field-value time-format" style="display: inline; float:left">
                    @for (prop of cardData[field] | keyvalue; track prop) {
                      <span>{{ prop.value }}</span>
                    }
                  </span>
                </div>
              }
              @if (!(checkType(cardData[field]) === 'array' || checkType(cardData[field]) === 'object')) {
                <div>
                  <span class="w-full flex justify-content-start mr-0">
                    <span class="w-25 flex justify-content-between ">
                      <span class="text-truncate">{{ getLangLabel('sys.' + field) }}
                      </span>
                      <span class="colon ml-1"> :</span>
                    </span>
                    <span class="ml-2 text-fit text-truncate">{{ (cardData[field]) }}</span>
                  </span>
                </div>
              }
              @if (checkType(cardData[field]) === 'array') {
                <div>
                  <span class="w-full flex justify-content-start mr-0">
                    <span class="w-25 flex justify-content-between ">
                      <span class="field-label" translate>{{ formateCamelCaseToSentence(getLangLabel('sys.' + field)) }}
                      </span>
                      <span class="colon ml-1"> :</span>
                    </span>
                    <span class="ml-2">{{ cardData[field][0] }}</span>
                  </span>
                </div>
              }
            </div>
          }
        </div>
      }
      <!-- card for image type  -->
      @if (imageCard) {
        <div class="grid mx-0 imageCardBody align-items-center" (click)="editCardData()">
          <div class="col-12 text-center">
            <div class="icon-container">
              <img [src]="getIcon(cardData['type'])"/>
            </div>
          </div>
          <div class="col-12 text-center">
            <span class="thing-label"
                  [ngStyle]="{'color':cardData.theme.color.primary }">{{ cardData['label'] | translate }}</span>
          </div>
        </div>
      }
      <ng-template pTemplate="footer">
        <div class="card-footer-container mt-3">
          @if (showDeleteButton || cardData.isDeleteRequired) {
            <div class="col-3">
              @if (showDeleteButton || cardData.isDeleteRequired) {
                <span (click)="deleteBtnClicked()" pTooltip="Delete" tooltipPosition="left" id="cardDeleteBtn">
                  <i class="pi pi-trash cursorPointer"></i>
                </span>
              }
            </div>
          }
          @if (cardData.updated) {
            <div class=" text-right  text-truncate lighterColor"
                 [ngClass]="showDeleteButton || cardData.isDeleteRequired ? 'col-9' : 'col-12'"
                 (click)="editCardData()">
              <span class="dotsOnOverflow"
                    pTooltip="{{'sys.last-updated' | translate}} : {{dateFormatter.convertDateToSelectedTimezone(cardData.updated)}}"
                    tooltipPosition="bottom">{{ cardData.updated | date: 'medium' }}</span>
            </div>
          }
        </div>
      </ng-template>
    </p-card>
  </div>
}
