/**
 * Created by chandru on 29/6/18.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable()
export class AttendanceService {
  constructor(private http: HttpClient, public configService: ConfigService) {}

  getAttendance(skip, limit, startDate?, endDate?, groups?, type?) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'attendance' + '?skip=' + skip + '&limit=' + limit;
    if (startDate) {
      queryUrl = queryUrl + '&startDate=' + startDate;
    }
    if (endDate) {
      queryUrl = queryUrl + '&endDate=' + endDate;
    }
    if (groups) {
      queryUrl = queryUrl + '&groups=' + groups;
    }
    if (type) {
      queryUrl = queryUrl + '&type=' + type;
    }
    return this.http.get(queryUrl);
  }

  getAttendanceCount(startDate?, endDate?, groups?, type?) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'attendance/count?';
    if (startDate) {
      queryUrl = queryUrl + 'startDate=' + startDate;
    }
    if (endDate) {
      queryUrl = queryUrl + '&endDate=' + endDate;
    }
    if (groups) {
      queryUrl = queryUrl + '&groups=' + groups;
    }
    if (type) {
      queryUrl = queryUrl + '&type=' + type;
    }
    return this.http.get(queryUrl);
  }

  getTodayAttendanceCount() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'attendance/today/count');
  }

  getFaceMatchImages(id, type) {
    /*const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
     return this.configService.appConfig.appBaseUrl + 'faceMatchDetection/image/' + id + '/' + type + '?token=' + token;*/
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    return this.configService.appConfig.appBaseUrl + 'image/faceMatchImages/' + id + '/' + type + '?token=' + token;
  }

  getAnalyticsData(startDate, endDate) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'attendance/analytics/count/' + startDate + '/' + endDate;
    return this.http.get(queryUrl);
  }

  getEmployeeAttendance(assetId, startDate, endDate) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'attendance/assetId/count/' + assetId + '/' + startDate + '/' + endDate;
    return this.http.get(queryUrl);
  }

  getAllVisitors() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'visitors/');
  }

  addVisitor(visitorData) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'visitors/save', visitorData);
  }

  addAttendance(data) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'attendance/add', data);
  }

  deleteVisitor(id) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'visitors/remove/id/' + id);
  }

  getVisitorFaceImage(id) {
    /*const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
     return this.configService.appConfig.appBaseUrl + 'faceDetection/image/' + id + '/' + type + '?token=' + token;*/
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    return this.configService.appConfig.appBaseUrl + 'image/visitors/imageId/' + id + '?token=' + token;
  }

  downloadAnalyticsData(startDate, endDate) {
    window.open(this.configService.appConfig.appBaseUrl + 'attendance/analytics/download/' + startDate + '/' + endDate + '?token=' + sessionStorage.getItem('token'), '_blank');
  }

  downloadMonthlyReport(startDate, endDate) {
    window.open(this.configService.appConfig.appBaseUrl + 'attendance/analytics/monthlyReports/' + startDate + '/' + endDate + '?token=' + sessionStorage.getItem('token'), '_blank');
  }

  downloadDailyReport(startDate, endDate, type?, skip?, limit?) {
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    let queryUrl = this.configService.appConfig.appBaseUrl + 'attendance/dailyReports' + '?startDate=' + startDate + '&endDate=' + endDate;
    if (type) {
      queryUrl = queryUrl + '&type=' + type;
    }
    if (skip && limit) {
      queryUrl = queryUrl + '&skip=' + skip + '&limit=' + limit;
    }
    window.open(queryUrl + '&token=' + token, '_blank');
  }

  downloadEmployeeReports(startDate, endDate, employeeId) {
    window.open(this.configService.appConfig.appBaseUrl + 'attendance/analytics/employeeReports/' + startDate + '/' + endDate + '/' + employeeId + '?token=' + sessionStorage.getItem('token'), '_blank');
  }

  getMonthlyEmployeeAttendanceCount(startDate, endDate, employeeId) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'attendance/assetId/monthlyCount/' + employeeId + '/' + startDate + '/' + endDate);
  }

  deleteAttendance(id) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'attendance/remove/' + id);
  }
}
