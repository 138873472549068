import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { addStore } from '../../state/Kiosk/kiosk.action';
import { AppState } from '../../state/app.state';
import { KioskStore } from '../kiosk/models/store.model';
import { CONSTANT } from "../../config/constant";
import { KioskActions, KioskSelectors } from '../../state/Kiosk';
import { Subscription } from 'rxjs';
import { Actions, Subject } from '../../auth/rules';
import { Ability } from '@casl/ability';

const KIOSK_STORE_LOCATION = CONSTANT.KIOSK.STORE_lOCATION


@Component({
  selector: 'app-kiosk-store-tab-menu',
  templateUrl: './kiosk-store-tab-menu.component.html',
  styleUrls: ['./kiosk-store-tab-menu.component.scss']
})
export class KioskStoreTabMenuComponent implements OnInit {
  // // tab menu config
  tabMenuItems: MenuItem[] = [{ label: 'Product', icon: 'pi pi-fw pi-box', routerLink: ['product'] },
    { label: 'Category', icon: 'pi pi-fw pi-list', routerLink: ['category'], queryParams: {origin:'tabMenu'} },]

  //tabMenuItems: MenuItem[] = [{ label: 'Product', icon: 'pi pi-fw pi-box', routerLink: ['product'] }]

  storeList: KioskStore[] = [];

  activeItem: MenuItem;
  subscriptionArray: Subscription[] = [];
  selectedStore: any;
  currentParams: any;
  SUBJECT = Subject
  ACTIONS=Actions
  isOnMegaMenu:boolean
  activeTabChanges(event) {
    if(event?.routerLink=='table'){
      this.isOnMegaMenu = false
    }else{
      this.isOnMegaMenu = true
    }
  }

  constructor(private store: Store<AppState>,private ability:Ability) { }

  ngOnInit() {
    let href = window.location.href  
    let pathParamArray = href.split("/")
    this.isOnMegaMenu = pathParamArray[pathParamArray.length-1]=='table' ? false : true

    if(this.ability.can(this.ACTIONS.TABLE_IN_STORE,this.SUBJECT.TABLE_MANAGEMENT)){
      this.tabMenuItems.push({ label: 'Table', icon: 'pi pi-money-bill', routerLink: ['table']})
    }
    this.store.dispatch(KioskActions.loadBranchList());

    this.subscriptionArray.push(this.store.select(KioskSelectors.getBranchList).subscribe(async (branchList) => {
      this.storeList = branchList;

      if (this.storeList !== null && this.storeList.length > 0) {

        const current_branch = JSON.parse(localStorage.getItem(KIOSK_STORE_LOCATION));

        if (current_branch !== null) {
          this.selectedStore = current_branch;
         
        }

        else {
          this.selectedStore = this.storeList[0];
          localStorage.setItem(KIOSK_STORE_LOCATION, JSON.stringify(this.storeList[0]));
  

        }


      }

    }))
   }

  addStore() {
    this.store.dispatch(addStore({isAddStoreSidebarOpen: true}))
  }

  isStoreSelected(event) {
    this.selectedStore = event.value
    localStorage.setItem(KIOSK_STORE_LOCATION, JSON.stringify(event.value));
    this.store.dispatch(KioskActions.currentBranch({ currentBranch: this.selectedStore }));

  }
}
