import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {ICount, IRootAssetCountsByTypes, IRootAssetTypes} from './interfaces/IAsset.types.modal';
import {Observable} from 'rxjs';
import {AssetImageConfig, Assets, AssetsLinked, Group, IAssetCardImgStatus} from './interfaces/Assets.modal';
import {IAllAssignedCarriersCount, IAllAssignedCarriersData} from './interfaces/IAllAssignedCarriers.model';
import {IAllDeliveriesData} from './interfaces/IAllDeliveries.model';
import {ICategorySequencePayload} from './interfaces/IAsset.category.model';
import { IOrderAsset, IOrderAssets } from "../../state/Assets/interfaces/IAsset.order.modal";
import {Moment} from "moment-timezone";
import { ImageUploadService } from '../../services/image.upload.service';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(private http: HttpClient, private configService: ConfigService, private imageUploadService: ImageUploadService) {
  }


  effectAssetTypesConfig(): Observable<IRootAssetTypes[]> {
    return this.http.get<IRootAssetTypes[]>(`${this.configService.appConfig.appBaseUrl}assetConfig/types`);
  }

  effectAssetCountByType(assetTypes: string, groups?: string): Observable<ICount> {
    const url = `${this.configService.appConfig.appBaseUrl}asset/count?type=${assetTypes} ${groups ? `&groups=${groups}` : ''}`;
    return this.http.get<ICount>(url);
  }

  effectAllAssetCountByType(archive = false) {
    const url = `${this.configService.appConfig.appBaseUrl}asset/countByType?archived=${archive}`;
    return this.http.get<IRootAssetCountsByTypes>(url);
  }

  effectAssetByType(assetTypes: string, skip: number, limit: number, groups?: string, fI?: string, fE?: string, archive?: string): Observable<Assets> {
    assetTypes = assetTypes ? `&type=${assetTypes}` : '';
    groups = groups ? `&groups=${groups}` : '';
    fI = fI ? `&fI=${fI}` : '';
    fE = fE ? `&fE=${fE}` : '';
    const isArchived = archive ?? false
    const url = `${this.configService.appConfig.appBaseUrl}asset?skip=${skip}&limit=${limit}${assetTypes}${groups}${fI}${fE}&archived=${isArchived}`;
    return this.http.get<Assets>(url);
  }

  effectGetAssetDefaultImgUrl(assetType: string) {
    return this.http.get<AssetImageConfig>(`${this.configService.appConfig.appBaseUrl}assetConfig/image/${assetType}`);
  }

  effectsAssetLinkedByAssetType(baseAssetIds: string, assetTypeLinked: string, isCount?: string | boolean, skip?: string | number, limit?: string | number): Observable<AssetsLinked[]> {
    let url = (`${this.configService.appConfig.appBaseUrl}asset/linkedAssets/${baseAssetIds}/${assetTypeLinked}`);
    if (isCount) {
      url = url + '?isCount=' + isCount;
    }
    if (skip || skip == 0) {
      url = url + '?skip=' + skip;
    }
    if (limit) {
      url = url + '&limit=' + limit;
    }
    return this.http.get<AssetsLinked[]>(url);
  }

  effectsAllAssignedCarriers(moduleName: string, status: string, isCount?: boolean, skip?: number, limit?: number, startDate?, endDate?, fInclude?: string, fExclude?: string, carrierIds?: string, orderPickUpCityId?: string, orderPickUpStoreId?: string): Observable<IAllAssignedCarriersCount | IAllAssignedCarriersData[]> {
    let queryUrl = (`${this.configService.appConfig.appBaseUrl}deliveryManagement/assignment/group/allCarrier?moduleType=${moduleName}&status=${status}`)

    if (isCount) {
      queryUrl = queryUrl + '&isCount=' + isCount;
    }
    if (skip || skip == 0) {
      queryUrl = queryUrl + '&skip=' + skip;
    }
    if (limit) {
      queryUrl = queryUrl + '&limit=' + limit;
    }
    if (startDate) {
      queryUrl = queryUrl + '&startDate=' + startDate;
    }
    if (endDate) {
      queryUrl = queryUrl + '&endDate=' + endDate;
    }
    if (fInclude) {
      queryUrl = queryUrl + '&fI=' + fInclude;
    }
    if (fExclude) {
      queryUrl = queryUrl + '&fE=' + fExclude;
    }
    if (carrierIds) {
      queryUrl = queryUrl + '&carrierIds=' + carrierIds;
    }
    if (orderPickUpCityId) {
      queryUrl = queryUrl + '&orderPickUpCityId=' + orderPickUpCityId;
    }
    if (orderPickUpStoreId) {
      queryUrl = queryUrl + '&orderPickUpStoreId=' + orderPickUpStoreId;
    }

    return this.http.get<IAllAssignedCarriersCount | IAllAssignedCarriersData[]>(queryUrl)
  }

  effectsAllDeliveryAssignments(obj): Observable<IAllDeliveriesData> {
    let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/assignment/group/all?status=${obj?.status}&module=${obj?.moduleName}`;

    // if (isCount) {
    //   queryUrl = queryUrl + '&isCount=' + isCount;
    // }
    if (obj?.skip || obj?.skip == 0) {
      queryUrl = `${queryUrl}&skip=${obj?.skip}`;
    }
    if (obj?.limit) {
      queryUrl = `${queryUrl}&limit=${obj?.limit}`;
    }
    if (obj?.startDate) {
      queryUrl = `${queryUrl}&startDate=${obj?.startDate.format('x')}`;
    }
    if (obj?.endDate) {
      queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
    }
    if (obj?.fInclude) {
      queryUrl = `${queryUrl}&fI=${obj?.fInclude}`;
    }
    if (obj?.fExclude) {
      queryUrl = `${queryUrl}&fE=${obj?.fExclude}`;
    }
    if (obj?.carrierIds) {
      queryUrl = `${queryUrl}&carrierIds=${obj?.carrierIds}`;
    }
    if (obj?.orderPickUpCityId) {
      queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
    }
    if (obj?.orderPickUpStoreId) {
      queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
    }
    if (obj?.isLinked) {
      queryUrl = `${queryUrl}&isLinked=${obj?.isLinked}`;
    }
    if (obj?.searchText) {
      queryUrl = `${queryUrl}&search=${obj?.searchText}`;
      if (obj?.searchDuration) {
        queryUrl = queryUrl + '&searchForLastDays=' + obj?.searchDuration;
      }
    }
    if (obj?.orderRoutePlanIds) {
      queryUrl = `${queryUrl}&orderRoutePlanIds=${obj?.orderRoutePlanIds}`;
    }
    if (obj?.sortOrder) {
      queryUrl = `${queryUrl}&sortOrder=${obj?.sortOrder}`;
    }
    if (obj?.pagination) {
      queryUrl = `${queryUrl}&pagination=${obj?.pagination}`;
    }
    return this.http.get<IAllDeliveriesData>(queryUrl)
  }

  effectPutCategorySequence(payLoad: ICategorySequencePayload) {
    return this.http.put(`${this.configService.appConfig.appBaseUrl}asset/update/sequence`, payLoad)
  }

  effectGetGroupLinkedCountByAssetType(assetTypes: string) {
    const queryUrl = `${this.configService.appConfig.appBaseUrl}assetGroup/count?assetTypes=${assetTypes}`;
    return this.http.get<ICount>(queryUrl);
  }

  effectGetGroupsLinkedByAssetType(skip: string | number, limit: string | number, assetTypes: string) {
    const queryUrl = `${this.configService.appConfig.appBaseUrl}assetGroup/?skip=${skip}&limit=${limit}&assetTypes=${assetTypes}`;
    return this.http.get<Group[]>(queryUrl);
  }

  getLinkedAssets(assetId) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'asset/linkedAssets/' + assetId);
  }


  effectGetAllRoutePlan(moduleName: string, isCount: string | boolean, skip?: string | number, limit?: string | number, fI?: string, pickUpCityIds?: string, pickUpLocation?: string, search?: string, routePlanMode?: string): Observable<any> {
    let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/orderRoutePlan/moduleType/${moduleName}`;
    queryUrl = `${queryUrl}?isCount=${isCount}`;

    if (skip || skip == 0) {
      queryUrl = queryUrl + '&skip=' + skip;
    }
    if (limit) {
      queryUrl = queryUrl + '&limit=' + limit;
    }
    if (fI) {
      queryUrl = queryUrl + '&fI=' + fI;
    }
    if (search) {
      queryUrl = queryUrl + '&search=' + search;
    }
    if (pickUpCityIds) {
      queryUrl = queryUrl + '&pickUpCityIds=' + pickUpCityIds
    }
    if (pickUpLocation) {
      queryUrl = queryUrl + '&pickUpLocationIds=' + pickUpLocation

    }
    if (routePlanMode) {
      queryUrl = queryUrl + '&archived=' + routePlanMode

    }
    return this.http.get(queryUrl);
  }


  effectO2DOrders(options: O2DRequestOptions) {
    let queryUrl = `${this.configService.appConfig.appBaseUrl}`;
      queryUrl = `${queryUrl}orderToDelivery/order?skip=${options?.skip ?? 0}&limit=${options?.limit ?? 20}`
    if (typeof (options?.search) === 'string' && options?.search.trim().length > 0) {
      queryUrl = `${queryUrl}&search=${options.search}`;
      if (options?.searchForLastDays) {
        queryUrl = `${queryUrl}&searchForLastDays=${options.searchForLastDays}`;
      }
    }    
    else {
      if (options?.search?.['text'] && options?.search?.['text'].trim().length > 0) {
        queryUrl = `${queryUrl}&search=${options.search?.['text']}`;
        if (options?.searchForLastDays) {
          queryUrl = `${queryUrl}&searchForLastDays=${options.searchForLastDays}`;
        }
      }

    }    

    if (options?.startDate) {
      queryUrl = `${queryUrl}&startDate=${options.startDate.valueOf()}`;
    }
    if (options?.endDate) {
      queryUrl = `${queryUrl}&endDate=${options.endDate.valueOf()}`;
    }
    if (typeof options?.isLpAssigned==='boolean') {
      queryUrl = `${queryUrl}&isLpAssigned=${options.isLpAssigned}`;
    }
    if (options?.deliveryStatus) {
      queryUrl = `${queryUrl}&deliveryStatus=${options.deliveryStatus}`;
    }
    if (options?.orderPickUpCityId) {
      queryUrl = `${queryUrl}&orderPickUpCityId=${options.orderPickUpCityId}`;
    }
    if (options?.orderPickUpStoreId) {
      queryUrl = `${queryUrl}&orderPickUpStoreId=${options.orderPickUpStoreId}`;
    }
    if (options?.lpName) {
      queryUrl = `${queryUrl}&lpName=${options?.lpName}`;
    }
    if (options?.lpId) {
      queryUrl = `${queryUrl}&lpId=${options.lpId}`;
    }
    if (options?.isAgentAssigned && (options?.isAgentAssigned !== 'null')) {
      queryUrl = `${queryUrl}&isAgentAssigned=${options.isAgentAssigned}`;
    }
    if (options?.orderStatus) {
      if (options?.orderSource === 'kiosk')
        queryUrl = `${queryUrl}&pickupStatus=${options?.orderStatus}`;
      else
        queryUrl = `${queryUrl}&orderStatus=${options?.orderStatus}`;
    }
    if (options?.sortByFieldsStr) {
      queryUrl = `${queryUrl}&sortByFieldsStr=${options.sortByFieldsStr}`;
    }
    if (options?.dateField) {
      queryUrl = `${queryUrl}&dateField=${options.dateField.valueOf()}`;
    }
    if (options?.deliverySlot) {
      queryUrl = `${queryUrl}&deliverySlot=${options.deliverySlot}`;
    }
    if (options?.orderType) {
      queryUrl = `${queryUrl}&orderType=${options.orderType}`;
    }
    if (options?.fInclude) {
      queryUrl = `${queryUrl}&fInclude=${options.fInclude}`;
    }
    if (options?.paymentStatus) {
      queryUrl = `${queryUrl}&paymentStatus=${options.paymentStatus}`;
    }
    if (options?.paymentMode) {
      queryUrl = `${queryUrl}&paymentMode=${options.paymentMode}`;
    }
    if (options?.dineType) {
      queryUrl = `${queryUrl}&dineType=${options.dineType}`;
    }
    if (options?.orderSource) {
      queryUrl = `${queryUrl}&orderSource=${options.orderSource}`;
    }
    if (options?.deliveryLocId) {
      queryUrl = `${queryUrl}&deliveryCenterIds=${options.deliveryLocId}`
    } 
    if (options?.pagination) {
      queryUrl = `${queryUrl}&pagination=${options.pagination}`
    }
    if (options?.dateFilter) {
      queryUrl = `${queryUrl}&dateFilter=${options.dateFilter}`
    }
    return this.http.get<IOrderAssets | IOrderAsset[]>(queryUrl);
  }

  getAssetCardsImageDisplayStatusData() {
    return this.http.get< IAssetCardImgStatus[] >(this.configService.appConfig.appBaseUrl + 'asset/showOnCardAvailable/assetType');
  }
  saveMultipleAssetsData(assetData, assetType: string) {
    const data = this.getAssetDataWithImages(assetData, assetType)
    return this.http.post(this.configService.appConfig.appBaseUrl + 'asset/create/multiple/asset', data);
  }

  getAssetDataWithImages(assetData, assetType: string) {
    const formData = new FormData();
    const modData = assetData.map((asset, assetIdx: number) => {
      if (asset.image) {
        for (const imgType in asset.image) {
          for (const label in asset.image[imgType]) {
            let file = this.imageUploadService.dataURLtoBlob(asset.image[imgType][label]);
            formData.append(`assetImage_${assetIdx + 1}`, file);
          }
        }
        asset = { ...asset }
        delete asset.image
      }
      return asset
    })
    formData.append('assetData', JSON.stringify(modData));
    formData.append('assetType', assetType);
    return formData
  }

}


export type O2DRequestOptions = {
  skip: number,
  limit: number,
  search?: string | unknown,
  startDate?: string | Moment,
  endDate?: string | Moment,
  isLpAssigned?: string | boolean,
  deliveryStatus?: string,
  orderPickUpCityId?: string,
  orderPickUpStoreId?: string,
  lpId?: string,
  isAgentAssigned?: string,
  orderStatus?: string | string[],
  sortByFieldsStr?: string,
  dateField?: string | Moment,
  deliverySlot?: string,
  orderType?: string,
  fInclude?: string,
  paymentStatus?: string,
  paymentMode?: string,
  dineType?: string,
  orderSource?: string,
  deliveryLocId? : string,
  lpName?: string,
  pagination?: boolean,
  searchForLastDays?: number,
  dateFilter?: string
}
