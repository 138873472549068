import {Component, OnDestroy, OnInit} from "@angular/core";
import {AppMainComponent} from "app/core/main/app.main.component";
import {animate, state, style, transition, trigger,} from "@angular/animations";
import {AppComponent} from "app/app.component";
import {forEach, map, sortBy} from 'lodash';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {loadAssetDefaultImage} from "state/Assets/assets.action";
import {assetConfigSelector, assetDefaultImageConfigsSelector, assetsCountSelector} from "state/Assets/assets.selector";
import {AssetImageConfigHolder, AssetsCounts} from "state/Assets/interfaces/Assets.modal";
import {IRootAssetTypes} from "state/Assets/interfaces/IAsset.types.modal";

@Component({
  selector: "app-megamenu",
  templateUrl: "./app.megamenu.component.html",
  animations: [
    trigger("children", [
      state(
        "hiddenAnimated",
        style({
          height: "0px",
        })
      ),
      state(
        "visibleAnimated",
        style({
          height: "*",
        })
      ),
      transition(
        "visibleAnimated => hiddenAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
      transition(
        "hiddenAnimated => visibleAnimated",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
    ]),
  ],
  styles: [`.icon-align {
    margin-bottom: -4px
  }
    
  :host ::ng-deep .p-badge {
       color: #ffffff !important
    }`]
})
export class AppMegamenuComponent implements OnInit, OnDestroy {
  things: IRootAssetTypes[] = [];
  subs = new Array<Subscription>(3);

  constructor(public app: AppComponent, public appMain: AppMainComponent, private store: Store) {

  }

  ngOnInit() {
    this.subs.push(
      this.store.select(assetDefaultImageConfigsSelector).subscribe((configs: AssetImageConfigHolder) => {
        configs && this.setImageUrl(configs)
      }),
      this.store.select(assetConfigSelector).subscribe((configs: IRootAssetTypes[]) => {
        if (configs?.length > 0) {
          this.things = configs;
          this.loadAssetDefaultImage(configs)
        }
      }),
      this.store.select(assetsCountSelector).subscribe((assetsCounts: AssetsCounts) => {
        this.updateCountInConfig(assetsCounts);
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  loadAssetDefaultImage(configs: IRootAssetTypes[]) {
    forEach(configs, (i) => {
      this.store.dispatch(loadAssetDefaultImage({assetType: i?.type}));
    });
  }

  setImageUrl(configObj: AssetImageConfigHolder) {
    this.things = map(this.things, (data) => {
      const type = data['type']
      if (data && type) {
        data = {...data, icon: configObj[type]?.url};
      } else {
        data = {...data, icon: '../../assets/exclamation-red.png'};
      }
      return data;
    })
    this.things = sortBy(this.things, [function (o) {
      return o.type;
    }]);
  }

  updateCountInConfig(assetsCounts: AssetsCounts) {
    for (const key in assetsCounts) {
      const index = this.things.findIndex(t => t.type === key)
      if (index > -1) {
        this.things = this.things.map((thing, idx) => {
          if (idx === index) {
            const thingData: any = this.things[index] || {}
            return {...thingData, count: assetsCounts[key].count};
          }
          return thing
        });
      }
    }
  }

}
