import { createAction, props } from "@ngrx/store";
import { Notifications } from "./interfaces/INotification.modal";
import { Notification } from "./interfaces/INotification.modal";

export const getAllNotifications = createAction(
    '[Effects API] fetch all notification'
  );
  
  export const allNotificationFetchSuccess = createAction(
    '[Effects API] fetch all notification succuess API request',
    props<{ notifications: Notifications }>()
  );
  
  export const allNotificationFetchFailure = createAction(
    '[Effect API] fetch all notification failure API request',
    props<{ error: string }>()
  );

  export const updateNotificationList = createAction(
    '[Store API] update notification list',
    props<{notification: Notification}>()
  );

  export const toggleNotificationEnabler = createAction(
    '[Effects API] toggle notification enabler',
    props<{ enableStatus: boolean}>()
  );

  export const toggleNotificationEnablerSuccess = createAction(
    '[Effects API] toggle notification enabler success',
    props<{res}>()
  );

  export const toggleNotificationEnablerError = createAction(
    '[Effects API] toggle notification enabler error',
    props<{ error: Error}>()
  );


  export const setTopMsgBannersCount = createAction(
    '[Effects API] toggle top msg banner count',
    props<{ count:number}>()
  );