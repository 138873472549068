@if (isFormReadyToDisplay) {
  <form [formGroup]="form">
    <div class="grid my-1">
      @for (field of formConfigData; track field) {
        <div class="form-group py-2 col-12 md:col-6 lg:col-6 xl:col-6 my-1" style="float: left">
          @if ((field.type === 'text') || (field.type === 'textarea') || (field.type === 'number') || (field.type === 'email')) {
            <div>
              <span class="p-float-label">
                <input id="float-input" class="full-width" type="text" formControlName={{field.field}} pInputText
                       placeholder="Enter the Reason">
                <label for="p-float-label">{{ field.label }}</label>
              </span>
            </div>
          }
          @if (field.type == 'time') {
            <div>
              <span class="p-float-label">
                <!-- <ngb-timepicker formControlName={{field.field}}></ngb-timepicker> -->
                <p-calendar id="float-input" (onSelect)="setStartEndDate($event,field.field)"
                            formControlName={{field.field}} showTime="true" [timeOnly]="true"
                            [hourFormat]="24"></p-calendar>
                <label for="p-float-label" class="timePicker-label">{{ field.label }}</label>
              </span>
              @if (timePickerValidator) {
                <small class="p-error block">Start time Can't be greater than End time</small>
              }
            </div>
          }
          <!--  -->
          @if (field.type == 'date') {
            <div>
              <input pInputText type="text" ngxDaterangepickerMd class="ng-date-picker-input"
                     [singleDatePicker]="datePickerVariables.singleDatePicker"
                     [autoApply]="datePickerVariables.autoApply" [maxDate]="datePickerVariables.maxDate"
                     [locale]="datePickerVariables.locale" [showDropdowns]="datePickerVariables.showDropdowns"
                     [minDate]="datePickerVariables.minDate"
                     [required]="field.required" placeholder="{{field.description}}" formControlName={{field.field}}>
            </div>
          }
          @if (field.type == 'dropDown') {
            <div>
              <p-dropdown [options]="field.dropDownValues" optionLabel="label" optionValue="value"
                          [(ngModel)]="field.value" [style]="{'width':'100%'}"
                          [placeholder]="field.description"></p-dropdown>
            </div>
          }
          @if (field.type == 'multiSelect') {
            <div>
              <p-multiSelect class="full-width" optionLabel="label" [options]="field.dropDownValues"
                             [(ngModel)]="field.value" [style]="{'width':'100%'}"
                             [placeholder]="field.description"></p-multiSelect>
            </div>
          }
        </div>
      }
    </div>
    <div class="grid my-1">
      <div class="col-6">
        @if (formConfigData.length > 0) {
          <button pButton class="mx-2" type="button" color="primary" [disabled]="!form.valid"
                  (click)="submitConfigDetails()">
            @if (isEditForm) {
              <span>{{ 'sys.update' | translate }}</span>
            }
            @if (!isEditForm) {
              <span>{{ 'sys.submit' | translate }}</span>
            }
          </button>
        }
      </div>
    </div>
  </form>
}

@if (showCircularLoader) {
  <div>
    <app-circular-loader></app-circular-loader>
  </div>
}
