<ul class="trackable-cars-list defaultBorder panelOpened">
  @if (!(trackableCarsListForSidePanel.length > 0)) {
    <div class="grid mx-0 justify-content-center align-items-center" style="height: 100%">
      @if (!(trackableCarsListForSidePanel.length > 0) && !showCircularLoader) {
        <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.no-data-available' | translate }}<span>&rdquo;</span>
        </h3>
      }
      @if (showCircularLoader) {
        <div>
          <app-circular-loader></app-circular-loader>
        </div>
      }
    </div>
  }
  <div class="list-toggle-button panel-toggle-button" (click)="toggleCarsList()">
    <span class="arrow-icon fas fa-chevron-left"></span>
  </div>
  @if (trackableCarsListForSidePanel.length > 0) {
    <div class="list-inner-grid PR">
      <div class="col-12 searchBoxGrid">
        <app-search-box [resetSearchTextToSearchBox]="resetSearchTextValue" [id]="'carSearch'"></app-search-box>
      </div>
      <div class="list-items-container" style="width: 100%">
        @for (car of trackableCarsListForSidePanel | trackerCarFilterPipe : searchForTrackedCar; track car) {
          <li class="p-1" (click)="getSelectedCar(car)" [ngClass]="car && car['cardClicked'] ? 'list-card-active' : ''">
            <div class="list-card-header">
              <div class="vid">
                <p>{{ (!searchForTrackedCar ? (car?.registrationNumber || car.name) : car[car['filteredBy']]).toUpperCase() }}</p>
                <p class="mobile">{{ car.mobileNo }}</p>
                @if (car && car.gpsData && car.gpsData.generatedTimeStamp) {
                  <p class="timeStamp" [pTooltip]="getLangLabel('sys.last-updated')"
                     tooltipPosition="left">{{ dateFormatterToText(car.gpsData.generatedTimeStamp) }}</p>
                }
              </div>
              <div class="ml-auto">
                @if (showSpeed && markersValueWithDeviceId[car['_id']] && !(markersValueWithDeviceId[car['_id']].isOffline)) {
                  <div class="lighterColor speed dotsOnOverflow"
                       [pTooltip]="getLangLabel('sys.speed')+' : ' + ((car.gpsData.speed >= 0) ? (roundOff(car.gpsData.speed)  + ' km/hr') : 'NA')"
                       tooltipPosition="right">
                    <mat-icon class="list-item-icon mr-1">speed</mat-icon>
                    <p>{{ (car.gpsData.speed >= 0) ? roundOff(car.gpsData.speed) : 'NA' }}</p>
                  </div>
                }
                @if (markersValueWithDeviceId[car['_id']]) {
                  <div class="status-indicator"
                       [ngStyle]="{'background-color': markersValueWithDeviceId[car['_id']].isOffline ? 'red' : '#00c851' }"
                       tooltip="{{markersValueWithDeviceId[car['_id']].isOffline ? getLangLabel('sys.offline') : getLangLabel('sys.online')}}"
                       tooltipPosition="right"></div>
                }
              </div>
            </div>
            <div class="list-card-body">
              <div class="sensor-data">
                @if (showIgnitionStatus) {
                  <div class="icon-pill" [pTooltip]="'Ignition'" tooltipPosition="left">
                    <mat-icon class="list-item-icon mr-1">vpn_key</mat-icon>
                    @if (markersValueWithDeviceId[car['_id']]?.ignitionStatus === undefined) {
                      <p>NA</p>
                    }
                    @if (markersValueWithDeviceId[car['_id']]?.ignitionStatus) {
                      <p>{{ (markersValueWithDeviceId[car['_id']] && !markersValueWithDeviceId[car['_id']].isOffline) ? getLangLabel('sys.on') : getLangLabel('sys.off') }}</p>
                    }
                  </div>
                }
                <div class="icon-pill" [pTooltip]="'Temperature'" tooltipPosition="left">
                  <mat-icon class="list-item-icon mr-1">thermostat</mat-icon>
                  <p>{{ car?.gpsData?.sensorData?.temperature ? (car?.gpsData?.sensorData?.temperature * 1).toFixed(2) : '-' }}</p>
                </div>
                <div class="icon-pill" [pTooltip]="'Cold Chain Box'" tooltipPosition="left">
                  <mat-icon class="list-item-icon mr-1">inventory_2</mat-icon>
                  <p>{{ car?.gpsData?.sensorData?.coldChainBox && car?.gpsData?.sensorData?.coldChainBox !== 'NA' ? car.gpsData.sensorData.coldChainBox : '-' }}</p>
                </div>
                <div class="icon-pill" [pTooltip]="'Humidity'" tooltipPosition="left">
                  <mat-icon class="list-item-icon mr-1">ac_unit</mat-icon>
                  <p>{{ car?.gpsData?.sensorData?.humidity ? car.gpsData.sensorData.humidity : '-' }}</p>
                </div>
                @if (car?.odoMeter && car.odoMeter > 0) {
                  <div class="icon-pill" [pTooltip]="'Odometer'" tooltipPosition="left">
                    <mat-icon class="list-item-icon mr-1">gas_meter</mat-icon>
                    <p>{{ car?.odoMeter }}</p>
                  </div>
                }
                @if (car?.gpsData?.platformInfo?.version) {
                  <div class="icon-pill" [pTooltip]="'ZenDMS Version'" tooltipPosition="left">
                    <mat-icon class="list-item-icon mr-1">smartphone</mat-icon>
                    <p>{{ car?.gpsData?.platformInfo?.version }}</p>
                  </div>
                }
              </div>
              <div class="btn-data">
                <button class="custom-btn" (click)="openRightPanelEmit(car)">View Details</button>
              </div>
            </div>
            @if (car.activeAssignments && car.activeAssignments.length > 0) {
              <div class="orders-container" (click)="orderListClicked()">
                @for (assignment of car.activeAssignments; track assignment; let i = $index) {
                  <div class="orders-grid lighterColor"
                       class="multiline-tooltip" [pTooltip]="buildOrderListTooltip(assignment)" tooltipPosition="left">
                    {{ assignment?.orderId }}@if (i < car.activeAssignments.length - 1) {
                    <span style="margin-right: 5px;">, </span>
                  }
                  </div>
                }
              </div>
            }
          </li>
        }
    </div>
  </div>
  }
</ul>
