/**
 * Created by chandru on 12/11/18.
 */

 import { Injectable } from '@angular/core';
 import {ConfigService} from './config.service';
 import {DateFormatterService} from './dateformat';
 import {CONSTANT} from '../config/constant';
 import { forEach, map, cloneDeep } from 'lodash';


 const ORDER = CONSTANT.ASSET_TYPES.ORDER;
 const VEHICLE = CONSTANT.ASSET_TYPES.VEHICLE;

 @Injectable()
 export class AssetDataModifyService {
   constructor( public configService: ConfigService, public dateFormatter: DateFormatterService) {}

   modifyTheResult(assetType, array, viewFormate) {
    const _this = this;
    let arrayCopy: any = [];
    arrayCopy = cloneDeep(array);
    arrayCopy = map(array, (data) => {
      return _this.modifyTheData(assetType, data, viewFormate);
    });
    return arrayCopy;
  }

  modifyTheData(assetType, data, viewFormate) {
    if (viewFormate === 'card') {
        switch (assetType) {
            case ORDER:
              if (data.assetData && data.assetData.orderId) {
                  data['orderId'] = data.assetData.orderId;
                }
                if (data.assetData && data.assetData.customerDetails) {
                  if ( data.assetData.customerDetails.firstName && data.assetData.customerDetails.lastName) {
                    data['custName'] = data.assetData.customerDetails.firstName + ' ' + data.assetData.customerDetails.lastName;
                    data.assetData['firstName'] = data.assetData.customerDetails.firstName + ' ' + data.assetData.customerDetails.lastName;
                  } else if (data.assetData.customerDetails.firstName ) {
                    data['custName'] = data.assetData.customerDetails.firstName;
                    data.assetData['firstName'] = data.assetData.customerDetails.firstName;
                  }

                  if (data.assetData.customerDetails.location) {
                    if (data.assetData.customerDetails.location.pincode) {
                      data['pincode'] = data.assetData.customerDetails.location.pincode;
                      data.assetData['pincode'] = data.assetData.customerDetails.location.pincode;
                    }
                    if (data.assetData.customerDetails.location.city) {
                      data['city'] = data.assetData.customerDetails.location.city;
                      data.assetData['city'] = data.assetData.customerDetails.location.city;
                    }
                  }
                }
                if (data.assetData &&  data.assetData.customerDetails && data.assetData.customerDetails.mobileNumber) {
                  data['custNo'] = data.assetData.customerDetails.mobileNumber;
                }
                
                if (data.assetData && data.assetData.expectedDeliveryDateTime) {
                  if (data.assetData.expectedDeliveryDateTime.dateTime) {
                    data['deliveryDate'] = this.dateFormatter.convertDateToSelectedTimezone(data.assetData.expectedDeliveryDateTime.dateTime, 'DD/MM/YY');
                    data.assetData['expectedDeliveryDate'] = this.dateFormatter.convertDateToSelectedTimezone(data.assetData.expectedDeliveryDateTime.dateTime, 'DD/MM/YY');
                  }
                  if (data.assetData.expectedDeliveryDateTime.time) {
                    data['deliveryDate'] = data['deliveryDate'] + ', ' + this.dateFormatter.getFormattedTimeSlot(data.assetData.expectedDeliveryDateTime.time);
                    data.assetData['expectedDeliveryTime'] = data.assetData.expectedDeliveryDateTime.time;
                  }
                }
  
                if (data.assetData && data.assetData.pickUpCity && data.assetData.pickUpCity.city) {
                  data.assetData['pickupCity'] = data.assetData.pickUpCity.city;
                }
                if (data.assetData && data.assetData.pickUpLocation && data.assetData.pickUpLocation.name) {
                  data.assetData['pickupLocation'] = data.assetData.pickUpLocation.name;
                }
                if (data.assetData && data.assetData.deliveryCenter && data.assetData.deliveryCenter.name) {
                  data.assetData['deliveryCenterName'] = data.assetData.deliveryCenter.name;
                }
              break;

            case VEHICLE: 
            break;  
            default:
              break;
          }
          return data;
    } else if (viewFormate === 'table'){
        if (data && data.status && data.status === "notAvailable") {
          //data["isNotClickable"] = true;
          //data["showEditBtn"] = false;
          data["showRemoveBtn"] = false;
        } else {
          //data["isNotClickable"] = false;
          //data["showEditBtn"] = true;
          data["showRemoveBtn"] = true;
        }
        switch (assetType) {
            case ORDER:
                data['custDetails'] = []
            if (data.assetData && data.assetData.orderId) {
                data['orderId'] = data.assetData.orderId;
            }
            if (data.assetData && data.assetData.customerDetails) {
                if (
                    data.assetData.customerDetails.firstName &&
                    data.assetData.customerDetails.lastName
                ) {
                    data["firstName"] =
                    data.assetData.customerDetails.firstName +
                    " " +
                    data.assetData.customerDetails.lastName;
                    data.assetData["firstName"] =
                    data.assetData.customerDetails.firstName +
                    " " +
                    data.assetData.customerDetails.lastName;
                    data['custDetails'].push(data["firstName"]);
                } else if (data.assetData.customerDetails.firstName) {
                    data["firstName"] = data.assetData.customerDetails.firstName;
                    data.assetData["firstName"] = data.assetData.customerDetails.firstName;
                    data['custDetails'].push(data["firstName"]);
                }
                if (data.assetData.customerDetails.mobileNumber) {
                    data["mobileNumber"] = data.assetData.customerDetails.mobileNumber;
                    data.assetData["customerMobile"] =
                    data.assetData.customerDetails.mobileNumber;
                    data['custDetails'].push(data["mobileNumber"]);
                }

            
                if (data.assetData.customerDetails.location) {
                    let cityWithPincode: string = '';
                    if (data.assetData.customerDetails.location.city) {
                    data["city"] = data.assetData.customerDetails.location.city;
                    data.assetData["city"] = data.assetData.customerDetails.location.city;
                    cityWithPincode = data.assetData.customerDetails.location.city;
                    }
                    if (data.assetData.customerDetails.location.pincode) {
                    data["pincode"] = data.assetData.customerDetails.location.pincode;
                    data.assetData["pincode"] =
                        data.assetData.customerDetails.location.pincode;
                    cityWithPincode = cityWithPincode + ' - ' + data.assetData.customerDetails.location.pincode;
                    }
                    data['custDetails'].push(cityWithPincode);
                }
            }


            if (data.assetData && data.assetData.expectedDeliveryDateTime) {
                if (data.assetData.expectedDeliveryDateTime.dateTime) {
                    data['expectedDeliveryDateTimeDetails'] = this.dateFormatter.convertDateToSelectedTimezone(data.assetData.expectedDeliveryDateTime.dateTime, 'DD/MM/YY');
                    data.assetData['expectedDeliveryDate'] = this.dateFormatter.convertDateToSelectedTimezone(data.assetData.expectedDeliveryDateTime.dateTime, 'DD/MM/YY');
                }
                if (data.assetData.expectedDeliveryDateTime.time) {
                    data['expectedDeliveryDateTimeDetails'] = data['expectedDeliveryDateTimeDetails'] + ', ' + this.dateFormatter.getFormattedTimeSlot(data.assetData.expectedDeliveryDateTime.time);
                    data.assetData['expectedDeliveryTime'] = data.assetData.expectedDeliveryDateTime.time;
                    data['deliveryDate'] = data['deliveryDate'] + ', ' + this.dateFormatter.getFormattedTimeSlot(data.assetData.expectedDeliveryDateTime.time);
                    data.assetData['expectedDeliveryTime'] = data.assetData.expectedDeliveryDateTime.time;
                    data['deliverySlot'] = data.assetData.expectedDeliveryDateTime.time;
                }
            }


            if (data.assetData.pickUpLocation && data.assetData.pickUpLocation.name) {
                if (data.assetData.pickUpLocation.city) {
                    data["pickupLocation"] =
                    data.assetData.pickUpLocation.name + ", " + data.assetData.pickUpLocation.city;
                    data["pickupCity"] = data.assetData.pickUpLocation.city;
                } else {
                    data["pickupLocation"] = data.AssetData.pickUpLocation.name;
                }
            }


            if (data.assetData && data.assetData.deliveryCenter && data.assetData.deliveryCenter.name) {
              data['deliveryLoc'] = data.assetData.deliveryCenter.name;
            }

            if (data.assetData.orderSource) {
               data['orderSource'] = data.assetData.orderSource;
            }
            if (
            data.assetData.orderDetails &&
            data.assetData.orderDetails.items &&
            data.assetData.orderDetails.items.length > 0
            ) {
                let item: string;
                item = "";
                let itemList = [];
                forEach(data.assetData.orderDetails.items, function(value) {
                    item = value["name"] + " - Qty: " + value["quantity"];
                    itemList.push(item)
                });
                data["items"] = itemList;
            }
              break;

            case VEHICLE:
                if (data.assetData && data.assetData.name) {
                    data['name'] = data.assetData.name;
                }
                if (data.assetData && data.assetData.registrationNumber) {
                    data['registrationNumber'] = data.assetData.registrationNumber;
                }
            break; 
        
            default:
              break;
          }
          return data;
    }
    
  }
 
 }
 
 export interface Timezone {
   timezone: string;
 }
 