import {Pipe, PipeTransform} from '@angular/core';
import {ILocation} from '../state/Assets/interfaces/IAsset.types.modal';

@Pipe({
  name: 'locationFilter'
})
export class LocationFilterPipe implements PipeTransform {
  transform(locations: ILocation[], cityId: string): ILocation[] {
    if (!locations || !cityId) {
      return locations;
    }

    return locations.filter(v => v.assetsLinked.find(l => l['_id'] === cityId));
  }
}
