import { createReducer, on } from "@ngrx/store";
import { CustomerCollaborationActions } from ".";
import { ICollaboration, IImageData } from "./interfaces/customer.collaboration.interface";

export interface CustomerCollaborationState {
  collabortionData: ICollaboration[];
  uploadStatus:IImageData[];
  customerNotifiedStatus:{msg:string};
}


export const initialState: CustomerCollaborationState = {
  collabortionData: null,
  uploadStatus: null,
  customerNotifiedStatus:null,
};

export const CustomerCollaborationReducer = createReducer(
  initialState,

  on(
    CustomerCollaborationActions.onGettingCustomerCollaborations,
    (state, { collaborations }) => {
      return {
        ...state,
        collabortionData: collaborations,
      };
    }
  ),

  on(
    CustomerCollaborationActions.onSuccessOfUploadOfProductImagesOfCollab,
    (state, { uploadStatus }) => {
      return {
        ...state,
        uploadStatus
      };
    }
  ),

  on(
    CustomerCollaborationActions.onSuccessOfSendingOrderCollabImagesToCustomer,
    (state, { customerNotifiedStatus }) => {
      return {
        ...state,
        customerNotifiedStatus
      };
    }
  ),
);
