import {createReducer, on} from '@ngrx/store';
import {UserActions} from '.';
import {IMapUserOptions} from './interfaces/IUserMap';


export interface UserState {
  error: Error,
  userMap: IMapUserOptions,
  googlePlaceApiKey: string,
  addressDetails: {
    state: string,
    pincode: unknown,
    city: string
  }
}

export const initialState: UserState = {
  error: null,
  userMap: null,
  googlePlaceApiKey: 'AIzaSyAXV6COhVSE0E_EfbtG7OKVmFMeghkI8To',
  addressDetails:{
    state:'',
    pincode:null,
    city:''
  }
};

export const UserReducers = createReducer(
  initialState,
  on(UserActions.getUserMapSuccess, (state, {userMap}) => {

    return {
      ...state,
      userMap: userMap
    };
  }),
  on(UserActions.getAddressDetailsSuccess, (state, {addressDetails}) => {
    return {
      ...state,
      addressDetails:addressDetails
    };
  }),
);
