<div class="user-profile">
  <a (click)="onProfileClick($event)" href="#" id="sidebar-profile-button" pRipple>
    <div>
      @if (!app.isSlim()) {
        <p-avatar
          [style]="{ 'background-color': '#2196F3', color: '#ffffff','border-radius': '50%' }"
          label=""
          shape="circle"
          size="xlarge"
          styleClass="mr-2"
        >{{ this.app?.roleAlphabet }}
        </p-avatar
        >
      }
      @if (app.isSlim()) {
        <p-avatar
          [style]="{ 'background-color': '#2196F3', color: '#ffffff','border-radius': '50%' }"
          label=""
          shape="circle"
          size="large"
          styleClass="mr-2"
        >{{ this.app?.roleAlphabet }}
        </p-avatar
        >
      }
    </div>
    <span class="sidebar-profile-role"
    >{{ this.app?.userDetails?.firstName }}
      {{ this.app?.userDetails?.lastName }}
        </span>
    <!-- <span class="sidebar-profile-role">Administrator</span> -->
  </a>

  <ul
    [@menu]="
        app.isSlim()
          ? app.usermenuActive
            ? 'visible'
            : 'hidden'
          : app.usermenuActive
          ? 'visibleAnimated'
          : 'hiddenAnimated'
      "
    [ngClass]="{ 'usermenu-active': app.usermenuActive }"
    class="usermenu"
    id="sidebar-usermenu"
  >
    <li
      #profile
      [ngClass]="{ 'menuitem-active': app.activeProfileItem === profile }"
    >
      <a (click)="onProfileItemClick($event, profile, 'profile');app.accountSettings()" href="#">
        <i class="pi pi-fw pi-user"></i>
        <span class="topbar-item-name">Profile</span>
      </a>
    </li>
    <li
      #accounts
      [ngClass]="{ 'menuitem-active': app.activeProfileItem === accounts }"
    >
      <a (click)="onProfileItemClick($event, accounts, 'accounts')"
         href="#"
         pRipple
      >
        <i class="pi pi-fw pi-cog"></i>
        <span class="topbar-item-name">Accounts</span>
        <i class="layout-menuitem-toggler pi pi-fw pi-angle-down"></i>
        <p-confirmPopup key="profile-popup"></p-confirmPopup>
      </a>
      <ul>
        @for (item of accountsList; track item) {
          <li
            [ngClass]="{ 'active-menuitem': activeId === item['_id'] }"
            role="menuitem"
          >
            <a (click)="app.onTopbarSubItemClick($event, 'accounts', item['_id'],'profile-popup')"
               [ngClass]="{ 'active-menuitem': activeId === item['_id'] }"
               href="#"
               pRipple
            >
              <i class="pi pi-fw pi-id-card"></i>
              <span>{{ item.name }}</span>
            </a>
          </li>
        }
        @if (showAccountLevelSettings()) {
          <li
            [ngClass]="{ 'active-menuitem':app.accountSettingsTabActive  }"
            role="menuitem"
          >
            <a (click)="app.accountSettings()"
               [ngClass]="{ 'active-menuitem': app.accountSettingsTabActive}"
               pRipple
            >
              <i class="pi pi-fw pi-cog"></i>
              <span>Settings</span>
            </a>
          </li>
        }
      </ul>
    </li>
    <li
      #notifications
      [ngClass]="{
          'menuitem-active': app.activeProfileItem === notifications
        }"
    >
      <a (click)="onProfileItemClick($event, notifications, 'notification'); app.onRightPanelButtonClick($event)"
         href="#"
         pRipple
      >
        <i class="pi pi-fw pi-bell"></i>
        <span class="topbar-item-name">Notifications</span>
      </a>
    </li>
    <li
      #logout
      [ngClass]="{ 'menuitem-active': app.activeProfileItem === logout }"
    >
      <a (click)="onProfileItemClick($event, logout, 'logout')" href="#" pRipple>
        <i class="pi pi-fw pi-sign-out"></i>
        <span class="topbar-item-name">Logout</span>
      </a>
    </li>
  </ul>
</div>
