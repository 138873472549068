<div class="custom-modal-dialog"></div>
<p-sidebar
  class="info-prime-sidebar"
  position="right"
  (onShow)="isSidebarClosed = false"
  (onHide)="closePopup()"
  [fullScreen]="(sidebarIsFullScreen$ | async)"
  [(visible)]="isCurrentIdActive"
  [dismissible]="false"
>
  <ng-template pTemplate="header">
    <div class="custom-modal-header">
      <h5 class="custom-modal-title uppercase" translate>@if (!isEventSelectedToEdit) {
        <span>Add</span>
      } {{ formHeaderLabel }}</h5>
    </div>
  </ng-template>
  <div
    class="custom-modal create-modal"
    [ngStyle]="{'display':'block'}"
    [ngClass]="{'custom-modal-view':true}"
    [id]="sidebarId"
  >
    <div class="custom-modal-content">
      <div class="custom-modal-body" >
        <app-static-data-dynamic-form
          [formConfigData]="formConfigData"
          (formSubmitHandler)="formSubmitHandler($event)"
          [resetFormData]="resetForm"
          [isEditForm]="isEventSelectedToEdit"
          [eventToEdit]="eventToEdit"
          (assetRegistrationErrorMsg)="assetRegistrationErrorMsg($event)"
        ></app-static-data-dynamic-form>
      </div>
    </div>
  </div>
</p-sidebar>
