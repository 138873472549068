<div class="wrapper">
  <p-splitter gutterSize="10" [style]="{ height: '85vh' }" (onResizeEnd)="dragEnd()" layout="vertical">
    <ng-template pTemplate>
      <p-dialog [position]="'center'" [transitionOptions]="'250ms'" [dismissableMask]="true"
                [(visible)]="deliveryTempModal" (onShow)="onDialogShow()" (onHide)="onDialogHide()" [modal]="true"
                [style]="{'width': '95vw', 'height':'95vh'}">
        <ng-template pTemplate="header">
          <div class="grid mx-0 flex align-items-center">
          <h5 class="m-0">{{header?.title?.value ? header.title.value  : 'Info'}}</h5>
            @if (header?.subTitle?.value) {
              <span style="margin:7px;" class="subTitle" [pTooltip]="header?.subTitle?.tooltip"
                    tooltipPosition="bottom">{{ header?.subTitle?.value }}</span>
            }
            @if (header?.orderTooltip?.tooltip) {
              <i class="pi pi-info-circle info-icon" [pTooltip]="header?.orderTooltip?.tooltip" tooltipPosition="bottom"
                 style="font-size: 15px; margin-left: 5px"></i>
            }
          </div>
        </ng-template>
        <div class="panel-container">
          @if (deliveryTempModal) {
            <div class="map-container">

              <div class="map-container__icons">
                <p-button class="exterLink-btn" (click)="toggleMapDialogVisibility()" icon="pi pi-window-minimize"
                  pTooltip="Minimize map" tooltipPosition="left" />
                </div>
                <div class="map-container__addditionBtns">
                  <p-speedDial [model]="items" direction="right" showIcon="pi pi-chevron-right" hideIcon="pi pi-chevron-left"
                    hideOnClickOutside="false">
                    <ng-template pTemplate="item" let-item>
                      @if(item?.icon === 'play'){
                      <ng-container *ngTemplateOutlet="playPath" />
                      }
                      @if(item?.icon === 'geofenceAndEventsList'){
                      <ng-container *ngTemplateOutlet="geofenceAndEventsList" />
                      }
                      @if((item?.icon === 'locationUpdateToggleButton') && accessLocationUpdate){
                      <ng-container *ngTemplateOutlet="locationUpdateToggleButton" />
                      }
                      @if(item?.icon === 'pointsOnRouteToggleButon'){
                      <ng-container *ngTemplateOutlet="pointsOnRouteToggleButon" />
                      }
                    </ng-template>
                  </p-speedDial>
              </div>

            <div class="map-frame" style="display: flex;align-items:center;justify-content: center;" >
              <div (leafletMapReady)="onMapReady($event)" [leafletLayers]="markers"
                   [leafletOptions]="options"
                   id="map"
                   leaflet>
                @if (legendList.length > 0 && displayPills) {
                  <div class="legend-containers">
                    <ul>
                      @for (legend of legendList; track legend?.label) {
                        <li (click)="legendClicked(legend);"
                            [ngStyle]="{'opacity': legend.isActive ? 1 : 0.4}"
                            class="legend-list-container cursorPointer">
                          <div class="legend" [ngStyle]="{'background-color': legend.color}"></div>
                          <span>{{ legend.label }}</span>
                        </li>
                      }
                    </ul>
                  </div>
                }
              </div>
            </div>
          </div>
          }
        </div>
      </p-dialog>
      <div class="panel-container">
        @if (!deliveryTempModal) {
          <div class="map-container">
            <div class="map-container__icons">
              <p-button class="exterLink-btn" (click)="toggleMapDialogVisibility()" icon="pi pi-window-maximize"
                        pTooltip="Expand map" tooltipPosition="left"/>
            </div>
            <div class="map-container__addditionBtns">
              <p-speedDial [model]="items" direction="right" showIcon="pi pi-chevron-right" hideIcon="pi pi-chevron-left"
                hideOnClickOutside="false">
                <ng-template pTemplate="item" let-item>
                  @if(item?.icon === 'play'){
                  <ng-container *ngTemplateOutlet="playPath" />
                  }
                  @if(item?.icon === 'geofenceAndEventsList'){
                  <ng-container *ngTemplateOutlet="geofenceAndEventsList" />
                  }
                  @if((item?.icon === 'locationUpdateToggleButton') && accessLocationUpdate){
                  <ng-container *ngTemplateOutlet="locationUpdateToggleButton" />
                  }
                  @if(item?.icon === 'pointsOnRouteToggleButon'){
                  <ng-container *ngTemplateOutlet="pointsOnRouteToggleButon" />
                  }
                </ng-template>
              </p-speedDial>
            </div>
            <div class="map-frame">
              <div (leafletMapReady)="onMapReady($event)" [leafletLayers]="markers"
                   [leafletOptions]="options"
                   id="map"
                   leaflet>
                  @if(loaderForSidebarGraph){
                  <app-circular-loader></app-circular-loader>
                  
                  }
                @if (legendList.length > 0 && displayPills) {
                  <div class="legend-containers">
                    <ul>
                      @for (legend of legendList; track legend?.label) {
                        <li (click)="legendClicked(legend);"
                            [ngStyle]="{'opacity': legend.isActive ? 1 : 0.4}"
                            class="legend-list-container cursorPointer">
                          <div class="legend" [ngStyle]="{'background-color': legend.color}"></div>
                          <span>{{ legend.label }}</span>
                        </li>
                      }
                    </ul>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </ng-template>
    <ng-template pTemplate>
      <div>


        <div class="journey-chart-container" (dblclick)="rightSideBarModalClicked()">
          <p-button class="outsideButton" (click)="rightSideBarModalClicked()" icon="pi pi-external-link"
            [disabled]="boxStats.showLoader"
                    pTooltip="Expand" tooltipPosition="bottom"></p-button>
          <!-- <p-dialog header="" [position]="'center'" [transitionOptions]="'250ms'" [dismissableMask]="true" [(visible)]="chartModalOpen"  [modal]="true"
          [style]="{width: '85vw'}">

          <app-car-status-graph [isSmallEChart]="false" [loader]="boxStats.showLoader" [chartData]="eChartGraphData" [show]="boxStats.show" [isDataZoomRequired]='true' [isTotalNotRequired]='true'></app-car-status-graph>

        </p-dialog> -->

          <app-car-status-graph [chartData]="eChartGraphData" [forceChartHeightInPx]="'350px'"
                                [isDataZoomRequired]='true'
                                [isSmallEChart]="false" [isTotalNotRequired]='true' [loader]="boxStats.showLoader"
                                [showTitleWithCounts]="true" [show]="boxStats.show"
                                (sendDataToPlotMarker)="sendDataToPlotMarker($event)"
                                [updateValue]="graphUpdateValue"></app-car-status-graph>

        </div>

      </div>
    </ng-template>
  </p-splitter>

  <p-toast position="top-center"></p-toast>
</div>

@if (showLoader) {
  <div>
    <app-loader></app-loader>
  </div>
}
<ng-template #pointsOnRouteToggleButon>
  <p-toggleButton [ngClass]="{
    'points-on-routes-btn-active': enableRoutePoints(),
    'points-on-routes-btn-inactive': !enableRoutePoints(),
    'points-on-routes':true
    }"
     [(ngModel)]="enableRoutePoints"  class="material-icons"
    (ngModelChange)="togglePointsOnRoute($event)"
    [pTooltip]="!enableRoutePoints() ? 'Show all actual route points' : 'Hide all actual route points'">
    <ng-template pTemplate="icon">
      <div class="icon-container">
        <div class="circle">
          
        </div>
      </div>
    </ng-template>
  </p-toggleButton>
</ng-template>

<ng-template #playPath>

     <p-splitButton 
    (onClick)="togglePathPlay(!playActualPath())"
    (onDropdownClick)="onPlayPathDropDownClick(op)"
    [pTooltip]="playActualPath() ? 'Pause' : 'Play'"
    [ngClass]="{'enabled-split-btn': playActualPath()}"
  >
    <ng-template pTemplate="icon">
      <i class="pi play-pause-icon" [ngClass]="{
        'pi-pause': playActualPath(),
        'pi-play': !playActualPath()
        }"></i>
        <span class="position-me">{{(4*speedMultiplicator())+'x'}}</span>
    </ng-template>
    <ng-template pTemplate="dropdownicon">
      <i [ngClass]="
        this.dataForGraph?.length ?
        (showPlayActualPanel() ? 'pi pi-angle-up' : 'pi pi-angle-down') : ''
        "></i>
    </ng-template>
  </p-splitButton>        
            
  
</ng-template>
<p-overlayPanel styleClass="play-path-panel" #op>
 <div class="container">
  
  <div class="play-path-slider">
    <span>{{'Speed :'}}</span>
  <p-selectButton 
    [options]="stateOptions" 
    [allowEmpty]="false"
    [(ngModel)]="speedMultiplicator"
 />
  </div>
  <div class="play-path-buttons">
    <span>Restart:</span>
    <i class="pi pi-refresh cursorPointer" (click)="restartPlay()"></i>
  </div>
 </div>
</p-overlayPanel>

<ng-template #locationUpdateToggleButton>
  @if (accessLocationUpdate) {
    <p-toggleButton
      [ngClass]="{
        'enable-loaction-update-btn-active': enableLocationUpdate,
        'enable-loaction-update-btn-inactive': !enableLocationUpdate,
        'enable-loaction-update-btn':true}"
      [onIcon]="'pi pi-pencil'"
      [offIcon]="'pi pi-pencil'"
      [(ngModel)]="enableLocationUpdate"
      (ngModelChange)="toggleLocationUpdatePermission($event)"
      [pTooltip]="enableLocationUpdate ? 'Click to Disable Edit Locations' : 'Click to Enable Edit Locations'">
    </p-toggleButton>
  }
</ng-template>

<ng-template #geofenceAndEventsList>
  <div style="display: flex;gap:0.25rem">
  <p-splitButton
    (onClick)="removeGeoFences()"
    (onDropdownClick)="showGeofencesListData()"
    [ngClass]="{'enabled-split-btn': showGeofences}"
  >
    <ng-template pTemplate="icon">
      <span class="material-icons material-icons-size">adjust</span>
    </ng-template>
    <ng-template pTemplate="dropdownicon">
      <i [ngClass]="showGeofencesList ? 'pi pi-angle-up' : 'pi pi-angle-down'"></i>
    </ng-template>
  </p-splitButton>

  <p-splitButton
    (onClick)="removeEvents()"
    (onDropdownClick)="showEventsListData()"
    [ngClass]="{'enabled-split-btn': showEvents}"
  >
    <ng-template pTemplate="icon">
      <span class="material-icons material-icons-size">event</span>
    </ng-template>
    <ng-template pTemplate="dropdownicon">
      <i [ngClass]="showEventsList ? 'pi pi-angle-up' : 'pi pi-angle-down'"></i>
    </ng-template>
  </p-splitButton>
</div>
  @if ((showGeofencesList || showEventsList)) {
    <div
      [ngClass]="{'events-geofence-list-opened': (showEventsList || showGeofencesList),'events-geofence-list-collapse': !(showEventsList || showGeofencesList)}"
      class="events-geofence-list"
    >
      <!-- <div *ngIf="showDelLocationsList">
      <div class="list-search-bar">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText [(ngModel)]="searchDelLocation" placeholder="Delivery Location">
        </span>
      </div>
      <div *ngFor="let delLocation of mapMarkers | deliveryLocationFilterPipe: searchDelLocation">
        <div (click)="delLocationCardClick(delLocation)"
        [ngClass]="{'focused-card': (
          delLocation?.pickupLocationId ?
          focusedDelLocation?.pickupLocationId===delLocation?.pickupLocationId :
          focusedDelLocation?.deliveryCenterId===delLocation?.deliveryCenterId
          )}"
          class="events-geofence-list-card">
          <p>{{delLocation?.pickupLocationName || delLocation?.deliveryCenterName}}</p><br>
        </div>
      </div>
    </div> -->
      @if ((showEventsList || showGeofencesList)) {
        <div class="list-search-bar">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          @if (showEventsList) {
            <input
              [disabled]="!eventsToPlotCopy?.length || !eventsToPlot?.length"
              type="text"
              pInputText
              [(ngModel)]="searchEvent"
              placeholder="Event"/>
          }
          @if (showGeofencesList) {
            <input
              [disabled]="!geoFences?.length"
              type="text"
              pInputText
              [(ngModel)]="searchGeoFence"
              placeholder="Geo-Fence"/>
          }
        </span>
      </div>
      }
      <div class="list-card-container">
        @for (event of (showEventsList ? (eventsToPlotCopy?.length ? eventsToPlotCopy : eventsToPlot) : []) | eventsFilterPipe :
        searchEvent; track event?.description) {
          <div>
            <div
              (click)="eventCardClick(event)"
              [ngClass]="{'focused-card': focusedEvent?._id===event?._id}"
              class="events-geofence-list-card"
              [pTooltip]="event?.description" tooltipPosition="left">
              <p>{{ event?.description }}</p>
            </div>
          </div>
        }
        @if (showEventsList && (!eventsToPlotCopy?.length || !eventsToPlot?.length)) {
          <div>
            <div
              class="events-geofence-list-card"><p>No Events</p></div>
          </div>
        }
        @for (geofence of (showGeofencesList ? geoFences : []) | geoFenceFilterPipe: searchGeoFence; track geofence?.label) {
          <div>
            <div (click)="geofenceCardClick(geofence)"
                 [ngClass]="{'focused-card': focusedGeoFence?._id===geofence?._id}"
                 class="events-geofence-list-card">
              <p>{{ geofence?.label }}</p><br>
              <span>Radius : {{ geofence?.radius }}</span>
            </div>
          </div>
        }
        @if (!geoFences?.length && showGeofencesList) {
          <div>
            <div
              class="events-geofence-list-card"><p>No Geo-Fence</p></div>
          </div>
        }
      </div>
    </div>
  }
  </ng-template>

@if (accessLocationUpdate && enableLocationUpdate) {
  <p-dialog class="edit-radius-dialog"
            [header]="'Geo-Fence Details' + ' (' + (updateGeoFenceRadiusData?.label)+ ')'"
            [(visible)]="geoFenceFormDialog" [style]="{ width: 'fit-content' }"
            (onShow)="closePopups()">
    <div>
      <div class='geo-fence-edit-input'>
        <div class='geo-fence-edit-title'>
          <p>Radius:</p>
        </div>
        <div class='geo-fence-radius-input'>
            <span class="full-width">
              <p-inputNumber id="number-input" [(ngModel)]="geoFenceRadius"></p-inputNumber>
            </span>
        </div>
        <div class='geo-fence-dialog-buttons'>
          <p-button (click)="geoFenceFormDialog=false" icon="pi pi-times"
                    styleClass="p-button-rounded p-button-danger p-button-outlined"></p-button>
          <p-button (click)="updateGeoFenceRadius()" icon="pi pi-check"
                    styleClass="p-button-rounded confirm-button"></p-button>
        </div>
      </div>
    </div>
  </p-dialog>
}
