import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { currentActiveId, selectIsSidebarFullScreen} from '../../../state/Sidebar/sidebar.selector';
import { CONSTANT } from '../../../config/constant';
declare let $: any;

const SIDEBAR_ID = CONSTANT.SIDEBAR_IDS.STATIC_DATA_DYNAMIC_FORM;

@Component({
  selector: 'app-static-data-dynamic-form-modal',
  templateUrl: './static-data-dynamic-form-modal.component.html',
  styleUrls: ['./static-data-dynamic-form-modal.component.scss']
})
export class StaticDataDynamicFormModalComponent implements OnInit, OnChanges, OnDestroy {
  sidebarId = SIDEBAR_ID;
   currentActiveSidebarId$;
  isSidebarClosed= true;
  sidebarIsFullScreen$ = this.store.select(selectIsSidebarFullScreen);
  @Input() isEventSelectedToEdit: boolean;
  @Input() eventToEdit: any;
  @Input() formConfigData: any;
  @Input() resetForm: number;
  @Output() assetRegistrationErrorMsgToAssetList: EventEmitter<string> = new EventEmitter();
  @Output() createModalClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() formSubmitTrigger: EventEmitter<any> = new EventEmitter();
  @Output() resetOnModelClosed: EventEmitter<any> = new EventEmitter();
  public showCircularLoader: boolean;

  @Input() formHeaderLabel: string;
  isCurrentIdActive = false;

  constructor(private store: Store) { }


  ngOnInit(): void {
    this.currentActiveSidebarId$ = this.store.select(currentActiveId).subscribe(res =>{
      if(res === this.sidebarId)
      this.isCurrentIdActive = true;
      else
      this.isCurrentIdActive = false;
    })
  }

  ngOnDestroy(){
    this.currentActiveSidebarId$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'resetForm') {
        if (change.currentValue !== change.previousValue) {
          this.showCircularLoader = true;
          // this.renderForm();
        }
      }
      if (propName === 'eventToEdit') {
        if (change.currentValue !== change.previousValue) {
          this.resetForm = Math.random();
        }
      }
    }
  }
  renderForm() {
    /*if ( this.formConfiguration && this.formConfiguration['label'] ) {
      this.formLabel = this.formConfiguration['label'];
      this.formDataForRendering = this.formService.formatAssetConfig(this.formData);
      this.showCircularLoader = false;
    }*/
  }
  closePopup() {
    // $('html').css('overflow-y', 'auto');
    if(!this.isSidebarClosed){
      this.createModalClosedEvent.emit(true);
      this.resetOnModelClosed.emit(this.resetForm);
    }
    this.isSidebarClosed = true;
    this.resetForm = Math.random();
    // this.formHeaderLabel = '';
  }

  assetRegistrationErrorMsg(errMsg: string) {
    this.assetRegistrationErrorMsgToAssetList.emit(errMsg);
  }
  formSubmitHandler(event: any) {
    // endTime: {hour: 19, minute: 0, second: 0}
    this.formSubmitTrigger.emit(event);
  }

}
