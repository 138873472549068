import { createAction, props } from "@ngrx/store";
import { IetaResponse } from "./interfaces/IetaResponse";

export const getETACalculationRequired = createAction(
    '[Effect API] fetch isETACalculationRequired api',
    props<{moduleType : string}>()
)

export const getETACalculationRequiredSuccess = createAction(
    '[Effect API] on success isETACalculationRequired',
    props<{response : IetaResponse,moduleType : string}>()
)
export const ETACalculationRequiredFailureMethod = createAction(
    '[Effect API] on failure ETACalculationRequired',
    props<{error : Error}>()
)

export const updateETACalculationRequired = createAction(
    '[Load ETA required API] fetch updateETACalculationRequired',
    props<{payload,moduleType : string}>()
)

export const updateETACalculationRequiredSuccess = createAction(
    '[Effect API] on success updateETACalculationRequired',
    props<{response : IetaResponse,moduleType : string}>()
)