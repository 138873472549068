import {HttpClient, HttpHeaders, HttpParams, HttpParamsOptions,} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ConfigService} from "./config.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AccountsService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  getLinkedAccounts(): Observable<Array<{
    _id: string
    name: string
    title: string
  }>> {
    const token = sessionStorage.getItem("token");
    if (token) {
      const headers = new HttpHeaders().set("Authorization", token);

      const params: any = {fI: "name,title"};

      const httpParams: HttpParamsOptions = {
        fromObject: params,
      } as HttpParamsOptions;

      const options = {params: new HttpParams(httpParams), headers: headers};

      return this.http.get<Array<{
        _id: string
        name: string
        title: string
      }>>(
        this.configService.appConfig.appBaseUrl + "user/self/profile/accounts",
        options
      );
    }
  }

  getActiveAccount() {
    const token = sessionStorage.getItem("token");

    if (token) {
      const headers = new HttpHeaders().set("Authorization", token);

      const options = {headers: headers};

      return this.http.get(
        this.configService.appConfig.appBaseUrl +
        "user/self/profile/active/account/",
        options
      );
    }
  }

  switchAccount(id: string) {
    const token = sessionStorage.getItem("token");

    if (token) {
      const headers = {Authorization: token};
      const body = {accountId: id};

      return this.http.put(
        this.configService.appConfig.appBaseUrl + "user/self/profile/account/",
        body,
        {headers}
      );
    }
  }

  getThemeConfig() {
    return this.http.get(`${this.configService.appConfig.appBaseUrl}accountUISettings/id`)
  }

  sendThemeConfig(themObj) {
    return this.http.put(`${this.configService.appConfig.appBaseUrl}accountUISettings/id`, themObj)
  }

  getRoleInfo() {
    return this.http.get(`${this.configService.appConfig.appBaseUrl}user/self/profile/ability`)
  }

  getActiveProfile() {
    return this.http.get(`${this.configService.appConfig.appBaseUrl}user/self/profile/active/account/`)
  }
}

