@if (!formFormAdmin) {
  <div class="custom-modal-dialog"></div>
}
@if (!formFormAdmin) {
  <p-sidebar
    class="info-prime-sidebar"
    position="right"
    (onShow)="isSidebarClosed = false"
    (onHide)="closeBtnClicked()"
    [fullScreen]="(sidebarIsFullScreen$ | async)"
    [(visible)]="isCurrentIdActive"
    [dismissible]="false"
  >
    <ng-template pTemplate="header">
      <div class="custom-modal-header">
        <h5 class="custom-modal-title uppercase" translate>app.authorization.add-user-asset-auth</h5>
      </div>
    </ng-template>
    <div
      class="custom-modal create-modal"
      [ngStyle]="{'display':'block'}"
      [ngClass]="{'custom-modal-view':true}"
      [id]="sidebarId"
    >
      <div class="custom-modal-content">
        <div class="custom-modal-body" >
          <div>
            <div class="grid">
              <div class="form-group my-3 py-2 col-12 md:col-6 lg:col-6 xl:col-6">
                <span class="p-float-label">
                  <input type="text" class="full-width" id="basicInput" pInputText disabled="true"
                         placeholder="{{'Account' | translate}}" [(ngModel)]="accountName">
                  <label for="basicInput">{{ 'Account' | translate }}</label>
                </span>
              </div>
              <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6">
                <span class="p-float-label dropdow-container full-width">
                  <p-multiSelect inputId="userDropdown" [options]="usersForSelectedAcc" [(ngModel)]="_form.userIds"
                                 optionLabel="firstName" optionValue="_id" placeholder="Select user"
                                 [disabled]="roleAssetAuthIdToEdit"></p-multiSelect>
                  <label for="userDropdown">{{ 'User' | translate }}</label>
                </span>
              </div>
              <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6">
                <span class="p-float-label dropdow-container">
                  <p-dropdown inputId="assetTypeDropdown" [autoDisplayFirst]="false"
                              [options]="accountLinkedAssetList | orderBy: 'label'"
                              [(ngModel)]="selectedAsset" [ngModelOptions]="{standalone: true}"
                              (ngModelChange)="setSelectedAssetType($event);"
                              placeholder="Select asset type">
                    <ng-template pTemplate="selectedItem" class="p-2">
                      <div>
                        <div>{{ selectedAsset?.label | translate }}</div>
                      </div>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                      <div class="dropown-customize" class="p-3">
                        <div><label>{{ option?.label | translate }}</label></div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <label for="assetTypeDropdown">{{ 'Asset Type' | translate }}</label>
                </span>
              </div>
              <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6"></div>
              @for (val of _selectAssetTypes; track val) {
                <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6">
                  @if (val !== 'city' && val !== 'product') {
                    <span class="p-float-label dropdow-container">
                      <p-multiSelect [inputId]="val" [options]="_selectAssetTypesObj[val] | orderBy: 'label'" [(ngModel)]="_form.assets[val]"
                                     optionLabel="name" optionValue="_id" placeholder="Select"></p-multiSelect>
                      <label [for]="val">{{getLabel(val) | translate}}</label>
                    </span>
                  }
                  @if (val === 'city') {
                    <span class="p-float-label dropdow-container">
                      <p-multiSelect [inputId]="val" [options]="_selectAssetTypesObj[val] | orderBy: 'city'" [(ngModel)]="_form.assets[val]"
                                     optionLabel="city" optionValue="_id" placeholder="Select"></p-multiSelect>
                      <label [for]="val">{{getLabel(val) | translate}}</label>
                    </span>
                  }
                  @if (val === 'product') {
                    <span class="p-float-label dropdow-container">
                      <p-multiSelect [inputId]="val" [options]="_selectAssetTypesObj[val] | orderBy: 'itemName'" [(ngModel)]="_form.assets[val]"
                                     optionLabel="itemName" optionValue="_id" placeholder="Select"></p-multiSelect>
                      <label [for]="val">{{getLabel(val) | translate}}</label>
                    </span>
                  }
                </div>
              }
            </div>
            <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6 linked-asset-checkobox">
              <p-checkbox [(ngModel)]="_form.showLinkedAssets" [binary]="true"></p-checkbox>
              <p>Add Linked Assets</p>
            </div>
            @if (!roleAlreadyHaveAssetFilter) {
              <button pButton type="button" label="{{'sys.submit' | translate}}" [disabled]="submitDisabled()"
                class="p-button-sm" (click)="save()">
              </button>
            }
            @if (roleAlreadyHaveAssetFilter) {
              <button pButton type="button" label="{{'sys.update' | translate}}" [disabled]="submitDisabled()"
                class="p-button-sm" (click)="update()">
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>
}

@if (formFormAdmin) {
  <div>
    <div class="grid">
      <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6">
        <!-- <span class="p-float-label dropdow-container">
        <p-dropdown inputId="assetTypeDropdown" [autoDisplayFirst]="false" [options]="accountLinkedAssetList | orderBy: 'name'"
          [(ngModel)]="selectedAsset" [ngModelOptions]="{standalone: true}" (ngModelChange)="setSelectedAssetType($event);"
          optionLabel="name"
        placeholder="Select asset type"></p-dropdown>
        <label for="assetTypeDropdown">{{'Asset Type' | translate}}</label>
      </span> -->
      <span class="p-float-label dropdow-container">
        <p-dropdown inputId="assetTypeDropdown" [autoDisplayFirst]="false" [options]="accountLinkedAssetList | orderBy: 'label'"
                    [(ngModel)]="selectedAsset" [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="setSelectedAssetType($event);"
                    placeholder="Select asset type">
          <ng-template pTemplate="selectedItem" class="p-2">
            <div>
              <div>{{ selectedAsset?.label | translate }}</div>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="dropown-customize" class="p-3">
              <div><label>{{ option?.label | translate }}</label></div>
            </div>
          </ng-template>
        </p-dropdown>
        <label for="assetTypeDropdown">{{'Asset Type' | translate}}</label>
      </span>
    </div>
    <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6"></div>
      @for (val of _selectAssetTypes; track val; let i = $index) {
        <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6">
          @if (val !== 'city' && val !== 'product') {
            <span class="p-float-label dropdow-container">
            <p-multiSelect [inputId]="val[0]" [options]="_selectAssetTypesObj[val] | orderBy: 'label'"
                           [(ngModel)]="_form.assets[val]"
            optionLabel="name" optionValue="_id" placeholder="Select"></p-multiSelect>
            <label [for]="val[0]">{{ getLabel(val) | translate }}</label>
          </span>
          }
          @if (val === 'city') {
            <span class="p-float-label dropdow-container">
            <p-multiSelect [inputId]="val[1]" [options]="_selectAssetTypesObj[val] | orderBy: 'city'"
                           [(ngModel)]="_form.assets[val]"
            optionLabel="city" optionValue="_id" placeholder="Select"></p-multiSelect>
            <label [for]="val[1]">{{ getLabel(val) | translate }}</label>
          </span>
          }
          @if (val === 'product') {
            <span class="p-float-label dropdow-container">
            <p-multiSelect [inputId]="val[2]" [options]="_selectAssetTypesObj[val] | orderBy: 'itemName'"
                           [(ngModel)]="_form.assets[val]"
            optionLabel="itemName" optionValue="_id" placeholder="Select"></p-multiSelect>
            <label [for]="val[2]">{{ getLabel(val) | translate }}</label>
          </span>
          }
        </div>
      }
  </div>
  <div class="form-group my-3 py-2 ol-sm-12 md:col-6 lg:col-6 xl:col-6 linked-asset-checkobox">
    <p-checkbox [(ngModel)]="_form.showLinkedAssets" [binary]="true"></p-checkbox>
    <p>Add Linked Assets</p>
  </div>
  <button pButton type="button" label="{{'sys.update' | translate}}" [disabled]="adminSubmitDisabled()" class="p-button-sm" (click)="update()">
  </button>
</div>
}

@if (showCircularLoader) {
  <app-circular-loader></app-circular-loader>
}
