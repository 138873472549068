<div class="assetListGrid P10" id="groupListingPopup">
  <div class="grid mx-0 my-1 py-1 justify-content-start align-items-center">
    <!--  Search Box-->
    <div class="input-outer-grid lg:col-6">
      <div class="input-icon-wrapper PR">
        <input type="text" placeholder="{{'sys.search-group' | translate}}"
               class="form-control" [autocomplete]="false" name="searchForAsset"
               [(ngModel)]="searchForGroup" (ngModelChange)="searchGroup()"/>
        <span><i class="fa fa-search"></i></span>
      </div>
    </div>
  </div>

  <div class="grid mx-0 my-1 mt-2 userManagement-cards-container">
    @for (group of groups; track group) {
      <div class="details-card-deck userManagement-card-deck col-12 md:col-6 align-items-center">
        <app-group-card [groupData]="group" [fieldsToBeDisplayed]="fieldsToBeDisplayed" [isCardClickable]="false"
                        [canAddToAsset]="true"
                        [cardLabel]="'label'" (linkGroupDetails)="linkGroupDetails($event)"></app-group-card>
      </div>
    }
    @if (groups.length < 1) {
      <div class="col-12">
        <div class="grid align-items-center justify-content-center" style="height:100%">
          <h3 class="asset-not-found text-center"><span>&ldquo;</span>{{ 'sys.groups-not-found' | translate }}<span>&rdquo;</span>
          </h3>
        </div>
      </div>
    }


  </div>

  <div class="grid mx-0 py-1 mt-3">
    <div class="col-12">
      <div class="grid mx-0 my-2 py-1 justify-content-start">
        @if (selectedGroupIdsToAdd.length > 0) {
          <button pButton pRipple color="accent" type="button"
                  class="linkBtn defaultButtonColor showAddGroupPopupBtn buttonEnable mx-1"
          ><i class="fa fa-link"
              aria-hidden="true"></i> {{ selectedGroupIdsToAdd.length === 1 ? getLangLabel('sys.add-group') : getLangLabel('sys.add-groups') }}
          </button>
        }
        <button pButton pRipple id="groupListBackBtn" class="mx-1 closePopUpBtn"
                (click)="resetSearchedGroups()">{{ 'sys.cancel' |translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="grid">
    @if (groups.length > 0) {
      <div class="md:col-12  col-12 flex justify-content-center MT20">
        @if (_groupPaginationFields.pagination.totalNoOfRecords > 0) {
          <mat-paginator #paginator [length]="_groupPaginationFields.pagination.totalNoOfRecords"
                         [showFirstLastButtons]="true" (page)="getAllGroups($event)" [pageIndex]="0"
                         [pageSize]="_groupPaginationFields.pagination.recordsPerPage">
          </mat-paginator>
        }
        <mat-form-field color="accent" style="text-align: center" class="matPagination">
          <mat-select placeholder="{{'sys.selectpage' | translate}}" [(ngModel)]="_groupPaginationFields.pageNumber"
                      (ngModelChange)="updatePageNumber($event)">
            @for (pageNo of _groupPaginationFields.noOfPagesList; track pageNo) {
              <mat-option [value]="pageNo">{{ pageNo }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
  </div>

  @if (showLoader) {
    <div>
      <app-circular-loader></app-circular-loader>
    </div>
  }
</div>
