import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;
import { PluginManagementServiceService } from '../../../services/plugin-management-service.service';

import { EventManagementService } from '../../../services/eventManagement.service';

@Component({
  selector: 'app-notification-event',
  templateUrl: './notification-event.component.html',
  styleUrls: ['./notification-event.component.scss']
})
export class NotificationEventComponent implements OnInit, OnChanges {

  notificationEventFormData: any;
  notificationEventConfig: any;
  allEventTypes: any;
  notificationEventstype = [];
  resetAddAssetFormValue: number;
  @Input() moduleName: string;
  @Input() isEventSelectedToEdit: boolean;
  @Input() isCloneBtnReq: boolean;
  @Input() showUpdateBtn: boolean;
  @Input() resetFormValue: number;
  @Input() selectedEventType: any;
  @Input() isEventManagement: boolean = true;
  @Input() selectedAccountId :string
  @Input() eventToEdit: any;
  @Output() formSubmitTrigger: EventEmitter<any> = new EventEmitter();
  @Output() resetOnModelClosedToParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private eventManagementService: EventManagementService,
    private pluginManagementService: PluginManagementServiceService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'selectedEventType') {
        if (change.currentValue !== change.previousValue) {
          if (this.selectedEventType) {
            this.notificationTypeToAdd( this.selectedEventType);
          }
        }
      }
    }
  }

  notificationTypeToAdd(notificatinConfigSelected) {
    /* const filteredArray = _.filter(this.assetCountByType, function(item) {
     return item['label'] === 'Car';
     });
     this.selectedAssetConfigDetails = filteredArray[0];
     this.resetAddAssetFormValue = Math.random(); */
    let eventTypeSelected;
    if (notificatinConfigSelected.type) {
       eventTypeSelected = notificatinConfigSelected.type;
    } else if (notificatinConfigSelected.eventType) {
       eventTypeSelected = notificatinConfigSelected.eventType;
    }
    this.getFormByModuleAndEventType( this.moduleName, eventTypeSelected)
      .then((eventConfigurationWithForm) => {
        this.notificationEventFormData = eventConfigurationWithForm['formFields'];
        this.notificationEventConfig = eventConfigurationWithForm['configuration'];
        this.resetAddAssetFormValue = Math.random();
      });
  }

  getFormByModuleAndEventType( _moduleName: string, eventType: string) {
    if(this.isEventManagement){
      return new Promise((resolve) => {
        this.eventManagementService.getFormByModuleAndType( _moduleName, eventType )
          .subscribe( formDataForEventType => {
            if ( formDataForEventType ) {
              resolve(formDataForEventType);
            } else {
              // this.allEventTypes = [];
            }
          });
      });
    }
    else{
      return new Promise((resolve)=>{
        this.pluginManagementService.getPluginToEdit(_moduleName, eventType).subscribe((formDataForPlugIn)=>{
          if ( formDataForPlugIn ) {
            resolve(formDataForPlugIn);
          } else {
            // this.allEventTypes = [];
          }
        })
      })
    }
  }

  onNotificationEventSubmit( eventObject: any) {
    this.formSubmitTrigger.emit(eventObject);
  }

  resetOnModelClosed(resetValue) {
    this.resetOnModelClosedToParent.emit(resetValue);
  }

}
