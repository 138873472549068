import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class FaceDetectionService {

  constructor(private http: HttpClient, public configService: ConfigService) { }

  getFaceMatchCardsBasedOnRange(skip, limit, faceMask) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'faceDetection/?skip=' + skip + '&limit=' + limit;
    if (faceMask) {
      queryUrl = queryUrl + '&faceMask=' + faceMask;
    }
    return this.http.get(queryUrl);
  }

  getFrameForFaceCard(id) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'faceDetection/image/' + id + '/frame');
  }

  getFaceMatchCardsCount(faceMask?) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'faceDetection/count';
    if (faceMask) {
      queryUrl = queryUrl + '?faceMask=' + faceMask;
    }
    return this.http.get(queryUrl);
  }

  getFaceMatchCountsWithFilter(startDate?, endDate?, faceMask?, camera?: string) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'faceDetection/count?';
    if (startDate) {
      queryUrl = queryUrl + 'startDate=' + startDate;
    }
    if (endDate) {
      queryUrl = queryUrl + '&endDate=' + endDate;
    }
    if (camera) {
      queryUrl = queryUrl + '&camera=' + camera;
    }
    if (faceMask) {
      queryUrl = queryUrl + '&faceMask=' + faceMask;
    }
    return this.http.get(queryUrl);
  }

  getFaceMatchCardsBasedOnRangeWithFilters(skip, limit, faceMask?, startDate?, endDate?, camera?: string) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'faceDetection';
    queryUrl += '?skip=' + skip + '&limit=' + limit;
    if (faceMask) {
      queryUrl = queryUrl + '&faceMask=' + faceMask;
    }
    if (startDate) {
      queryUrl = queryUrl + '&startDate=' + startDate;
    }
    if (endDate) {
      queryUrl = queryUrl + '&endDate=' + endDate;
    }
    if (camera) {
      queryUrl = queryUrl + '&camera=' + camera;
    }
    return this.http.get(queryUrl);
  }

  getAllFaceMatchCardsDataOnSearch(searchtext) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'faceDetection/search/asset/';
    if (searchtext.length > 0) {
      queryUrl += searchtext;
    }
    return this.http.get(queryUrl);
  }

  getImages(id, type) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'faceDetection/image/' + id + '/' + type, { responseType: 'blob' });
  }

  getFaceDetectionImages(id, type) {
    /*const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    return this.configService.appConfig.appBaseUrl + 'faceDetection/image/' + id + '/' + type + '?token=' + token;*/
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    return this.configService.appConfig.appBaseUrl + 'image/faceDetection/' + id + '/' + type + '?token=' + token;
  }

}
