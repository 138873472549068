import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class PluginManagementServiceService {

  constructor(private http: HttpClient, private configService: ConfigService) { }


  getAllPlugins( module: string ) {
    return this.http.get(this.configService.appConfig.appBaseUrl + `pluginMeta/allTypes?module=${module}`);
  }
  getPluginToEdit(module: any, id: any){
    return this.http.get(this.configService.appConfig.appBaseUrl + `pluginMeta/form/module/${module}/type/${id}

    `)
  }

  getAllEvents(){
    // return this.http.get(`http://10.10.10.127:4500/api/pluginManager/all`);

    return this.http.get(this.configService.appConfig.appBaseUrl + `pluginManager/`)
    
  }
  getEventById(id){
    return this.http.get(this.configService.appConfig.appBaseUrl + `pluginManager/${id}`)
  }

  submitPlugin(formdata){
      return this.http.post(this.configService.appConfig.appBaseUrl + `pluginManager/`, formdata)
  }

  updatePlugin(id, formdata){
      return this.http.put(this.configService.appConfig.appBaseUrl + `pluginManager/${id}`, formdata)
  }
  
  deletePlugin(id){
    return this.http.delete(this.configService.appConfig.appBaseUrl + `pluginManager/plugin/${id}`)
  }

  getAllMetaDataTogether(payments:string){
    return this.http.get(this.configService.appConfig.appBaseUrl + `pluginMeta?categories=`+payments)
  }

}
