import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../../services/config.service';
import {SearchService} from '../../services/search.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public iframeSrcUrl: string;
  public token: string;
  public theme: string;
  public showMetabase: boolean;
  constructor(public sanitizer: DomSanitizer, private configService: ConfigService,  private searchService: SearchService) {
    this.showMetabase = false;
    const METABASE_SITE_URL = this.configService.appConfig.dashboardUrl;
    if (METABASE_SITE_URL !== 'https://dtc.iotzen.app') {
      this.showMetabase = true;
    } else {
      this.showMetabase = false;
    }
    const superAdminToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjJ9LCJwYXJhbXMiOnt9LCJpYXQiOjE1NTU2NjIxOTF9.0uovIU3ucWlRLcsgFavBI2H3AjhVH27Yx74PQQrAXPU';
    const operatorToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjN9LCJwYXJhbXMiOnt9LCJpYXQiOjE1NTU2NjI0NDJ9.hbYfy3TuW3Un-DsCDPNue2yleXS9Sa3F2iIPaNWII6o';
    this.theme = '';
    // const user = JSON.parse((sessionStorage.getItem('userRoles')));
    const dashboard = JSON.parse((sessionStorage.getItem('dashboard')));
    /*if ((user[0]) === 'superadmin') {
      this.token = superAdminToken;
    } else if ((user[0]) === 'admin') {
      this.token = adminToken;
    } else if ((user[0]) === 'tracker') {
      this.token = trackerToken;
    } else {
      this.token = operatorToken;
    }*/
    if (dashboard && dashboard === 1) {
      this.token = superAdminToken;
    } else if (dashboard && dashboard === 3) {
      this.token = operatorToken;
    } else {
      this.token = operatorToken;
    }
    if (sessionStorage.getItem('theme')) {
      if (sessionStorage.getItem('theme') === 'dark-theme') {
        this.theme = 'theme=night&';
      } else {
        this.theme = '';
      }
    }
    this.iframeSrcUrl = METABASE_SITE_URL + '/embed/dashboard/' + this.token + '#' + this.theme + 'bordered=true&titled=true';
  }

  ngOnInit() {
    this.searchService.showSearchBar(false);
  }
}
