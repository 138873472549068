import { createAction, props } from "@ngrx/store";

export const changeSubscriptionParmaters = createAction(
  "[reports subscription] change reports subscription parmaters",
  props<{moduleType: string;event;assetType: string;report: string;apiKey: string;}>()
);


export const onSuccessOfChangeSubscriptionParmaters = createAction(
  "[reports subscription] change reports subscription parmaters success",
  props<{moduleType: string;changedParmaters:any}>()
);

export const subscribeToReport = createAction(
  "[reports subscription] subscribe to report",
  props<{parameters:any;moduleType: string}>()
);

export const editReportSubscription = createAction(
  "[reports subscription] edit report subscription",
  props<{parameters:any;moduleType: string;subscriptionId: string}>()
);

export const onSuccessOfReportSubscription = createAction(
  "[reports subscription] subscribe to report success"
);

export const getSubscriptions = createAction(
  "[reports subscription] get subscriptions by report",
  props<{reportKey?: string;moduleType: string;}>()
);

export const onGettingSubscriptions = createAction(
  "[reports subscription] get subscriptions by report success",
  props<{reportSubscriptions: [];moduleType: string;}>()
);

export const removeSubscription = createAction(
  "[reports subscription] remove subscription",
  props<{subscriptionId: string;moduleType: string}>()
);